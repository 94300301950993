import { KEY_GOOGLE_ANA } from './constants';
/*Google analytics*/
import ReactGA from 'react-ga4';
class GoogleAnalytics {
  static sendInformation(path) {
    ReactGA.initialize(KEY_GOOGLE_ANA, { debug: false });
    //console.log(ReactGA);
    // ReactGA.pageview(path);
  }
}

export default GoogleAnalytics;
