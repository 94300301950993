class ExportHelper {
  static PARAMS_LIST = [
    'indicators',
    'regions',
    'years',
    'collection',
    'titleText',
    'subtitleText',
    'chartTypesOptions',
    'chartGuidesOption',
    'chartColorsOption',
    'chartLegendOption',
    'text',
  ];

  static PARSE_PARAMS_DICT = {
    indicators: true,
    regions: true,
    years: true,
    chartTypesOptions: true,
    text: true,
  };

  static PARSE_INT_PARAMS_LIST = {
    collection: true,
  };

  static createElementFromDataSelection(selection) {}

  static validateExportParams(params) {
    //console.log(params);
    const validationResult = ExportHelper.PARAMS_LIST.reduce((accum, curr) => {
      let paramValue = params.get(curr);
      //console.log("paramsValue", paramValue);
      //console.log("acum", accum);
      if (!!accum && paramValue !== null) {
        if (curr in ExportHelper.PARSE_PARAMS_DICT) {
          try {
            //accum[curr] = paramValue;
            //console.log("el curr", curr);
            //console.log(paramValue);
            let paramParsed = paramValue;
            if(curr != 'text'){
              paramParsed = decodeURIComponent(paramValue);
            }
            
            console.log("Hice el decodeURIB")
            accum[curr] = JSON.parse(paramParsed);
          } catch (error) {
            console.log('error haceindo parse del paramvalue', error);
            return null;
          }
        } else if (curr in ExportHelper.PARSE_INT_PARAMS_LIST) {
          try {
            // accum[curr] = paramValue;
            accum[curr] = parseInt(paramValue);
          } catch (error) {
            console.log('error haciendo el parse to int', error);
            return null;
          }
        } else {
          accum[curr] = paramValue;
        }

        return accum;
      } else {
        console.log('error param values es null o acum no es valido en conversion', curr);
        return null;
      }
    }, {});
    // console.log('validationResult', validationResult);
    return validationResult;
  }

  // static getDataUrl(element) {
  //   // switch (libraryToUse) {
  //   //   case 'domtoimage':
  //   //     return generateDataUrlWithDomToImage(element);
  //   //   case 'html2canvas':
  //   //     return generateDataUrlWithHtml2Canvas(element);
  //   // }
  //   return generateDataUrlWithDomToImage(element);
  // }

  // static getBlob(element) {
  //   return new Promise((resolve, reject) => {
  //     domtoimage
  //       .toBlob(element)
  //       .then((blob) => {
  //         resolve(blob);
  //       })
  //       .catch((error) => {
  //         console.error('oops, something went wrong!', error);
  //         reject();
  //       });
  //   });
  // }

  // static getExportData(element) {
  //   return new Promise((resolve, reject) => {
  //     Promise.all([
  //       ExportHelper.getDataUrl(element),
  //       ExportHelper.getBlob(element),
  //     ])
  //       .then((results) => {
  //         console.log('results', results);
  //         resolve({
  //           dataUrl: results[0],
  //           blob: results[1],
  //         });
  //       })
  //       .catch((error) => {
  //         console.error('oops, something went wrong!', error);
  //         reject();
  //       });
  //   });
  // }
}

export default ExportHelper;
