import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { PATHS_HIDE_HEADER, headerModes } from './shared/constants';
import { useMediaQuery } from './hooks/useMediaQuery';
import { HEADER_MODE_SET } from './actions/types';
import store from './store';
import { useSelector } from 'react-redux';
import { ReactComponent as Twitterx } from "./img/twitter-x-icon.svg";

function Header() {
  const ref = useRef();
  const [rootElementDisplay, setRootElementDisplay] = useState('block');
  
  const [visible, setVisible] = React.useState(false);

  let location = useLocation();
  let isMobileSite = useMediaQuery('(max-width: 991px)');

  /* Acceso al modo del header */
  const selectHeaderMode = (state) => state.headerMode;
  const headerMode = useSelector(selectHeaderMode);

  /**
   * Efecto para determinar si el encabezado se despliega o se oculta
   */
  useEffect(() => {
    const pathAnalysis = location.pathname.match(/(\/\w+)/g);
    if (
      !!pathAnalysis &&
      pathAnalysis.length &&
      pathAnalysis[0] in PATHS_HIDE_HEADER
    ) {
      setRootElementDisplay('none');
      return;
    }
    setRootElementDisplay('block');
  }, [location]);

  useEffect(() => {
    const headerMode = isMobileSite
      ? headerModes.COLLAPSED
      : headerModes.COMPLETE;

    store.dispatch({
      type: HEADER_MODE_SET,
      payload: headerMode,
    });
  }, [isMobileSite]);

  useEffect(() => {
    if (headerMode === headerModes.COLLAPSED) {
      ref.current.classList.remove(headerModes.COMPLETE);
      ref.current.classList.add(headerModes.COLLAPSED);
    } else {
      ref.current.classList.remove(headerModes.COLLAPSED);
      ref.current.classList.add(headerModes.COMPLETE);
    }
  }, [headerMode]);

  const onHeaderDotsClick = () => {
    store.dispatch({
      type: HEADER_MODE_SET,
      payload: headerModes.COMPLETE,
    });
  };

  const onScrollMock = () => {
    store.dispatch({
      type: HEADER_MODE_SET,
      payload: headerModes.COLLAPSED,
    });
  };

  const getHeaderContentComplete = () => {
    return (
      <>
        <div className="nav-top">
          <div className="row" onClick={onScrollMock}>
            <nav className="col-lg-8">
              <li
                id="menu-item-119"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-119"
              >
                <a title="						" href="https://estadonacion.or.cr/quienes-somos/">
                  Sobre Nosotros
                </a>
              </li>
              <li
                id="menu-item-130"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-130"
              >
                <a href="https://estadonacion.or.cr/proyectos-estadonacion/">
                  ¿Qué hacemos?
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu-item-2804"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2804"
                  >
                    <a href="https://estadonacion.or.cr/proyectos-estadonacion/">
                      Estado de la Nación
                    </a>
                  </li>
                  <li
                    id="menu-item-2768"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2768"
                  >
                    <a href="https://estadonacion.or.cr/proyectos-estado-de-la-region/">
                      Estado de la Región
                    </a>
                  </li>
                  <li
                    id="menu-item-2769"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2769"
                  >
                    <a href="https://estadonacion.or.cr/proyectos-estado-de-la-educacion/">
                      Estado de la Educación
                    </a>
                  </li>
                  <li
                    id="menu-item-2770"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2770"
                  >
                    <a href="https://estadonacion.or.cr/proyectos-estado-de-la-justicia/">
                      Estado de la Justicia
                    </a>
                  </li>
                  <li
                    id="menu-item-2767"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2767"
                  >
                    <a href="https://estadonacion.or.cr/proyectos-hipatia/">
                      HIPATIA
                    </a>
                  </li>
                </ul>
              </li>
              <li
                id="menu-item-120"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120"
              >
                <a title="						" href="https://estadonacion.or.cr/contacto/">
                  Contáctenos
                </a>
              </li>
            </nav>
            <div className="col-lg-4 text-right d-none d-lg-block">
              <a
                className="link-menu"
                target="_blank"
                href="https://twitter.com/EstadoNacion"
              >

                <Twitterx className="twitter-x-icon-14" />
                
              </a>
              <a
                className="link-menu"
                target="_blank"
                href="https://www.facebook.com/EstadoNacion"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="link-menu"
                target="_blank"
                href="https://www.linkedin.com/company/programa-estado-de-la-naci%C3%B3n-pen/"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                className="link-menu"
                target="_blank"
                href="https://www.instagram.com/programaestadonacion/"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className="nav-top__dots" onClick={onHeaderDotsClick}></div>
        </div>
        <div className="nav-main">
          <div className="logo">
            <a href="https://estadonacion.or.cr">
              <img
                alt="Logo de PEN"
                data-src="https://estadonacion.or.cr/wp-content/themes/pen/public-build/img/logo.svg"
                className=" ls-is-cached lazyloaded"
                src="https://estadonacion.or.cr/wp-content/themes/pen/public-build/img/logo.svg"
              />
              <noscript>
                <img
                  alt="Logo de PEN"
                  data-src="https://estadonacion.or.cr/wp-content/themes/pen/public-build/img/logo.svg"
                  className="lazyload"
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                />
              </noscript>
              <noscript>
                <img
                  src="https://estadonacion.or.cr/wp-content/themes/pen/public-build/img/logo.svg"
                  alt="Logo de PEN"
                />
              </noscript>
            </a>
          </div>
          <div className="nav-main__contact">
            <a href="#">
              <i className="icon-mail"></i>
            </a>
            <a href="#">
              <i className="icon-phone"></i>
            </a>
            <a href="https://www.facebook.com/EstadoNacion">
              <i className="icon-facebook"></i>
            </a>
          </div>
          <div className="nav-main__items">
            <nav>
              <a href="https://estadonacion.or.cr/">Inicio</a>
              <a title="						" href="https://estadonacion.or.cr/herramientas/">
                Herramientas
              </a>
              <a title="						" href="https://estadonacion.or.cr/blog/">
                Blog
              </a>
            </nav>
            <nav style={{ width: '100%' }}>
              <a
                title="						"
                href="https://estadonacion.or.cr/informes/"
                style={{ opacity: 1, display: 'block' }}
              >
                Informes
              </a>
              <a
                href="http://estadisticas.estadonacion.or.cr"
                aria-current="page"
                style={{ opacity: 1, display: 'block' }}
              >
                Estadisticas
              </a>
              <a
                title="						"
                href="https://estadonacion.or.cr/investigaciones/"
                style={{ opacity: 1, display: 'block' }}
              >
                Investigaciones
              </a>
              <a
                href="https://estadonacion.or.cr/base-datos/"
                style={{ opacity: 1, display: 'block' }}
              >
                Bases de datos
              </a>
              <a
                href="#"
                className="nav-main__dropdown-btn"
                style={{ width: '0px', opacity: 0 }}
              >
                Menú
              </a>
            </nav>
            <button className="nav-main__mobile-icon" onClick={() => setVisible(!visible)}>Menú</button>
          </div>
          <div
            className="nav-main__dropdown"
            style={{display: visible ? 'block' : 'none'}}
          >
            <div className="shadow-soft">

              <div className="nav-main__dropdown-items container bg-white">
                <button className="close" onClick={() => setVisible(!visible)}>Cerrar</button>
                <div className="row justify-content-center">
                  <div className="mb-3 mb-lg-0 col-md-4 col-lg-3 text-center card-infografico px-2">
                    <a href="https://estadonacion.or.cr/base-datos/">
                      <div className="py-3 shadow-right">
                        {' '}
                        <div className="card-infografico__image fill">
                          <div className="card-infografico__image-inner">
                            <svg
                              width="62"
                              height="62"
                              viewBox="0 0 62 62"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0)">
                                <path
                                  d="M57.58 12C57.35 11.18 57.02 10.39 56.6 9.64L59.72 6.52L55.48 2.28L52.36 5.4C51.61 4.98 50.82 4.65 50 4.41V0H44V4.41C43.18 4.64 42.39 4.97 41.64 5.39L38.52 2.27L34.28 6.51L37.4 9.63C36.98 10.38 36.65 11.17 36.42 11.99H32V17.99H36.42C36.62 18.68 36.89 19.35 37.22 19.99H24V27.99V29.99V35.99H20V59.99H16V26H12V10H28V0H10V9V10V26H6V60H2V13H0V62H49V60H44V46H40V42H56V32H38V41V42V46H34V60H30V36H26V30H42V24.79C42.64 25.12 43.31 25.39 44 25.59V30H50V25.58C50.82 25.35 51.61 25.02 52.36 24.6L55.48 27.72L59.72 23.48L56.6 20.36C57.02 19.61 57.35 18.82 57.58 18H62V12H57.58ZM12 2H26V8H12V2ZM40 34H54V40H40V34ZM8 28H14V60H8V28ZM36 48H42V60H36V48ZM28 60H22V38H28V60ZM40 28H26V22H40V28ZM60 16H55.98L55.82 16.8C55.59 17.93 55.15 18.99 54.51 19.96L54.06 20.64L56.9 23.48L55.49 24.89L52.65 22.05L51.97 22.5C51 23.14 49.94 23.58 48.81 23.81L48 23.98V28H46V23.98L45.2 23.82C44.07 23.59 43.01 23.15 42.04 22.51L42 22.48V20H39.43L39.49 19.96C38.85 18.99 38.41 17.93 38.18 16.8L38.02 16H34V14H38.02L38.18 13.2C38.41 12.07 38.85 11.01 39.49 10.04L39.94 9.36L37.1 6.51L38.51 5.1L41.35 7.94L42.03 7.49C43 6.85 44.06 6.41 45.19 6.18L46 6.02V2H48V6.02L48.8 6.18C49.93 6.41 50.99 6.85 51.96 7.49L52.64 7.94L55.48 5.1L56.89 6.51L54.05 9.35L54.5 10.03C55.14 11 55.58 12.06 55.81 13.19L55.97 13.99H60V16Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M47 10C44.24 10 42 12.24 42 15C42 17.76 44.24 20 47 20C49.76 20 52 17.76 52 15C52 12.24 49.76 10 47 10ZM47 18C45.35 18 44 16.65 44 15C44 13.35 45.35 12 47 12C48.65 12 50 13.35 50 15C50 16.65 48.65 18 47 18Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M24 4H18V6H24V4Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M16 4H14V6H16V4Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M52 36H46V38H52V36Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M44 36H42V38H44V36Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M38 24H32V26H38V24Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M30 24H28V26H30V24Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M2 5.41009L6.29 9.71009L7.71 8.29009L0 0.590088V11.0001H2V5.41009Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M52.29 55.71L56.59 60H51V62H61.41L53.71 54.29L52.29 55.71Z"
                                  fill="#1362A0"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect
                                    width="62"
                                    height="62"
                                    fill="white"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                        <h3 className="font-hero-subtitulo mb-3 mt-0">
                          Base de Datos
                        </h3>
                        <p className="my-0">
                          Colección de bases de datos nuestras y de otros.
                        </p>
                      </div>{' '}
                    </a>
                  </div>
                  <div className="mb-3 mb-lg-0 col-md-4 col-lg-3 text-center card-infografico px-2">
                    <a href="https://estadonacion.or.cr/investigaciones/">
                      <div className="py-3 shadow-right">
                        {' '}
                        <div className="card-infografico__image fill">
                          <div className="card-infografico__image-inner">
                            <svg
                              width="62"
                              height="62"
                              viewBox="0 0 62 62"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M39 0C26.32 0 16 10.32 16 23C16 26.92 16.99 30.62 18.73 33.86L17 35.59L14.71 33.3L13.3 34.71L15.59 37L14 38.59L11.71 36.3L10.3 37.71L12.59 40L1.95 50.64C0.69 51.89 0 53.56 0 55.34C0 59.01 2.99 62 6.66 62C8.44 62 10.11 61.31 11.37 60.05L22 49.41L24.29 51.7L25.7 50.29L23.41 48L25 46.41L27.29 48.7L28.7 47.29L26.41 45L28.14 43.27C31.38 45.01 35.08 46 39 46C51.68 46 62 35.68 62 23C62 10.32 51.68 0 39 0ZM9.95 58.64C9.07 59.52 7.9 60 6.66 60C4.09 60 2 57.91 2 55.34C2 54.1 2.48 52.93 3.36 52.05L14 41.41L20.59 48L9.95 58.64ZM22 46.59L15.41 40L17 38.41L23.59 45L22 46.59ZM25 43.59L18.41 37L19.78 35.63C21.51 38.25 23.75 40.49 26.37 42.22L25 43.59ZM39 44C28.44 44 19.7 36.16 18.24 26H25.51L29.98 19.74L38.98 32.74L48.96 18.76L52.46 24H56V22H53.54L49.03 15.24L39.01 29.26L30.01 16.26L24.49 24H18.06C18.03 23.67 18 23.34 18 23C18 22.66 18.03 22.33 18.05 22H20V20H18.24C19.7 9.84 28.44 2 39 2C50.24 2 59.42 10.89 59.95 22H58V24H59.95C59.92 24.68 59.86 25.34 59.76 26H56V28H59.37C57.12 37.17 48.85 44 39 44Z"
                                fill="#03579A"
                              ></path>
                              <path
                                d="M45.0004 5C42.7904 5 41.0004 6.79 41.0004 9C41.0004 9.8 41.2404 10.54 41.6504 11.17L37.1904 17.42L38.8204 18.58L43.1504 12.52C43.7004 12.82 44.3304 13 45.0004 13C47.2104 13 49.0004 11.21 49.0004 9C49.0004 6.79 47.2104 5 45.0004 5ZM45.0004 11C43.9004 11 43.0004 10.1 43.0004 9C43.0004 7.9 43.9004 7 45.0004 7C46.1004 7 47.0004 7.9 47.0004 9C47.0004 10.1 46.1004 11 45.0004 11Z"
                                fill="#03579A"
                              ></path>
                              <path
                                d="M54 26H52V28H54V26Z"
                                fill="#03579A"
                              ></path>
                              <path
                                d="M24 20H22V22H24V20Z"
                                fill="#03579A"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <h3 className="font-hero-subtitulo mb-3 mt-0">
                          Investigaciones
                        </h3>
                        <p className="my-0">
                          Repositorio de investigaciones de base y muchos más.
                        </p>
                      </div>{' '}
                    </a>
                  </div>
                  <div className="mb-3 mb-lg-0 col-md-4 col-lg-3 text-center card-infografico px-2">
                    <a href="https://estadonacion.or.cr/informes/">
                      <div className="py-3 shadow-right">
                        {' '}
                        <div className="card-infografico__image fill">
                          <div className="card-infografico__image-inner">
                            <svg
                              width="62"
                              height="62"
                              viewBox="0 0 62 62"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M50 13V10H53C57.96 10 62 5.96 62 1V0H57C52.54 0 48.84 3.27 48.13 7.53C47.08 6.17 45.65 5.11 44 4.53V0H9.59L0 9.59V48H38.8C39.69 49.14 40.76 50.15 42 50.94V52H40V54H42V56H40V58H42V62H56V58H58V56H56V54H58V52H56V50.94C59.72 48.56 62 44.42 62 40C62 33.17 56.7 27.56 50 27.05V25H53C57.96 25 62 20.96 62 16V15H57C54.17 15 51.65 16.31 50 18.36V14V13ZM57 17H59.93C59.44 20.39 56.52 23 53 23H50.07C50.56 19.61 53.48 17 57 17ZM57 2H59.93C59.44 5.39 56.52 8 53 8H50.07C50.56 4.61 53.48 2 57 2ZM47.93 12H45C41.48 12 38.56 9.39 38.07 6H41C44.52 6 47.44 8.61 47.93 12ZM9 3.41V9H3.41L9 3.41ZM2 46V11H11V2H42V4.06C41.67 4.02 41.34 4 41 4H36V5C36 8.91 38.51 12.24 42 13.48V19.06C41.67 19.02 41.34 19 41 19H36V20C36 24.14 38.82 27.63 42.64 28.67C38.68 30.91 36 35.14 36 40C36 42.12 36.54 44.17 37.5 46H2ZM50.6 43.66L50 43.92V52H48V43.92L47.4 43.66C45.94 43.02 45 41.59 45 40C45 37.79 46.79 36 49 36C51.21 36 53 37.79 53 40C53 41.59 52.06 43.02 50.6 43.66ZM45 27C41.48 27 38.56 24.39 38.07 21H41C44.52 21 47.44 23.61 47.93 27H45ZM43.5 49.52C40.11 47.55 38 43.9 38 40C38 34.27 42.4 29.56 48 29.05V34.09C45.17 34.57 43 37.03 43 40C43 42.16 44.16 44.13 46 45.19V52H44V49.81L43.5 49.52ZM54 56H52V58H54V60H44V58H50V56H44V54H54V56ZM60 40C60 43.9 57.89 47.55 54.5 49.52L54 49.81V52H52V45.19C53.84 44.13 55 42.15 55 40C55 37.03 52.83 34.57 50 34.09V29.05C55.6 29.56 60 34.27 60 40ZM48 22.36C46.96 21.08 45.58 20.09 44 19.53V13.95C44.33 13.99 44.66 14.01 45 14.01H48V22.36Z"
                                fill="#1362A0"
                              ></path>
                              <path
                                d="M4 44H34V26H4V44ZM6 28H32V42H6V28Z"
                                fill="#1362A0"
                              ></path>
                              <path
                                d="M10 30H8V32H10V30Z"
                                fill="#1362A0"
                              ></path>
                              <path
                                d="M30 30H12V32H30V30Z"
                                fill="#1362A0"
                              ></path>
                              <path
                                d="M30 34H8V36H30V34Z"
                                fill="#1362A0"
                              ></path>
                              <path
                                d="M30 38H28V40H30V38Z"
                                fill="#1362A0"
                              ></path>
                              <path
                                d="M26 38H8V40H26V38Z"
                                fill="#1362A0"
                              ></path>
                              <path d="M15 4H13V6H15V4Z" fill="#1362A0"></path>
                              <path d="M34 4H17V6H34V4Z" fill="#1362A0"></path>
                              <path d="M34 8H13V10H34V8Z" fill="#1362A0"></path>
                              <path
                                d="M34 12H13V14H34V12Z"
                                fill="#1362A0"
                              ></path>
                              <path
                                d="M34 16H4V18H34V16Z"
                                fill="#1362A0"
                              ></path>
                              <path
                                d="M30 20H4V22H30V20Z"
                                fill="#1362A0"
                              ></path>
                              <path
                                d="M34 20H32V22H34V20Z"
                                fill="#1362A0"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <h3 className="font-hero-subtitulo mb-3 mt-0">
                          Informes
                        </h3>
                        <p className="my-0">
                          Explore todos los informes generados por el PEN.
                        </p>
                      </div>{' '}
                    </a>
                  </div>
                  <div className="mb-3 mb-lg-0 col-md-4 col-lg-3 text-center card-infografico px-2">
                    <a href="https://estadonacion.or.cr/estadisticas/">
                      <div className="py-3 shadow-right">
                        {' '}
                        <div className="card-infografico__image fill">
                          <div className="card-infografico__image-inner">
                            <svg
                              width="62"
                              height="62"
                              viewBox="0 0 62 62"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0)">
                                <path
                                  d="M57.58 12C57.35 11.18 57.02 10.39 56.6 9.64L59.72 6.52L55.48 2.28L52.36 5.4C51.61 4.98 50.82 4.65 50 4.41V0H44V4.41C43.18 4.64 42.39 4.97 41.64 5.39L38.52 2.27L34.28 6.51L37.4 9.63C36.98 10.38 36.65 11.17 36.42 11.99H32V17.99H36.42C36.62 18.68 36.89 19.35 37.22 19.99H24V27.99V29.99V35.99H20V59.99H16V26H12V10H28V0H10V9V10V26H6V60H2V13H0V62H49V60H44V46H40V42H56V32H38V41V42V46H34V60H30V36H26V30H42V24.79C42.64 25.12 43.31 25.39 44 25.59V30H50V25.58C50.82 25.35 51.61 25.02 52.36 24.6L55.48 27.72L59.72 23.48L56.6 20.36C57.02 19.61 57.35 18.82 57.58 18H62V12H57.58ZM12 2H26V8H12V2ZM40 34H54V40H40V34ZM8 28H14V60H8V28ZM36 48H42V60H36V48ZM28 60H22V38H28V60ZM40 28H26V22H40V28ZM60 16H55.98L55.82 16.8C55.59 17.93 55.15 18.99 54.51 19.96L54.06 20.64L56.9 23.48L55.49 24.89L52.65 22.05L51.97 22.5C51 23.14 49.94 23.58 48.81 23.81L48 23.98V28H46V23.98L45.2 23.82C44.07 23.59 43.01 23.15 42.04 22.51L42 22.48V20H39.43L39.49 19.96C38.85 18.99 38.41 17.93 38.18 16.8L38.02 16H34V14H38.02L38.18 13.2C38.41 12.07 38.85 11.01 39.49 10.04L39.94 9.36L37.1 6.51L38.51 5.1L41.35 7.94L42.03 7.49C43 6.85 44.06 6.41 45.19 6.18L46 6.02V2H48V6.02L48.8 6.18C49.93 6.41 50.99 6.85 51.96 7.49L52.64 7.94L55.48 5.1L56.89 6.51L54.05 9.35L54.5 10.03C55.14 11 55.58 12.06 55.81 13.19L55.97 13.99H60V16Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M47 10C44.24 10 42 12.24 42 15C42 17.76 44.24 20 47 20C49.76 20 52 17.76 52 15C52 12.24 49.76 10 47 10ZM47 18C45.35 18 44 16.65 44 15C44 13.35 45.35 12 47 12C48.65 12 50 13.35 50 15C50 16.65 48.65 18 47 18Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M24 4H18V6H24V4Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M16 4H14V6H16V4Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M52 36H46V38H52V36Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M44 36H42V38H44V36Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M38 24H32V26H38V24Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M30 24H28V26H30V24Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M2 5.41009L6.29 9.71009L7.71 8.29009L0 0.590088V11.0001H2V5.41009Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M52.29 55.71L56.59 60H51V62H61.41L53.71 54.29L52.29 55.71Z"
                                  fill="#1362A0"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect
                                    width="62"
                                    height="62"
                                    fill="white"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                        <h3 className="font-hero-subtitulo mb-3 mt-0">
                          Estadística
                        </h3>
                        <p className="my-0">
                          Genere sus propios gráficos a partir de nuestros
                          compendios.
                        </p>
                      </div>{' '}
                    </a>
                  </div>
                </div>
              </div>
              <div className="nav-main__search">
                <h3>
                  O consulte un tema de interés por medio de palabras claves
                </h3>
                <form className="input-group p-r input-search search-general">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="EXPLORE UN TEMA DE INTERÉS"
                    aria-label="tema"
                    aria-describedby="tema"
                    required=""
                  />
                  <button
                    type="submit"
                    className="input-search__submit align-Y p-a"
                  ></button>
                </form>
                <button className="close" onClick={() => setVisible(!visible)}>Cerrar</button>
              </div>
            </div>
          </div>
          <div
            className="nav-main__mobile"
            style={{ top: '154.188px', height: '821.812px' }}
          >
            <div className="nav-main__mobile-items">
              <nav className="nav-main__mobile-first">
                <a href="https://estadonacion.or.cr/">Inicio</a>
                <a title="						" href="https://estadonacion.or.cr/herramientas/">
                  Herramientas
                </a>
                <a title="						" href="https://estadonacion.or.cr/blog/">
                  Blog
                </a>
              </nav>
              <nav className="nav-main__mobile-second">
                <a title="						" href="https://estadonacion.or.cr/informes/">
                  Informes
                </a>
                <a
                  href="https://estadonacion.or.cr/estadisticas/"
                  aria-current="page"
                >
                  Estadisticas
                </a>
                <a title="						" href="https://estadonacion.or.cr/investigaciones/">
                  Investigaciones
                </a>
                <a href="https://estadonacion.or.cr/base-datos/">
                  Bases de datos
                </a>
              </nav>
              <div className="nav-main__mobile-after">
                <button
                  className="nav-main__mobile-btn"
                  data-toggle="modal"
                  data-target="#navModal"
                ></button>
              </div>
            </div>
            <div className="nav-main__mobile-half">
              <p>O consulte un tema de interés por medio de palabras claves</p>
              <form className="input-group p-r input-search search-general">
                <input
                  type="search"
                  className="form-control"
                  placeholder="EXPLORE UN TEMA DE INTERÉS"
                  aria-label="tema"
                  aria-describedby="tema"
                  required=""
                />
                <button
                  type="submit"
                  className="input-search__submit align-Y p-a"
                ></button>
              </form>
            </div>
            <div
              className="modal-nav-mobile modal fade"
              id="navModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="navModalTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-nav-mobile__content">
                    <div className="mb-3 mb-lg-0 col-md-4 col-lg-3 text-center card-infografico px-2">
                      <a href="https://estadonacion.or.cr/base-datos/">
                        <div className="py-3 shadow-right">
                          {' '}
                          <div className="card-infografico__image fill">
                            <div className="card-infografico__image-inner">
                              <svg
                                width="62"
                                height="62"
                                viewBox="0 0 62 62"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M57.58 12C57.35 11.18 57.02 10.39 56.6 9.64L59.72 6.52L55.48 2.28L52.36 5.4C51.61 4.98 50.82 4.65 50 4.41V0H44V4.41C43.18 4.64 42.39 4.97 41.64 5.39L38.52 2.27L34.28 6.51L37.4 9.63C36.98 10.38 36.65 11.17 36.42 11.99H32V17.99H36.42C36.62 18.68 36.89 19.35 37.22 19.99H24V27.99V29.99V35.99H20V59.99H16V26H12V10H28V0H10V9V10V26H6V60H2V13H0V62H49V60H44V46H40V42H56V32H38V41V42V46H34V60H30V36H26V30H42V24.79C42.64 25.12 43.31 25.39 44 25.59V30H50V25.58C50.82 25.35 51.61 25.02 52.36 24.6L55.48 27.72L59.72 23.48L56.6 20.36C57.02 19.61 57.35 18.82 57.58 18H62V12H57.58ZM12 2H26V8H12V2ZM40 34H54V40H40V34ZM8 28H14V60H8V28ZM36 48H42V60H36V48ZM28 60H22V38H28V60ZM40 28H26V22H40V28ZM60 16H55.98L55.82 16.8C55.59 17.93 55.15 18.99 54.51 19.96L54.06 20.64L56.9 23.48L55.49 24.89L52.65 22.05L51.97 22.5C51 23.14 49.94 23.58 48.81 23.81L48 23.98V28H46V23.98L45.2 23.82C44.07 23.59 43.01 23.15 42.04 22.51L42 22.48V20H39.43L39.49 19.96C38.85 18.99 38.41 17.93 38.18 16.8L38.02 16H34V14H38.02L38.18 13.2C38.41 12.07 38.85 11.01 39.49 10.04L39.94 9.36L37.1 6.51L38.51 5.1L41.35 7.94L42.03 7.49C43 6.85 44.06 6.41 45.19 6.18L46 6.02V2H48V6.02L48.8 6.18C49.93 6.41 50.99 6.85 51.96 7.49L52.64 7.94L55.48 5.1L56.89 6.51L54.05 9.35L54.5 10.03C55.14 11 55.58 12.06 55.81 13.19L55.97 13.99H60V16Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M47 10C44.24 10 42 12.24 42 15C42 17.76 44.24 20 47 20C49.76 20 52 17.76 52 15C52 12.24 49.76 10 47 10ZM47 18C45.35 18 44 16.65 44 15C44 13.35 45.35 12 47 12C48.65 12 50 13.35 50 15C50 16.65 48.65 18 47 18Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M24 4H18V6H24V4Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M16 4H14V6H16V4Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M52 36H46V38H52V36Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M44 36H42V38H44V36Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M38 24H32V26H38V24Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M30 24H28V26H30V24Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M2 5.41009L6.29 9.71009L7.71 8.29009L0 0.590088V11.0001H2V5.41009Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M52.29 55.71L56.59 60H51V62H61.41L53.71 54.29L52.29 55.71Z"
                                    fill="#1362A0"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect
                                      width="62"
                                      height="62"
                                      fill="white"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                          <h3 className="font-hero-subtitulo mb-3 mt-0">
                            Base de Datos
                          </h3>
                          <p className="my-0">
                            Colección de bases de datos nuestras y de otros.
                          </p>
                        </div>{' '}
                      </a>
                    </div>
                    <div className="mb-3 mb-lg-0 col-md-4 col-lg-3 text-center card-infografico px-2">
                      <a href="https://estadonacion.or.cr/investigaciones/">
                        <div className="py-3 shadow-right">
                          {' '}
                          <div className="card-infografico__image fill">
                            <div className="card-infografico__image-inner">
                              <svg
                                width="62"
                                height="62"
                                viewBox="0 0 62 62"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M39 0C26.32 0 16 10.32 16 23C16 26.92 16.99 30.62 18.73 33.86L17 35.59L14.71 33.3L13.3 34.71L15.59 37L14 38.59L11.71 36.3L10.3 37.71L12.59 40L1.95 50.64C0.69 51.89 0 53.56 0 55.34C0 59.01 2.99 62 6.66 62C8.44 62 10.11 61.31 11.37 60.05L22 49.41L24.29 51.7L25.7 50.29L23.41 48L25 46.41L27.29 48.7L28.7 47.29L26.41 45L28.14 43.27C31.38 45.01 35.08 46 39 46C51.68 46 62 35.68 62 23C62 10.32 51.68 0 39 0ZM9.95 58.64C9.07 59.52 7.9 60 6.66 60C4.09 60 2 57.91 2 55.34C2 54.1 2.48 52.93 3.36 52.05L14 41.41L20.59 48L9.95 58.64ZM22 46.59L15.41 40L17 38.41L23.59 45L22 46.59ZM25 43.59L18.41 37L19.78 35.63C21.51 38.25 23.75 40.49 26.37 42.22L25 43.59ZM39 44C28.44 44 19.7 36.16 18.24 26H25.51L29.98 19.74L38.98 32.74L48.96 18.76L52.46 24H56V22H53.54L49.03 15.24L39.01 29.26L30.01 16.26L24.49 24H18.06C18.03 23.67 18 23.34 18 23C18 22.66 18.03 22.33 18.05 22H20V20H18.24C19.7 9.84 28.44 2 39 2C50.24 2 59.42 10.89 59.95 22H58V24H59.95C59.92 24.68 59.86 25.34 59.76 26H56V28H59.37C57.12 37.17 48.85 44 39 44Z"
                                  fill="#03579A"
                                ></path>
                                <path
                                  d="M45.0004 5C42.7904 5 41.0004 6.79 41.0004 9C41.0004 9.8 41.2404 10.54 41.6504 11.17L37.1904 17.42L38.8204 18.58L43.1504 12.52C43.7004 12.82 44.3304 13 45.0004 13C47.2104 13 49.0004 11.21 49.0004 9C49.0004 6.79 47.2104 5 45.0004 5ZM45.0004 11C43.9004 11 43.0004 10.1 43.0004 9C43.0004 7.9 43.9004 7 45.0004 7C46.1004 7 47.0004 7.9 47.0004 9C47.0004 10.1 46.1004 11 45.0004 11Z"
                                  fill="#03579A"
                                ></path>
                                <path
                                  d="M54 26H52V28H54V26Z"
                                  fill="#03579A"
                                ></path>
                                <path
                                  d="M24 20H22V22H24V20Z"
                                  fill="#03579A"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <h3 className="font-hero-subtitulo mb-3 mt-0">
                            Investigaciones
                          </h3>
                          <p className="my-0">
                            Repositorio de investigaciones de base y muchos más.
                          </p>
                        </div>{' '}
                      </a>
                    </div>
                    <div className="mb-3 mb-lg-0 col-md-4 col-lg-3 text-center card-infografico px-2">
                      <a href="https://estadonacion.or.cr/informes/">
                        <div className="py-3 shadow-right">
                          {' '}
                          <div className="card-infografico__image fill">
                            <div className="card-infografico__image-inner">
                              <svg
                                width="62"
                                height="62"
                                viewBox="0 0 62 62"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M50 13V10H53C57.96 10 62 5.96 62 1V0H57C52.54 0 48.84 3.27 48.13 7.53C47.08 6.17 45.65 5.11 44 4.53V0H9.59L0 9.59V48H38.8C39.69 49.14 40.76 50.15 42 50.94V52H40V54H42V56H40V58H42V62H56V58H58V56H56V54H58V52H56V50.94C59.72 48.56 62 44.42 62 40C62 33.17 56.7 27.56 50 27.05V25H53C57.96 25 62 20.96 62 16V15H57C54.17 15 51.65 16.31 50 18.36V14V13ZM57 17H59.93C59.44 20.39 56.52 23 53 23H50.07C50.56 19.61 53.48 17 57 17ZM57 2H59.93C59.44 5.39 56.52 8 53 8H50.07C50.56 4.61 53.48 2 57 2ZM47.93 12H45C41.48 12 38.56 9.39 38.07 6H41C44.52 6 47.44 8.61 47.93 12ZM9 3.41V9H3.41L9 3.41ZM2 46V11H11V2H42V4.06C41.67 4.02 41.34 4 41 4H36V5C36 8.91 38.51 12.24 42 13.48V19.06C41.67 19.02 41.34 19 41 19H36V20C36 24.14 38.82 27.63 42.64 28.67C38.68 30.91 36 35.14 36 40C36 42.12 36.54 44.17 37.5 46H2ZM50.6 43.66L50 43.92V52H48V43.92L47.4 43.66C45.94 43.02 45 41.59 45 40C45 37.79 46.79 36 49 36C51.21 36 53 37.79 53 40C53 41.59 52.06 43.02 50.6 43.66ZM45 27C41.48 27 38.56 24.39 38.07 21H41C44.52 21 47.44 23.61 47.93 27H45ZM43.5 49.52C40.11 47.55 38 43.9 38 40C38 34.27 42.4 29.56 48 29.05V34.09C45.17 34.57 43 37.03 43 40C43 42.16 44.16 44.13 46 45.19V52H44V49.81L43.5 49.52ZM54 56H52V58H54V60H44V58H50V56H44V54H54V56ZM60 40C60 43.9 57.89 47.55 54.5 49.52L54 49.81V52H52V45.19C53.84 44.13 55 42.15 55 40C55 37.03 52.83 34.57 50 34.09V29.05C55.6 29.56 60 34.27 60 40ZM48 22.36C46.96 21.08 45.58 20.09 44 19.53V13.95C44.33 13.99 44.66 14.01 45 14.01H48V22.36Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M4 44H34V26H4V44ZM6 28H32V42H6V28Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M10 30H8V32H10V30Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M30 30H12V32H30V30Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M30 34H8V36H30V34Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M30 38H28V40H30V38Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M26 38H8V40H26V38Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M15 4H13V6H15V4Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M34 4H17V6H34V4Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M34 8H13V10H34V8Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M34 12H13V14H34V12Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M34 16H4V18H34V16Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M30 20H4V22H30V20Z"
                                  fill="#1362A0"
                                ></path>
                                <path
                                  d="M34 20H32V22H34V20Z"
                                  fill="#1362A0"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <h3 className="font-hero-subtitulo mb-3 mt-0">
                            Informes
                          </h3>
                          <p className="my-0">
                            Explore todos los informes generados por el PEN.
                          </p>
                        </div>{' '}
                      </a>
                    </div>
                    <div className="mb-3 mb-lg-0 col-md-4 col-lg-3 text-center card-infografico px-2">
                      <a href="https://estadonacion.or.cr/estadisticas/">
                        <div className="py-3 shadow-right">
                          {' '}
                          <div className="card-infografico__image fill">
                            <div className="card-infografico__image-inner">
                              <svg
                                width="62"
                                height="62"
                                viewBox="0 0 62 62"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M57.58 12C57.35 11.18 57.02 10.39 56.6 9.64L59.72 6.52L55.48 2.28L52.36 5.4C51.61 4.98 50.82 4.65 50 4.41V0H44V4.41C43.18 4.64 42.39 4.97 41.64 5.39L38.52 2.27L34.28 6.51L37.4 9.63C36.98 10.38 36.65 11.17 36.42 11.99H32V17.99H36.42C36.62 18.68 36.89 19.35 37.22 19.99H24V27.99V29.99V35.99H20V59.99H16V26H12V10H28V0H10V9V10V26H6V60H2V13H0V62H49V60H44V46H40V42H56V32H38V41V42V46H34V60H30V36H26V30H42V24.79C42.64 25.12 43.31 25.39 44 25.59V30H50V25.58C50.82 25.35 51.61 25.02 52.36 24.6L55.48 27.72L59.72 23.48L56.6 20.36C57.02 19.61 57.35 18.82 57.58 18H62V12H57.58ZM12 2H26V8H12V2ZM40 34H54V40H40V34ZM8 28H14V60H8V28ZM36 48H42V60H36V48ZM28 60H22V38H28V60ZM40 28H26V22H40V28ZM60 16H55.98L55.82 16.8C55.59 17.93 55.15 18.99 54.51 19.96L54.06 20.64L56.9 23.48L55.49 24.89L52.65 22.05L51.97 22.5C51 23.14 49.94 23.58 48.81 23.81L48 23.98V28H46V23.98L45.2 23.82C44.07 23.59 43.01 23.15 42.04 22.51L42 22.48V20H39.43L39.49 19.96C38.85 18.99 38.41 17.93 38.18 16.8L38.02 16H34V14H38.02L38.18 13.2C38.41 12.07 38.85 11.01 39.49 10.04L39.94 9.36L37.1 6.51L38.51 5.1L41.35 7.94L42.03 7.49C43 6.85 44.06 6.41 45.19 6.18L46 6.02V2H48V6.02L48.8 6.18C49.93 6.41 50.99 6.85 51.96 7.49L52.64 7.94L55.48 5.1L56.89 6.51L54.05 9.35L54.5 10.03C55.14 11 55.58 12.06 55.81 13.19L55.97 13.99H60V16Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M47 10C44.24 10 42 12.24 42 15C42 17.76 44.24 20 47 20C49.76 20 52 17.76 52 15C52 12.24 49.76 10 47 10ZM47 18C45.35 18 44 16.65 44 15C44 13.35 45.35 12 47 12C48.65 12 50 13.35 50 15C50 16.65 48.65 18 47 18Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M24 4H18V6H24V4Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M16 4H14V6H16V4Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M52 36H46V38H52V36Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M44 36H42V38H44V36Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M38 24H32V26H38V24Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M30 24H28V26H30V24Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M2 5.41009L6.29 9.71009L7.71 8.29009L0 0.590088V11.0001H2V5.41009Z"
                                    fill="#1362A0"
                                  ></path>
                                  <path
                                    d="M52.29 55.71L56.59 60H51V62H61.41L53.71 54.29L52.29 55.71Z"
                                    fill="#1362A0"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect
                                      width="62"
                                      height="62"
                                      fill="white"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                          <h3 className="font-hero-subtitulo mb-3 mt-0">
                            Estadística
                          </h3>
                          <p className="my-0">
                            Genere sus propios gráficos a partir de nuestros
                            compendios.
                          </p>
                        </div>{' '}
                      </a>
                    </div>
                  </div>
                  <button
                    className="modal-nav-mobile__close"
                    data-dismiss="modal"
                  >
                    <i className="icon"></i>
                    <span>Cerrar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getHeaderContent = () => {
    return getHeaderContentComplete();
  };

  return (
    <header
      className="p-f w-100 zi-1051 headerMain"
      style={{ display: rootElementDisplay }}
      ref={ref}
    >
      {getHeaderContent()}
    </header>
  );
}

export default Header;
