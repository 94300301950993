import React, { memo } from 'react';
import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilterValuesPage from './FilterValuesPage';
import { NUM_VALUES_PER_FILTER_VALUES_PAGE } from '../../shared/constants';
const { v4: uuidv4 } = require('uuid');

const settings = {
  dots: true,
  infinite: false,
  lazyLoad: true,
  speed: 500,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const numValuesPerPage = NUM_VALUES_PER_FILTER_VALUES_PAGE;

/* FILTER VALUES CAROUSEL COMPONENT */
const FilterValuesCarousel = memo(({ id, values, selectedValues }) => {
  console.log('[FilterValuesCarousel] is running', id);

  const getFilterValuesPages = () => {
    const numValues = values.length;
    // console.log('numValues', numValues);
    const numPages = Math.ceil(numValues / numValuesPerPage);
    // console.log('numPages', numPages);
    return (
      <Slider className="indicators-carousel" {...settings}>
        {[...Array(numPages).keys()].map((el) => (
          <FilterValuesPage
            id={id}
            pageInd={el}
            key={btoa(el)}
            // key={uuidv4()}
            values={values}
            selectedValues={selectedValues}
          />
        ))}
      </Slider>
    );
  };

  return (
    <Row className="indicators-carousel-container">
      <Col>{getFilterValuesPages()}</Col>
    </Row>
  );
});

export default FilterValuesCarousel;
