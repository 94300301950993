import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ChartColorsOption from './ChartColorsOption';
import {
  COOL_PALETTE_1_ID,
  COOL_PALETTE_2_ID,
  WARM_PALETTE_1_ID,
  WARM_PALETTE_2_ID,
} from '../../shared/constants';

const getColorsOptions = () => {
  const colorOptions = {};
  colorOptions[COOL_PALETTE_1_ID] = {
    id: COOL_PALETTE_1_ID,
    label: 'Fríos 1',
  };
  colorOptions[COOL_PALETTE_2_ID] = {
    id: COOL_PALETTE_2_ID,
    label: 'Fríos 2',
  };
  colorOptions[WARM_PALETTE_1_ID] = {
    id: WARM_PALETTE_1_ID,
    label: 'Cálidos 1',
  };
  colorOptions[WARM_PALETTE_2_ID] = {
    id: WARM_PALETTE_2_ID,
    label: 'Cálidos 2',
  };
  return colorOptions;
};

function ChartColorsEditorContent(props) {
  //console.log('props', props);

  const settings = {
    dots: true,
    infinite: false,
    lazyLoad: true,
    speed: 500,
    centerMode: false,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const colorsOptions = getColorsOptions();

  const [colorsOption, setColorsOption] = useState(null);

  useEffect(() => {
    const element = props.dataElementConfiguration;

    if (!!element) {
      setColorsOption(element.chart_colors_option);
    }
  }, [props]);

  const handleColorsOptionChange = (event) => {
    const selectedColorsOption = event.target.value;
    setColorsOption(selectedColorsOption);
    props.dataElementConfiguration.chart_colors_option = selectedColorsOption;
  };

  return (
    <Col>
      <Slider {...settings}>
        {Object.values(colorsOptions).map((el) => (
          <ToggleButton
            className="chart-color-option"
            type="radio"
            name="colorRadios"
            value={el.id}
            checked={!!colorsOption && el.id === colorsOption}
            onChange={handleColorsOptionChange}
          >
            <ChartColorsOption id={el.id} label={el.label} />
          </ToggleButton>
        ))}
      </Slider>
    </Col>
  );
}

export default ChartColorsEditorContent;
