import React, { memo } from 'react';
import ChartContent from './ChartContent';
import ChartLegend from './ChartLegend';
import { legendOptions } from '../../shared/constants';

/* CHART COMPONENT */
const Chart = memo(
  ({
    id,
    elementData,
    chartTypesOptions,
    chartGuidesOption,
    chartColorsOption,
    chartLegendOption,
    chartNestingLevel,
    indicatorsDict,
    adaptedData,
    visualizerMode,
    chartNestingLevelGroupingKeys,
    maxNumElems,
    yearsNestedData,
    yearsGroupingKeys,
    linesAdaptedData,
    barsAdaptedData,
    subXScaleKeys,
  }) => {
    /* MAIN */
    const getIndicatorUnit = (elementData) => elementData.indicators[0].unit;
    const getMainContent = () => {
      const chartContent = (
        <ChartContent
          id={id}
          elementData={elementData}
          chartTypesOptions={chartTypesOptions}
          chartGuidesOption={chartGuidesOption}
          chartColorsOption={chartColorsOption}
          chartNestingLevel={chartNestingLevel}
          indicatorsDict={indicatorsDict}
          adaptedData={adaptedData}
          visualizerMode={visualizerMode}
          chartNestingLevelGroupingKeys={chartNestingLevelGroupingKeys}
          maxNumElems={maxNumElems}
          yearsNestedData={yearsNestedData}
          yearsGroupingKeys={yearsGroupingKeys}
          linesAdaptedData={linesAdaptedData}
          barsAdaptedData={barsAdaptedData}
          subXScaleKeys={subXScaleKeys}
          indicatorUnit={getIndicatorUnit(elementData)}
        />
      );
      const chartLegend =
        chartLegendOption !== legendOptions.NONE ? (
          <ChartLegend elementData={elementData} colors={chartColorsOption} />
        ) : null;

      switch (chartLegendOption) {
        case legendOptions.NONE:
          return <>{chartContent}</>;
        case legendOptions.UP:
          return (
            <>
              {chartLegend}
              {chartContent}
            </>
          );
        case legendOptions.DOWN:
          return (
            <>
              {chartContent}
              {chartLegend}
            </>
          );
        default:
          return null;
      }
    };

    /* COMPONENT DEFINITION */
    return <>{getMainContent()}</>;
  },
);

export default Chart;
