export const colors = {
  regular: [
    '#990099',
    '#DC3912',
    '#FF9900',
    '#109618',
    '#3366CC',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#B82E2E',
    '#316395',
    '#994499',
    '#22AA99',
    '#AAAA11',
    '#6633CC',
    '#E67300',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
  ],
  cool1: [
    '#1F2949',
    '#418DAF',
    '#24C8BA',
    '#495784',
    '#5BC5F5',
    '#18FFEB',
    '#244E61',
    '#A8B6E3',
    '#BAE5F8',
    '#094A44',
    '#CAD1D3',
    '#24867D',
  ],
  warm1: [
    '#444444',
    '#CD8D00',
    '#F48C17',
    '#FACF01',
    '#C36209',
    '#FFF2B5',
    '#FEAF55',
    '#665B28',
    '#D3B798',
    '#B17F0E',
    '#BAA065',
    '#766B60',
  ],
  cool2: [
    '#211F49',
    '#5F5ABF',
    '#59ADFF',
    '#C7D8FF',
    '#0061C0',
    '#4154AF',
    '#6A73A1',
    '#9A96DB',
    '#0080FF',
    '#A2B2FF',
    '#617CFF',
    '#004A94',
  ],
  warm2: [
    '#48512C',
    '#D5E026',
    '#FFE76D',
    '#99A20D',
    '#6C5F1D',
    '#CAD067',
    '#558A23',
    '#E9FF00',
    '#C8AB1C',
    '#E7E7A2',
    '#C6BD8F',
    '#676B1F',
  ],
};
