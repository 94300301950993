import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PATHS_HIDE_FOOTER } from './shared/constants';

function Footer() {
  const [rootElementDisplay, setRootElementDisplay] = useState('block');
  let location = useLocation();

  const goToBegin = () => {
    console.log("Ir a inicio");
    window.parent.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * Efecto para determinar si el encabezado se despliega o se oculta
   */
  useEffect(() => {
    const pathAnalysis = location.pathname.match(/(\/\w+)/g);
    if (
      !!pathAnalysis &&
      pathAnalysis.length &&
      pathAnalysis[0] in PATHS_HIDE_FOOTER
    ) {
      setRootElementDisplay('none');
      return;
    }
    setRootElementDisplay('block');
  }, [location]);

  return (
    <footer className="mt-auto" style={{ display: rootElementDisplay }}>
      <div className="list-rs bg-gray">
        <div className="container">
          <div className="row">
            <a
              target="_blank"
              href="mailto:programa@estadonacion.or.cr"
              className="col list-rs-icon"
            >
              <i className="icon-mail"></i>
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/EstadoNacion"
              className="col list-rs-icon"
            >
              <i className="icon-facebook"></i>
            </a>
            <a
              target="_blank"
              href="tel:2519-5845"
              className="col list-rs-icon"
            >
              <i className="icon-phone"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
              <div className="logo">
                <img
                  alt="Logo de PEN"
                  data-src="https://estadonacion.or.cr/wp-content/themes/pen/public-build/img/logo.svg"
                  className=" ls-is-cached lazyloaded"
                  src="https://estadonacion.or.cr/wp-content/themes/pen/public-build/img/logo.svg"
                />
                <noscript>
                  <img
                    alt="Logo de PEN"
                    data-src="https://estadonacion.or.cr/wp-content/themes/pen/public-build/img/logo.svg"
                    className="lazyload"
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                  />
                </noscript>
                <noscript>
                  <img
                    src="https://estadonacion.or.cr/wp-content/themes/pen/public-build/img/logo.svg"
                    alt="Logo de PEN"
                  />
                </noscript>
              </div>
              <address>
                <a
                  target="_blank"
                  href="https://www.google.com/maps?ll=9.950657,-84.12463&amp;z=16&amp;t=m&amp;hl=es&amp;gl=CR&amp;mapclient=embed&amp;q=Edificio+Dr+Franklin+Chang+Diaz+Calle+Alexander+Humboldt+San+Jos%C3%A9"
                >
                  CONARE, Edificio Dr. Franklin Chang Díaz,
                  <br />
                  300 metros al norte del Parque de La Amistad.
                  <br />
                  Pavas, San José, Costa Rica. Apdo. 1174-1200
                  <br />
                </a>
                <b>
                  Tel.{' '}
                  <a target="_blank" href="tel:2519-5845">
                    2519-5845
                  </a>
                </b>
                <a target="_blank" href="tel:2519-5845">
                  <br />
                </a>
              </address>
            </div>
            <div className="col-md-6 col-lg-2 mb-3 mb-lg-0">
              <h3 className="mb-2">SOBRE NOSOTROS</h3>
              <ul className="list-bullet-blue list-clear footer__links">
                <li
                  id="menu-item-141"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-141"
                >
                  <a href="https://estadonacion.or.cr/historia/">Historia</a>
                </li>
                <li
                  id="menu-item-125"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-125"
                >
                  <a title="						" href="https://estadonacion.or.cr/quienes-somos/">
                    ¿Quiénes Somos?
                  </a>
                </li>
                <li
                  id="menu-item-2806"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2806"
                >
                  <a href="https://estadonacion.or.cr/wp-content/uploads/2019/05/Proceso-PEN.pdf">
                    ¿Cómo trabajamos?
                  </a>
                </li>
                <li
                  id="menu-item-3827"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3827"
                >
                  <a href="http://repositorio.conare.ac.cr/">
                    Repositorio CONARE
                  </a>
                </li>
              </ul>{' '}
            </div>
            <div className="col-md-6 col-lg-2 mb-3 mb-lg-0">
              <h3 className="mb-2">NUESTRO TRABAJO</h3>
              <ul className="list-bullet-blue list-clear footer__links">
                <li
                  id="menu-item-126"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-126"
                >
                  <a title="						" href="https://estadonacion.or.cr/informes/">
                    Informes
                  </a>
                </li>
                <li
                  id="menu-item-127"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-127"
                >
                  <a
                    title="						"
                    href="https://estadonacion.or.cr/investigaciones/"
                  >
                    Investigaciones
                  </a>
                </li>
                <li
                  id="menu-item-147"
                  className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-142 current_page_item menu-item-147"
                >
                  <a
                    href="https://estadonacion.or.cr/estadisticas/"
                    aria-current="page"
                  >
                    Estadisticas
                  </a>
                </li>
                <li
                  id="menu-item-146"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-146"
                >
                  <a href="https://estadonacion.or.cr/base-datos/">
                    Bases de datos
                  </a>
                </li>
              </ul>{' '}
            </div>
            <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
              <h3 className="mb-2">
                <a href="https://estadonacion.or.cr/blog/">Blog</a>
              </h3>
              <a href="https://estadonacion.or.cr/blog/">
                {/* <h3 className="mb-2">Noticias</h3> */}
              </a>
            </div>
            <a href="https://estadonacion.or.cr/blog/"></a>
          </div>
          <a href="https://estadonacion.or.cr/blog/"></a>
        </div>
        <a href="https://estadonacion.or.cr/blog/"></a>
      </div>
      <a href="https://estadonacion.or.cr/blog/"></a>
      <div className="footer-bottom">
        <a href="https://estadonacion.or.cr/blog/"></a>
        <div className="container">
          <a href="https://estadonacion.or.cr/blog/"></a>
          <div className="row justify-content-between">
            <a href="https://estadonacion.or.cr/blog/"></a>
            <div className="col-md-6 col-lg-4 px-lg-3 mb-3 mb-lg-0">
              <a href="https://estadonacion.or.cr/blog/"></a>
              <a href="/" className="m-0">
                Programa Estado de la Nación
              </a>
            </div>
            <div className="col-md-6 col-lg-4 px-lg-3">
              <nav className="nav-footer-bottom list-clear d-flex justify-content-between flex-column flex-lg-row color-white"></nav>
            </div>
            <div className="col-md-12 col-lg-4 pr-lg-5">
              <a
                onClick={goToBegin}
                className="m-0 d-flex align-items-center justify-content-end"
              >
                IR AL INICIO{' '}
                <img
                  data-src="https://estadonacion.or.cr/wp-content/themes/pen/public-build/svg/icon.svg"
                  className="ml-2 ls-is-cached lazyloaded"
                  src="https://estadonacion.or.cr/wp-content/themes/pen/public-build/svg/icon.svg"
                />
                <noscript>
                  <img
                    className="ml-2"
                    src="https://estadonacion.or.cr/wp-content/themes/pen/public-build/svg/icon.svg"
                  />
                </noscript>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
