import React, { forwardRef } from 'react';
import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SearchResultsPage from './SearchResultsPage';
import { NUM_INDICATORS_PER_SEARCH_RESULTS_PAGE } from '../../shared/constants';

/* Settings del carrusel, se pasan al componente de slick carousel */
const settings = {
  dots: true,
  infinite: false,
  lazyLoad: true,
  speed: 500,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

/* SEARCH RESULTS CAROUSEL COMPONENT */
const SearchResultsCarousel = forwardRef(({ data }, ref) => {
  const indicators = data;

  /**
   * Devuelve las páginas de resultados para el carrusel
   */
  const getSearchResultsPages = () => {
    const numPages = Math.ceil(
      indicators.length / NUM_INDICATORS_PER_SEARCH_RESULTS_PAGE,
    );

    return (
      <Slider id="search-results-carousel" {...settings}>
        {[...Array(numPages).keys()].map((el) => (
          <SearchResultsPage
            key={btoa(el)}
            pageInd={el}
            data={[...indicators].slice(
              el * NUM_INDICATORS_PER_SEARCH_RESULTS_PAGE,
              NUM_INDICATORS_PER_SEARCH_RESULTS_PAGE * (el + 1),
            )}
            ref={ref}
          />
        ))}
      </Slider>
    );
  };

  return <>{getSearchResultsPages()}</>;
});

export default SearchResultsCarousel;
