import Form from 'react-bootstrap/Form';
import { COLLECTION_FILTER_ID, resourceTypes } from '../../shared/constants';

const specialMenuResourceIds = {
  [resourceTypes.METADATA]: true,
  [resourceTypes.COLLECTION]: true,
  [resourceTypes.TOPIC]: true,
};

function SelectionMenu({
  resourceId,
  fieldId,
  fieldType,
  setParentSelection,
  parentSelection,
  options,
}) {
  const onOptionChecked = (event) => {
    const optionId = event.target.id;

    const newCheckedValue = !parentSelection[resourceId]['fields'][fieldId][
      optionId
    ]['checked'];

    // Si el comportamiento de las opciones del menú debe simular radio,
    // cualquier otra opción seleccionada se debe pasar a false
    let fieldSelection;
    if (fieldType === 'radio') {
      fieldSelection = Object.keys(
        parentSelection[resourceId]['fields'][fieldId],
      ).reduce((optionsAccum, currOptionId) => {
        let checkedValue;
        if (currOptionId !== optionId) {
          checkedValue = false;
        } else {
          checkedValue = newCheckedValue;
        }
        optionsAccum[currOptionId] = {
          ...parentSelection[resourceId]['fields'][fieldId][currOptionId],
          checked: checkedValue,
        };
        return optionsAccum;
      }, {});
    } else {
      fieldSelection = {
        ...parentSelection[resourceId]['fields'][fieldId],
        [optionId]: {
          ...parentSelection[resourceId]['fields'][fieldId][optionId],
          checked: newCheckedValue,
        },
      };
    }

    setParentSelection({
      ...parentSelection,
      [resourceId]: {
        ...parentSelection[resourceId],
        fields: {
          ...parentSelection[resourceId]['fields'],
          [fieldId]: fieldSelection,
        },
      },
    });
  };

  const isOptionChecked = (optionId) => {
    return parentSelection[resourceId]['fields'][fieldId][optionId]['checked'];
  };

  const getOptions = () => {
    return options.map((option) => {
      let optionId = option.id;
      let optionLabel = option.label;
      return (
        <Form.Check
          key={optionId}
          id={optionId}
          label={optionLabel}
          checked={isOptionChecked(optionId)}
          onChange={onOptionChecked}
          type={fieldType}
        />
      );
    });
  };

  const getClasses = () => {
    const baseClasses = 'pen-selection-menu';
    let finalClasses;
    if (
      resourceId in specialMenuResourceIds ||
      resourceId.includes(COLLECTION_FILTER_ID)
    ) {
      finalClasses = 'data-menu';
    } else {
      finalClasses = 'regular-menu';
    }
    return `${baseClasses} ${finalClasses}`;
  };

  return (
    <Form.Group controlId={`${resourceId}-${fieldId}`} className={getClasses()}>
      {getOptions()}
    </Form.Group>
  );
}

export default SelectionMenu;
