import React from 'react';
import PENTool from './PENTool';

function ToolPage(props) {
  return (
    <div className="pen-tools-carousel-page">
      <PENTool
        id={props.id}
        label={props.label}
        year={props.year}
        collection={props.collection}
        extension={props.extension}
        url={props.url}
        
        rootUrl={props.rootUrl}
        setLink = {props.setLink}
        showModal={props.showModal}
      />
    </div>
  );
}

export default ToolPage;
