import { nest as d3_nest } from 'd3-collection';
import { filter } from 'jszip';
import {
  X_VARIABLE_FIELD_ID,
  Y_VARIABLE_FIELD_ID,
  chartNestingOptions,
  chartTypes,
} from './constants';
import PENDataService from './PENDataService';

const valueFormatter = (cell, formatterParams, onRendered) => {
  //cell - the cell component
  //formatterParams - parameters set for the column
  //onRendered - function to call when the formatter has been rendered
  const value = cell.getValue();
  if (typeof value !== 'undefined') {
    return PENDataService.getMaskedValue(value);
  } else {
    return value;
  }
};

const indicatorColumn = {
  title: 'Indicadores',
  field: 'name',
  frozen: true,
  headerSort: false,
  width: 300,
  formatter: 'textarea',
  vertAlign: 'middle',
};

const regionColumn = {
  title: 'Región',
  field: 'region',
  frozen: true,
  headerSort: false,
  width: 100,
  formatter: 'textarea',
  vertAlign: 'middle',
};

class DataAdapter {
  static getNestingFieldIdForChartData(chartNesting) {
    // Si solo hay un único indicador en los datos, las series se construyen por región
    // Si hay más de un indicador en los datos, las series se construyen por indicador
    const nestingFieldId =
      chartNesting === chartNestingOptions.INDICATOR ? 'id' : 'region';
    return nestingFieldId;
  }

  static adaptDataByRegion(data) {
    return DataAdapter.adaptData(data, 'region');
  }

  static adaptDataByIndicator(data) {
    return DataAdapter.adaptData(data, 'id');
  }

  static adaptDataByIndicatorName(data) {
    return DataAdapter.adaptData(data, 'name', false);
  }

  static nestedDataKeysSorterFunction = (a, b) => {
    return a.key > b.key ? 1 : -1;
  };

  static entriesSorterFunction = (a, b) => {
    return a[X_VARIABLE_FIELD_ID] > b[X_VARIABLE_FIELD_ID] ? 1 : -1;
  };

  static adaptData(data, nestingFieldId, sort = true) {
    const entries = data.rows;
    const nestedData = d3_nest()
      .key((d) => d[nestingFieldId])
      .entries(entries);
    // TODO: si es el campo de indicador, se debería poder dejar el orden en que fueron
    // ingresados los indicadores al array de indicadores

    if (sort) {
      nestedData.sort(DataAdapter.nestedDataKeysSorterFunction);
      // El array de datos debe estar ordenado para que los ejes se contruyan correctamente
      entries.sort(DataAdapter.entriesSorterFunction);
    }

    // console.log('nestedData', nestedData);
    return {
      nestedData,
      entries,
    };
  }

  static adaptTableData(data) {
    const entries = data.rows;
    const indicators = data.indicators;

    const indicatorsDict = indicators.reduce(
      (indicatorsAccum, currIndicator) => {
        let indicatorId = currIndicator['id'];
        let indicatorName = currIndicator['name'];
        let indicatorUnit = currIndicator['unit'];

        let indicatorFullDisplayName =
          !!indicatorUnit && indicatorUnit !== ''
            ? `${indicatorName} (${indicatorUnit})`
            : indicatorName;

        indicatorsAccum[`${indicatorId}`] = {
          id: indicatorId,
          fullDisplayName: indicatorFullDisplayName,
        };

        return indicatorsAccum;
      },
      {},
    );

    const tableNestedData = d3_nest()
      .key((d) => d['id'])
      .key((d) => d['region'])
      .key((d) => d['year'])
      .entries(entries);

    const tableRows = [];
    const yearsDict = {};

    Object.values(tableNestedData).forEach((indicatorGroup) => {
      indicatorGroup.values.forEach((regionGroup) => {
        // Para cada par de (indicator, region) se debe crear una fila en la tabla
        let rowObject = {
          id: indicatorGroup.key,
          name: indicatorsDict[indicatorGroup.key].fullDisplayName,
          region: regionGroup.key,
        };

        regionGroup.values.forEach((yearGroup) => {
          // El dato correspondiente a cada año se debe incorporar al objeto de la fila
          // para que aparezca dentro de la columna de la tabla
          // El dato de la tripleta (indicator, region. año) solo está bajo un año,
          // por eso solo se utiliza directamente el primer elemento de values
          // en lugar de iterar values
          rowObject[yearGroup.key] = yearGroup.values[0]['value'];

          // Se guardan los años para construir las columnas correspondientes
          yearsDict[yearGroup.key] = true;
        });

        tableRows.push(rowObject);
      });

      // console.log('yearsDict', yearsDict);
    });
    // console.log('tableNestedData', tableNestedData);

    const tableColumns = [indicatorColumn, regionColumn];
    Object.keys(yearsDict).forEach((year) => {
      let columnObject = {
        title: `${year}`,
        field: `${year}`,
        headerSort: false,
        width: 100,
        hozAlign: 'right',
        vertAlign: 'middle',
        formatter: valueFormatter,
      };
      tableColumns.push(columnObject);
    });
    const tableData = { columns: tableColumns, data: tableRows };
    // console.log('tableData', tableData);
    return tableData;
  }

  static adaptSourcesData(data) {
    return data.map((elem) => elem.name);
  }

  static getIndicatorsDict(indicatorsArray) {
    return indicatorsArray.reduce((accum, curr) => {
      accum[curr.id] = curr.name;
      return accum;
    }, {});
  }

  static adaptPublicStoryDataAsFormData(
    story,
    official = false,
    extraData = null,
  ) {
    const formData = new FormData();
    const stringifiedJSONstory = JSON.stringify(story);
    const encodedJSONstory = encodeURIComponent(stringifiedJSONstory);
    formData.append('story', encodedJSONstory);
    formData.append('official', official ? 1 : 0);
    formData.append('title', !!extraData ? extraData.title : null);
    formData.append('description', !!extraData ? extraData.description : null);
    formData.append('image', !!extraData ? extraData.image : null);
    return formData;
  }

  static getChartNestingLevelGroupingKeys(chartNestingLevel, nestedData) {
    const parsingFunction =
      chartNestingLevel === chartNestingOptions.INDICATOR ? parseInt : null;

    const chartNestingLevelGroupingKeys = nestedData.map((d) => {
      if (!!parsingFunction && typeof parsingFunction === 'function') {
        return parsingFunction(d.key);
      } else {
        return d.key;
      }
    });

    return chartNestingLevelGroupingKeys;
  }

  static getMaxNumElems(nestedData) {
    // Para establecer el ancho del gráfico, se necesita conocer el máximo de entradas que hay en una serie
    let biggestSeriesNumElems = -1;
    let currentSeriesNumElems;
    nestedData.forEach((d) => {
      currentSeriesNumElems = d.values.length;

      if (currentSeriesNumElems > biggestSeriesNumElems) {
        biggestSeriesNumElems = currentSeriesNumElems;
      }
    });

    return biggestSeriesNumElems;
  }

  static getYearsNestedData(entries) {
    // Agrupación de los datos por años
    const yearsNestedData = d3_nest()
      .key((d) => d[X_VARIABLE_FIELD_ID])
      .entries(entries);
    return yearsNestedData;
  }

  static getYearsGroupingKeys(yearsNestedData) {
    // Para construcción de grupos en eje horizontal
    const yearsGroupingKeys = yearsNestedData.map((d) => d.key);
    return yearsGroupingKeys;
  }

  static getLinesAndBarsData(
    chartNestingLevel,
    nestedData,
    types,
    yearsNestedData,
    indicators,
    chartNestingLevelGroupingKeys,
  ) {
    // Se deben adaptar los datos antes de la renderización


    for(let indicator in nestedData){
      let array = nestedData[indicator].values;
      var filtered = array.filter(function(value, index, arr){ 
        //console.log(value.value)
        return value.value != 0;
      });
      nestedData[indicator].values = filtered;
    }

    //console.log("Despues", nestedData);
    const linesAdaptedData = [];
    const barsAdaptedData = [];

    // Para definir la escala x1
    // Se incluyen solo los elementos que se grafican como barras
    let subXScaleKeys = [];

    if (chartNestingLevel === chartNestingOptions.INDICATOR) {
      // El único caso en que se deben manejar 2 escalas x es que haya 2 indicadores y ambos con la especificación de tipo bar

      // Ids de los indicadores que se grafican con barras
      const barsFilteredData = {};

      let chartType;
      let indicator;

      nestedData.forEach((nestedDataEntry) => {
        indicator = nestedDataEntry.key;
        chartType = types[indicator];
        // console.log(`Se grafica ${indicator} como ${chartType}`);

        if (chartType === chartTypes.BAR) {
          barsFilteredData[indicator] = true;
          subXScaleKeys.push(indicator);
        } else if (chartType === chartTypes.LINE) {
          linesAdaptedData.push(nestedDataEntry);
        }
      });

      let barsAdaptedDataElem;
      let yearNestedDataEntryKey;
      yearsNestedData.forEach((yearNestedDataEntry) => {
        barsAdaptedDataElem = {};
        yearNestedDataEntryKey = yearNestedDataEntry.key;
        barsAdaptedDataElem.year = parseInt(yearNestedDataEntryKey);

        // console.log('yearNestedDataEntry', yearNestedDataEntry);

        yearNestedDataEntry.values.forEach((value) => {
          if (barsFilteredData[value.id]) {
            // console.log('value', value);
            barsAdaptedDataElem[value.id] = value.value;
          }
        });

        barsAdaptedData.push(barsAdaptedDataElem);
      });
    } else if (chartNestingLevel === chartNestingOptions.REGION) {
      // El único caso en que se deben manejar 2 escalas x es que haya más de una región
      // y que el único indicador tenga la especificación de tipo bar
      const indicator = indicators[0].id;
      // console.log('indicator', indicator);
      const chartType = types[indicator];
      // console.log(`Se grafican todos los de ${indicator} como ${chartType}`);

      if (chartType === chartTypes.BAR) {
        subXScaleKeys = chartNestingLevelGroupingKeys;

        let barsAdaptedDataElem;
        let yearNestedDataEntryKey;
        yearsNestedData.forEach((yearNestedDataEntry) => {
          barsAdaptedDataElem = {};
          yearNestedDataEntryKey = yearNestedDataEntry.key;
          barsAdaptedDataElem.year = parseInt(yearNestedDataEntryKey);

          yearNestedDataEntry.values.forEach((value) => {
            barsAdaptedDataElem[value.region] = value.value;
            barsAdaptedDataElem['id'] = value.id;
          });

          barsAdaptedData.push(barsAdaptedDataElem);
        });
      } else if (chartType === chartTypes.LINE) {
        nestedData.forEach((nestedDataEntry) => {
          linesAdaptedData.push(nestedDataEntry);
        });
      }
    }

    return {
      linesAdaptedData,
      barsAdaptedData,
      subXScaleKeys,
    };
  }
}

export default DataAdapter;
