import React, { useRef, useState, useEffect, memo } from 'react';
import ChartHelper from '../../shared/ChartHelper';
import {
  scaleBand as d3_scaleBand,
  scalePoint as d3_scalePoint,
  select as d3_select,
  axisBottom as d3_axisBottom,
} from 'd3';
import {
  X_VARIABLE_FIELD_ID,
  Y_VARIABLE_FIELD_ID,
  modes,
  chartNestingOptions,
} from '../../shared/constants';
import useResizeObserver from './useResizeObserver';
const { v4: uuidv4 } = require('uuid');

const withScrollModes = {
  [modes.EDIT]: true,
  [modes.READONLY]: true,
};

/* CHART SVGS COMPONENT */
const ChartSvgs = memo(
  ({
    id,
    colors,
    guides,
    margin,
    dimensions,
    chartNestingLevel,
    indicatorsDict,
    adaptedData,
    visualizerMode,
    chartNestingLevelGroupingKeys,
    yearsGroupingKeys,
    linesAdaptedData,
    barsAdaptedData,
    subXScaleKeys,
    indicatorUnit,
  }) => {
    // console.log('[ChartSvgs] is running', id);

    const innerSvgRef = useRef();
    const outerSvgRef = useRef();

    /* HOOKS */

    /**
     * Efecto para renderizar todo el gráfico completo
     */
    useEffect(() => {
      ChartHelper.removeTooltipsDivById(id);

      // Datos

      const entries = adaptedData.entries;

      // Dimensiones y SVGs

      const width = dimensions.innerWidth;
      const height = dimensions.height - margin.bottom;

      innerSvgRef.current.style.width = dimensions.innerWidth;

      const innerSvg = d3_select(innerSvgRef.current);
      const plotArea = innerSvg.select('.plot-area');
      const outerSvg = d3_select(outerSvgRef.current);

      // Escalas
      const x0BarChart = d3_scaleBand() // When creating bar charts scaleBand helps to determine the geometry of the bars, taking into account padding between each bar.
        .domain(yearsGroupingKeys)
        .range([margin.left, width - margin.right])
        .padding(0.5);

      // The width of each band can be accessed using .bandwidth()
      // console.log('bandwidth', x0BarChart.bandwidth());

      const x0LineChart = d3_scalePoint() // creates scale functions that map from a discrete set of values to equally spaced points along the specified range
        .domain(yearsGroupingKeys)
        .range([
          margin.left + x0BarChart.bandwidth() / 2, // para que inicie en la mitad del espacio destinado a la primera barra
          width - margin.right - x0BarChart.bandwidth() / 2, // para que termine en la mitar del espacio destinado a la última barra
        ])
        .padding(0.5);

      const x1 = d3_scaleBand()
        .domain(subXScaleKeys)
        .range([0, x0BarChart.bandwidth()])
        .padding(0.05);

      const y = ChartHelper.getYScale(entries, height);

      // Ejes
      const xAxisBarChart = ChartHelper.getXAxis(x0BarChart, height);
      const yAxis = ChartHelper.getYAxis(y);

      innerSvg.select('.x-axis').call(xAxisBarChart);
      outerSvg.select('.y-axis').call(yAxis);

      // Obtiene las medidas de la escala vertical para sacar la mitad
      let outerSVGBounds = outerSvg.select('.y-axis').node().getBoundingClientRect();

      //Se agrega leyenda de las unidades en los ejes. 
      if(!!indicatorUnit && indicatorUnit.length > 30){
        outerSvg.append("text") //Text for the y axis. 
        .attr("class", "indicador-txt indicador-txt-largo")
        .attr("text-anchor", "middle")
        .attr("dy", ".75em")
        .attr("dx", -1 * outerSVGBounds.height/2)
        .attr("transform", "rotate(-90)")
        .text(indicatorUnit);
      }else{
        outerSvg.append("text") //Text for the y axis. 
        .attr("class", "indicador-txt indicador-txt-corto")
        .attr("text-anchor", "middle")
        .attr("dy", ".75em")
        .attr("dx", -1 * outerSVGBounds.height/2)
        .attr("transform", "rotate(-90)")
        .text(indicatorUnit);
      }


      // Tooltip
      const tooltipDiv = d3_select('#data-tooltips-container')
        .append('div')
        .attr(
          'class',
          `d3-tooltip d3-tooltip-general-div d3-tooltip-general-div-${id}`,
        )
        .style('position', 'absolute')
        .style('z-index', '10')
        .style('visibility', 'hidden')
        .style('padding', '10px')
        .style('border-radius', '4px');

      // Renderización
      const y0 = () => {
        return y(0);
      };

      plotArea
        .append('g')
        .attr('class', 'x axis zero')
        .attr('transform', 'translate(0,' + y0() + ')')
        .call(d3_axisBottom(x0BarChart).tickFormat('').tickSize(0));

      // Función para dibujar las líneas
      const line = ChartHelper.getLineFunction(x0LineChart, y);

      // Colores
      const colorsArray = ChartHelper.getChartColors(colors);
      // Función para asignar los colores de la paleta
      const color = ChartHelper.getColorFunction(
        chartNestingLevelGroupingKeys,
        colorsArray,
      );

      // Se calcula el ancho de la columna con un máximo
      const availableColumnWidth = x1.bandwidth();
      const actualColumnWidth = Math.min(availableColumnWidth, 100);
      const columnOffset = (availableColumnWidth - actualColumnWidth) / 2;

      // Renderización de barras
      plotArea
        .append('g')
        .selectAll('g')
        .data(barsAdaptedData)
        .join('g')
        .attr(
          'transform',
          (d) => `translate(${x0BarChart(d[X_VARIABLE_FIELD_ID])},0)`,
        )
        .selectAll('rect')
        .data((d) => {
          return chartNestingLevelGroupingKeys.map((key) => {
            if (chartNestingLevel === chartNestingOptions.INDICATOR) {
              return { key: key, value: d[key] };
            } else {
              return { key: key, value: d[key], id: d['id'] };
            }
          });
        })
        .join('rect')
        .attr('x', (d) => x1(d.key) + columnOffset) // Se calcula la coordenada x donde debe iniciar la columna tomando en cuenta el offset que podría haber si la columna no ocupa todo el espacio disponible (efecto del máximo)
        .attr('y', (d) =>
          y0() > y(d[Y_VARIABLE_FIELD_ID]) ? y(d[Y_VARIABLE_FIELD_ID]) : y0(),
        )
        .attr('width', actualColumnWidth)
        .attr('height', (d) => Math.abs(y0() - y(d[Y_VARIABLE_FIELD_ID])))
        .attr('fill', (d) => color(d.key))
        .on('mouseover', (event, d) => {
          const tooltipContentHtml = ChartHelper.getTooltipContentHtml(
            d,
            color,
            chartNestingLevel,
            indicatorsDict,
            id,
          );
          tooltipDiv.html(tooltipContentHtml).style('visibility', 'visible');
          // d3_select(event.target).transition().attr('fill', hoverColor);
        })
        .on('mousemove', (event, d) => {
          tooltipDiv
            .style('top', event.pageY - 10 + 'px')
            .style('left', event.pageX + 10 + 'px');
        })
        .on('mouseout', (event, d) => {
          tooltipDiv.html(``).style('visibility', 'hidden');
          // d3_select(event.target).transition().attr('fill', color);
        });

      // Renderización de líneas y círculos
      // Como son del mismo color, el orden en que se dibujan no importa
      linesAdaptedData.forEach((d) => {
        plotArea
          .append('path')
          .attr('fill', 'none')
          .attr('stroke', color(d.key))
          .attr('stroke-width', 2)
          .attr('class', 'line')
          .attr('d', line(d.values));

        plotArea
          .selectAll('dot')
          .data(d.values)
          .enter()
          .append('circle')
          .attr('fill', color(d.key))
          .attr('r', 3.5)
          .attr('cx', (d) => x0LineChart(d[X_VARIABLE_FIELD_ID]))
          .attr('cy', (d) => y(d[Y_VARIABLE_FIELD_ID]))
          .on('mouseover', (event, d) => {
            const tooltipContentHtml = ChartHelper.getTooltipContentHtml(
              {
                ...d,
                id: d.id,
                key:
                  chartNestingLevel === chartNestingOptions.INDICATOR
                    ? d.id
                    : d.region,
              },
              color,
              chartNestingLevel,
              indicatorsDict,
              id,
            );
            tooltipDiv.html(tooltipContentHtml).style('visibility', 'visible');
            // d3_select(event.target).transition().attr('fill', hoverColor);
          })
          .on('mousemove', (event, d) => {
            tooltipDiv
              .style('top', event.pageY - 10 + 'px')
              .style('left', event.pageX + 10 + 'px');
          })
          .on('mouseout', (event, d) => {
            tooltipDiv.html(``).style('visibility', 'hidden');
            // d3_select(event.target).transition().attr('fill', color);
          });
      });

      // pone el fondo blanco al SVG de la escala vertical
      // if (visualizerMode in withScrollModes) {
      const whiteBackground = outerSvg.select('.chart-white-background');

      whiteBackground
        .append('rect') // attach a rectangle
        .attr('x', 0) // position the left of the rectangle
        .attr('y', 0) // position the top of the rectangle
        .attr('height', height) // set the height
        .attr('width', margin.left + 1) // set the width
        .style('fill', 'white');
      // }
    }, []);

    /* COMPONENT DEFINITION */
    return (
      <>
        <svg className="chart-outer-svg" ref={outerSvgRef}>
          <g className="chart-white-background" />
          <g className="y-axis" />
        </svg>
        <svg
          className="chart-inner-svg"
          ref={innerSvgRef}
          style={{
            width: dimensions.innerWidth,
          }}
        >
          <g className="plot-area" />
          <g className="x-axis" />
        </svg>
      </>
    );
  },
);

/* CHART CONTENT COMPONENT */
const ChartContent = memo(
  ({
    id,
    chartGuidesOption,
    chartColorsOption,
    chartNestingLevel,
    indicatorsDict,
    adaptedData,
    visualizerMode,
    chartNestingLevelGroupingKeys,
    maxNumElems,
    yearsGroupingKeys,
    linesAdaptedData,
    barsAdaptedData,
    subXScaleKeys,
    indicatorUnit,
  }) => {
    // console.log('[ChartContent] is running', id);

    const wrapperRef = useRef();
    const wrapperDimensions = useResizeObserver(wrapperRef);

    const [dimensions, setDimensions] = useState(null);

    /* HOOKS */

    /**
     * Efecto para adaptación a cambios en las dimensiones
     */
    useEffect(() => {
      const wrapperCurrentDimensions =
        wrapperDimensions || wrapperRef.current.getBoundingClientRect();
      const innerWidth =
        visualizerMode in withScrollModes
          ? Math.max(wrapperCurrentDimensions.width, maxNumElems * 100)
          : wrapperCurrentDimensions.width;
      setDimensions({
        width: wrapperCurrentDimensions.width,
        height: wrapperCurrentDimensions.height,
        innerWidth,
      });
    }, [wrapperDimensions]);

    /* MAIN */
    const getMainContent = () => {
      return (
        <ChartSvgs
          // Para evitar la doble renderización de líneas
          key={uuidv4()}
          id={id}
          colors={chartColorsOption}
          guides={chartGuidesOption}
          margin={{ ...ChartHelper.MARGIN }}
          dimensions={dimensions}
          chartNestingLevel={chartNestingLevel}
          indicatorsDict={indicatorsDict}
          adaptedData={adaptedData}
          visualizerMode={visualizerMode}
          chartNestingLevelGroupingKeys={chartNestingLevelGroupingKeys}
          yearsGroupingKeys={yearsGroupingKeys}
          linesAdaptedData={linesAdaptedData}
          barsAdaptedData={barsAdaptedData}
          subXScaleKeys={subXScaleKeys}
          indicatorUnit={indicatorUnit}
        />
      );
    };

    /* COMPONENT DEFINITION */
    return (
      <div
        className="chart-svg-wrapper"
        ref={wrapperRef}
        style={{
          overflowX: visualizerMode in withScrollModes ? 'scroll' : 'hidden',
        }}
      >
        {!!dimensions && getMainContent()}
      </div>
    );
  },
);

export default ChartContent;
