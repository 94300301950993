import React, { useCallback, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { StoryProvider } from './components/DataVisualization/StoryContext';
import { DataSelectionProvider } from './components/DataSelection/DataSelectionContext';
import Home from './Home';
// TODO
// Quitar el componente de prueba
import Test from './components/Test';
import VisualizationTool from './VisualizationTool';
import ListHistories from './ListHistories';
import VisualizationToolProtectedRoute from './components/RouteProtection/VisualizationToolProtectedRoute';
import DataSelector from './DataSelector';
import DataSelectorProtectedRoute from './components/RouteProtection/DataSelectorProtectedRoute';
import DataManager from './DataManager';
import DataDownload from './DataDownload';
import NotFound from './NotFound';
import Container from 'react-bootstrap/Container';
import DataElementEditor from './components/DataVisualization/DataElementEditor';
import DataElementEditorProtectedRoute from './components/RouteProtection/DataElementEditorProtectedRoute';
import DataElementMetadata from './DataElementMetadata';
import DataElementMetadataProtectedRoute from './components/RouteProtection/DataElementMetadataProtectedRoute';
import DataManagerProtectedRoute from './components/RouteProtection/DataManagerProtectedRoute';
import Login from './Login';
import {
  EXPORT_STORY_PATH,
  VISUALIZER_EDIT_MODE_PATH,
  VISUALIZER_READONLY_MODE_PATH,
  VISUALIZER_SHARE_MODE_PATH,
  DATA_SELECTION_PATH,
  DATA_MANAGEMENT_PATH,
  DATA_ELEMENT_EDITION_PATH,
  ADMIN_PATH,
  DATA_DOWNLOAD_PATH,
  DATA_ELEMENT_METADATA_PATH,
  headerModes,
  EXPORT_PATH, VIEW_ALL_STORIES, HOME_ADMIN,
} from './shared/constants';
import { useMediaQuery } from './hooks/useMediaQuery';
import { useSelector } from 'react-redux';
// import ExportElement from './components/DataVisualization/ExportElement';
import ExportFullElement from './components/DataVisualization/ExportFullElement';
import ExportFullHistory from './components/DataVisualization/ExportFullHistory';
import GoogleAnalytics from './shared/GoogleAnalytics.js';
import HomeAdmin from './HomeAdmin';

function MainComponent() {
  /* Acceso al modo del header */
  const selectHeaderMode = (state) => state.headerMode;
  const headerMode = useSelector(selectHeaderMode);
  let isMobileSite = useMediaQuery('(max-width: 991px)');
  /*GOOGLE ANALYTICS*/

  useEffect(() => {
    GoogleAnalytics.sendInformation(
      window.location.pathname + window.location.search,
    );
  });

  const contentDivRef = useCallback(
    (node) => {
      if (node !== null) {
        // console.log(node);

        // Si el elemento tiene la clase content,
        // se asigna la clase header complete o header-collapsed
        // según corresponda
        const classList = node.classList;
        if (classList.length) {
          const firstClasss = classList[0];
          if (firstClasss === 'content') {
            if (isMobileSite && headerMode === headerModes.COLLAPSED) {
              node.classList.remove('header-complete');
              node.classList.add('header-collapsed');
            } else {
              node.classList.remove('header-collapsed');
              node.classList.add('header-complete');
            }
          }
        }
      }
    },
    [isMobileSite, headerMode],
  );
  return (
    <StoryProvider>
      <DataSelectionProvider>
        <Container className="main" fluid>
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => <Home {...props} ref={contentDivRef} />}
            />
            <Route
              path={`/${DATA_SELECTION_PATH}`}
              exact
              render={(props) => (
                <DataSelectorProtectedRoute
                  {...props}
                  component={DataSelector}
                  ref={contentDivRef}
                />
              )}
            />
            <Route
              path={`/${VISUALIZER_EDIT_MODE_PATH}`}
              exact
              render={(props) => (
                <VisualizationToolProtectedRoute
                  {...props}
                  component={VisualizationTool}
                  ref={contentDivRef}
                />
              )}
            />
            <Route
              path={`/${VIEW_ALL_STORIES}`}
              exact
              render={(props) => (
                <DataManagerProtectedRoute
                  {...props}
                  component={ListHistories}
                  ref={contentDivRef}
                />
              )}
            />
            <Route
              path={`/${VISUALIZER_READONLY_MODE_PATH}/:key`}
              exact
              render={(props) => (
                <VisualizationToolProtectedRoute
                  {...props}
                  component={VisualizationTool}
                  ref={contentDivRef}
                />
              )}
            />
            <Route
              path={`/editHistory/:key`}
              exact
              render={(props) => (
                <VisualizationToolProtectedRoute
                  {...props}
                  component={VisualizationTool}
                  ref={contentDivRef}
                />
              )}
            />
            <Route
              path={`/${VISUALIZER_SHARE_MODE_PATH}/:key`}
              exact
              render={(props) => (
                <VisualizationToolProtectedRoute
                  {...props}
                  component={VisualizationTool}
                  ref={contentDivRef}
                />
              )}
            />
            <Route
              path={`/${DATA_ELEMENT_EDITION_PATH}`}
              exact
              render={(props) => (
                <DataElementEditorProtectedRoute
                  {...props}
                  component={DataElementEditor}
                  ref={contentDivRef}
                />
              )}
            />
            <Route
              path={`/${DATA_MANAGEMENT_PATH}`}
              exact
              render={(props) => (
                <DataManagerProtectedRoute
                  {...props}
                  ref={contentDivRef}
                  component={DataManager}
                />
              )}
            />
            <Route
              path={`/${HOME_ADMIN}`}
              exact
              render={(props) => (
                <DataManagerProtectedRoute
                  {...props}
                  component={HomeAdmin}
                  ref={contentDivRef}
                />
              )}
            />
            <Route
              path={`/${DATA_DOWNLOAD_PATH}`}
              exact
              render={(props) => (
                <DataDownload {...props} ref={contentDivRef} />
              )}
            />
            <Route
              path={`/${DATA_ELEMENT_METADATA_PATH}`}
              exact
              render={(props) => (
                <DataElementMetadataProtectedRoute
                  {...props}
                  component={DataElementMetadata}
                  ref={contentDivRef}
                />
              )}
            />
            <Route
              path={`/${ADMIN_PATH}`}
              exact
              render={(props) => <Login {...props} ref={contentDivRef} />}
            />

            {/* TODO */}
            {/* Quitar el path para el componente de prueba */}
            <Route
              path={`/${EXPORT_STORY_PATH}/:key`}
              exact
              render={(props) => (
                <ExportFullHistory {...props} ref={contentDivRef} />
              )}
            />
            <Route
              path={`/${EXPORT_PATH}`}
              exact
              render={(props) => (
                // <ExportElement {...props} ref={contentDivRef} />
                <ExportFullElement {...props} ref={contentDivRef} />
              )}
            />
            <Route
              render={(props) => <NotFound {...props} ref={contentDivRef} />}
            />
          </Switch>
        </Container>
      </DataSelectionProvider>
    </StoryProvider>
  );
}

export default MainComponent;
