import { resourceTypes } from '../shared/constants';
const resourceOptionsHistory = {
    // all: {
    //   id: 'all',
    //   label: 'Todos',
    //   fields: [],
    // },
    chart: {
      id: resourceTypes.CHART,
      label: 'Historia',
      fields: [
        {
          id: 'format',
          label: 'Seleccione Formato',
          type: 'checkbox',
          options: [
            {
              id: `${resourceTypes.CHART}-format-pdf`,
              label: 'PDF',
            },
            {
              id: `${resourceTypes.CHART}-format-png`,
              label: 'PNG',
            },
          ],
        },
        {
          id: 'empty',
        },
      ],
    },
  };
  
  export default resourceOptionsHistory;
  