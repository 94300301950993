import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

function ErrorMessage(props) {
  // console.log('props', props);
  const onOKClicked = props.onOKClicked;

  const getTitleSection = () => {
    return (
      <Row>
        <p>Error</p>
      </Row>
    );
  };

  const getMessageSection = () => {
    return (
      <Row>
        <p>No se pudo ejecutar la acción. Por favor intente más tarde.</p>
      </Row>
    );
  };

  return (
    <Container fluid className="action-modal-container">
      {getTitleSection()}
      {getMessageSection()}
      <br />
      <Row>
        <Button
          variant="danger"
          onClick={onOKClicked}
          className="center-in-row"
        >
          OK
        </Button>
      </Row>
    </Container>
  );
}

export default ErrorMessage;
