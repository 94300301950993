import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

function ChartLegendEditorContent(props) {
  const [legendOption, setLegendOption] = useState(null);

  useEffect(() => {
    const element = props.dataElementConfiguration;

    if (!!element) {
      setLegendOption(element.chart_legend_option);
    }
  }, [props]);

  const handleLegendOptionChange = (event) => {
    const selectedLegendOption = event.target.id;
    setLegendOption(selectedLegendOption);
    props.dataElementConfiguration.chart_legend_option = selectedLegendOption;
  };

  return (
    <>
      <Col md="6">
        <Form.Check
          type="radio"
          label="Sin Leyenda"
          name="legendRadios"
          id="none"
          checked={!!legendOption && legendOption === 'none'}
          onChange={handleLegendOptionChange}
        />
      </Col>
      <Col md="6">
        <Form.Check
          type="radio"
          label="Arriba"
          name="legendRadios"
          id="up"
          checked={!!legendOption && legendOption === 'up'}
          onChange={handleLegendOptionChange}
        />
        <Form.Check
          type="radio"
          label="Abajo"
          name="legendRadios"
          id="down"
          checked={!!legendOption && legendOption === 'down'}
          onChange={handleLegendOptionChange}
        />
      </Col>
    </>
  );
}

export default ChartLegendEditorContent;
