const tools = {
  waki: {
    id: 'waki',
    label: 'Waki',
    year: 2021,
    collection: 'Estado de la Educación',
    extension: 'webp',
    url: 'http://waki.cr',
  },
  dcifra: {
    id: 'dcifra',
    label: 'Dcifra tu Cantón',
    year: 2021,
    collection: 'Estado de la Nación',
    extension: 'webp',
    url: 'https://dcifra.cr',
  },
  hipatia: {
    id: 'hipatia',
    label: 'Hipatia',
    year: 2021,
    collection: 'Estado de las Capacidades en Ciencia, Tecnología e Innovación',
    extension: 'webp',
    url: 'https://hipatia.cr',
  },
  votometro: {
    id: 'votometro',
    label: 'Votemos CR',
    year: 2021,
    collection: 'Estado de la Nación',
    extension: 'svg',
    url: 'http://www.votemoscr.com',
  },
  // conozca_escuela: {
  //   id: 'conozca_escuela',
  //   label: 'Conozca Su Escuela',
  //   year: 2021,
  //   collection: 'Estado de la Educación',
  //   extension: 'png',
  //   url: 'https://www.conozcasuescuela.ac.cr',
  // },
  atlas_educacion_superior: {
    id: 'atlas_educacion_superior',
    label: 'Atlas de la Educación Superior',
    year: 2021,
    collection: 'Estado de la Educación',
    extension: 'webp',
    url: 'https://www.universidades.estadonacion.or.cr',
  },
};

export default tools;
