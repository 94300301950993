import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
} from 'react';
import Row from 'react-bootstrap/Row';
import UserSessionDataService from '../../shared/UserSessionDataService';
import { useSelector } from 'react-redux';
import { StoryContext } from '../DataVisualization/StoryContext';
import { useParams, useHistory } from 'react-router-dom';
import {
  EXPORT_STORY_PATH,
  modes,
  USE_MOCK_STORY,
  elementTypes,
} from '../../shared/constants';
import { trackPromise } from 'react-promise-tracker';
import Disclaimer from '../common/Disclaimer';


import TextElementExport from '../DataVisualization/TextElementExport';

import DataElementExport from '../DataVisualization/DataElementExport';
import GoogleAnalytics from '../../shared/GoogleAnalytics.js';
// TODO
// Controlar cuando está en modo READONLY o SHARE pero no hay un key válido
// Se podría ir a not found en ese caso

const getSessionString = () => {
  return `{"user_stories": {"0":{"story_elements":{
    "4c8a5920-4fd8-4c75-a4fe-c4743e0b1758":{"key":"4c8a5920-4fd8-4c75-a4fe-c4743e0b1758","type":"text","content_text":null,"mode":"readonly"},
    "92fe4de7-d95f-4351-a66e-aef20dbfc6c2":{"key":"92fe4de7-d95f-4351-a66e-aef20dbfc6c2","type":"data","indicators":[10002],"collection":0,"title_text":"","subtitle_text":"","chart_types_options":{"10002":"line"},"chart_legend_option":"down","chart_colors_option":"cool1","chart_guides_option":false},
    "e9a9c792-1262-459b-8970-5c43360968ce":{"key":"e9a9c792-1262-459b-8970-5c43360968ce","type":"text","content_text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.","mode":"readonly"},
    "dcb138de-b885-42b2-a591-2b07c06668d6":{"key":"dcb138de-b885-42b2-a591-2b07c06668d6","type":"text","content_text":"6789101112","mode":"readonly"},
    "cc48a536-d1a1-401f-9017-83b897e4b8f6":{"key":"cc48a536-d1a1-401f-9017-83b897e4b8f6","type":"data","indicators":[200004],"collection":2,"title_text":"","subtitle_text":"","chart_types_options":{"200004":"line"},"chart_legend_option":"down","chart_colors_option":"cool1","chart_guides_option":false},
    "dcb138de-b885-42b2-a591-2b07c06668d7":{"key":"dcb138de-b885-42b2-a591-2b07c06668d7","type":"text","content_text":"Texto de prueba del gráfico de erca","mode":"readonly"}},"key":"0"}
  }}`;
};

// key se utiliza cuando no se cargan datos de la historia de la sesión, sino de una historia pública
// key se extrae de los párametros del url, vía useParams

/* VISUALIZATION TOOL COMPONENT */
const ExportFullElement = forwardRef(({ location }, ref) => {
  // console.log('[VisualizationTool] is running', props);

  useEffect(()=>{
    GoogleAnalytics.sendInformation(window.location.pathname + window.location.search);
  });
  let history = useHistory();

  let { key } = useParams();

  /* Acceso a la historia del visualizador */

    
  /* Acceso a la historia del visualizador como contexto */
  const context = useContext(StoryContext);
    /* HELPERS */
    const getElementsArray = () => {
      console.log(context)
      return context.session.getSessionStory().getElementsArray();
    };
  const exportContainerRef = useRef();

  const [visualizerMode, setvisualizerMode] = useState(null);
  const [showConfirmResetModal, setConfirmResetModal] = useState(false);
  const [storyTexts, setStoryTexts] = useState(null);
  const [showShareOptionsModal, setShowShareOptionsModal] = useState(false);
  const [showCreatePENStoryModal, setShowCreatePENStoryModal] = useState(false);
  const [rootElementClass, setRootElementClass] = useState('content');

  /* Acceso al objeto de la sesión */
  const selectSession = (state) => state.session;
  const session = useSelector(selectSession);

  /* Acceso a la cookie de la sesión */
  const selectCookie = (state) => state.cookie;
  const cookie = useSelector(selectCookie);

  const selectAdminSession = (state) => state.adminSession;
  const adminSession = useSelector(selectAdminSession);

  /* Acceso a la opción de mostrar el mensaje de error */
  const selectShowErrorMessage = (state) => state.showErrorMessage;
  const showErrorMessage = useSelector(selectShowErrorMessage);

  /* HOOKS */

  /**
   * Efecto para hacer scroll al inicio de la página o algún elemento del visualizador
   */
  useEffect(() => {
    // En los modos share y readonly no queda definido state pero no es necesario
    // el acceso porque no hay element para hacer scroll
    console.log(key);
    const scrollElementKey = key; 
    if (!!key) {
      const scrollElementKey = key;
      // Si no se viene de data selector o de search results page no hay element para hacer scroll
      console.log('scrollElementKey', scrollElementKey);
      if (!!scrollElementKey) {
        // Se guarda en el context y se utiliza en el tab content
        context.setScrollElementKey(scrollElementKey);
        // Para que se borre el element id y no se utilice si se actualiza la página
        history.replace({ state: undefined });
      } else {
        // Scroll to top of the window
        // window.scrollTo(0, 0);
      }
    } else {
      // Scroll to top of the window
      // window.scrollTo(0, 0);
    }

  }, []);

  /**
   * Efecto para obtener los datos que se van a mostrar en el visualizador y enviar
   * a renderizar VisualizationToolContent
   */
  useEffect(() => {
    // console.log('session', session);
    if (visualizerMode === modes.EDIT && !!session) {
      // En modo edit se visualiza la historia de la sesión

      if (!USE_MOCK_STORY) {
        context.parseSession(session);
      } else {
        const testSessionString = getSessionString();
        const testSession = JSON.parse(testSessionString);
        context.parseSession(testSession);
      }
    } else if (true) {
      
      // En modos readonly y share se visualiza la historia públcia identificada con el parámetro key
      trackPromise(
        UserSessionDataService.getPublicStoryByKey(key)
          .then((result) => {
            console.log('result', result);
            const publicStory = JSON.parse(result.story);

            if (result.official) {
              //console.log('result', result);
              const titleText =
                'title' in result &&
                result.title !== null &&
                result.title !== ''
                  ? result.title
                  : 'Historia oficial';
              const descriptionText =
                'description' in result &&
                result.description !== null &&
                result.description !== ''
                  ? result.description
                  : null;
              setStoryTexts([titleText, descriptionText]);
            }
            const storyElements = publicStory.story_elements;
            console.log('storyElements', storyElements);
            // Se simula una sesión con los elementos de la historia pública
            const session = {
              user_stories: {
                0: {
                  story_elements: storyElements,
                  key: '0',
                },
              },
            };
            context.parseSession(session);
            // context.setElements(storyElements);
          })
          .catch((error) => {
            console.log('error', error);
            // TODO
            // Ir a not found
          }),
      );
    }
  }, [key]);

  /*Modal de informacion de edicion de titulo y subtitulo*/
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    sessionStorage.setItem('notAlreadyDisplayed', false);
  };
  const thereIsGraph = (elements) =>
    !!elements.find((obj) => obj.type === elementTypes.DATA);
  const modalAlreadyDisplayed = () =>
    !!!sessionStorage.getItem('notAlreadyDisplayed');
  useEffect(() => {
    if (!!context.session) {
      const elements = getElementsArray();
      if (thereIsGraph(elements) && modalAlreadyDisplayed()) {
        setShow(true);
      }
    }
  }, [context.session]);


  /**
   * Efecto para determinar el modo que se le aplica al visualizador
   */
  useEffect(() => {
    //console.log('location.pathname', location.pathname);
    
    const pathAnalysis = location.pathname.match(/(\/\w+)/g);
    console.log(pathAnalysis);
    if (pathAnalysis.length) {
      switch (pathAnalysis[0]) {
        case `/${EXPORT_STORY_PATH}`:
          setvisualizerMode(modes.SHARE);
          setRootElementClass('shared-content');
          break;
      }
    } else {
      setvisualizerMode(null);
    }
  }, [location]);

  const getTitleText = () => {
    return visualizerMode === modes.EDIT
      ? 'Visualizador de datos'
      : !!storyTexts
      ? storyTexts[0]
      : 'Historia oficial';
  };

  const getTitleSection = () => {
    return (
      <Row className="row-info-container">
        <h2>{getTitleText()}</h2>
      </Row>
    );
  };

  /* MAIN */
 const getMainContent = () => {
    if (context.session === null) {
      return null;
    }
    console.log(getElementsArray());
    const mappedElements = getElementsArray().map((element, index) => {
      if (element.type === elementTypes.DATA) {
        return (
          <DataElementExport
            key={`${btoa(index)}-${element.version}`}
            id={element.key}
            visualizerMode={visualizerMode}
            ref={ref}
          />
        );
      } else if (element.type === elementTypes.TEXT) {
        return (
          <TextElementExport
            key={btoa(index)}
            id={element.key}
            visualizerMode={visualizerMode}
          />
        );
      } else {
        return null;
      }
    });
    return mappedElements;
  };



  /* COMPONENT DEFINITION */
  return (
    <div className="shared-content" ref={ref}>
      <div className="actual-export-content" id="actual-export-content">
        <div className="chart-box">
        <Row>
        {!!visualizerMode && getTitleSection()}
        </Row>
        {!!visualizerMode && getMainContent()}
        <Row className="visualizer-disclaimer-container">
          <Disclaimer />
        </Row>
        </div>
      </div>
    </div>
  );
});

export default ExportFullElement;
