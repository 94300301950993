import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import PENStory from './components/Data/PENStory';
import SearchResults from './components/DataSelection/SearchResults';
import UserSessionDataService from './shared/UserSessionDataService';
import PENDataService from './shared/PENDataService.js';
import {
  DATA_DOWNLOAD_PATH,
  DATA_SELECTION_PATH,
  dataSelectionModes,
  MODULE_NAME,
  MODULE_FULL_NAME,
} from './shared/constants';
import Modal from 'react-bootstrap/Modal';
import IndicatorDetails from './components/Data/IndicatorDetails';
import { INDICATOR_DETAILS_OPTION_CLOSED } from './actions/types';
import { useSelector } from 'react-redux';
import store from './store';
import { trackPromise } from 'react-promise-tracker';
import ToolsCarousel from './components/Tools/ToolsCarousel';
import { ReactComponent as Twitterx } from "./img/twitter-x-icon.svg";

const Home = forwardRef(({}, ref) => {
  let history = useHistory();
  const [searchString, setSearchString] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [penStories, setPENStories] = useState([]);

  let inputSearchBar = useRef();

  /* Opción de mostrar el contenedor de resultados de búsqueda */
  const [showSearchResults, setShowSearchResults] = useState(false);

  /* Acceso a la opción de ver los detalles de un indicador */
  const selectShowIndicatorDetails = (state) =>
    state.indicators.showIndicatorDetails;
  const showIndicatorDetails = useSelector(selectShowIndicatorDetails);

  /**
   * Envía a la información compartida la acción para cerrar los detalles del indicador
   */
  const closeIndicatorDetails = () => {
    store.dispatch({
      type: INDICATOR_DETAILS_OPTION_CLOSED,
    });
  };

  /**
   * Handler para el cierre del modal de detalles del indicador
   */
  const handleClose = () => closeIndicatorDetails();

  /**
   * Devuelve el modal de detalles del indicator
   */
  const getIndicatorDetailsModal = () => {
    return (
      <Modal
        size="lg"
        className="modal-indicator-details"
        show={showIndicatorDetails}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <IndicatorDetails />
        </Modal.Body>
      </Modal>
    );
  };

  const getSearchResultsComponent = (searchResults) => {
    if (showSearchResults) {
      return <SearchResults data={searchResults} ref={inputSearchBar} />;
    } else {
      return null;
    }
  };

  const handleSearchInputChange = (event) => {
    const searchString = event.target.value.trim();
    if (!!searchString) {
      setSearchString(searchString);
    } else {
      setSearchString(null);
    }
  };

  const hideSearchResults = () => {
    setShowSearchResults(false);
  };

  const handleSearchInputKeyDown = (event) => {
    if (event.key === 'Escape') {
      //Do whatever when esc is pressed
      hideSearchResults();
    } else if (event.key === 'Enter') {
      // console.log('ENTER HIT !!!');
      if (showSearchResults && searchResults.length) {
        history.push({
          pathname: `/${DATA_SELECTION_PATH}`,
          state: {
            dataSelectionMode: dataSelectionModes.NEW,
            elementKey: null,
            searchString: searchString,
          },
        });
      }
    }
  };

  const handleSearchInputLeave = (event) => {
    const relatedTarget = event.relatedTarget;
    const searchResultsContainerElement = document.getElementById(
      'search-results-container',
    );
    //console.log('node', searchResultsContainerElement);

    if (!!searchResultsContainerElement) {
      if (!searchResultsContainerElement.contains(relatedTarget)) {
        hideSearchResults();
      }
    } else {
      hideSearchResults();
    }
  };

  const handleIrADatos = (event) => {
    if (!!event) {
      event.preventDefault();
    }
    // No se envía elementKey
    history.push({
      pathname: `/${DATA_SELECTION_PATH}`,
      state: {
        dataSelectionMode: dataSelectionModes.NEW,
        elementKey: null,
        searchString: null,
      },
    });
  };

  const getOfficialStories = () => {
    if (penStories.length) {
      return (
        <>
          {penStories.map((penStory, index) => (
            <PENStory
              key={btoa(index)}
              id={penStory.id}
              name={penStory.name}
              title={penStory.title}
              description={penStory.description}
              extension={penStory.extension}
              rootUrl={window.location.href}
              setLink = {setLinkText}
              showShareModal={setShowShareModuleModal}
            />
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  const getPENTools = () => {
    return (
      <div className="col-12">
        <div className="pen-tools-title-container">
          <h3>HERRAMIENTAS DE VISUALIZACIÓN</h3>
          <hr className="pen-small-hr"/>
          <p>Conozca sobre temas específicos de forma fácil e interactiva.</p>
        </div>
        <div className="pen-tools-carousel-container">
          <ToolsCarousel
            rootUrl={window.location.href}
            setLink = {setLinkText}
            showShareModal={setShowShareModuleModal}
          />
        </div>
      </div>
    );
  };

  const getSearchBarClasses = () => {
    const baseClasses = 'form-control home-search-bar';
    let finalClasses = '';
    if (showSearchResults) {
      finalClasses = 'show-results';
    }
    return `${baseClasses} ${finalClasses}`;
  };

  useEffect(() => {
    setSearchResults([]);
    if (!!searchString) {
      trackPromise(
        PENDataService.findIndicatorsBySearchString(searchString)
          .then((result) => {
            //console.log('result', result);
            const indicatorsResult = result;
            setSearchResults(indicatorsResult);
            setShowSearchResults(true);
          })
          .catch((error) => {
            console.log('error', error);
          }),
      );
    } else {
      setShowSearchResults(false);
    }
  }, [searchString]);

  useEffect(() => {
    trackPromise(
      UserSessionDataService.getPENStories()
        .then((result) => {
          setPENStories(result);
        })
        .catch((error) => {
          console.log('error', error);
        }),
    );
  }, []);
  let displayStyleNone = {
    display: 'none'
  }
  let displayStyleShow = {
    display: 'inline'
  }
  /* SHARE OPTIONS */
  const [displayState, setDisplayState] = useState(displayStyleNone);  
  const [showShareModuleModal, setShowShareModuleModal] = useState(false);
  const [linkText, setLinkText] = useState(window.location.href);
  const handleCloseShareModuleModal = () => setShowShareModuleModal(false);
  const handleShowShareModuleModal = () => setShowShareModuleModal(true);
  const handleExportarClick = () =>{
    setLinkText(window.location.href);
    handleShowShareModuleModal()
  }
  const handleOnclickCopyLink = () => {
    copyLink(linkText)
    }

  const copyLink = (url) => {
    console.log("copiado");
    var input = document.body.appendChild(document.createElement("input"));
    input.value = url;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode.removeChild(input);

    setDisplayState(displayStyleShow);
    setTimeout(function(){
      setDisplayState(displayStyleNone);
    }, 1000);
  }

  const getShareModuleModal = () => {
    const siteUrl = linkText;
    return (
      <div className="modal__share open">
        <h3 className="modal__share__title">Compartir</h3>
        <p className="modal__share__site-title">{MODULE_FULL_NAME}</p>
        <p className="modal__share__url">{siteUrl}</p>
        <div className="modal__share__social">
          <button onClick={handleOnclickCopyLink} className="modal__share__button btn-copy-link">
            <i className="far fa-copy pen-copiar pen-link"></i>
            Copiar link
            <span style={displayState} className="btn-copy-link__tooltip">
              Texto copiado con éxito 
            </span>
          </button>
          <a
            className="modal__share__button"
            href={`https://www.facebook.com/sharer/sharer.php?u=${siteUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
            <span>Facebook</span>
          </a>
          <a
            className="modal__share__button"
            href={`https://twitter.com/share?url=${siteUrl}&amp;text=${MODULE_FULL_NAME}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="circle-icon-modal fa-twitter">
              <Twitterx className="twitter-x-60" />
              </div>

            <span>Twitter</span>
          </a>
          <a
            className="modal__share__button"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${siteUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin-in"></i>
            <span>LinkedIn</span>
          </a>
          <a
            className="modal__share__button"
            href={`https://www.instagram.com/programaestadonacion/`}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram"></i>
            <span>Instagram</span>
          </a>
          <a
            className="modal__share__button"
            href={`mailto:?subject=PEN:%20${MODULE_FULL_NAME} &amp;body=PEN:%20@title%20${siteUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <i className="far fa-envelope"></i>
            <span>Email App</span>
          </a>
        </div>
        <button
          className="modal__share__close"
          onClick={handleCloseShareModuleModal}
        >
          <i className="fas fa-times-circle"></i>
        </button>
      </div>
    );
  };

  return (
    <>
      {showShareModuleModal && getShareModuleModal()}
      {getIndicatorDetailsModal()}
      <div className="row" ref={ref}>
        <div className="col-12">
          <div className="row jumbo">
            <div className="col-12">
              <div className="home-title-div">
                <h2 className="hero-simple__title">{MODULE_NAME}</h2>
                <p className="hero-simple__content">
                  4 compendios, más de 90 temas y más de 750 indicadores.
                </p>
              </div>
              <div className="home-search-bar-div">
                <div className="input-group input-group-lg home-search-bar-group">
                  <input
                    type="text"
                    className={getSearchBarClasses()}
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Busque por palabra clave"
                    onInput={handleSearchInputChange}
                    onKeyDown={handleSearchInputKeyDown}
                    onBlur={handleSearchInputLeave}
                    ref={inputSearchBar}
                  />
                  {getSearchResultsComponent(searchResults)}
                </div>
              </div>
              <div className="home-data-button-div">
                <Button
                  onClick={handleIrADatos}
                  className="btn-lg home-data-button"
                >
                  Ver datos disponibles
                </Button>
              </div>
              <div className="row">
                <Button
                  variant="exportar-btn"
                  id="exp-header"
                  onClick={handleExportarClick}
                ></Button>
              </div>
            </div>
          </div>
          <div className="row home-content">
            <div className="col-12 text-center">
              <p>
                <Link to={`/${DATA_DOWNLOAD_PATH}`} className="link-on-body">
                  Descarga directa de compendios <br/>estadísticos completos &#62;
                </Link>
              </p>
            </div>
            <div className="col-12">
              <div className="home-visualizer-section row">
                <div className="col-md-8">
                  <img className="home-visualizer-section-img" />
                </div>
                <div className="col-md-4">
                  <p className="label">[ estadística ]</p>
                  <h5>Visualizador de datos</h5>
                  <p>
                    Explore nuestros compendios estadísticos creando sus propios
                    gráficos. Construya historias de datos que cambien el mundo,
                    y ¡compartelas!
                  </p>
                  <p>
                    <Link
                      to="#"
                      onClick={handleIrADatos}
                      className="content-link"
                    >
                      Crear gráfico
                    </Link>
                  </p>
                </div>
              </div>
              <div>
                <p className="enunciado-colecciones mt-5">
                  Conozca más sobre nuestras <span className="resalte-suave">COLECCIONES</span> explorando <br/> estas <span className="resalte-fuerte">historias de datos</span> ya graficadas
                </p>
                <hr className="pen-small-hr mb-5"/>
              </div>
              <div className="row">{getOfficialStories()}</div>
            </div>
          </div>
          <div className="row pen-tools-section">{getPENTools()}</div>
        </div>
      </div>
    </>
  );
});

export default Home;
