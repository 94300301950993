import StoryElement from './StoryElement';
import {
  CONTENT_TEXT_KEY,
  TEXT_MODE_KEY,
  textModes,
  DEFAULT_TEXT_ELEMENT,
  ELEMENT_TYPE_KEY,
} from '../shared/constants';

class TextElement extends StoryElement {
  constructor(dbElementObject) {
    // console.log('dbElementObject en TextElement', dbElementObject);

    super(dbElementObject);

    this._type =
      ELEMENT_TYPE_KEY in dbElementObject
        ? dbElementObject[ELEMENT_TYPE_KEY]
        : DEFAULT_TEXT_ELEMENT[ELEMENT_TYPE_KEY];
    this._contentText =
      CONTENT_TEXT_KEY in dbElementObject
        ? dbElementObject[CONTENT_TEXT_KEY]
        : DEFAULT_TEXT_ELEMENT[CONTENT_TEXT_KEY];
    this._textMode =
      TEXT_MODE_KEY in dbElementObject
        ? dbElementObject[TEXT_MODE_KEY]
        : DEFAULT_TEXT_ELEMENT[TEXT_MODE_KEY];
  }

  get contentText() {
    return this._contentText;
  }
  get textMode() {
    return this._textMode;
  }

  set contentText(contentText) {
    this._contentText = contentText;
  }
  set mode(mode) {
    this._textMode = mode;
  }

  setText(text) {
    this._contentText = text;
    this._textMode = textModes.READONLY;
  }

  toggleTextMode() {
    // console.log('current text mode', this._textMode);
    const newTextMode =
      this._textMode === textModes.READONLY
        ? textModes.EDIT
        : textModes.READONLY;
    // console.log('new text mode', newTextMode);
    this._textMode = newTextMode;
  }

  getDbObject() {
    const dbObject = {};
    const parentAttributes = StoryElement.prototype.getDbObject.apply(this);
    const textElementAttributes = {
      [CONTENT_TEXT_KEY]: this._contentText,
      [TEXT_MODE_KEY]: this._textMode,
    };
    Object.assign(dbObject, parentAttributes, textElementAttributes);
    return dbObject;
  }
}

export default TextElement;
