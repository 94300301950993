import React, { useState, useEffect, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import PENDataService from '../../shared/PENDataService';
import Disclaimer from '../common/Disclaimer';
import ProcessedDataElement from '../../models/ProcessedDataElement';
import DataAdapter from '../../shared/DataAdapter';
import ExportChart from '../DataVisualization/ExportChart';
import { modes } from '../../shared/constants';
import Row from 'react-bootstrap/Row';
import { trackPromise } from 'react-promise-tracker';
import ExportHelper from '../../shared/ExportHelper';
import GoogleAnalytics from '../../shared/GoogleAnalytics.js';
const { v4: uuidv4 } = require('uuid');

const ExportFullElement = forwardRef((props, ref) => {
  let location = useLocation();

  const [exportParamsData, setExportParamsData] = useState(null);
  const [exportElementData, setExportElementData] = useState(null);

  useEffect(()=>{
    GoogleAnalytics.sendInformation(window.location.pathname + window.location.search);
  });
  /* DATA REQUESTS */
  const getElementDataPromise = (indicators, source, regions, years) => {
    return PENDataService.getIndicatorsDataEntries(
      indicators,
      source,
      regions,
      years,
    );
  };

  const getElementSourcesPromise = (indicators, source) => {
    return PENDataService.getIndicatorsSourcesByIndicatorsKeys(
      indicators,
      source,
    );
  };

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);

    if (!!params) {
      const exportParamsData = ExportHelper.validateExportParams(params);
       console.log('test', exportParamsData);
      if (!!exportParamsData) {
        setExportParamsData(exportParamsData);
      }
    }
  }, [location]);

  useEffect(() => {
    let mounted = true;

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    if (!!exportParamsData) {
      if (exportParamsData.indicators.length) {
        trackPromise(
          Promise.all([
            getElementDataPromise(
              exportParamsData.indicators,
              source,
              exportParamsData.regions,
              exportParamsData.years,
            ),
            getElementSourcesPromise(exportParamsData.indicators, source),
          ])
            .then((results) => {
              if (mounted) {
                const elementData = results[0];
                const elementSources = results[1];
                const elementIndicatorsNames = DataAdapter.adaptSourcesData(
                  results[0].indicators,
                );
                const collection = exportParamsData.collection;
                const chartTypes = exportParamsData.chartTypesOptions;
                const processedDataElement = new ProcessedDataElement(
                  elementData,
                  elementSources,
                  collection,
                  chartTypes,
                  elementIndicatorsNames,
                );
                let exportElementData ={
                  collection: exportParamsData.collection,
                  indicators: results[0].indicators,
                  indicatorsNames: processedDataElement.indicatorsNames,
                  sources: processedDataElement.sources,
                  elementData: processedDataElement.data,
                  chartTypesOptions: exportParamsData.chartTypesOptions,
                  chartGuidesOption: exportParamsData.chartGuidesOption,
                  chartColorsOption: exportParamsData.chartColorsOption,
                  chartLegendOption: exportParamsData.chartLegendOption,
                  text: exportParamsData.text,
                  chartNestingLevel: processedDataElement.chartNestingLevel,
                  indicatorsDict: processedDataElement.indicatorsDict,
                  adaptedData: processedDataElement.adaptedData,
                  chartNestingLevelGroupingKeys:
                    processedDataElement.chartNestingLevelGroupingKeys,
                  maxNumElems: processedDataElement.maxNumElems,
                  yearsNestedData: processedDataElement.yearsNestedData,
                  yearsGroupingKeys: processedDataElement.yearsGroupingKeys,
                  linesAdaptedData:
                    processedDataElement.linesBarsData.linesAdaptedData,
                  barsAdaptedData:
                    processedDataElement.linesBarsData.barsAdaptedData,
                  subXScaleKeys:
                    processedDataElement.linesBarsData.subXScaleKeys,
                }
                if(exportParamsData.titleText.length > 0 && exportParamsData.titleText != 'undefined'){
                  exportElementData.titleText = exportParamsData.titleText
                }
                if(exportParamsData.subtitleText.length > 0 && exportParamsData.subtitleText != 'undefined'){
                  exportElementData.subtitleText = exportParamsData.subtitleText
                }
                setExportElementData(exportElementData);
              }
            })
            .catch((error) => {
              console.log('error', error);
            }),
        );
      }
    }

    return function cleanup() {
      mounted = false;
      source.cancel('axios request cancelled');
    };
  }, [exportParamsData]);

  return (
    <div className="shared-content" ref={ref}>
      {!!exportElementData && (
        <div className="actual-export-content" id="actual-export-content">
          <div className="chart-box">
            {console.log("Envio del elemento",exportElementData)}
            <ExportChart
              id={uuidv4()}
              visualizerMode={modes.PREVIEW}
              indicatorsNames={exportElementData.indicatorsNames}
              collection={exportElementData.collection}
              sources={exportElementData.sources}
              titleText={exportElementData.titleText}
              subtitleText={exportElementData.subtitleText}
              elementData={exportElementData.elementData}
              chartTypesOptions={exportElementData.chartTypesOptions}
              chartGuidesOption={exportElementData.chartGuidesOption}
              chartColorsOption={exportElementData.chartColorsOption}
              chartLegendOption={exportElementData.chartLegendOption}
              chartNestingLevel={exportElementData.chartNestingLevel}
              indicatorsDict={exportElementData.indicatorsDict}
              adaptedData={exportElementData.adaptedData}
              chartNestingLevelGroupingKeys={
                exportElementData.chartNestingLevelGroupingKeys
              }
              maxNumElems={exportElementData.maxNumElems}
              yearsNestedData={exportElementData.yearsNestedData}
              yearsGroupingKeys={exportElementData.yearsGroupingKeys}
              linesAdaptedData={exportElementData.linesAdaptedData}
              barsAdaptedData={exportElementData.barsAdaptedData}
              subXScaleKeys={exportElementData.subXScaleKeys}
            />
            <div>
              {exportElementData.text.map((contentText) => {
                if (contentText === null || contentText === '') {
                  return null;
                } else {
                  return (
                    <div className="text-content">
                      {Object.values(contentText.split('\n')).map(
                        (contentTextLine, index) => {
                          return <p key={btoa(index)}>{contentTextLine}</p>;
                        },
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <Row className="visualizer-disclaimer-container">
            <Disclaimer />
          </Row>
          <br />
        </div>
      )}
    </div>
  );
});

export default ExportFullElement;
