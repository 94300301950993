import StoryElement from './StoryElement';
import ChartConfiguration from './ChartConfiguration';
import {
  INDICATORS_KEY,
  COLLECTION_KEY,
  REGIONS_KEY,
  YEARS_KEY,
  TITLE_TEXT_KEY,
  SUBTITLE_TEXT_KEY,
  DEFAULT_DATA_ELEMENT,
  ELEMENT_TYPE_KEY,
} from '../shared/constants';

// Cuando es un elemento nuevo, la llave se genera dinámicamente pero los indicadores y la colección sí se devben pasar como parámetros
class DataElement extends StoryElement {
  constructor(dbElementObject) {
    //console.log('dbElementObject en DataElement', dbElementObject);

    super(dbElementObject);

    this._type =
      ELEMENT_TYPE_KEY in dbElementObject
        ? dbElementObject[ELEMENT_TYPE_KEY]
        : DEFAULT_DATA_ELEMENT[ELEMENT_TYPE_KEY];

    // Se maneja como un array donde se almacenan los ids de los indicadores
    // No puede estar ausente en dbElementObject
    this._indicators = dbElementObject[INDICATORS_KEY];

    // No puede estar ausente en dbElementObject
    this._collection = dbElementObject[COLLECTION_KEY];

    // Se maneja como un array donde se almacenan los ids de las regiones
    this._regions =
      REGIONS_KEY in dbElementObject
        ? dbElementObject[REGIONS_KEY]
        : DEFAULT_DATA_ELEMENT[REGIONS_KEY];

    // Se maneja como un array donde se almacenan los años
    this._years =
      YEARS_KEY in dbElementObject
        ? dbElementObject[YEARS_KEY]
        : DEFAULT_DATA_ELEMENT[YEARS_KEY];

    this._titleText =
      TITLE_TEXT_KEY in dbElementObject
        ? dbElementObject[TITLE_TEXT_KEY]
        : DEFAULT_DATA_ELEMENT[TITLE_TEXT_KEY];

    this._subtitleText =
      SUBTITLE_TEXT_KEY in dbElementObject
        ? dbElementObject[SUBTITLE_TEXT_KEY]
        : DEFAULT_DATA_ELEMENT[SUBTITLE_TEXT_KEY];

    this._chartConfiguration = new ChartConfiguration(dbElementObject);
  }

  get indicators() {
    return this._indicators;
  }
  get collection() {
    return this._collection;
  }
  get regions() {
    return this._regions;
  }
  get years() {
    return this._years;
  }
  get titleText() {
    return this._titleText;
  }
  get subtitleText() {
    return this._subtitleText;
  }
  get chartConfiguration() {
    return this._chartConfiguration;
  }

  set titleText(titleText) {
    this._titleText = titleText;
  }
  set subtitleText(subtitleText) {
    this._subtitleText = subtitleText;
  }
  set chartConfiguration(chartConfiguration) {
    this._chartConfiguration = chartConfiguration;
  }

  setDataSelection(indicators, collection, regions, years) {
    this._indicators = indicators;
    this._collection = collection;
    this._regions = regions;
    this._years = years;

    this._chartConfiguration.adjustTypes(this.getDbObject());

    StoryElement.prototype.updateVersion.apply(this);
  }

  // Reemplazar por uno que utilice el formato más reciente para especificar elementos
  updateOldFormat(configurationOldFormat) {
    this._titleText = configurationOldFormat.title_text;
    this._subtitleText = configurationOldFormat.subtitle_text;
    this._chartConfiguration.updateOldFormat(configurationOldFormat);

    StoryElement.prototype.updateVersion.apply(this);
  }

  update(configuration) {
    this.updateOldFormat(configuration);
  }

  getDbObject() {
    const dbObject = {};
    const parentAttributes = StoryElement.prototype.getDbObject.apply(this);
    const dataElementAttributes = {
      [INDICATORS_KEY]: this._indicators,
      [COLLECTION_KEY]: this._collection,
      [REGIONS_KEY]: this._regions,
      [YEARS_KEY]: this._years,
      [TITLE_TEXT_KEY]: this._titleText,
      [SUBTITLE_TEXT_KEY]: this._subtitleText,
    };
    const chartConfigurationAttributes = this._chartConfiguration.getDbObject();
    Object.assign(
      dbObject,
      parentAttributes,
      dataElementAttributes,
      chartConfigurationAttributes,
    );

    return dbObject;
  }
}

export default DataElement;
