import React, { useState, useEffect, forwardRef } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import UserSessionDataService from './shared/UserSessionDataService';
import { useSelector } from 'react-redux';
import { HOME_ADMIN, } from './shared/constants';
import GoogleAnalytics from './shared/GoogleAnalytics.js';
const Login = forwardRef(({}, ref) => {
  
  useEffect(()=>{
    GoogleAnalytics.sendInformation(window.location.pathname + window.location.search);
  });
  const [userTextValue, setUserTextValue] = useState(null);
  const [passwordTextValue, setPasswordTextValue] = useState(null);

  const selectCookie = (state) => state.cookie;
  const cookie = useSelector(selectCookie);

  const onSubmit = (event) => {
    UserSessionDataService.adminLogin(cookie, userTextValue, passwordTextValue)
      .then((result) => {
        // window.location = `/${VIEW_ALL_STORIES}`;
        window.location = `/${HOME_ADMIN}`;
      })
      .catch((error) => {
        //console.log('error', error);
      });
  };

  const onInput = (event) => {
    //console.log('event', event);

    if (!!event.target && !!event.target.id) {
      const id = event.target.id;

      if (id === 'user') {
        if (event.target.value !== null && event.target.value !== '') {
          setUserTextValue(event.target.value);
        } else {
          setUserTextValue(null);
        }
      } else if (id === 'password') {
        if (event.target.value !== null && event.target.value !== '') {
          setPasswordTextValue(event.target.value);
        } else {
          setPasswordTextValue(null);
        }
      }
    }
  };

  const isLoginDisabled = () => {
    return userTextValue === null || passwordTextValue === null;
  };

  return (
    <div className="content mb-4" ref={ref}>
      <Row className="row-info-container">
        <h2>Inicio de sesión Administrador</h2>
      </Row>
      <Row>
        <Form encType="multipart/form-data" as={Col}>
          <Form.Group
            as={Row}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Col xs={12} md={2}>
              <Form.Label column>Usuario</Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Control
                required
                type="text"
                maxLength={255}
                id="user"
                name="usuario"
                onInput={onInput}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Col xs={12} md={2}>
              <Form.Label column>Contraseña</Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Control
                required
                type="password"
                maxLength={32}
                id="password"
                name="contrasena"
                onInput={onInput}
              />
            </Col>
          </Form.Group>
          <br />
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={isLoginDisabled()}
            >
              Ingresar
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
});

export default Login;
