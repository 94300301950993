import React, { useState, useEffect, useContext, forwardRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilterElement from './components/DataSelection/FilterElement';
import IndicatorsCarousel from './components/DataSelection/IndicatorsCarousel';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import IndicatorDetails from './components/Data/IndicatorDetails';
import GenericMessage from './shared/GenericMessage';
import UserSessionDataService from './shared/UserSessionDataService';
import PENDataService from './shared/PENDataService.js';
import axios from 'axios';
import { useSelector } from 'react-redux';
import store from './store';
import {
  INDICATOR_DETAILS_OPTION_CLOSED,
  SESSION_SET,
  ERROR_MESSAGE_OPENED,
  SELECTION_SET,
} from './actions/types';
import {
  dataSelectionModes,
  VISUALIZER_EDIT_MODE_PATH,
  COLLECTION_FILTER_ID,
  TOPIC_FILTER_ID,
  REGION_FILTER_ID,
  YEAR_FILTER_ID,
  STORY_DATA_ELEMENTS_LIMIT_REACHED_MESSAGE,
  KEY_GOOGLE_ANA,
} from './shared/constants';
import filters from './models/filters';
import { DataSelectionContext } from './components/DataSelection/DataSelectionContext';
import { useHistory } from 'react-router-dom';
import Selection from './models/Selection';
import { trackPromise } from 'react-promise-tracker';
import DataElementDownloadOptions from './components/Data/DataElementDownloadOptions';
import GoogleAnalytics from './shared/GoogleAnalytics.js';
// Se puede llegar desde la página de inicio. En este caso no hay elementKey
// Se puede llegar desde la opción de editar datos del gráfico. En este caso sí hay elementKey
// elementKey se extrae del historial de navegación, vía location

// Si se llega con datos del buscador o con datos del elementKey, hay que cargar esos datos

/* DATA SELECTOR COMPONENT */
const DataSelector = forwardRef(({ location }, ref) => {
  console.log('Soy un ref', ref);
  // console.log('[DataSelector] is running');
  useEffect(() => {
    GoogleAnalytics.sendInformation(
      window.location.pathname + window.location.search,
    );
  });

  let history = useHistory();

  const elementKey = location.state.elementKey;
  const dataSelectionMode = location.state.dataSelectionMode;
  const loadedSearchString = location.state.searchString;
  const selectedValue = location.state.selectedValue;
  //console.log(selectedValue);

  const cleanSelectedValue = () => (location.state.selectedValue = null);

  const [submitActionDisabled, setSubmitActionDisabled] = useState(true);
  const [downloadActionDisabled, setDownloadActionDisabled] = useState(true);

  const [loading, setLoading] = useState(true);

  const [allYearSelected, setAllYearSelected] = useState(true);

  const [accountedLoadedSearchString, setAccountedLoadedSearchString] =
    useState(false);
  const [accountedExistingData, setAccountedExistingData] = useState(false);

  /* Acceso a la selección de datos como contexto */
  const context = useContext(DataSelectionContext);

  /* Acceso al objeto de la sesión */
  const selectSession = (state) => state.session;
  const session = useSelector(selectSession);

  /* Acceso a la cookie de la sesión */
  const selectCookie = (state) => state.cookie;
  const cookie = useSelector(selectCookie);

  /* Acceso al objeto de selección */
  const selectSelection = (state) => state.selection;
  const selectionSimpleFormatObject = useSelector(selectSelection);
  console.log(selectionSimpleFormatObject);
  /* DATA REQUESTS */
  const getIndicatorsPromise = (selection, source) => {
    const searchString = selection.searchString;
    return PENDataService.getNumIndicators(searchString, source);
  };

  const getFiltersPromise = (selection, filterId, source) => {
    const indicatorsSelectedValues = selection.getIndicatorsSelectedValues();
    const filters = [
      {
        id: COLLECTION_FILTER_ID,
        selectedValues: selection.getFilterSelectedValues(COLLECTION_FILTER_ID),
      },
      {
        id: TOPIC_FILTER_ID,
        selectedValues: selection.getFilterSelectedValues(TOPIC_FILTER_ID),
      },
      {
        id: REGION_FILTER_ID,
        selectedValues: selection.getFilterSelectedValues(REGION_FILTER_ID),
      },
      {
        id: YEAR_FILTER_ID,
        selectedValues: selection.getFilterSelectedValues(YEAR_FILTER_ID),
      },
    ];
    return PENDataService.getFilterValuesComplete(
      filterId,
      indicatorsSelectedValues,
      filters,
      source,
    );
  };

  /* HOOKS */

  /**
   * Efecto para hacer scroll al inicio de la página siempre que se entre al componente
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Efecto para crear el objeto que encapsula la sesión en el context
   */
  useEffect(() => {
    if (!!session) {
      // console.log('session', session);
      context.parseSession(session);
    }
  }, [session]);

  /**
   * Efecto para manejar la sesión y la selección desde el context
   */
  useEffect(() => {
    if (!!selectionSimpleFormatObject && !!context.session) {
      let mounted = true;

      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      const selection = new Selection(selectionSimpleFormatObject);

      if (!!loadedSearchString && !accountedLoadedSearchString) {
        selection.searchString = loadedSearchString;
      }
      //PARA manejar caso en donde en el buscador se selecciona un buscador. en element key viene al key del indicador
      if (
        dataSelectionMode === dataSelectionModes.NEW &&
        !!location.state.selectedValue
      ) {
        selection.indicatorsSelectedValues = [selectedValue];
      }

      if (
        dataSelectionMode === dataSelectionModes.EDIT &&
        !!elementKey &&
        !accountedExistingData
      ) {
        const parsedSession = context.session;
        if (!UserSessionDataService.sessionHasValidStory(parsedSession)) {
          return;
        }

        const story = UserSessionDataService.getSessionStory(parsedSession);

        if (
          !UserSessionDataService.storyHasValidElementByKey(story, elementKey)
        ) {
          return;
        }

        const currentElement = UserSessionDataService.getStoryElementByKey(
          story,
          elementKey,
        );
        selection.collectionFilterSelectedValues = [currentElement.collection];

        selection.indicatorsSelectedValues = currentElement.indicators;
        selection.regionFilterSelectedValues = currentElement.regions;
        selection.yearFilterSelectedValues = currentElement.years;
        console.log(selection.indicatorsSelectedValues);
      }
      trackPromise(
        Promise.all([
          getIndicatorsPromise(selection, source),
          getFiltersPromise(selection, COLLECTION_FILTER_ID, source),
          getFiltersPromise(selection, TOPIC_FILTER_ID, source),
          getFiltersPromise(selection, REGION_FILTER_ID, source),
          getFiltersPromise(selection, YEAR_FILTER_ID, source),
        ])
          .then((results) => {
            return new Promise((resolve, reject) => {
              if (mounted) {
                setAccountedExistingData(true);
                setAccountedLoadedSearchString(true);

                const selectionCopy = selection.getCopy();

                const numIndicators = results[0];
                selectionCopy.setNumIndicators(numIndicators);

                const collectionValues = results[1];

                selectionCopy.setFilterValues(
                  COLLECTION_FILTER_ID,
                  collectionValues,
                );
                const topicValues = results[2];
                selectionCopy.setFilterValues(TOPIC_FILTER_ID, topicValues);

                const regionValues = results[3];
                selectionCopy.setFilterValues(REGION_FILTER_ID, regionValues);

                // Para poder mostrar una opción de "Todos" en el caso del filtro año,
                // la cual debe estar seleccionada por default si no hay ninguno seleccionado
                const allYearsOption = {
                  id: 'year-all',
                  name: 'Todos',
                  enabled: 1,
                };
                const retrievedYearValues = results[4];
                const actualSelectedYears =
                  selectionCopy.getFilterSelectedValues(YEAR_FILTER_ID);
                const yearValues = [allYearsOption].concat(retrievedYearValues);
                selectionCopy.setFilterValues(YEAR_FILTER_ID, yearValues);

                resolve(selectionCopy);
              } else {
                reject();
              }
            });
          })
          .then((selection) => {
            // console.log('selection', selection);
            // console.log('context', context);
            context.setSelection(selection);
            setLoading(false);
            //Esta linea limpia el valor seleccionado para que no se ejecute una y otra vez
            cleanSelectedValue();
          })
          .catch((error) => {
            console.log('error', error);
          }),
      );

      return function cleanup() {
        mounted = false;
        source.cancel('axios request cancelled');
      };
    }
  }, [selectionSimpleFormatObject, context.session]);

  /**
   * Efecto para determinar si la acción de enviar está habilitada
   */
  useEffect(() => {
    let disable = true;
    const selection = context.selection;
    if (
      !!selection &&
      context.oneCollectionSelected() &&
      context.atLeastOneIndicatorSelected()
    ) {
      console.log('ES cuando la colleccion');
      disable = false;
    }

    setSubmitActionDisabled(disable);
    setDownloadActionDisabled(disable);
  }, [context.selection]);

  /* MESSAGE MODAL */
  const [messageTexts, setMessageTexts] = useState(null);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    setMessageTexts(null);
  };
  const handleShowMessageModal = () => setShowMessageModal(true);

  const getMessageModal = () => {
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        show={!!messageTexts && showMessageModal}
        onHide={handleCloseMessageModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <GenericMessage
            title={messageTexts[0]}
            message={messageTexts[1]}
            onOKClicked={handleCloseMessageModal}
          />
        </Modal.Body>
      </Modal>
    );
  };

  /* INDICATOR DETAILS MODAL */

  /* Acceso a la opción de ver los detalles de un indicador */
  const selectShowIndicatorDetails = (state) =>
    state.indicators.showIndicatorDetails;
  const showIndicatorDetailsModal = useSelector(selectShowIndicatorDetails);

  /**
   * Handler para el cierre del modal de detalles del indicador
   */
  const handleCloseIndicatorDetailsModal = () => closeIndicatorDetails();

  /**
   * Envía a la información compartida la acción para cerrar los detalles del indicador
   */
  const closeIndicatorDetails = () => {
    store.dispatch({
      type: INDICATOR_DETAILS_OPTION_CLOSED,
    });
  };

  /**
   * Devuelve el modal de detalles del indicator
   */
  const getIndicatorDetailsModal = () => {
    return (
      <Modal
        size="lg"
        className="modal-indicator-details"
        show={showIndicatorDetailsModal}
        onHide={handleCloseIndicatorDetailsModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <IndicatorDetails />
        </Modal.Body>
      </Modal>
    );
  };

  /* DOWNLOAD OPTIONS */
  const [showDownloadOptionsModal, setShowDownloadOptionsModal] =
    useState(false);

  const handleCloseDownloadOptionsModal = () => {
    setShowDownloadOptionsModal(false);
  };

  const handleShowDownloadOptionsModal = () => {
    setShowDownloadOptionsModal(true);
  };

  const getDownloadOptionsModal = () => {
    return (
      <Modal
        size="lg"
        className="modal-indicator-details"
        show={showDownloadOptionsModal}
        onHide={handleCloseDownloadOptionsModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <DataElementDownloadOptions
            elementKey={null}
            collection={
              context.getFilterSelectedValues(COLLECTION_FILTER_ID)[0]
            }
            indicators={context.getIndicatorsSelectedValues()}
            regions={context.getFilterSelectedValues(REGION_FILTER_ID)}
            years={context.getFilterSelectedValues(YEAR_FILTER_ID)}
          />
        </Modal.Body>
      </Modal>
    );
  };

  /**
   * Devuelve los elementos para filtrado según la especificación del modelo de filtros
   */
  const getFilters = () => {
    return Object.values(filters).map((element, index) => {
      return (
        <FilterElement
          // Acá el uso de una llave nueva más bien bloquea la aplicación
          // por exceso de procesamiento
          key={btoa(index)}
          id={element.id}
          label={element.label}
          values={context.getFilterValues(element.id)}
          selectedValues={context.getFilterSelectedValues(element.id)}
        />
      );
    });
  };

  /* MAIN */
  const getFiltersSection = () => {
    return (
      <>
        <Row>
          <Col className="filters-col">{getFilters()}</Col>
        </Row>
      </>
    );
  };

  const getIndicatorsSection = () => {
    return (
      <IndicatorsCarousel
        numIndicators={context.getNumIndicators()}
        collectionFilterSelectedValues={context.getFilterSelectedValues(
          COLLECTION_FILTER_ID,
        )}
        topicFilterSelectedValues={context.getFilterSelectedValues(
          TOPIC_FILTER_ID,
        )}
        regionFilterSelectedValues={context.getFilterSelectedValues(
          REGION_FILTER_ID,
        )}
        yearFilterSelectedValues={context.getFilterSelectedValues(
          YEAR_FILTER_ID,
        )}
        selectedValues={context.getIndicatorsSelectedValues()}
        searchString={context.getSearchString()}
      />
    );
  };

  /* SUBMIT BUTTON */
  const getSubmitText = () => {
    return dataSelectionMode === dataSelectionModes.NEW
      ? 'Graficar'
      : 'Actualizar datos';
  };

  /* OTHER HELPERS */
  const handleSelectedDataElementCreation = (
    indicatorsSelectedValues,
    selectedCollection,
    selectedRegions,
    selectedYears,
  ) => {
    return new Promise((resolve, reject) => {
      const parsedSession = context.session;
      // console.log('parsedSession', parsedSession);
      const canAddElement =
        UserSessionDataService.checkCanAddStoryDataElement(parsedSession);

      if (!canAddElement) {
        reject(new Error('EXCEED_DATA_ELEMENTS_LIMIT'));
      } else {
        UserSessionDataService.createStoryDataElementWithIndicators(
          cookie,
          parsedSession,
          indicatorsSelectedValues,
          selectedCollection,
          selectedRegions,
          selectedYears,
        )
          .then((result) => {
            // console.log('result', result);
            resolve(result);
          })
          .catch((error) => {
            console.log('error', error);
            reject(new Error('INTERNAL_ERROR'));
          });
      }
    });
  };

  const handleSelectedDataElementUpdate = (
    indicatorsSelectedValues,
    selectedCollection,
    selectedRegions,
    selectedYears,
  ) => {
    console.log('Esoty aacasdada');
    return new Promise((resolve, reject) => {
      const parsedSession = context.session;
      UserSessionDataService.updateStoryDataElementIndicators(
        cookie,
        parsedSession,
        elementKey,
        indicatorsSelectedValues,
        selectedCollection,
        selectedRegions,
        selectedYears,
      )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          console.log('error', error);
          reject(new Error('INTERNAL_ERROR'));
        });
    });
  };

  /* EVENTS HANDLERS */

  /**
   * Handler para el click del botón de enviar
   * (Puede ser Graficar o Actualizar los datos)
   */
  const onSubmitClicked = () => {
    // TODO
    // Agregar manejo de la colección para actualizar el elemento de datos
    const selectedCollection =
      context.getFilterSelectedValues(COLLECTION_FILTER_ID)[0];
    // console.log('collection', selectedCollection);

    //console.log('indicatorsSelectedValues', dataSelection.indicatorsSelectedValues);
    // TODO
    // Agregar comprobación de que hay al menos un indicador seleccionado
    // Esto ya no es necesario si se maneja con la deshabilitación del botón
    const finalSelectedIndicators = context.getIndicatorsSelectedValues();
    // console.log('finalSelectedIndicators', finalSelectedIndicators);

    const selectedRegions = context.getFilterSelectedValues(REGION_FILTER_ID);
    const selectedYears = context.getFilterSelectedValues(YEAR_FILTER_ID);

    // Si el modo es new, no hay elementKey, entonces se envían solo los datos para el nuevo elemento
    // Si el modo es edit, sí hay elementKey, entonces se envían los datos y el id del elemento por actualizar
    let elementPromise;
    if (dataSelectionMode === dataSelectionModes.NEW) {
      elementPromise = handleSelectedDataElementCreation(
        finalSelectedIndicators,
        selectedCollection,
        selectedRegions,
        selectedYears,
      );
    } else {
      elementPromise = handleSelectedDataElementUpdate(
        finalSelectedIndicators,
        selectedCollection,
        selectedRegions,
        selectedYears,
      );
    }
    trackPromise(
      elementPromise
        .then((result) => {
          // console.log('result', result);
          // El primer elemento del resultado es el id del elemento creado/actualizado
          // El segundo elemento del resultado es la sesión actualizada

          // Guardar los datos de la sesión actualizada en redux store
          // por si no se actualiza la página
          store.dispatch({
            type: SESSION_SET,
            payload: result[1],
          });

          // Borrar la selección en el context por si se regresa a esta ruta
          // sin haber actualizado la página
          context.clearShowFilterValues();
          clearSelection();

          history.push({
            pathname: `/${VISUALIZER_EDIT_MODE_PATH}`,
            state: {
              elementKey: result[0],
            },
          });
        })
        .catch((error) => {
          if (
            !!error.message &&
            error.message === 'EXCEED_DATA_ELEMENTS_LIMIT'
          ) {
            setMessageTexts([
              null,
              <p>
                {`${STORY_DATA_ELEMENTS_LIMIT_REACHED_MESSAGE}`}. Puede
                administrar los gráficos existentes en el{' '}
                <a href={`${VISUALIZER_EDIT_MODE_PATH}`}>visualizador</a>.
              </p>,
            ]);
            handleShowMessageModal();
          } else {
            store.dispatch({
              type: ERROR_MESSAGE_OPENED,
            });
          }
        }),
    );
  };

  const onDownloadClicked = () => {
    handleShowDownloadOptionsModal();
  };

  /**
   * Handler para el click del botón de limpiar la selección
   */
  const onClearSelectionClicked = () => {
    clearSelection();
  };

  /**
   * Handler para el click del botón de regresar
   */
  const onReturnClicked = (e) => {
    e.preventDefault();

    // console.log(history);

    // Borrar la selección en el context por si se regresa a esta ruta
    // sin haber actualizado la página
    // context.clearSelection();
    context.clearShowFilterValues();
    clearSelection();
    history.goBack();
  };

  const clearSelection = () => {
    store.dispatch({
      type: SELECTION_SET,
      payload: {
        collectionFilterValues: [],
        collectionFilterSelectedValues: [],

        topicFilterValues: [],
        topicFilterSelectedValues: [],

        regionFilterValues: [],
        regionFilterSelectedValues: [],

        yearFilterValues: [],
        yearFilterSelectedValues: [],

        indicatorsSelectedValues: [],
        numIndicators: 0,
        searchString: '',
      },
    });
  };

  /* COMPONENT DEFINITION */
  return (
    <>
      {!!messageTexts && getMessageModal()}
      {getIndicatorDetailsModal()}
      {getDownloadOptionsModal()}
      <div className="content" ref={ref}>
        <Row className="mb-4">
          <Col>
            <Link to="/" onClick={onReturnClicked} className="volver-link">
              Volver
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="row-info-container selector-titulo">
            <h2>Datos</h2>
            <h4 className="body-highlight">
              Explore y seleccione los datos que desea utilizar:
            </h4>
          </Col>
        </Row>
        <Row className="reversible-row">
          <Col md="6" className="row-info-container">
            {/* La sección de la izquierda tiene la selección de valores de los filtros */}
            {loading === false && getFiltersSection()}
          </Col>
          <Col md="6" className="data-list-selector">
            {/* La sección de la derecha tiene el buscador y la selección de los indicadores */}
            {loading === false && getIndicatorsSection()}
          </Col>
        </Row>
        <Row className="data-selector-action-bar">
          <Button variant="link-like" onClick={onClearSelectionClicked}>
            Limpiar selección
          </Button>
          <Button
            variant="secondary"
            onClick={onDownloadClicked}
            disabled={downloadActionDisabled}
          >
            Descargar datos
          </Button>
          <Button onClick={onSubmitClicked} disabled={submitActionDisabled}>
            {getSubmitText()}
          </Button>
        </Row>
      </div>
    </>
  );
});

export default DataSelector;
