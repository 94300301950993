import React, { useState, useEffect, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import SelectionMenu from './SelectionMenu';
import resourcesOptions from '../../models/resourcesOptions';
import PENDataService from '../../shared/PENDataService';
import UserSessionDataService from '../../shared/UserSessionDataService';
import { StoryContext } from '../../components/DataVisualization/StoryContext';
import axios from 'axios';
import {
  modes,
  DOWNLOAD_CONFIRMATION_TITLE,
  DOWNLOAD_CONFIRMATION_MESSAGE,
  fileExtensions,
  COLLECTION_FILTER_SHORT_LABEL,
  resourceTypes,
  DEFAULT_DATA_ELEMENT,
  DEFAULT_CHART_TYPE,
  CHART_COLORS_KEY,
  CHART_GUIDES_KEY,
  CHART_LEGEND_KEY,
  TITLE_TEXT_KEY,
  SUBTITLE_TEXT_KEY,
} from '../../shared/constants';
import jszip from 'jszip';
import jsPDF from 'jspdf';
import { trackPromise } from 'react-promise-tracker';

// Para entrar a este componente se debe haber seleccionado un elemento de datos de la historia

/* DATA ELEMENT DOWNLOAD OPTIONS COMPONENT */
function DataElementDownloadOptions(props) {
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState(null);
  // const [processedDataElement, setProcessedDataElement] = useState(null);
  const [indicators, setIndicators] = useState(null);
  const [downloadDisabled, setDownloadDisabled] = useState(true);
  const [showConfirmDownloadModal, setShowConfirmDownloadModal] = useState(
    false,
  );
  const [exportParams, setExportParams] = useState(null);

  const elementKey = props.elementKey;

  /* Acceso a la historia del visualizador */
  const context = useContext(StoryContext);

  /* DATA REQUESTS */
  const getElementDataPromise = (indicators, source, regions, years) => {
    return PENDataService.getIndicatorsDataEntries(
      indicators,
      source,
      regions,
      years,
    );
  };

  const getElementSourcesPromise = (indicators, source) => {
    return PENDataService.getIndicatorsSourcesByIndicatorsKeys(
      indicators,
      source,
    );
  };

  const downloadMetadataPromise = (indicators, source) => {
    return new Promise((resolve, reject) => {
      PENDataService.downloadIndicatorsMetadataFile(indicators, source)
        .then((result) => {
          // console.log('result', result);
          const fileData = result.data;
          const filename = 'metadatos';
          const fileExtension = fileExtensions.SPREADSHEET;

          resolve({
            data: fileData,
            name: filename,
            extension: fileExtension,
            createBlob: true,
          });
        })
        .catch((error) => {
          console.log('error', error);
          reject();
        });
    });
  };

  const downloadDataPromise = (indicators, regions, years, source) => {
    return new Promise((resolve, reject) => {
      PENDataService.downloadIndicatorsDataFile(
        indicators,
        source,
        regions,
        years,
      )
        .then((result) => {
          // console.log('result', result);
          const fileData = result.data;
          const filename = 'datos';
          const fileExtension = fileExtensions.SPREADSHEET;

          resolve({
            data: fileData,
            name: filename,
            extension: fileExtension,
            createBlob: true,
          });
        })
        .catch((error) => {
          console.log('error', error);
          reject();
        });
    });
  };

  const downloadCollectionPromise = (collection, source) => {
    return new Promise((resolve, reject) => {
      PENDataService.downloadCollectionFile(collection, [], source)
        .then((result) => {
          // console.log('result', result);
          const fileData = result.data;

          // Para que el nombre sea de la forma compendio_0, compendio_1, ...
          const filename = `${COLLECTION_FILTER_SHORT_LABEL.toLowerCase()}_${collection}`;

          const fileExtension = fileExtensions.SPREADSHEET;

          resolve({
            data: fileData,
            name: filename,
            extension: fileExtension,
            createBlob: true,
          });
        })
        .catch((error) => {
          console.log('error', error);
          reject();
        });
    });
  };

  const downloadChartPromise = async (type) => {
    if (exportParams === null) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }

    const adaptedExportParams = {
      ...exportParams,
      type: fileExtensions.PNG,
      encode: true,
    };

    return new Promise((resolve, reject) => {
      UserSessionDataService.getExportElement(adaptedExportParams)
        .then((result) => {
          const imageToPdf = type === fileExtensions.PDF;
          resolve({
            data: result,
            name: 'grafico',
            extension: type,
            imageToPdf,
            createBlob: false,
          });
        })
        .catch((error) => {
          console.log('error', error);
          reject();
        });
    });
  };

  /* OTHER HELPERS */
  const elementKeyIsValid = () => {
    return context.session.getSessionStory().elementKeyIsValid(elementKey);
  };

  const getElement = () => {
    return context.session.getSessionStory().getElementByKey(elementKey);
  };

  const getStory = () => {
    return context.session.getSessionStory();
  };

  const getOptionsArray = (field) => {
    let optionsArray = field.options;
    if (field.id === 'indicator') {
      optionsArray = optionsArray.concat(
        indicators.map((indicator) => {
          return {
            ...indicator,
            id: `${resourceTypes.METADATA}-indicator-${indicator.id}`,
            label: indicator.name,
          };
        }),
      );
    }
    return optionsArray;
  };

  const isFieldDisabled = (resourceId) => {
    return !selection[resourceId]['checked'];
  };

  const getResourceOptionFieldLabel = (resourceId, fieldId, defaultValue) => {
    const selectedValues = Object.values(
      selection[resourceId]['fields'][fieldId],
    ).reduce((valuesAccum, currOption) => {
      if (currOption['checked']) {
        valuesAccum.push(currOption['label']);
      }
      return valuesAccum;
    }, []);

    return selectedValues.length ? selectedValues.join(', ') : defaultValue;
  };

  const mapFormatSpecification = (formatSpecification) => {
    switch (formatSpecification) {
      case `${resourceTypes.CHART}-format-pdf`:
      case `${resourceTypes.DATA}-format-pdf`:
        return fileExtensions.PDF;
      case `${resourceTypes.CHART}-format-png`:
        return fileExtensions.PNG;
      case `${resourceTypes.DATA}-format-xls`:
        return fileExtensions.SPREADSHEET;
      default:
        return null;
    }
  };

  const mapIndicatorSpecificaction = (indicatorSpecification) => {
    const actualIndicatorString = indicatorSpecification.replace(
      `${resourceTypes.METADATA}-indicator-`,
      '',
    );

    if (actualIndicatorString === 'all') {
      return !!elementKey ? getElement().indicators : props.indicators;
    }

    return [parseInt(actualIndicatorString)];
  };

  const getSelectedResources = () => {
    return Object.keys(selection).reduce((resourcesAccum, currResourceId) => {
      // El recurso debe aparecer como checked para que se considere seleccionado
      if (selection[currResourceId]['checked']) {
        const fields = selection[currResourceId]['fields'];

        if (currResourceId === resourceTypes.DATA) {
          const rangeSelection = Object.keys(fields['range']).reduce(
            (rangeAccum, currRangeOptionId) => {
              if (
                selection[currResourceId]['fields']['range'][currRangeOptionId][
                  'checked'
                ]
              ) {
                rangeAccum = currRangeOptionId.replace(
                  `${resourceTypes.DATA}-range-`,
                  '',
                );
              }
              return rangeAccum;
            },
            null,
          );

          if (!!rangeSelection) {
            Object.keys(fields['format']).reduce(
              (formatAccum, currFormatOptionId) => {
                if (
                  selection[currResourceId]['fields']['format'][
                    currFormatOptionId
                  ]['checked']
                ) {
                  resourcesAccum.push({
                    type: resourceTypes.DATA,
                    format: mapFormatSpecification(currFormatOptionId),
                    range: rangeSelection,
                    indicators: null,
                  });
                }
                return formatAccum;
              },
              null,
            );
          }
        } else if (currResourceId === resourceTypes.CHART) {
          Object.keys(fields['format']).reduce(
            (formatAccum, currFormatOptionId) => {
              if (
                selection[currResourceId]['fields']['format'][
                  currFormatOptionId
                ]['checked']
              ) {
                resourcesAccum.push({
                  type: resourceTypes.CHART,
                  format: mapFormatSpecification(currFormatOptionId),
                  range: null,
                  indicators: null,
                });
              }
              return formatAccum;
            },
            null,
          );
        } else if (currResourceId === resourceTypes.METADATA) {
          Object.keys(fields['indicator']).reduce(
            (indicatorAccum, currIndicatorOptionId) => {
              if (
                selection[currResourceId]['fields']['indicator'][
                  currIndicatorOptionId
                ]['checked']
              ) {
                resourcesAccum.push({
                  type: resourceTypes.METADATA,
                  format: null,
                  range: null,
                  indicators: mapIndicatorSpecificaction(currIndicatorOptionId),
                });
              }
              return indicatorAccum;
            },
            null,
          );
        }
      }

      return resourcesAccum;
    }, []);
  };

  const getDefaultChartTypesOptions = (indicators) => {
    return indicators.reduce((accum, curr) => {
      accum[curr] = DEFAULT_CHART_TYPE;
      return accum;
    }, {});
  };

  /* HOOKS */

  /**
   * Efecto para crear el objeto que encapsula los datos del elemento en el context
   */
  useEffect(() => {
    // Si elementKey es null porque se viene del selector de datos, no es necesario hacer esta validación
    // Igualmente las asignaciones que dependen de elementKey se brincan si elementKey es null,
    // porque al venir del selector de datos no puede haber contexto de historia ni historia
    if (!!elementKey && !elementKeyIsValid()) {
      return;
    }

    let mounted = true;

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const currentElement = !!elementKey ? getElement() : null;
    const story = !!elementKey ? getStory() : null;

    const elements = !!story
      ? UserSessionDataService.getStoryElementsArray(story)
      : null;

    const texts = !!elements
      ? UserSessionDataService.getFollowingTextsArrayForDataElementByKey(
          elements,
          currentElement.key,
        )
      : [];

    const indicators = !!currentElement
      ? currentElement.indicators
      : props.indicators;
    const regions = !!currentElement ? currentElement.regions : props.regions;
    const years = !!currentElement ? currentElement.years : props.years;

    if (indicators.length) {
      trackPromise(
        Promise.all([
          getElementDataPromise(indicators, source, regions, years),
          getElementSourcesPromise(indicators, source),
        ])
          .then((results) => {
            if (mounted) {
              const exportParams = {
                indicators: !!currentElement
                  ? currentElement.indicators
                  : props.indicators,
                regions: !!currentElement
                  ? currentElement.regions
                  : props.regions,
                years: !!currentElement ? currentElement.years : props.years,
                collection: !!currentElement
                  ? currentElement.collection
                  : props.collection,
                titleText: !!currentElement
                  ? currentElement.titleText
                  : DEFAULT_DATA_ELEMENT[TITLE_TEXT_KEY],
                subtitleText: !!currentElement
                  ? currentElement.subtitleText
                  : DEFAULT_DATA_ELEMENT[SUBTITLE_TEXT_KEY],
                chartTypesOptions: !!currentElement
                  ? currentElement.chartConfiguration.types
                  : getDefaultChartTypesOptions(indicators),
                chartGuidesOption: !!currentElement
                  ? currentElement.chartConfiguration.guides
                  : DEFAULT_DATA_ELEMENT[CHART_GUIDES_KEY],
                chartColorsOption: !!currentElement
                  ? currentElement.chartConfiguration.colors
                  : DEFAULT_DATA_ELEMENT[CHART_COLORS_KEY],
                chartLegendOption: !!currentElement
                  ? currentElement.chartConfiguration.legend
                  : DEFAULT_DATA_ELEMENT[CHART_LEGEND_KEY],
                text: texts,
              };
              // type y encode se asignan al hacer la descarga con export params
              setIndicators(results[0].indicators);
              setExportParams(exportParams);
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log('error', error);
          }),
      );
    }

    return function cleanup() {
      mounted = false;
      source.cancel('axios request cancelled');
    };
  }, []);

  /**
   * Efecto para manejar el objeto de selección
   */
  useEffect(() => {
    if (!loading) {
      const selection = Object.values(resourcesOptions).reduce(
        (selectionAccum, currResource) => {
          const fields = currResource.fields.reduce(
            (fieldsAccum, currField) => {
              if (currField.id !== 'empty') {
                let optionsArray = getOptionsArray(currField);
                const options = optionsArray.reduce(
                  (optionsAccum, currOption) => {
                    optionsAccum[currOption.id] = {
                      checked: false,
                      label: currOption.label,
                    };
                    return optionsAccum;
                  },
                  {},
                );

                fieldsAccum[currField.id] = options;
              }

              return fieldsAccum;
            },
            {},
          );
          selectionAccum[currResource.id] = {
            checked: false,
            fields,
          };
          return selectionAccum;
        },
        {},
      );
      setSelection(selection);
    }
  }, [loading]);

  /**
   * Efecto para manejar la deshabilitación del botón de descarga
   */
  useEffect(() => {
    const getNumResourcesWithValidSelection = () => {
      return Object.keys(selection).reduce((resourcesAccum, currResourceId) => {
        if (selection[currResourceId]['checked']) {
          const fields = selection[currResourceId]['fields'];
          const numFields = Object.keys(fields).length;

          // Se recorren todos los campos del recurso para ver si la selección es válida

          const numFieldsWithValidSelection = Object.keys(fields).reduce(
            (fieldsAccum, currFieldId) => {
              const options = selection[currResourceId]['fields'][currFieldId];
              const numSelectedOptions = Object.keys(options).reduce(
                (optionsAccum, currOptionId) => {
                  if (
                    selection[currResourceId]['fields'][currFieldId][
                      currOptionId
                    ]['checked']
                  ) {
                    optionsAccum += 1;
                  }

                  return optionsAccum;
                },
                0,
              );

              if (numSelectedOptions) {
                fieldsAccum += 1;
              }

              return fieldsAccum;
            },
            0,
          );

          if (numFieldsWithValidSelection === numFields) {
            resourcesAccum += 1;
          }
        }

        return resourcesAccum;
      }, 0);
    };

    const getNumSelectedResources = () => {
      return Object.keys(selection).reduce((resourcesAccum, currResourceId) => {
        if (selection[currResourceId]['checked']) {
          resourcesAccum += 1;
        }
        return resourcesAccum;
      }, 0);
    };

    if (!!selection) {
      const numSelectedResources = getNumSelectedResources();
      const numResourcesWithValidSelection = getNumResourcesWithValidSelection();
      // Todos los recursos seleccionados tienen que tener una selección válida en sus campos
      const disable =
        numResourcesWithValidSelection === 0 ||
        numSelectedResources !== numResourcesWithValidSelection;
      setDownloadDisabled(disable);
    }
  }, [selection]);

  /* CONFIRMATION */
  const handleCloseConfirmModal = () => setShowConfirmDownloadModal(false);
  const handleShowConfirmModal = () => setShowConfirmDownloadModal(true);

  const getConfirmDownloadModal = () => {
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        show={showConfirmDownloadModal}
        onHide={handleCloseConfirmModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h2>{DOWNLOAD_CONFIRMATION_TITLE}</h2>
          <p className="text-center">{DOWNLOAD_CONFIRMATION_MESSAGE}</p>
          <Row>
            <Button
              variant="secondary"
              onClick={onDownloadClicked}
              className="center-in-row"
            >
              Descargar
            </Button>
          </Row>
          <Row>
            <Button
              variant="link-like"
              onClick={handleCloseConfirmModal}
              className="center-in-row"
            >
              cancelar
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  /* EVENTS HANDLERS */
  const onResourceChecked = (event) => {
    const resourceId = event.target.id;

    const newCheckedValue = !selection[resourceId]['checked'];

    const clearedResourceSelection = Object.keys(
      selection[resourceId]['fields'],
    ).reduce((fieldsAccum, currFieldId) => {
      const fieldSelection = Object.keys(
        selection[resourceId]['fields'][currFieldId],
      ).reduce((optionsAccum, currOptionId) => {
        optionsAccum[currOptionId] = {
          ...selection[resourceId]['fields'][currFieldId][currOptionId],
          checked: false,
        };
        return optionsAccum;
      }, {});
      fieldsAccum[currFieldId] = fieldSelection;
      return fieldsAccum;
    }, {});

    setSelection({
      ...selection,
      [resourceId]: {
        ...selection[resourceId],
        checked: newCheckedValue,
        fields: clearedResourceSelection,
      },
    });
  };

  const onDownloadClicked = () => {
    // Se extraen del objeto de selección los recursos para descargar
    // y se envían para la ejecución de las descargas correspondientes
    const selectedResources = getSelectedResources();
    executeDownloads(selectedResources);
  };

  /* DOWNLOAD OPERATIONS */

  const executeDownloads = (selectedResources) => {
    // console.log('selectedResources', selectedResources);

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const downloadsPromises = selectedResources.map((resource) => {
      const type = resource.type;
      if (type === resourceTypes.CHART) {
        return downloadChartPromise(resource.format);
      } else if (type === resourceTypes.DATA) {
        if (resource.range === 'collection') {
          return downloadCollectionPromise(
            !!elementKey ? getElement().collection : props.collection,
            source,
          );
        } else if (resource.range === 'element') {
          return downloadDataPromise(
            !!elementKey ? getElement().indicators : props.indicators,
            !!elementKey ? getElement().regions : props.regions,
            !!elementKey ? getElement().years : props.years,
            source,
          );
        }
      } else if (type === resourceTypes.METADATA) {
        return downloadMetadataPromise(resource.indicators, source);
      }
      return null;
    });

    // console.log('downloadsPromises', downloadsPromises);

    trackPromise(
      Promise.all(downloadsPromises)
        .then((results) => {
          // console.log('results', results);
          if (results.length === 1) {
            const fileWrapper = results[0];
            handleFileData(fileWrapper);
          } else {
            const zip = new jszip();
            let fileData;
            let filename;
            let fileExtension;
            results.forEach((fileWrapper) => {
              fileData = fileWrapper.data;
              filename = fileWrapper.name;
              fileExtension = fileWrapper.extension;
              const createBlob = fileWrapper.createBlob;
              if (
                fileExtension === fileExtensions.PDF &&
                'imageToPdf' in fileWrapper &&
                fileWrapper.imageToPdf
              ) {
                const pdf = new jsPDF('l', 'px', 'letter');

                const imgProps = pdf.getImageProperties(fileData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(fileData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                zip.file(`${filename}.${fileExtension}`, pdf.output('blob'));
              } else {
                if (createBlob) {
                  zip.file(`${filename}.${fileExtension}`, fileData);
                } else {
                  zip.file(`${filename}.${fileExtension}`, fileData, {
                    base64: true,
                  });
                }
              }
            });
            zip.generateAsync({ type: 'blob' }).then((content) => {
              handleZipFile(content, 'descargas');
            });
          }
        })
        .catch((error) => {
          // TODO
          // Mostrar el modal de error
          console.log('error', error);
        })
        .finally(() => {
          handleCloseConfirmModal();
        }),
    );
  };

  const handleFileData = (fileWrapper) => {
    const fileData = fileWrapper.data;
    const filename = fileWrapper.name;
    const fileExtension = fileWrapper.extension;
    const createBlob = fileWrapper.createBlob;
    if (
      fileExtension === fileExtensions.PDF &&
      'imageToPdf' in fileWrapper &&
      fileWrapper.imageToPdf
    ) {
      const pdf = new jsPDF('l', 'px', 'letter');

      const imgProps = pdf.getImageProperties(fileData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(fileData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${filename}.${fileExtension}`);
    } else {
      const url = createBlob
        ? window.URL.createObjectURL(new Blob([fileData]))
        : `data:image/png;base64,${fileData}`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}.${fileExtension}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleZipFile = (fileData, filename) => {
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.zip`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  /* MAIN */
  const getResourcesOptions = () => {
    return Object.values(resourcesOptions).map((resource) => {
      let resourceId = resource.id;
      let resourceLabel = resource.label;

      let resourceElement = (
        <Col xs={12} lg={2}>
          <Form.Check
            key={`${resourceId}-resource`}
            id={resourceId}
            label={resourceLabel}
            className="form-boxed"
            onChange={onResourceChecked}
          />
        </Col>
      );

      let numColumns = resource.fields.length === 1 ? 10 : 5;
      let numColumnsXS = resource.fields.length === 1 ? 12 : 12;

      let resourceOptionFields = resource.fields.map((field) => {
        let fieldId = field.id;
        let fieldLabel = field.label;
        let fieldType = field.type;
        switch (fieldId) {
          case 'empty':
            return <Col xs={numColumnsXS} lg={numColumns} />;
          default:
            return (
              <Col xs={numColumnsXS} lg={numColumns}>
                <Dropdown
                  key={`${resourceId}-${fieldId}-dropdown`}
                  id={`${resourceId}-${fieldId}`}
                >
                  <Dropdown.Toggle
                    variant="light"
                    disabled={isFieldDisabled(resourceId)}
                  >
                    <Form.Label>
                      {getResourceOptionFieldLabel(
                        resourceId,
                        fieldId,
                        fieldLabel,
                      )}
                    </Form.Label>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <SelectionMenu
                      resourceId={resourceId}
                      fieldId={fieldId}
                      fieldType={fieldType}
                      setParentSelection={setSelection}
                      parentSelection={selection}
                      options={getOptionsArray(field)}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            );
        }
      });
      return (
        <Row>
          {resourceElement}
          {resourceOptionFields}
        </Row>
      );
    });
  };

  /* COMPONENT DEFINITION */
  return (
    <>
      {getConfirmDownloadModal()}
      <Container fluid className="action-modal-container">
        <Row>
          <h2>Opciones de descarga</h2>
        </Row>
        <Row className="pen-options">
          <Col>
            <Row>
              <h4>Documentos</h4>
            </Row>
            {!!selection && getResourcesOptions()}
          </Col>
        </Row>
        <Row>
          <Button
            variant="secondary"
            className="center-in-row"
            disabled={downloadDisabled}
            onClick={handleShowConfirmModal}
          >
            Descargar
          </Button>
        </Row>
      </Container>
    </>
  );
}

export default DataElementDownloadOptions;
