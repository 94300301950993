import React, { useEffect, useContext, createRef } from 'react';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import IconButton from '@material-ui/core/IconButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { StoryContext } from './StoryContext';
import UserSessionDataService from '../../shared/UserSessionDataService';
import store from '../../store';
import { useSelector } from 'react-redux';
import { modes } from '../../shared/constants';
import { SESSION_SET } from '../../actions/types';
import { scroller, Element as ScrollElement } from 'react-scroll';

function TextReadOnlyTextExport(props) {
  const visualizerMode = props.visualizerMode;
  const id = props.id;

  /* Acceso a la historia del visualizador */
  const context = useContext(StoryContext);



  const selectCookie = (state) => state.cookie;
  const cookie = useSelector(selectCookie);

  const getElement = () => {
    return context.session.getSessionStory().getElementByKey(id);
  };

  const getText = () => {
    const contentText = getElement().contentText;
    if (contentText === null || contentText === '') {
      return null;
    }

    const contentTextLines = contentText.split('\n');
    return Object.values(contentTextLines).map((contentTextLine, index) => {
      return <p key={btoa(index)}>{contentTextLine}</p>;
    });
  };

  const toggleTextElementMode = () => {
    getElement().toggleTextMode();
  };

  /**
   * Efecto para pasar al modo de edición en un elemento de texto recién creado
   */
  useEffect(() => {
    if (
      !!context.textEditionElementKey &&
      context.textEditionElementKey === id
    ) {
      toggleTextElementMode();
      context.setScrollElementKey(id);
      store.dispatch({
        type: SESSION_SET,
        payload: context.session.getDbObject(),
      });
    }
  }, [id, context.textEditionElementKey]);

  const onEditTextElementContentClicked = () => {
    toggleTextElementMode();
    context.setScrollElementKey(id);
    store.dispatch({
      type: SESSION_SET,
      payload: context.session.getDbObject(),
    });
  };

  const onDeleteTextElementClicked = () => {
    const session = context.session;
    UserSessionDataService.deleteStoryElement(cookie, session, id)
      .then((result) => {
        // Se obtuvieron los datos de la sesión actualizada
        // Se guardan en redux store
        store.dispatch({
          type: SESSION_SET,
          payload: result,
        });
      })
      .catch(() => {
        console.log('error');
      });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      <Image src={`/img/kebab.svg`} fluid />
    </IconButton>
  ));

  return (

      <Row className="">
        <div className=" readonly">
          <div className=" readonly">{getText()}</div>
        </div>
      </Row>
  );
}

export default TextReadOnlyTextExport;
