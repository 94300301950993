import React from 'react';
import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ToolPage from './ToolPage';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import tools from '../../models/tools';

function ToolsCarousel(props) {
  // let isMobileSite = useMediaQuery('(max-width: 960px)');
  const rootUrl = props.rootUrl;
  const setLink = props.setLink;
  const showModal = props.showShareModal;

  /* Settings del carrusel, se pasan al componente de slick carousel */
  const settings = {
    dots: true,
    infinite: false,
    lazyLoad: true,
    speed: 500,
    centerMode: false,
    //slidesToShow: isMobileSite ? 1 : 3,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Slider
      id="pen-tools-carousel"
      className="pen-tools-carousel"
      {...settings}
    >
      {Object.values(tools).map((element, index) => (
        <ToolPage
          key={btoa(index)}
          id={element.id}
          label={element.label}
          year={element.year}
          collection={element.collection}
          extension={element.extension}
          url={element.url}
          pageInd={index}
          rootUrl={rootUrl}
          setLink = {setLink}
          showModal={showModal}
        />
      ))}
    </Slider>
  );
}

export default ToolsCarousel;
