import React, { forwardRef } from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from '../../Login';

const DataManagerProtectedRoute = forwardRef(
  ({ component: Component, ...rest }, ref) => {
    /* Acceso a la cookie de la sesión */
    const selectCookie = (state) => state.cookie;
    const cookie = useSelector(selectCookie);
    const selectAdminSession = (state) => state.adminSession;
    const adminSession = useSelector(selectAdminSession);

    if (adminSession) {
      return(
        <Route 
          {...rest}
          render={(props) => {
            return <Component {...props} ref={ref} />;
          }}
        />
      );
    }else{
      return (<Route
              exact
              render={(props) => <Login {...props} ref={ref} />}
            />);
    }
  },
);
export default DataManagerProtectedRoute;