import React, { forwardRef } from 'react';
import Tabs from './Tabs';
import TabContent from './TabContent';
import Row from 'react-bootstrap/Row';

const DataElement = forwardRef(({ id, visualizerMode }, ref) => {
  return (
    <Row className="hoja-vis chart-container">
      <Tabs id={id} visualizerMode={visualizerMode} ref={ref}>
        <div label="Gráfico" key={`c-d-${id}`}>
          <TabContent
            contentId="chart"
            id={id}
            visualizerMode={visualizerMode}
            key={`tab-content-${id}`}
            ref={ref}
          />
        </div>
        <div label="Tabla" key={`t-d-${id}`}>
          <TabContent
            contentId="table"
            id={id}
            visualizerMode={visualizerMode}
            key={`tab-content-${id}`}
          />
        </div>
      </Tabs>
    </Row>
  );
});

export default DataElement;
