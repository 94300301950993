import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store';
import Header from './Header';
import Footer from './Footer';
import MainComponent from './MainComponent';
import CookieService from './shared/CookieService';
import UserSessionDataService from './shared/UserSessionDataService';
import LoadingSpinner from './components/common/LoadingSpinner';
import { COOKIE_SET, SESSION_SET, ADMIN_SESSION_SET } from './actions/types';
import GoogleTagManager from './shared/GoogleTagManager';
(async function() {
  await import('bootstrap/dist/css/bootstrap.min.css')
  await import('./App.css')
 })()

/* Información compartida = Redux store */

/* COOKIES AND DB HELPERS */

/**
 * Obtiene un nuevo valor de cookie de sesión, lo almacena en la información compartida
 * y lo devuelve encapsulado en una promesa
 * @returns {Promise} Promesa que representa el valor de la nueva cookie para la sesión
 */
async function getAndStoreNewSessionCookie() {
  const cookie = await UserSessionDataService.getNewSessionCookie();

  return new Promise((resolve, reject) => {
    if (!!cookie && !(cookie instanceof Error)) {
      // Se obtuvo una cookie
      // Se guarda en browser
      CookieService.setCookie(cookie);
      // Se guarda en redux store
      store.dispatch({
        type: COOKIE_SET,
        payload: cookie,
      });
      // Se envía como resultado de éxito de la promesa
      resolve(cookie);
    } else {
      // Se indica fallo de la promesa
      reject(cookie);
    }
  });
}

/**
 * Obtiene el json de la sesión desde la base de datos para un valor de cookie de sesión,
 * lo almacena en la información compartida y lo devuelve encapsulado en una promesa
 * @returns {Promise} Promesa que representa el json de la sesión correspondiente a la cookie de sesión
 */
function getAndStoreSessionDataFromDb(cookie) {
  return new Promise((resolve, reject) => {
    UserSessionDataService.getSessionData(cookie)
      .then((result) => {
        const session = result;
        // Se obtuvieron los datos de la sesión
        // Se guardan en redux store
        store.dispatch({
          type: SESSION_SET,
          payload: session,
        });
        // Se envían como resultado de éxito de la promesa
        resolve(session);
      })
      .catch((error) => {
        // Se indica fallo de la promesa
        reject(error);
      });
  });
}

function getAndStoreIsAdminSession(cookie) {
  return new Promise((resolve, reject) => {
    UserSessionDataService.isAdminSession(cookie)
      .then((result) => {
        const isAdminSession = result;
        if (isAdminSession) {
          store.dispatch({
            type: ADMIN_SESSION_SET,
          });
        }
        resolve(result);
      })
      .catch((error) => {
        // Se indica fallo de la promesa
        reject(error);
      });
  });
}

/* SESSION CONFIGURATION */

/**
 * Configura la sesión a nivel de cookies, base de datos e información compartida de la aplicación
 */
async function configureSession() {
  //console.log('configureSession call in App !!!');

  let cookie = CookieService.getCookie();
  if (!!cookie) {
    // Se encontró una cookie
    //console.log(cookie);
    // Se guarda en redux store
    store.dispatch({
      type: COOKIE_SET,
      payload: cookie,
    });
  } else {
    // No se encontró una cookie, por lo tanto se debe obtener una nueva para una nueva sesión
    cookie = await getAndStoreNewSessionCookie();
  }

  return new Promise((resolve, reject) => {
    // Si no se pudo obtener la cookie, la configuración de la sesión ha fallado
    if (cookie === null || typeof cookie === 'undefined') {
      reject(new Error('GENERIC_SERVER_ERROR'));
    } else if (cookie instanceof Error) {
      reject(cookie);
    }

    // Se obtiene la sesión desde BD para el valor de cookie obtenido
    getAndStoreSessionDataFromDb(cookie)
      .then((result) => {
        const session = result;
        //console.log('datos sesión', session);
        return getAndStoreIsAdminSession(cookie);
      })
      .then((result) => {
        // Para este punto ya se han configurado la cookie, la sesión y si es sesión de administrador
        resolve();
        //console.log('result IsAdminSession', result);
      })
      .catch((error) => {
        console.log('error', error);
        // La configuración de la sesión ha fallado
        if (error instanceof Error) {
          if (error.message === 'SESSION_NOT_FOUND') {
            // Si no se encontró una sesión para la cookie pero la conexión con la base de datos
            // no da error, se elimina la cookie para que se pueda configurar una sesión nueva
            CookieService.deleteCookie();
          }
          reject(error);
        } else {
          reject(new Error('GENERIC_SERVER_ERROR'));
        }
      });
  });
}

/* APP COMPONENT */
function App() {
  return (
    <Router>
      {GoogleTagManager.InitGoogleTag()}
      <LoadingSpinner />
      <Header />
      <MainComponent />
      <Footer />
    </Router>
  );
}

/* Configuración de la sesión cuando se entra desde cualquier punto de la aplicación */
configureSession()
  .then(() => {})
  .catch((error) => {
    if (error instanceof Error && error.message === 'DB_CONNECTION_ERROR') {
      // Si el error es de conexión a la base de datos, se notifica
      alert('No se pudo establecer la conexión. Por favor intente de nuevo.');
    }
    // Los demás errores no se manejan en este punto, de momento
  });

export default App;
