import React, { createContext, useState } from 'react';
import UserSession from '../../models/UserSession';
export const StoryContext = createContext('second');

// This context provider is passed to any component requiring the context

export const StoryProvider = (props) => {
  const parseSession = (dbSessionObject) => {
    console.log('Estoy en story context', dbSessionObject);
    const parsedSession = new UserSession(dbSessionObject);
    setSession(parsedSession);
  };

  const [session, setSession] = useState(null);
  const [textEditionElementKey, setTextEditionElementKey] = useState(null);
  const [scrollElementKey, setScrollElementKey] = useState(null);

  return (
    <StoryContext.Provider
      value={{
        session,
        scrollElementKey,
        textEditionElementKey,
        parseSession,
        setScrollElementKey,
        setTextEditionElementKey,
      }}
    >
      {props.children}
    </StoryContext.Provider>
  );
};
