import {
  COLLECTION_FILTER_ID,
  COLLECTION_FILTER_LABEL,
  TOPIC_FILTER_ID,
  TOPIC_FILTER_LABEL,
  REGION_FILTER_ID,
  REGION_FILTER_LABEL,
  YEAR_FILTER_ID,
  YEAR_FILTER_LABEL,
} from '../shared/constants';

const filters = {
  collection: {
    id: COLLECTION_FILTER_ID,
    label: COLLECTION_FILTER_LABEL,
  },
  topic: {
    id: TOPIC_FILTER_ID,
    label: TOPIC_FILTER_LABEL,
  },
  region: {
    id: REGION_FILTER_ID,
    label: REGION_FILTER_LABEL,
  },
  year: {
    id: YEAR_FILTER_ID,
    label: YEAR_FILTER_LABEL,
  },
};
export default filters;
