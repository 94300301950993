import DataElement from './DataElement';
import TextElement from './TextElement';
import {
  STORY_ELEMENTS_KEY,
  ELEMENT_TYPE_KEY,
  elementTypes,
} from '../shared/constants';

const getElements = (dbStoryObject) => {
  // console.log('dbStoryObject', dbStoryObject);

  let elements = {};

  if (!(STORY_ELEMENTS_KEY in dbStoryObject)) {
    return elements;
  }

  const dbElementsObject = dbStoryObject[STORY_ELEMENTS_KEY];

  if (Object.keys(dbElementsObject) === 0) {
    return elements;
  }

  // Si no hay elementos en la historia, se devuelve un diccionario vacío
  // De otra manera, se procesan los elementos para crear los objetos correspondientes
  // y devolver el diccionario con los elementos

  let storyElement;
  let storyElementKey;
  elements = Object.values(dbElementsObject).reduce((accum, curr) => {
    // curr es un elemento
    // se debe evaluar si es de texto o de datos para crear el objeto respectivo
    if (curr[ELEMENT_TYPE_KEY] === elementTypes.DATA) {
      storyElement = new DataElement(curr);
    } else if (curr[ELEMENT_TYPE_KEY] === elementTypes.TEXT) {
      storyElement = new TextElement(curr);
    } else {
      return accum;
    }

    storyElementKey = storyElement.key;
    accum[storyElementKey] = storyElement;
    return accum;
  }, {});

  return elements;
};

class UserStory {
  constructor(dbStoryObject) {
    //console.log('Estoy en userStory', dbStoryObject);
    this._key = dbStoryObject.key;

    if ('officialKey' in dbStoryObject) {
      this._officcialKey = dbStoryObject['officialKey'];
    } else {
      this._officcialKey = null;
    }

    // Se maneja como un diccionario donde cada value es un elemento de la historia
    this._elements = getElements(dbStoryObject);
  }

  get key() {
    return this._key;
  }

  get elements() {
    return this._elements;
  }

  setSingleStoryElement(key, element) {
    this._elements = {
      [key]: element,
    };
  }

  setStoryElements(elements) {
    this._elements = elements;
  }

  addStoryElement(key, element) {
    this._elements[key] = element;
  }

  deleteStoryElement(key) {
    delete this._elements[key];
  }

  deleteStoryElements() {
    this._elements = {};
  }

  elementKeyIsValid(key) {
    return Object.keys(this._elements).length > 0 && key in this._elements;
  }

  hasValidElements() {
    return Object.keys(this._elements).length > 0;
  }

  getElementsArray() {
    if (Object.keys(this._elements) === 0) {
      return [];
    } else {
      return Object.values(this._elements);
    }
  }

  getElementByKey(key) {
    if (key in this._elements) {
      return this._elements[key];
    } else {
      return null;
    }
  }

  getDataElementByKeyAndFollowingTextElements(key) {
    if (!(key in this._elements)) {
      return null;
    }

    const elements = {};
    let foundKeyElement = false;

    for (let element of Object.values(this._elements)) {
      if (foundKeyElement) {
        if (element.type === elementTypes.DATA) {
          // condición de parada, porque solo se recuperan los elementos de texto siguientes al elemento de la llave
          break;
        } else if (element.type === elementTypes.TEXT) {
          // es un elemento de texto siguiente al elemento de la llave, por lo tanto se incluye en el resultado
          elements[element.key] = element;
        }
      } else if (element.key === key) {
        foundKeyElement = true;
        elements[key] = element;
      }
    }

    return elements;
  }

  getDbObject() {
    let storyElementKey;

    const elements = Object.values(this._elements).reduce((accum, curr) => {
      storyElementKey = curr.key;
      accum[storyElementKey] = curr.getDbObject();
      return accum;
    }, {});

    return {
      key: this._key,
      [STORY_ELEMENTS_KEY]: elements,
    };
  }
}

export default UserStory;
