import { TOOL_LABEL } from '../../shared/constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

function PENTool(props) {
  const id = props.id;
  const label = props.label;
  const year = props.year;
  const collection = props.collection;
  const extension = props.extension;
  const url = props.url;

  const rootUrl = props.rootUrl;
  const setLink = props.setLink;
  const showModal = props.showModal;

  
  const handleShareClick = () => {
    props.setLink(url)
    props.showModal(true);
  }

  return (
    <Col>
      <div className="pen-tool-container">
          <Row className="tool-header">
            <Col xs={10}>
              <span className="element-categoria">[{TOOL_LABEL}]</span>
            </Col>
            <Col xs={2}>
              <a onClick={handleShareClick} className="points text-hide">
                <span>Share</span>
              </a>
            </Col>
          </Row>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className="link-on-tool"
              >
                    <Row>
                      <Col>
                        <Image
                          className="tool-img"
                          src={`/static/assets/images/tools/${id}.${extension}`}
                        />
                      </Col>
                    </Row>
                    <Row className="tool-main">
                      <Col xs={8}>
                        <h3 className="tool-label">{label}</h3>
                      </Col>
                      <Col xs={4}>
                        <h3 className="tool-year">{year}</h3>
                      </Col>
                      <Col>
                      <hr/>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="tool-collection">{collection}</Col>
                    </Row>
              </a>
      </div>
    </Col>
  );
}
export default PENTool;
