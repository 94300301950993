import React, { useContext, memo, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilterValuesTable from './FilterValuesTable';
import FilterValuesCarousel from './FilterValuesCarousel';
import DoubleColumnFilterValuesTable from './DoubleColumnFilterValuesTable';
import { DataSelectionContext } from './DataSelectionContext';
import {
  COLLECTION_FILTER_ID,
  REGION_FILTER_ID,
  TOPIC_FILTER_ID,
  YEAR_FILTER_ID,
} from '../../shared/constants';
import { SELECTION_SET } from '../../actions/types';
import store from '../../store';

/* FILTER ELEMENT COMPONENT */
const FilterElement = memo(({ id, label, values, selectedValues }) => {
  // console.log('[FilterElement] is running', id);

  // Acceso a la selección de datos
  const context = useContext(DataSelectionContext);

  useEffect(() => {
    if (id === COLLECTION_FILTER_ID && selectedValues.length === 0) {
      const collectionsToSelect = values.filter((value) => value.enabled);
      if (collectionsToSelect.length === 1) {
        context.selectFilterValue(
          COLLECTION_FILTER_ID,
          collectionsToSelect[0].id,
        );
        sendActionSelectionSet();
      }
    }
  }, [context.selection]);

  const sendActionSelectionSet = () => {
    store.dispatch({
      type: SELECTION_SET,
      payload: context.selection.getSimpleFormatObject(),
    });
  };

  /**
   * Handler para el click en el header del filtro
   */
  const handleFilterHeaderClick = () => {
    context.toggleFilterShowValues(id);
  };

  const getShowValues = () => {
    return context.getFilterShowValues(id);
  };

  const getNumSelectedValues = () => {
    return selectedValues.length;
  };

  const getNumValues = () => {
    // No se debe contar la opción de "Todos"
    // return values.length;
    return values.filter((value) => value.id !== `${id}-all`).length;
  };

  /**
   * Devuelve el encabezado del filtro
   */
  const getFilterHeader = () => {
    return (
      <p>
        <span>
          Filtre por <b>{label}</b>
        </span>
        <span className="filter-counter">
          {getNumSelectedValues()} / {getNumValues()}
        </span>
      </p>
    );
  };

  /**
   * Devuelve las notas del filtro
   */
  const getFilterValuesNote = () => {
    if (id === COLLECTION_FILTER_ID) {
      // Si el filtro es para la colección, se indica que no es posible mezclar
      // indicadores de distintas colecciones
      return (
        <p>
          * Los indicadores de las diferentes colecciones no pueden mezclarse
          entre sí.
        </p>
      );
    } else {
      // Para los demás filtros, no se incluyen notas
      return null;
    }
  };

  const getMainContent = () => {
    switch (id) {
      case COLLECTION_FILTER_ID:
        return (
          <FilterValuesTable
            id={id}
            values={values}
            selectedValues={selectedValues}
          />
        );
      case REGION_FILTER_ID:
        return (
          <DoubleColumnFilterValuesTable
            id={id}
            values={values}
            selectedValues={selectedValues}
          />
        );
      case TOPIC_FILTER_ID:
      case YEAR_FILTER_ID:
        return (
          <FilterValuesCarousel
            id={id}
            values={values}
            selectedValues={selectedValues}
          />
        );
      default:
        return null;
    }
  };

  const getFilterValuesContainer = () => {
    if (getShowValues()) {
      return (
        // <Row className={getFilterValuesContainerClass()}>
        <Row className="filter-values-container">
          <Col>
            {getMainContent()}
            <Row className="filter-values-note">{getFilterValuesNote()}</Row>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  };

  return (
    <Row className="filter-container">
      <Col>
        <Row className="filter-header" onClick={handleFilterHeaderClick}>
          {getFilterHeader()}
        </Row>
        {getShowValues() && getFilterValuesContainer()}
      </Col>
    </Row>
  );
});

export default FilterElement;
