import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

function ChartColorsOption(props) {
  return (
    <Row>
      <Col>
        <Row>
          <Image src={`img/${props.id}.svg`} fluid />
        </Row>
        <Row>
          <label className="chart-color-option-label">{props.label}</label>
        </Row>
      </Col>
    </Row>
  );
}

export default ChartColorsOption;
