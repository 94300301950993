import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChartColorsEditorContent from './ChartColorsEditorContent';
import ChartLegendEditorContent from './ChartLegendEditorContent';
import ChartTextsEditorContent from './ChartTextsEditorContent';

function GenericChartEditor(props) {
  //console.log('props', props);

  const [showChartEditor, setShowChartEditor] = useState(false);

  const handleChartEditorHeaderClick = () => {
    setShowChartEditor(!showChartEditor);
  };

  const getChartEditorHeader = () => {
    return (
      <p>
        <span>
          {props.titleRegular} <b>{props.titleEmphasis}</b>
        </span>
      </p>
    );
  };

  const getChartEditorContent = () => {
    switch (props.id) {
      case 'color':
        return (
          <ChartColorsEditorContent
            dataElementConfiguration={props.dataElementConfiguration}
          />
        );
      case 'legend':
        return (
          <ChartLegendEditorContent
            dataElementConfiguration={props.dataElementConfiguration}
          />
        );
      case 'texts':
        return (
          <ChartTextsEditorContent
            dataElementConfiguration={props.dataElementConfiguration}
          />
        );
    }
  };

  const getChartEditorContainerClass = () => {
    if (showChartEditor) {
      return 'chart-editor-container';
    } else {
      return 'chart-editor-container hidden';
    }
  };

  return (
    <Row className="chart-editor-container filter-container">
      <Col>
        <Row
          className="chart-editor-header"
          onClick={handleChartEditorHeaderClick}
        >
          {getChartEditorHeader()}
        </Row>
        <Row className={getChartEditorContainerClass()}>
          {getChartEditorContent()}
        </Row>
      </Col>
    </Row>
  );
}

export default GenericChartEditor;
