import React, { useState, useEffect } from 'react';
import DataAdapter from '../../shared/DataAdapter';
import { chartNestingOptions } from '../../shared/constants';
import ChartHelper from '../../shared/ChartHelper';

function ChartLegend(props) {
  //console.log('[ChartLegend] is running', props);

  const [legendElements, setLegendElements] = useState([]);
  const data = props.elementData;

  // Efecto para renderizar todo el gráfico completo
  useEffect(() => {
    // Datos
    let adaptedData = DataAdapter.adaptDataByRegion(data);
    let nestedData = adaptedData.nestedData;

    // Si en nested data hay más de una región, se agrupan los datos por regiones
    // En otro caso, se agrupan por indicador
    const chartNestingLevel =
      nestedData.length > 1
        ? chartNestingOptions.REGION
        : chartNestingOptions.INDICATOR;

    // Si los datos se agrupan por indicador, se debe volver a llamar la función
    // para adaptar los datos
    if (chartNestingLevel === chartNestingOptions.INDICATOR) {
      adaptedData = DataAdapter.adaptDataByIndicator(data);
      nestedData = adaptedData.nestedData;
    }

    const indicatorsDict = DataAdapter.getIndicatorsDict(data.indicators);

    const colors = props.colors;
    const colorsArray = ChartHelper.getChartColors(colors);

    const elementsKeys = nestedData.map((d) => d.key);

    // console.log('elementsKeys', elementsKeys);

    const colorFunction = ChartHelper.getColorFunction(
      elementsKeys,
      colorsArray,
    );
    let elementText;
    const elements = elementsKeys.map((key) => {
      elementText =
        chartNestingLevel === chartNestingOptions.INDICATOR
          ? indicatorsDict[key]
          : key;
      return { key: elementText, color: colorFunction(key) };
    });

    // console.log('elements', elements);

    setLegendElements(elements);
  }, []);

  const getLegendElements = () => {
    const mappedElements = legendElements.map((element, index) => {
      return (
        <div key={btoa(index)} className="legend-element-container">
          <div
            className="box legend-element-box"
            style={{
              backgroundColor: element.color,
            }}
          ></div>
          <div className="legend-element-text">{element.key}</div>
        </div>
      );
    });
    return mappedElements;
  };

  return <div className="legend-container">{getLegendElements()}</div>;
}

export default ChartLegend;
