import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';
import PENDataService from '../../shared/PENDataService.js';
import DataAdapter from '../../shared/DataAdapter';
import axios from 'axios';

// Para entrar a este componente se debe haber seleccionado un indicador

function IndicatorDetails() {
  const selectIndicatorId = (state) => state.indicators.indicatorDetailsId;
  const indicatorId = useSelector(selectIndicatorId);

  const [details, setDetails] = useState({});

  /* DATA REQUESTS */
  const getIndicatorDetailsPromise = (indicatorId, source) => {
    return PENDataService.getIndicatorDetailsByIndicatorKey(
      indicatorId,
      source,
    );
  };

  const getElementSourcesPromise = (indicators, source) => {
    return PENDataService.getIndicatorsSourcesByIndicatorsKeys(
      indicators,
      source,
    );
  };

  useEffect(() => {
    if (!!indicatorId) {
      let mounted = true;

      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      Promise.all([
        getIndicatorDetailsPromise(indicatorId, source),
        getElementSourcesPromise([indicatorId], source),
      ])
        .then((results) => {
          if (mounted) {
            //console.log('result', result);
            const generalDetails = results[0];
            const sources = results[1];
            const adaptedSources = DataAdapter.adaptSourcesData(sources);
            const sourcesString = getSourcesText(adaptedSources);
            setDetails({
              name: generalDetails.name,
              description: generalDetails.description,
              source: sourcesString,
            });
          }
        })
        .catch((error) => {
          //console.log('error', error);
        });

      return function cleanup() {
        mounted = false;
        source.cancel('axios request cancelled');
      };
    }
  }, [indicatorId]);

  const getSourcesText = (elementSources) => {
    const enumeratedSourcesString = elementSources.join(', ');
    const finalPoint = '.';
    return `${enumeratedSourcesString}${finalPoint}`;
  };

  const getIndicatorDetail = (detailFieldName) => {
    return detailFieldName in details ? details[detailFieldName] : '';
  };

  const getIndicatorName = () => {
    return getIndicatorDetail('name');
  };
  const getIndicatorDefinition = () => {
    return getIndicatorDetail('description');
  };
  const getIndicatorSource = () => {
    return getIndicatorDetail('source');
  };

  const onDownloadMetadataClicked = () => {
    // console.log('Descargar metadatos clicked !!!');
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    PENDataService.downloadIndicatorsMetadataFile([indicatorId], source)
      .then((result) => {
        // console.log('result', result);
        const fileData = result.data;
        handleFileData(fileData, 'metadatos');
      })
      .catch((error) => {
        //console.log('error', error);
      });
  };

  const onDownloadDataClicked = () => {
    // console.log('Descargar datos clicked !!!');
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    PENDataService.downloadIndicatorsDataFile([indicatorId], source)
      .then((result) => {
        console.log('result', result);
        const fileData = result.data;
        handleFileData(fileData, 'datos');
      })
      .catch((error) => {
        //console.log('error', error);
      });
  };

  const handleFileData = (fileData, filename) => {
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Container fluid>
      <br />
      <Row>
        <h4>{getIndicatorName()}</h4>
      </Row>
      <Row>
        <p>
          <b>Definición:</b> {getIndicatorDefinition()}
        </p>
      </Row>
      <Row>
        <p className="txt-resalte-azul">
          <b>Fuente:</b> {getIndicatorSource()}
        </p>
      </Row>
      <Row>
        <ListGroup>
          <ListGroup.Item action onClick={onDownloadMetadataClicked}>
            <span className="detalles-btn-item">Descargar metadatos</span>
            <span className="menu-icon icn-blue">
              <i className="pen-download"></i>
            </span>
          </ListGroup.Item>
        </ListGroup>
      </Row>
      <Row>
        <ListGroup>
          <ListGroup.Item action onClick={onDownloadDataClicked}>
            <span className="detalles-btn-item">Descargar datos</span>
            <span className="menu-icon icn-blue">
              <i className="pen-download"></i>
            </span>
          </ListGroup.Item>
        </ListGroup>
      </Row>
    </Container>
  );
}

export default IndicatorDetails;
