import { resourceTypes } from '../shared/constants';

const resourcesOptions = {
  // all: {
  //   id: 'all',
  //   label: 'Todos',
  //   fields: [],
  // },
  chart: {
    id: resourceTypes.CHART,
    label: 'Gráfico',
    fields: [
      {
        id: 'format',
        label: 'Seleccione Formato',
        type: 'checkbox',
        options: [
          {
            id: `${resourceTypes.CHART}-format-pdf`,
            label: 'PDF',
          },
          {
            id: `${resourceTypes.CHART}-format-png`,
            label: 'PNG',
          },
        ],
      },
      {
        id: 'empty',
      },
    ],
  },
  data: {
    id: resourceTypes.DATA,
    label: 'Datos',
    fields: [
      {
        id: 'format',
        label: 'Seleccione Formato',
        type: 'checkbox',
        options: [
          {
            id: `${resourceTypes.DATA}-format-xls`,
            label: 'XLS',
          },
        ],
      },

      {
        id: 'range',
        label: 'Seleccione Rango',
        type: 'radio',
        options: [
          {
            id: `${resourceTypes.DATA}-range-element`,
            label: 'Solo datos del gráfico',
          },
          {
            id: `${resourceTypes.DATA}-range-collection`,
            label: 'Todo el compendio',
          },
        ],
      },
    ],
  },
  metadata: {
    id: resourceTypes.METADATA,
    label: 'Metadatos',
    fields: [
      {
        id: 'indicator',
        label: 'Seleccione los indicadores',
        type: 'radio',
        options: [
          {
            id: `${resourceTypes.METADATA}-indicator-all`,
            label: 'Todos',
          },
        ],
      },
    ],
  },
};

export default resourcesOptions;
