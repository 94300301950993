import React, { useRef, useContext, memo } from 'react';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';
import { DataSelectionContext } from './DataSelectionContext';
import { NUM_VALUES_PER_FILTER_VALUES_PAGE } from '../../shared/constants';
import store from '../../store';
import { SELECTION_SET } from '../../actions/types';
const { v4: uuidv4 } = require('uuid');

/* FILTER VALUES PAGE COMPONENT */
const FilterValuesPage = memo(({ id, pageInd, values, selectedValues }) => {
  console.log('[FilterValuesPage] is running', id);

  // Acceso a la selección de datos
  const context = useContext(DataSelectionContext);

  /* Acceso al objeto de la tabla */
  let tableRef = useRef(null);

  const sendActionSelectionSet = () => {
    store.dispatch({
      type: SELECTION_SET,
      payload: context.selection.getSimpleFormatObject(),
    });
  };

  /**
   * Envía a la información compartida la acción de seleccionar un valor del filtro
   */
  const sendActionFilterValueSelected = (filterValue) => {
    if (filterValue !== `${id}-all`) {
      context.selectFilterValue(id, filterValue);
    } else {
      context.selectAllFilterValues(id);
    }
    sendActionSelectionSet();
  };

  /**
   * Envía a la información compartida la acción de seleccionar un valor del filtro
   * @param {number} filterValue
   */
  const sendActionFilterValueDeselected = (filterValue) => {
    if (filterValue !== `${id}-all`) {
      context.deselectFilterValue(id, filterValue);
    } else {
      context.deselectAllFilterValues(id);
    }
    sendActionSelectionSet();
  };

  const getValuesSlice = () => {
    const startIndex = pageInd * NUM_VALUES_PER_FILTER_VALUES_PAGE;
    const endIndex = NUM_VALUES_PER_FILTER_VALUES_PAGE * (pageInd + 1);
    const valuesSlice = [...values].slice(startIndex, endIndex);
    return valuesSlice;
  };

  const onFilterValuesLoaded = (data) => {
    if (!!tableRef && !!tableRef.current) {
      const table = tableRef.current.table;
      if (!!table) {
        // console.log('context', context);
        // console.log('table', table);
        // console.log('selectedValues', selectedValues);
        if (selectedValues.length) {
          //console.log('Entra a selected values con tamaño > 0');
          table.selectRow(selectedValues);

          // Si todos los valores del filtro que no corresponden a la opción "Todos"
          // se encuentran seleccionados, se debe marcar manualmente la opción de "Todos",
          // porque nunca se va a incoporar a selectedValues
          if (
            selectedValues.length ===
            values.filter((value) => value.id !== `${id}-all`).length
          ) {
            table.selectRow(`${id}-all`);
          }
        }
      }
    }
  };

  /**
   * Handler para la selección de un valor del filtro
   */
  const onFilterValueRowSelected = (row) => {
    const rowData = row.getData();
    const filterValue = rowData.id;
    sendActionFilterValueSelected(filterValue);
  };

  /**
   * Handler para la deselección de un valor del filtro
   */
  const onFilterValueRowDeselected = (row) => {
    const rowData = row.getData();
    const filterValue = rowData.id;
    sendActionFilterValueDeselected(filterValue);
  };

  /**
   * Handler para la opción selectableCheck de la tabla
   */
  const isRowSelectable = (row) => {
    const rowData = row.getData();
    return rowData.enabled;
  };

  /* Opciones de la tabla, se pasan al componente de tabulator */
  const options = {
    headerVisible: false, //hide header
    resizableColumns: false,
    rowSelected: onFilterValueRowSelected,
    rowDeselected: onFilterValueRowDeselected,
    selectableCheck: isRowSelectable,
    dataLoaded: onFilterValuesLoaded,
  };

  /* Columnas de la tabla, se pasan al componente de tabulator */
  const columns = [
    {
      formatter: 'rowSelection',
      hozAlign: 'center',
      headerSort: false,
      cellClick:function(e, cell){
         cell.getRow().toggleSelect();
      }
    },
    { field: 'name', headerSort: false, width: '90%' },
  ];

  return (
    <div className="indicators-carousel-page">
      <ReactTabulator
        ref={tableRef}
        data={getValuesSlice()}
        columns={columns}
        tooltips={false}
        options={options}
        key={uuidv4()}
      />
    </div>
  );
});

export default FilterValuesPage;
