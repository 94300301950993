import axios from 'axios';
import DataElement from '../models/DataElement';
import TextElement from '../models/TextElement';
import PENDataService from './PENDataService';
import {
  NUM_MAX_INDICATORS_REGULAR_CASE,
  NUM_MAX_INDICATORS_ERCA_CASE,
  ERCA_COLLECTION_ID,
  NUM_MAX_STORY_DATA_ELEMENTS,
  NUM_MAX_STORY_TEXT_ELEMENTS,
  elementTypes,
} from './constants';
const { v4: uuidv4 } = require('uuid');

const getSessionCopy = (session) => {
  return session.getCopy();
};

const getNewSessionWithNewElement = (element, session) => {
  const sessionCopy = getSessionCopy(session);
  sessionCopy.getSessionStory().addStoryElement(element.key, element);
  return sessionCopy;
};

const getNewSessionWithDeletedElement = (key, session) => {
  const sessionCopy = getSessionCopy(session);
  sessionCopy.getSessionStory().deleteStoryElement(key);
  return sessionCopy;
};

const getNewSessionWithIndicatorsForElement = (
  key,
  indicators,
  collection,
  regions,
  years,
  session,
) => {
  const sessionCopy = getSessionCopy(session);
  sessionCopy
    .getSessionStory()
    .getElementByKey(key)
    .setDataSelection(indicators, collection, regions, years);
  return sessionCopy;
};

// TODO
// Ajustar el método para la configuración del elemento de datos
const getNewSessionWithUpdatedConfiguration = (key, configuration, session) => {
  const sessionCopy = getSessionCopy(session);
  sessionCopy.getSessionStory().getElementByKey(key).update(configuration);
  return sessionCopy;
};

const getNewSessionWithUpdatedContentText = (key, text, session) => {
  const sessionCopy = getSessionCopy(session);
  sessionCopy.getSessionStory().getElementByKey(key).setText(text);
  return sessionCopy;
};

const getNewSessionWithDeletedElements = (session) => {
  const sessionCopy = getSessionCopy(session);
  sessionCopy.getSessionStory().deleteStoryElements();
  return sessionCopy;
};

const updateSessionData = async (cookie, session) => {
  const dbSessionObject = session.getDbObject();
  const stringifiedJSON = JSON.stringify(dbSessionObject);
  const encodedJSON = encodeURIComponent(stringifiedJSON);
  const response = await axios.post(`/api/sesion/${cookie}`, {
    json: encodedJSON,
  });

  return new Promise((resolve, reject) => {
    try {
      const actualResponse = response.data;
      if (!!actualResponse && 'result' in actualResponse) {
        if (actualResponse.result) {
          resolve(dbSessionObject);
        } else {
          reject();
        }
      } else {
        reject();
      }
    } catch (error) {
      //console.log('error', error);
      reject();
    }
  });
};

class UserSessionDataService {
  /* DB HELPERS */

  // En el caso de las consultas relacionadas con la configuración de la sesión,
  // se obtienen errores específicos para reportar al inicio del proceso

  static async getNewSessionCookie() {
    const response = await axios.get('/api/nueva_sesion');

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const cookie = data.cookie;
            resolve(cookie);
          } else {
            if (actualResponse.errorDescription === 'DB_CONNECTION_ERROR') {
              reject(new Error('DB_CONNECTION_ERROR'));
            }
          }
        }
        reject(new Error('GENERIC_SERVER_ERROR'));
      } catch (error) {
        console.log('error', error);
        reject(new Error('GENERIC_SERVER_ERROR'));
      }
    });
  }

  static async getSessionData(cookie) {
    const response = await axios.get(`/api/sesion/${cookie}`);

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const userSessionJSON = data.json;
            resolve(userSessionJSON);
          } else {
            if (actualResponse.errorDescription === 'DB_CONNECTION_ERROR') {
              reject(new Error('DB_CONNECTION_ERROR'));
            } else if (
              actualResponse.errorDescription === 'SESSION_NOT_FOUND'
            ) {
              reject(new Error('SESSION_NOT_FOUND'));
            }
          }
        }
        reject(new Error('GENERIC_SERVER_ERROR'));
      } catch (error) {
        //console.log('error', error);
        reject(new Error('GENERIC_SERVER_ERROR'));
      }
    });
  }

  /* CRUD */

  static async createStoryDataElementWithIndicator(
    cookie,
    session,
    indicator,
    collection = null,
  ) {
    if (collection === null) {
      // Se trae el collectionId con PENDataService
      const collectionResult =
        await PENDataService.getIndicatorCollectionByIndicatorKey(indicator);

      if (
        collectionResult === null ||
        typeof collectionResult === 'undefined'
      ) {
        return new Promise((resolve, reject) => {
          reject();
        });
      }

      collection = collectionResult.collection_id;
    }

    const indicators = [indicator];
    const dataElement = new DataElement({
      indicators,
      collection,
    });
    const newSession = getNewSessionWithNewElement(dataElement, session);
    const updateResult = await updateSessionData(cookie, newSession);
    const newElementKey = dataElement.key;
    const dbSessionObject = updateResult;

    return new Promise((resolve, reject) => {
      if (!!updateResult) {
        resolve([newElementKey, dbSessionObject]);
      } else {
        reject();
      }
    });
  }

  static async createStoryDataElementWithIndicators(
    cookie,
    session,
    indicators,
    collection,
    regions,
    years,
  ) {
    const dataElement = new DataElement({
      indicators,
      collection,
      regions,
      years,
    });
    const newSession = getNewSessionWithNewElement(dataElement, session);
    const updateResult = await updateSessionData(cookie, newSession);
    const newElementKey = dataElement.key;
    const dbSessionObject = updateResult;

    return new Promise((resolve, reject) => {
      if (!!updateResult) {
        resolve([newElementKey, dbSessionObject]);
      } else {
        reject();
      }
    });
  }

  static async updateStoryDataElementIndicators(
    cookie,
    session,
    elementKey,
    indicators,
    collection,
    regions,
    years,
  ) {
    const newSession = getNewSessionWithIndicatorsForElement(
      elementKey,
      indicators,
      collection,
      regions,
      years,
      session,
    );
    const updateResult = await updateSessionData(cookie, newSession);
    const dbSessionObject = updateResult;

    return new Promise((resolve, reject) => {
      if (!!updateResult) {
        resolve([elementKey, dbSessionObject]);
      } else {
        reject();
      }
    });
  }

  static async updateStoryDataElementConfiguration(
    cookie,
    session,
    elementKey,
    dataElementConfiguration,
  ) {
    const newSession = getNewSessionWithUpdatedConfiguration(
      elementKey,
      dataElementConfiguration,
      session,
    );
    const updateResult = await updateSessionData(cookie, newSession);
    const dbSessionObject = updateResult;

    return new Promise((resolve, reject) => {
      if (!!updateResult) {
        resolve([elementKey, dbSessionObject]);
      } else {
        reject();
      }
    });
  }

  static async createStoryTextElement(cookie, session) {
    const textElement = new TextElement({});
    const newSession = getNewSessionWithNewElement(textElement, session);
    const updateResult = await updateSessionData(cookie, newSession);
    const newElementKey = textElement.key;
    const dbSessionObject = updateResult;

    return new Promise((resolve, reject) => {
      if (!!updateResult) {
        resolve([newElementKey, dbSessionObject]);
      } else {
        reject();
      }
    });
  }

  static async deleteStoryElement(cookie, session, key) {
    const newSession = getNewSessionWithDeletedElement(key, session);
    const updateResult = await updateSessionData(cookie, newSession);
    const dbSessionObject = updateResult;

    return new Promise((resolve, reject) => {
      if (!!updateResult) {
        resolve(dbSessionObject);
      } else {
        reject();
      }
    });
  }

  static async updateStoryTextElementContent(cookie, session, key, text) {
    const newSession = getNewSessionWithUpdatedContentText(key, text, session);
    const updateResult = await updateSessionData(cookie, newSession);
    const dbSessionObject = updateResult;

    return new Promise((resolve, reject) => {
      if (!!updateResult) {
        resolve(dbSessionObject);
      } else {
        reject();
      }
    });
  }

  static async deleteStoryElements(cookie, session) {
    const newSession = getNewSessionWithDeletedElements(session);
    const updateResult = await updateSessionData(cookie, newSession);
    const dbSessionObject = updateResult;

    return new Promise((resolve, reject) => {
      if (!!updateResult) {
        resolve(dbSessionObject);
      } else {
        reject();
      }
    });
  }

  static async getPublicStoryByKey(storyKey) {
    const response = await axios.get(`/api/historia/${storyKey}`);

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            resolve(data);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  /* HELPERS */

  static async updateDataBase(collection, formData) {
    const response = await axios({
      method: 'post',
      url: `/api/update_db/${collection}`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return new Promise((resolve, reject) => {
      try {
        console.log(response);
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          resolve(actualResponse);
        } else {
          reject();
        }
      } catch (error) {
        console.log('error', error);
        reject();
      }
    });
  }

  static async createPENStory(storyKey, formData) {
    const response = await axios({
      method: 'post',
      url: `/api/historia_oficial/${storyKey}`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            resolve(data);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  static async updatePENStory(storyKey, formData) {
    const response = await axios({
      method: 'post',
      url: `/api/update_historia_oficial/${storyKey}`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            resolve(data);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  static async createPublicStory(storyKey, formData) {
    const response = await axios({
      method: 'post',
      url: `/api/historia_publica/${storyKey}`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            resolve(data);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  static async getPENStories() {
    const response = await axios.get(`/api/historias_pen`);

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const rows = data.rows;
            resolve(rows);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }
  static async getPENStory(key) {
    const response = await axios.get(`/api/historias_pen/${key}`);

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            resolve(data);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  static async deleteOficialHistory(storyKey) {
    const response = await axios.post(`/api/delete_historia/${storyKey}`);

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            resolve(data);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  static sessionHasValidStory(session) {
    return !!session.getSessionStory();
  }

  static getSessionStory(session) {
    return session.getSessionStory();
  }

  static storyHasValidElements(story) {
    return story.hasValidElements();
  }

  static getStoryElementsArray(story) {
    return story.getElementsArray();
  }

  static storyHasValidElementByKey(story, key) {
    return story.elementKeyIsValid(key);
  }

  static getStoryElementByKey(story, key) {
    return story.getElementByKey(key);
  }

  static async adminLogin(cookie, user, pass) {
    const response = await axios.post(`/api/inicio_sesion_admin/${cookie}`, {
      user,
      pass,
    });

    //console.log('response', response);
    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            resolve();
          } else {
            //console.log('Credenciales inválidos !!!');
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  static async isAdminSession(cookie) {
    const response = await axios.get(`/api/sesion_admin/${cookie}`);
    //console.log('response', response);
    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const adminSession = data.adminSession;
            resolve(adminSession);
          } else {
            //console.log('No se encontró sesión !!!');
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject(error);
      }
    });
  }

  static getEncodedPublicStoryKey(decodedKey) {
    // return btoa(decodedKey);
    return decodedKey;
  }

  static generatePublicStoryKey() {
    const key = uuidv4();
    return key;
  }

  static checkCanAddIndicator(
    collectionFilterSelectedValues,
    indicatorsSelectedValues,
  ) {
    let canAddIndicator = true;

    if (collectionFilterSelectedValues.length) {
      const selectedCollection = collectionFilterSelectedValues[0];
      const numMaxIndicators =
        selectedCollection === ERCA_COLLECTION_ID
          ? NUM_MAX_INDICATORS_ERCA_CASE
          : NUM_MAX_INDICATORS_REGULAR_CASE;
      if (
        !!indicatorsSelectedValues &&
        indicatorsSelectedValues.length === numMaxIndicators
      ) {
        canAddIndicator = false;
      }
    }

    // console.log('canAddIndicator', canAddIndicator);

    return canAddIndicator;
  }

  static checkCanAddStoryDataElement(session) {
    return UserSessionDataService.checkCanAddStoryElement(
      session,
      elementTypes.DATA,
    );
  }

  static checkCanAddStoryTextElement(session) {
    return UserSessionDataService.checkCanAddStoryElement(
      session,
      elementTypes.TEXT,
    );
  }

  static checkCanAddStoryElement(session, elementType) {
    // Primero se verifica la estructura de la sesión
    if (!UserSessionDataService.sessionHasValidStory(session)) {
      // TODO
      // Si la estructura de la sesión es inválida, se debe manejar el error
      return false;
    }

    const story = UserSessionDataService.getSessionStory(session);

    if (!UserSessionDataService.storyHasValidElements(story)) {
      // Si no hay elementos en la historia, se pueden agregar nuevos porque no se excede el límite
      return true;
    }

    const elements = UserSessionDataService.getStoryElementsArray(story);

    // Se filtran los elementos por el tipo de elemento para el que se evalúa la condición
    const filteredElements = elements.filter(
      (element) => element.type === elementType,
    );

    const numMaxElements =
      elementType === elementTypes.DATA
        ? NUM_MAX_STORY_DATA_ELEMENTS
        : NUM_MAX_STORY_TEXT_ELEMENTS;

    // Si no se excede el límite del tipo de elemento para el que se evalúa la condición
    // se puede añadir el nuevo elemento y el resultado es verdadero
    return filteredElements.length < numMaxElements;
  }

  static getFollowingTextsArrayForDataElementByKey(elements, key) {
    const texts = [];
    let foundKeyElement = false;

    for (let element of elements) {
      if (foundKeyElement) {
        if (element.type === elementTypes.DATA) {
          // condición de parada, porque solo se recuperan los elementos de texto siguientes al elemento de la llave
          break;
        } else if (element.type === elementTypes.TEXT) {
          // es un elemento de texto siguiente al elemento de la llave, por lo tanto se incluye en el resultado
          texts.push(element.contentText);
        }
      } else if (element.key === key) {
        foundKeyElement = true;
      }
    }

    return texts;
  }

  static async getExportElement(exportParams) {
    const indicators = exportParams.indicators;
    const stringifiedIndicators = JSON.stringify(indicators);
    const encodedIndicators = encodeURIComponent(stringifiedIndicators);
    const regions = exportParams.regions;
    const stringfiedRegions = JSON.stringify(regions);
    const encodedRegions = encodeURIComponent(stringfiedRegions);
    const years = exportParams.years;
    const stringifiedYears = JSON.stringify(years);
    const encodedYears = encodeURIComponent(stringifiedYears);
    const collection = exportParams.collection;
    const titleText = exportParams.titleText;
    const subTitleText = exportParams.subTitleText;
    const chartTypesOptions = exportParams.chartTypesOptions;
    const stringifiedChartTypesOptions = JSON.stringify(chartTypesOptions);
    const encodedChartTypesOptions = encodeURIComponent(
      stringifiedChartTypesOptions,
    );
    const chartGuidesOption = exportParams.chartGuidesOption;
    const chartColorsOption = exportParams.chartColorsOption;
    const chartLegendOption = exportParams.chartLegendOption;
    const text = exportParams.text;
    const stringifiedText = JSON.stringify(text);
    const encodedText = encodeURIComponent(stringifiedText);
    const type = exportParams.type;
    const encode = exportParams.encode;

    const params = {
      indicators: encodedIndicators,
      regions: encodedRegions,
      years: encodedYears,
      collection,
      titleText,
      subTitleText,
      chartTypesOptions: encodedChartTypesOptions,
      chartGuidesOption,
      chartColorsOption,
      chartLegendOption,
      text: encodedText,
      type,
      encode,
    };

    const responseType = type === 'png' && encode ? 'image/png' : 'blob';

    const response = await axios({
      url: '/api/export_element',
      params,
      // cancelToken: source.token,
      method: 'GET',
      responseType,
    });

    return new Promise((resolve, reject) => {
      try {
        if (!!response) {
          resolve(response.data);
        } else {
          reject(response);
        }
      } catch (error) {
        console.log('error', error);
        reject(error);
      }
    });
  }

  static async getExportHistory(exportParams) {
    const historyId = exportParams.key;
    const type = exportParams.type;
    const encode = exportParams.encode;

    const params = {
      type,
      encode,
    };

    const responseType = type === 'png' && encode ? 'image/png' : 'blob';

    const response = await axios({
      url: `/api/export_history/${historyId}`,
      params,
      // cancelToken: source.token,
      method: 'GET',
      responseType,
    });

    return new Promise((resolve, reject) => {
      try {
        if (!!response) {
          resolve(response.data);
        } else {
          reject(response);
        }
      } catch (error) {
        console.log('error', error);
        reject(error);
      }
    });
  }
}

export default UserSessionDataService;
