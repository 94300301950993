import React, { forwardRef } from 'react';
import Tabs from './Tabs';
import TabContent from './TabContent';
import TabContentExport from './TabContentExport';
import Row from 'react-bootstrap/Row';

const DataElement = forwardRef(({ id, visualizerMode }, ref) => {
    console.log(ref);
  return (
    <Row className="hoja-vis chart-container">
          <TabContentExport
            contentId="chart"
            id={id}
            visualizerMode={visualizerMode}
            key={`tab-content-${id}`}
            ref={ref}
          />
    </Row>
  );
});

export default DataElement;
