import React, { useContext } from 'react';
import TextEditableContent from './TextEditableContent';
import TextReadonlyContent from './TextReadonlyContent';
import { StoryContext } from './StoryContext';
import { textModes } from '../../shared/constants';

function TextElement(props) {
  const visualizerMode = props.visualizerMode;
  const id = props.id;

  /* Acceso a la historia del visualizador */
  const context = useContext(StoryContext);

  const getTextMode = () => {
    return context.session.getSessionStory().getElementByKey(id).textMode;
  };

  const getTextElementContent = () => {
    const textMode = getTextMode();
    // console.log('textMode', textMode);
    if (textMode === textModes.EDIT) {
      return <TextEditableContent id={id} />;
    } else if (textMode === textModes.READONLY) {
      return <TextReadonlyContent id={id} visualizerMode={visualizerMode} />;
    } else {
      return null;
    }
  };

  return getTextElementContent();
}

export default TextElement;
