import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { useMediaQuery } from '../../hooks/useMediaQuery';

function RotateDeviceMessage() {
  let isMobileSite = useMediaQuery('(max-width: 991px)');

  const getMainContent = () => {
    if (isMobileSite) {
      return (
        <div className="rotate-device-message">
          <div className="rotate-device-message-content">
            <Row>
              <Image src={'/img/rotar_cel.svg'} fluid />
            </Row>
            <Row>
              <Col>
                <p>DELE VUELTA AL CELULAR PARA UNA MEJOR EXPERIENCIA</p>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return getMainContent();
}

export default RotateDeviceMessage;
