import React, { createContext, useState } from 'react';
import UserSession from '../../models/UserSession';
import Selection from '../../models/Selection';
import {
  COLLECTION_FILTER_ID,
  TOPIC_FILTER_ID,
  REGION_FILTER_ID,
  YEAR_FILTER_ID,
} from '../../shared/constants';

export const DataSelectionContext = createContext('first');

// This context provider is passed to any component requiring the context

export const DataSelectionProvider = (props) => {
  const clearSelection = () => {
    // if (!!selection) {
    //   selection.collectionFilterSelectedValues = [];
    //   selection.topicFilterSelectedValues = [];
    //   selection.regionFilterSelectedValues = [];
    //   selection.yearFilterSelectedValues = [];
    //   selection.indicatorsSelectedValues = []
    //   selection.searchString = '';
    // }
    // setIndicatorsSelectedValues([]);
    // setCollectionFilterSelectedValues([]);
    // setTopicFilterSelectedValues([]);
    // setRegionFilterSelectedValues([]);
    // setYearFilterSelectedValues([]);
    // setSearchString(null);
  };

  const clearShowFilterValues = () => {
    setCollectionFilterShowValues(false);
    setTopicFilterShowValues(false);
    setRegionFilterShowValues(false);
    setYearFilterShowValues(false);
  };

  /* Indicators */
  const selectIndicator = (indicatorId) => {
    !!selection && selection.selectIndicator(indicatorId);
  };

  const deselectIndicator = (indicatorId) => {
    !!selection && selection.deselectIndicator(indicatorId);
  };

  const getIndicatorsSelectedValues = () => {
    return !!selection ? selection.indicatorsSelectedValues : [];
  };

  const getNumIndicators = () => {
    return !!selection ? selection.numIndicators : 0;
  };

  const setNumIndicators = (numIndicators) => {
    !!selection && selection.setNumIndicators(numIndicators);
  };

  const canSelectIndicator = () => {
    return !!selection && selection.canSelectIndicator();
  };

  /* Filters */
  const getFilterValues = (filterId) => {
    return !!selection ? selection.getFilterValues(filterId) : [];
  };

  const getFilterSelectedValues = (filterId) => {
    return !!selection ? selection.getFilterSelectedValues(filterId) : [];
  };

  const getFilterShowValues = (filterId) => {
    switch (filterId) {
      case COLLECTION_FILTER_ID:
        return collectionFilterShowValues;
      case TOPIC_FILTER_ID:
        return topicFilterShowValues;
      case REGION_FILTER_ID:
        return regionFilterShowValues;
      case YEAR_FILTER_ID:
        return yearFilterShowValues;
      default:
        return null;
    }
  };

  const setFilterValues = (filterId, values) => {
    !!selection && selection.setFilterValues(filterId, values);
  };

  const selectFilterValue = (filterId, filterValue) => {
    !!selection && selection.selectFilterValue(filterId, filterValue);
  };

  const deselectFilterValue = (filterId, filterValue) => {
    !!selection && selection.deselectFilterValue(filterId, filterValue);
  };

  const selectAllFilterValues = (filterId) => {
    !!selection && selection.selectAllFilterValues(filterId);
  };

  const deselectAllFilterValues = (filterId) => {
    !!selection && selection.deselectAllFilterValues(filterId);
  };

  const toggleFilterShowValues = (id) => {
    switch (id) {
      case COLLECTION_FILTER_ID:
        setCollectionFilterShowValues(!collectionFilterShowValues);
        break;
      case TOPIC_FILTER_ID:
        setTopicFilterShowValues(!topicFilterShowValues);
        break;
      case REGION_FILTER_ID:
        setRegionFilterShowValues(!regionFilterShowValues);
        break;
      case YEAR_FILTER_ID:
        setYearFilterShowValues(!yearFilterShowValues);
        break;
      default:
        break;
    }
  };

  /* Collection filter */
  const [collectionFilterShowValues, setCollectionFilterShowValues] = useState(
    false,
  );

  /* Topic filter */
  const [topicFilterShowValues, setTopicFilterShowValues] = useState(false);

  /* Region filter */
  const [regionFilterShowValues, setRegionFilterShowValues] = useState(false);

  /* Year filter */
  const [yearFilterShowValues, setYearFilterShowValues] = useState(false);

  /* Search */
  const getSearchString = () => {
    return !!selection ? selection.searchString : '';
  };

  const setSearchString = (searchString) => {
    if (!!selection) {
      selection.searchString = searchString;
    }
  };

  /* SELECTION */
  const [selection, setSelection] = useState(null);

  const parseSelection = (selectionSimpleFormatObject) => {
    const parsedSelection = new Selection(selectionSimpleFormatObject);
    setSelection(parsedSelection);
  };

  const oneCollectionSelected = () => {
    return !!selection && selection.oneCollectionSelected();
  };

  const atLeastOneIndicatorSelected = () => {
    return !!selection && selection.atLeastOneIndicatorSelected();
  };

  /* USER SESSION */
  const [session, setSession] = useState(null);

  const parseSession = (dbSessionObject) => {
    const parsedSession = new UserSession(dbSessionObject);
    setSession(parsedSession);
  };

  return (
    <DataSelectionContext.Provider
      value={{
        session,
        parseSession,

        selection,
        parseSelection,
        setSelection,

        getSearchString,
        setSearchString,

        clearSelection,
        clearShowFilterValues,

        selectIndicator,
        deselectIndicator,
        selectAllFilterValues,
        deselectAllFilterValues,

        getFilterValues,
        getFilterSelectedValues,
        getFilterShowValues,

        selectFilterValue,
        deselectFilterValue,
        setFilterValues,
        toggleFilterShowValues,

        getIndicatorsSelectedValues,
        getNumIndicators,
        setNumIndicators,
        oneCollectionSelected,
        atLeastOneIndicatorSelected,
        canSelectIndicator,
      }}
    >
      {props.children}
    </DataSelectionContext.Provider>
  );
};
