import React, { useState, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import IconButton from '@material-ui/core/IconButton';
import UserSessionDataService from '../../shared/UserSessionDataService';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import GenericMessage from '../../shared/GenericMessage';
import { StoryContext } from './StoryContext';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import store from '../../store';
import {
  DATA_SELECTION_PATH,
  dataSelectionModes,
  STORY_DATA_ELEMENTS_LIMIT_REACHED_MESSAGE,
  STORY_TEXT_ELEMENTS_LIMIT_REACHED_MESSAGE,
} from '../../shared/constants';
import { SESSION_SET, ERROR_MESSAGE_OPENED } from '../../actions/types';

function EmptyElement() {
  let history = useHistory();

  /* Acceso a la historia del visualizador */
  const context = useContext(StoryContext);

  const [showAddElementModal, setShowAddElementModal] = useState(false);

  const handleClose = () => setShowAddElementModal(false);
  const handleShow = () => setShowAddElementModal(true);

  const selectCookie = (state) => state.cookie;
  const cookie = useSelector(selectCookie);

  /* MESSAGE MODAL */
  const [messageTexts, setMessageTexts] = useState(null);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    setMessageTexts(null);
  };
  const handleShowMessageModal = () => setShowMessageModal(true);

  const getMessageModal = () => {
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        show={!!messageTexts && showMessageModal}
        onHide={handleCloseMessageModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <GenericMessage
            title={messageTexts[0]}
            message={messageTexts[1]}
            onOKClicked={handleCloseMessageModal}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const getTitleDiv = () => {
    return <div className="add-container-title">Añadir elemento</div>;
  };

  const getDetailDiv = () => {
    return (
      <div className="add-container-help">
        Agregue gráficos y/o textos según necesite.
      </div>
    );
  };

  const getAddElementModal = () => {
    return (
      <Modal
        id="modal-add-element"
        className="modal-add-element"
        show={showAddElementModal}
        onHide={handleClose}
        centered
      >
        <ListGroup>
          <ListGroup.Item action onClick={onAddDataElementClicked}>
            <span className="menu-item">Añadir gráfico</span>
            <span className="menu-icon icn-blue">
              <i className="pen-share"></i>
            </span>
          </ListGroup.Item>
          <ListGroup.Item action onClick={onAddTextElementClicked}>
            <span className="menu-item">Añadir texto</span>
            <span className="menu-icon icn-blue">
              <i className="pen-edit"></i>
            </span>
          </ListGroup.Item>
        </ListGroup>
      </Modal>
    );
  };

  const handleAddDataElement = () => {
    const session = context.session;
    return new Promise((resolve, reject) => {
      const canAddElement = UserSessionDataService.checkCanAddStoryDataElement(
        session,
      );
      // console.log('canAddElement', canAddElement);

      if (!canAddElement) {
        reject(new Error('EXCEED_DATA_ELEMENTS_LIMIT'));
      } else {
        resolve();
      }
    });
  };

  const handleAddTextElement = () => {
    const session = context.session;
    return new Promise((resolve, reject) => {
      const canAddElement = UserSessionDataService.checkCanAddStoryTextElement(
        session,
      );
      // console.log('canAddElement', canAddElement);

      if (!canAddElement) {
        reject(new Error('EXCEED_TEXT_ELEMENTS_LIMIT'));
      } else {
        UserSessionDataService.createStoryTextElement(cookie, session)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            console.log('error', error);
            reject(new Error('INTERNAL_ERROR'));
          });
      }
    });
  };

  const onAddDataElementClicked = () => {
    handleAddDataElement()
      .then((result) => {
        handleClose();
        // No se envía elementKey porque es elemento nuevo
        history.push({
          pathname: `/${DATA_SELECTION_PATH}`,
          state: {
            dataSelectionMode: dataSelectionModes.NEW,
            elementKey: null,
          },
        });
      })
      .catch((error) => {
        console.log('error', error);
        handleClose();
        if (!!error.message && error.message === 'EXCEED_DATA_ELEMENTS_LIMIT') {
          setMessageTexts([
            null,
            <p>{`${STORY_DATA_ELEMENTS_LIMIT_REACHED_MESSAGE}`}.</p>,
          ]);
          handleShowMessageModal();
        } else {
          store.dispatch({
            type: ERROR_MESSAGE_OPENED,
          });
        }
      });
  };

  const onAddTextElementClicked = () => {
    handleAddTextElement()
      .then((result) => {
        console.log('result', result);
        handleClose();
        const newElementKey = result[0];
        const newSession = result[1];
        context.setTextEditionElementKey(newElementKey);
        context.setScrollElementKey(newElementKey);
        // Se obtuvieron los datos de la sesión actualizada
        // Se guardan en redux store
        store.dispatch({
          type: SESSION_SET,
          payload: newSession,
        });
      })
      .catch((error) => {
        console.log('error', error);
        handleClose();
        if (!!error.message && error.message === 'EXCEED_TEXT_ELEMENTS_LIMIT') {
          setMessageTexts([
            null,
            <p>{`${STORY_TEXT_ELEMENTS_LIMIT_REACHED_MESSAGE}`}.</p>,
          ]);
          handleShowMessageModal();
        } else {
          store.dispatch({
            type: ERROR_MESSAGE_OPENED,
          });
        }
      });
  };

  return (
    <>
      {!!messageTexts && getMessageModal()}
      {getAddElementModal()}
      <Row className="hoja-vis">
        <div className="empty-element-container">
          <Col>
            <Row>{getTitleDiv()}</Row>
            <Row>
              <IconButton
                className="icon-button-add-element"
                onClick={handleShow}
                disabled={!!context.textEditionElementKey}
              >
                <i className="pen-mas add-element-icn"></i>
              </IconButton>
            </Row>
            <Row>{getDetailDiv()}</Row>
          </Col>
        </div>
      </Row>
    </>
  );
}

export default EmptyElement;
