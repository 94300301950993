import React, { createRef, useEffect, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from 'react-bootstrap/Button';
import { StoryContext } from './StoryContext';
import UserSessionDataService from '../../shared/UserSessionDataService';
import { useSelector } from 'react-redux';
import store from '../../store';
import { SESSION_SET } from '../../actions/types';
import { scroller, Element as ScrollElement } from 'react-scroll';

function TextEditableContent(props) {
  const id = props.id;

  /* Acceso a la historia del visualizador */
  const context = useContext(StoryContext);

  useEffect(() => {
    if (!!context.scrollElementKey && context.scrollElementKey === props.id) {
      // Para hacer scroll al elemento cuando corresponde
      scroller.scrollTo(`scroll-${props.id}`, {
        duration: 500,
        delay: 500,
        offset: -150, // Scrolls to element + -150 pixels (header space)
      });
      // context.setScrollElementKey(null);
    }
  }, []);

  const getElement = () => {
    return context.session.getSessionStory().getElementByKey(id);
  };

  const selectCookie = (state) => state.cookie;
  const cookie = useSelector(selectCookie);

  let textInput = createRef();

  const onApplyTextClicked = () => {
    const text = getCurrentText();
    toggleTextElementMode();
    context.setTextEditionElementKey(null);
    const session = context.session;
    UserSessionDataService.updateStoryTextElementContent(
      cookie,
      session,
      id,
      text,
    )
      .then((result) => {
        context.setScrollElementKey(id);
        // Se obtuvieron los datos de la sesión actualizada
        // Se guardan en redux store
        store.dispatch({
          type: SESSION_SET,
          payload: result,
        });
      })
      .catch(() => {
        console.log('error');
        toggleTextElementMode();
        // context.setTextEdition(true);
        context.setTextEditionElementKey(id);
      });
  };

  const handleTextInputLeave = (event) => {
    // console.log('event', event);
    // console.log('textInput', textInput);

    const relatedTarget = event.relatedTarget;
    if (relatedTarget !== null) {
      // console.log('relatedTarget', relatedTarget);
      // console.log('relatedTarget classes', relatedTarget.classList);
      if (relatedTarget.classList.contains('modal-add-element')) {
        textInput.current.focus();
        return;
      } else if (relatedTarget.classList.contains('icon-button-add-element')) {
        textInput.current.focus();
        return;
      } else if (relatedTarget.id === 'btn-text-content-apply-change') {
        return;
      }
    }

    // context.setTextEdition(false);
    context.setTextEditionElementKey(null);
    toggleTextElementMode();
  };

  const getOriginalText = () => {
    return getElement().contentText;
  };

  const toggleTextElementMode = () => {
    return getElement().toggleTextMode();
  };

  const getCurrentText = () => {
    return textInput.current.value;
  };

  return (
    <ScrollElement name={`scroll-${id}`}>
      <Row className="hoja-vis">
        <div className="text-container edit">
          <TextareaAutosize
            id="text-area-text-content"
            autoFocus
            rowsMin="2"
            placeholder="Escriba su texto aquí..."
            defaultValue={getOriginalText()}
            className="text-content edit"
            ref={textInput}
            onBlur={handleTextInputLeave}
            // onFocus="this.setSelectionRange(this.value.length,this.value.length);"
          ></TextareaAutosize>
          <Row className="text-edit-options">
            <Col>
              <Button
                id="btn-text-content-apply-change"
                onClick={onApplyTextClicked}
              >
                Aplicar
              </Button>
            </Col>
          </Row>
        </div>
      </Row>
    </ScrollElement>
  );
}

export default TextEditableContent;
