import React, { useContext } from 'react';
import TextReadOnlyExport from './TextReadOnlyExport';
import { StoryContext } from './StoryContext';

function TextElementExport(props) {
  const visualizerMode = props.visualizerMode;
  const id = props.id;

  /* Acceso a la historia del visualizador */
  const context = useContext(StoryContext);

  const getTextMode = () => {
    return context.session.getSessionStory().getElementByKey(id).textMode;
  };

  const getTextElementContent = () => {
      return <TextReadOnlyExport id={id} visualizerMode={visualizerMode} />;
  };

  return getTextElementContent();
}

export default TextElementExport;
