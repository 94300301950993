import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import { LOADING_SPINNER_COLOR } from '../../shared/constants';

function LoadingSpinner() {
  // const promiseInProgress = true;
  const { promiseInProgress } = usePromiseTracker();

  const getSpinnerClasses = () => {
    const baseClasses = 'spinner';
    let finalClasses = '';
    if (promiseInProgress) {
      finalClasses = 'loading-spinner';
    }
    return `${baseClasses} ${finalClasses}`;
  };

  return (
    <div className={getSpinnerClasses()}>
      {promiseInProgress ? (
        <Loader
          type="Oval"
          color={LOADING_SPINNER_COLOR}
          height="100"
          width="100"
        />
      ) : null}
    </div>
  );
}

export default LoadingSpinner;
