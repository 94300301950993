import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
} from 'react';
import VisualizationToolContent from './components/DataVisualization/VisualizationToolContent';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UserSessionDataService from './shared/UserSessionDataService';
import DataElementShareOptions from './components/Data/DataElementShareOptions';
import OfficialStoryCreator from './components/Data/OfficialStoryCreator';
import DataUpdater from './components/Data/DataUpdater';
import ErrorMessage from './shared/ErrorMessage';
import { useSelector } from 'react-redux';
import { StoryContext } from './components/DataVisualization/StoryContext';
import { useParams, useHistory, Link } from 'react-router-dom';
import store from './store';
import {
  VISUALIZER_EDIT_MODE_PATH,
  VISUALIZER_READONLY_MODE_PATH,
  VISUALIZER_SHARE_MODE_PATH,
  modes,
  USE_MOCK_STORY,
} from './shared/constants';
import {
  SESSION_SET,
  ERROR_MESSAGE_CLOSED,
  // HIDE_EXPORT_CHART,
  // EXPORT_DATA_CLEARED,
} from './actions/types';
import { trackPromise } from 'react-promise-tracker';
import Disclaimer from './components/common/Disclaimer';
import RotateDeviceMessage from './components/common/RotateDeviceMessage';
import ChartHelper from './shared/ChartHelper';
import GoogleAnalytics from './shared/GoogleAnalytics.js';

import DataHistoryDownloadOption from './components/Data/DataHistoryDownloadOption';
// TODO
// Controlar cuando está en modo READONLY o SHARE pero no hay un key válido
// Se podría ir a not found en ese caso

const getSessionString = () => {
  return `{"user_stories": {"0":{"story_elements":{
    "4c8a5920-4fd8-4c75-a4fe-c4743e0b1758":{"key":"4c8a5920-4fd8-4c75-a4fe-c4743e0b1758","type":"text","content_text":null,"mode":"readonly"},
    "92fe4de7-d95f-4351-a66e-aef20dbfc6c2":{"key":"92fe4de7-d95f-4351-a66e-aef20dbfc6c2","type":"data","indicators":[10002],"collection":0,"title_text":"","subtitle_text":"","chart_types_options":{"10002":"line"},"chart_legend_option":"down","chart_colors_option":"cool1","chart_guides_option":false},
    "e9a9c792-1262-459b-8970-5c43360968ce":{"key":"e9a9c792-1262-459b-8970-5c43360968ce","type":"text","content_text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.","mode":"readonly"},
    "dcb138de-b885-42b2-a591-2b07c06668d6":{"key":"dcb138de-b885-42b2-a591-2b07c06668d6","type":"text","content_text":"6789101112","mode":"readonly"},
    "cc48a536-d1a1-401f-9017-83b897e4b8f6":{"key":"cc48a536-d1a1-401f-9017-83b897e4b8f6","type":"data","indicators":[200004],"collection":2,"title_text":"","subtitle_text":"","chart_types_options":{"200004":"line"},"chart_legend_option":"down","chart_colors_option":"cool1","chart_guides_option":false},
    "dcb138de-b885-42b2-a591-2b07c06668d7":{"key":"dcb138de-b885-42b2-a591-2b07c06668d7","type":"text","content_text":"Texto de prueba del gráfico de erca","mode":"readonly"}},"key":"0"}
  }}`;
};

// key se utiliza cuando no se cargan datos de la historia de la sesión, sino de una historia pública
// key se extrae de los párametros del url, vía useParams

/* VISUALIZATION TOOL COMPONENT */
const VisualizationTool = forwardRef(({ location }, ref) => {
  // console.log('[VisualizationTool] is running', props);
  useEffect(() => {
    GoogleAnalytics.sendInformation(
      window.location.pathname + window.location.search,
    );
  });

  const history = useHistory();

  let { key } = useParams();

  /* Acceso a la historia del visualizador como contexto */
  const context = useContext(StoryContext);

  const exportContainerRef = useRef();

  const [visualizerMode, setvisualizerMode] = useState(null);
  const [showConfirmResetModal, setConfirmResetModal] = useState(false);
  const [storyTexts, setStoryTexts] = useState(null);
  const [showShareOptionsModal, setShowShareOptionsModal] = useState(false);
  const [showCreatePENStoryModal, setShowCreatePENStoryModal] = useState(false);
  const [rootElementClass, setRootElementClass] = useState('content');

  /* Acceso al objeto de la sesión */
  const selectSession = (state) => state.session;
  const session = useSelector(selectSession);

  /* Acceso a la cookie de la sesión */
  const selectCookie = (state) => state.cookie;
  const cookie = useSelector(selectCookie);

  const selectAdminSession = (state) => state.adminSession;
  const adminSession = useSelector(selectAdminSession);

  /* Acceso a la opción de mostrar el mensaje de error */
  const selectShowErrorMessage = (state) => state.showErrorMessage;
  const showErrorMessage = useSelector(selectShowErrorMessage);

  /*Funciones para administrar datos de datos guardados en session storage para la edicion de una historia publica */
  const storageID = 'EDIT_PUBLIC_STORY_PEN';
  const saveKeyInSessionStorage = (key) => {
    sessionStorage.setItem(storageID, key);
  };

  const getKeyInSessionStorage = () => {
    //console.log(sessionStorage.getItem(storageID));
    return sessionStorage.getItem(storageID);
  };

  const isPublicHistoryEditing = () => {
    return !!sessionStorage.getItem(storageID);
  };

  const deleteKeyInSessionStorage = () => {
    sessionStorage.removeItem(storageID);
  };


  /*Funciones para ir al listado de historia*/
  const showPublicHistory = () => {

    window.location = `/historias`;
  };

  /*Funcion para elminar una historia oficial*/
  const deleteOficialHistory = () => {
    UserSessionDataService.deleteOficialHistory(key).then((result) => {
      //console.log(result);
      deleteKeyInSessionStorage();
      window.location = `/`;
    });
  };
  /*Modal para la edicion de una historia */
  const [showUpdatePENStoryModal, setShowUpdatePENStoryModal] = useState(false);

  const handleCloseUpdatePENStoryModal = () =>
    setShowUpdatePENStoryModal(false);

  const handleShowUpdatePENStoryModal = () => setShowUpdatePENStoryModal(true);

  const getUpdatePENStoryModal = () => {
    let key = getKeyInSessionStorage();
    //console.log(key);
    return (
      <Modal
        size="lg"
        className="modal-indicator-details"
        show={showUpdatePENStoryModal}
        onHide={handleCloseUpdatePENStoryModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <DataUpdater
            storyKey={key}
            deleteKeyFunction={deleteKeyInSessionStorage}
            resetSessionFunction={onResetStoryClicked}
          />
        </Modal.Body>
      </Modal>
    );
  };
  /*Modal para confirmar la eliminacion de una historia*/
  const [deleteHistoryModal, setDeleteHistoryModal] = useState(false);

  const handleHideDeleteHistoryModal = () => setDeleteHistoryModal(false);

  const handleShowDeleteHistoryModal = () => setDeleteHistoryModal(true);

  const getConfirmDeleteModal = () => {
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        show={deleteHistoryModal}
        onHide={handleHideDeleteHistoryModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h2>¿Seguro(a) desea eliminar esta historia?</h2>
          <p className="text-center">esta acción es irreversible</p>
          <Row>
            <Button
              variant="secondary"
              onClick={deleteOficialHistory}
              className="center-in-row"
            >
              Eliminar
            </Button>
          </Row>
          <Row>
            <Button
              variant="link-like"
              onClick={handleHideDeleteHistoryModal}
              className="center-in-row"
            >
              cancelar
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  //Cuando se ingresa por el link de edit history
  const makeHistorySessionForEdit = (key) => {
    if (!!key) {
      //console.log('La key es: ', key);
      saveKeyInSessionStorage(key);
      trackPromise(
        UserSessionDataService.getPublicStoryByKey(key)
          .then((result) => {
            //console.log('result', result);
            const publicStory = JSON.parse(result.story);
            //console.log('Story del result', result.story);
            if (result.official) {
              //console.log('result', result);
              const titleText =
                'title' in result &&
                result.title !== null &&
                result.title !== ''
                  ? result.title
                  : 'Historia oficial';
              const descriptionText =
                'description' in result &&
                result.description !== null &&
                result.description !== ''
                  ? result.description
                  : null;
              setStoryTexts([titleText, descriptionText]);
            }
            const storyElements = publicStory.story_elements;
            //console.log('storyElements', storyElements);
            // Se simula una sesión con los elementos de la historia pública
            const session = {
              user_stories: {
                0: {
                  story_elements: storyElements,
                  key: '0',
                },
              },
            };
            //console.log('Esta es la session', session);
            store.dispatch({
              type: SESSION_SET,
              payload: session,
            });
            //console.log('Este es el context', context.session);
            // context.setElements(storyElements);
          })
          .catch((error) => {
            console.log('error', error);
            // TODO
            // Ir a not found
          }),
      );
    }
  };

  /* HOOKS */

  /**
   * Efecto para hacer scroll al inicio de la página o algún elemento del visualizador
   */
  useEffect(() => {
    // En los modos share y readonly no queda definido state pero no es necesario
    // el acceso porque no hay element para hacer scroll
    if (!!location.state && 'elementKey' in location.state) {
      const scrollElementKey = location.state.elementKey;
      // Si no se viene de data selector o de search results page no hay element para hacer scroll
      // console.log('scrollElementKey', scrollElementKey);
      if (!!scrollElementKey) {
        // Se guarda en el context y se utiliza en el tab content
        context.setScrollElementKey(scrollElementKey);
        // Para que se borre el element id y no se utilice si se actualiza la página
        history.replace({ state: undefined });
      } else {
        // Scroll to top of the window
        // window.scrollTo(0, 0);
      }
    } else {
      // Scroll to top of the window
      // window.scrollTo(0, 0);
    }
  }, []);

  /**
   * Efecto para obtener los datos que se van a mostrar en el visualizador y enviar
   * a renderizar VisualizationToolContent
   */
  useEffect(() => {
    if (visualizerMode === modes.EDIT && !!session) {
      // En modo edit se visualiza la historia de la sesión
      if (!USE_MOCK_STORY) {
        //console.log('----------------------------', session);
        context.parseSession(session);
      } else {
        const testSessionString = getSessionString();
        const testSession = JSON.parse(testSessionString);
        context.parseSession(testSession);
      }
    } else if (
      (visualizerMode === modes.READONLY || visualizerMode === modes.SHARE) && // Añadi edit
      !!key
    ) {
      // En modos readonly y share se visualiza la historia públcia identificada con el parámetro key
      trackPromise(
        UserSessionDataService.getPublicStoryByKey(key)
          .then((result) => {
            //console.log('result', result);
            const publicStory = JSON.parse(result.story);
            //console.log('Story del result', result.story);
            if (result.official) {
              //console.log('result', result);
              const titleText =
                'title' in result &&
                result.title !== null &&
                result.title !== ''
                  ? result.title
                  : 'Historia oficial';
              const descriptionText =
                'description' in result &&
                result.description !== null &&
                result.description !== ''
                  ? result.description
                  : null;
              setStoryTexts([titleText, descriptionText]);
            }

            const storyElements = publicStory.story_elements;
            //console.log('storyElements', storyElements);
            // Se simula una sesión con los elementos de la historia pública
            const session = {
              user_stories: {
                0: {
                  story_elements: storyElements,
                  key: '0',
                },
              },
            };
            //console.log('Visualization tool con session', session);
            context.parseSession(session);
            // context.setElements(storyElements);
          })
          .catch((error) => {
            //console.log('error', error);
            // TODO
            // Ir a not found
          }),
      );
    }
  }, [session, visualizerMode, key]);

  /**
   * Efecto para determinar el modo que se le aplica al visualizador
   */
  useEffect(() => {
    //console.log('location.pathname', location.pathname);

    const pathAnalysis = location.pathname.match(/(\/\w+)/g);
    if (pathAnalysis.length) {
      //console.log(pathAnalysis[0]);
      switch (pathAnalysis[0]) {
        case '/editHistory':
          makeHistorySessionForEdit(key);
          setvisualizerMode(modes.EDIT);
          setRootElementClass('content');
          break;
        case `/${VISUALIZER_EDIT_MODE_PATH}`:
          setvisualizerMode(modes.EDIT);
          setRootElementClass('content');
          break;
        case `/${VISUALIZER_READONLY_MODE_PATH}`:
          setvisualizerMode(modes.READONLY); // Aqui era readOnlu
          setRootElementClass('content');
          break;
        case `/${VISUALIZER_SHARE_MODE_PATH}`:
          setvisualizerMode(modes.SHARE);
          setRootElementClass('shared-content');
          break;
        default:
          setvisualizerMode(null);
          setRootElementClass('content');
          break;
      }
    } else {
      setvisualizerMode(null);
    }
  }, [location]);

  /* SHARE OPTIONS MODAL */
  const handleCloseShareOptionsModal = () => {
    // store.dispatch({
    //   type: EXPORT_DATA_CLEARED,
    // });
    // store.dispatch({
    //   type: HIDE_EXPORT_CHART,
    // });
    setShowShareOptionsModal(false);
  };

  const handleShowShareOptionsModal = () => setShowShareOptionsModal(true);

  const getShareOptionsModal = () => {
    //console.log('Estoy en compartir', key);
    return (
      <Modal
        size="lg"
        className="modal-indicator-details"
        show={showShareOptionsModal}
        onHide={handleCloseShareOptionsModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <DataElementShareOptions elementKey={null} publicStoryKey={key} />
        </Modal.Body>
      </Modal>
    );
  };

  /*DOWNLOAD modal*/
  const [showDownloadOptionsModal, setShowDownloadOptionsModal] =
    useState(false);
  const handleShowDownloadOptionsModal = () => {
    setShowDownloadOptionsModal(true);
  };
  const handleCloseDownloadOptionsModal = () => {
    setShowDownloadOptionsModal(false);
  };
  const getDownloadOptionsModal = () => {
    return (
      <Modal
        size="lg"
        className="modal-indicator-details"
        show={showDownloadOptionsModal}
        onHide={handleCloseDownloadOptionsModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <DataHistoryDownloadOption elementKey={key} />
        </Modal.Body>
      </Modal>
    );
  };
  /* CREATE PEN STORY MODAL */
  const handleCloseCreatePENStoryModal = () =>
    setShowCreatePENStoryModal(false);

  const handleShowCreatePENStoryModal = () => setShowCreatePENStoryModal(true);

  const getCreatePENStoryModal = () => {
    return (
      <Modal
        size="lg"
        className="modal-indicator-details"
        show={showCreatePENStoryModal}
        onHide={handleCloseCreatePENStoryModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <OfficialStoryCreator />
        </Modal.Body>
      </Modal>
    );
  };

  /* CONFIRM RESET MODAL */
  const handleCloseConfirmResetModal = () => setConfirmResetModal(false);

  const handleShowConfirmResetModal = () => setConfirmResetModal(true);

  const getConfirmResetModal = () => {
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        show={showConfirmResetModal}
        onHide={handleCloseConfirmResetModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h2>¿Seguro(a) desea reiniciar este gráfico?</h2>
          <p className="text-center">
            Si lo reinicia deberá volver a seleccionar los indicadores para
            graficar
          </p>
          <Row>
            <Button
              variant="secondary"
              onClick={onResetStoryClicked}
              className="center-in-row"
            >
              Reiniciar
            </Button>
          </Row>
          <Row>
            <Button
              variant="link-like"
              onClick={handleCloseConfirmResetModal}
              className="center-in-row"
            >
              cancelar
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  /* HELPERS */
  const storyHasValidElements = () => {
    //console.log(context.session);
    //console.log(context.session.getStories());
    //console.log('soy la key', key);
    return (
      context.session !== null &&
      context.session.getSessionStory().hasValidElements()
    );
  };

  const getTitleText = () => {
    return visualizerMode === modes.EDIT
      ? 'Visualizador de datos'
      : !!storyTexts
      ? storyTexts[0]
      : 'Historia oficial';
  };

  const getNavigationTrack = () => {
  };

  const getTitleSection = () => {
    return (
      <Row className="row-info-container">
        <h2>{getTitleText()}</h2>
      </Row>
    );
  };

  const getSecondaryTextSection = () => {
    if (!!storyTexts && !!storyTexts[1]) {
      return (
        <Row className="row-info-container">
          <p>{storyTexts[1]}</p>
        </Row>
      );
    } else {
      return null;
    }
  };

  /* ERROR MESSAGE MODAL */
  const handleCloseErrorMessageModal = () => {
    store.dispatch({
      type: ERROR_MESSAGE_CLOSED,
    });
  };

  const getErrorMessageModal = () => {
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        show={showErrorMessage}
        onHide={handleCloseErrorMessageModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <ErrorMessage onOKClicked={handleCloseErrorMessageModal} />
        </Modal.Body>
      </Modal>
    );
  };

  /* HANDLERS */
  const onResetStoryClicked = () => {
    const session = context.session;
    trackPromise(
      UserSessionDataService.deleteStoryElements(cookie, session)
        .then((result) => {
          // Se obtuvieron los datos de la sesión actualizada
          // Se guardan en redux store
          store.dispatch({
            type: SESSION_SET,
            payload: result,
          });
          handleCloseConfirmResetModal();

          // Cuando se eliminan elementos de datos, se deben eliminar todos los tooltips
          // que puedan existir para este punto
          ChartHelper.removeTooltipsDivs();
        })
        .catch(() => {
          // console.log('error');
          handleCloseConfirmResetModal();
        }),
    );
  };

  const onResetEditStoryClicked = () => {
    // redirecion a la vista anterios    no se está eliminando la session
    history.push(`/historias`)

    // se deja el codgo anterios por si
    // const session = context.session;
    // trackPromise(
      // UserSessionDataService.deleteStoryElements(cookie, session)
      //   .then((result) => {
      //     // Se obtuvieron los datos de la sesión actualizada
      //     // Se guardan en redux store
      //     store.dispatch({
      //       type: SESSION_SET,
      //       payload: result,
      //     });
      //     deleteKeyInSessionStorage();

      //     // Cuando se eliminan elementos de datos, se deben eliminar todos los tooltips
      //     // que puedan existir para este punto
      //     ChartHelper.removeTooltipsDivs();
      // }
      // )
    //     .catch(() => {
    //       // console.log('error');
    //       handleCloseConfirmResetModal();
    //     }),
    // );
  };

  /* MAIN */
  const getMainContent = () => {
    return (
      <VisualizationToolContent
        visualizerMode={visualizerMode}
        ref={exportContainerRef}
      />
    );
  };

  /* COMPONENT DEFINITION */
  return (
    <>

      <RotateDeviceMessage />
      <div className={rootElementClass} ref={ref}>
        <div className="col-12">
          <Link onClick={() => history.push(`/home`)} className="volver-link">
            Volver
          </Link>
        </div>
        {visualizerMode === modes.EDIT &&
          isPublicHistoryEditing() &&
          getErrorMessageModal()}
        {visualizerMode !== modes.PREVIEW && getShareOptionsModal()}
        {visualizerMode !== modes.SHARE && getConfirmResetModal()}
        {getConfirmDeleteModal()}
        {visualizerMode !== modes.SHARE && getNavigationTrack()}
        {visualizerMode === modes.EDIT && getCreatePENStoryModal()}
        {visualizerMode !== modes.SHARE && getTitleSection()}
        {visualizerMode === modes.READONLY && getSecondaryTextSection()}
        {visualizerMode === modes.EDIT &&
          isPublicHistoryEditing() &&
          getUpdatePENStoryModal()}
        <Row className="right-btn-container">
          {visualizerMode === modes.EDIT &&
            !!adminSession &&
            !isPublicHistoryEditing() && (
              <Button
              variant="primaryhistory-btn"
                onClick={showPublicHistory}
              >
                Ver las historias
              </Button>)}
          {!visualizerMode === modes.EDIT && !isPublicHistoryEditing() && (
            <Button
              variant="reiniciar-btn"
              onClick={handleShowConfirmResetModal}
              disabled={!storyHasValidElements()}
            >
              Reiniciar
            </Button>
          )}
          {visualizerMode === modes.EDIT &&
            !!adminSession &&
            !isPublicHistoryEditing() && (
              <Button
                variant="story-btn"
                onClick={handleShowCreatePENStoryModal}
                disabled={!storyHasValidElements}
              >
                Crear historia PEN
              </Button>
            )}

          {visualizerMode === modes.EDIT &&
            !!adminSession &&
            isPublicHistoryEditing() && (
              <Button
                variant="danger"
                onClick={handleShowDeleteHistoryModal}
                disabled={!storyHasValidElements()}
              >
                Eliminar historia
              </Button>
            )}

          {visualizerMode === modes.EDIT &&
            !!adminSession &&
            isPublicHistoryEditing() && (
              <Button variant="reiniciar-btn" onClick={onResetEditStoryClicked}>
                Cancelar edicion historia
              </Button>
            )}

          {visualizerMode === modes.EDIT &&
            !!adminSession &&
            isPublicHistoryEditing() && (
              <Button
                variant="story-btn"
                onClick={handleShowUpdatePENStoryModal}
                disabled={!storyHasValidElements()}
              >
                Actualizar historia PEN
              </Button>
            )}

          {showDownloadOptionsModal &&
            !isPublicHistoryEditing() &&
            getDownloadOptionsModal()}

          {visualizerMode !== modes.PREVIEW && !isPublicHistoryEditing() && (
            <Button
              variant="exportar-btn"
              onClick={handleShowShareOptionsModal}
              disabled={!storyHasValidElements()}
            ></Button>
          )}

          {visualizerMode !== modes.PREVIEW && !isPublicHistoryEditing() && (
            <Button
              id="download-button"
              className="download-special-button btn btn-primary dl-grande"
              disabled={!storyHasValidElements()}
              onClick={setShowDownloadOptionsModal}
            ></Button>
          )}
        </Row>
        {/*
         * Se puede crear el contenido principal una vez que se haya establecido
         * el modo de visualización
         */}
        {!!visualizerMode && getMainContent()}
        <Row className="visualizer-disclaimer-container">
          <Disclaimer />
        </Row>
      </div>
    </>
  );
});

export default VisualizationTool;
