import {
  CHART_TYPES_KEY,
  CHART_COLORS_KEY,
  CHART_GUIDES_KEY,
  CHART_LEGEND_KEY,
  DEFAULT_DATA_ELEMENT,
  INDICATORS_KEY,
  DEFAULT_CHART_TYPE,
} from '../shared/constants';

const getTypes = (dbElementObject) => {
  // console.log('dbElementObject', dbElementObject);

  let types = {};

  if (!(INDICATORS_KEY in dbElementObject)) {
    return types;
  }

  // Si no hay indicadores en el elemento, se devuelve un diccionario vacío
  // De otra manera, se devuelve el diccionario con los tipos

  const dbTypesObject = dbElementObject[CHART_TYPES_KEY];
  // console.log('dbTypesObject', dbTypesObject);

  const dbIndicatorsArray = dbElementObject[INDICATORS_KEY];
  // console.log('dbIndicatorsArray', dbIndicatorsArray);

  types = Object.values(dbIndicatorsArray).reduce((accum, curr) => {
    // curr es un id de indicador

    if (
      !!dbTypesObject &&
      typeof dbTypesObject !== 'undefined' &&
      curr in dbTypesObject
    ) {
      accum[curr] = dbTypesObject[curr];
    } else {
      accum[curr] = DEFAULT_CHART_TYPE;
    }

    return accum;
  }, {});

  return types;
};

class ChartConfiguration {
  constructor(dbElementObject) {
    // Se maneja como un diccionario donde cada key es un indicador y cada value es un tipo de gráfico
    this._types = getTypes(dbElementObject);
    this._colors =
      CHART_COLORS_KEY in dbElementObject
        ? dbElementObject[CHART_COLORS_KEY]
        : DEFAULT_DATA_ELEMENT[CHART_COLORS_KEY];
    this._guides =
      CHART_GUIDES_KEY in dbElementObject
        ? dbElementObject[CHART_GUIDES_KEY]
        : DEFAULT_DATA_ELEMENT[CHART_GUIDES_KEY];
    this._legend =
      CHART_LEGEND_KEY in dbElementObject
        ? dbElementObject[CHART_LEGEND_KEY]
        : DEFAULT_DATA_ELEMENT[CHART_LEGEND_KEY];
  }

  get types() {
    return this._types;
  }
  get colors() {
    return this._colors;
  }
  get guides() {
    return this._guides;
  }
  get legend() {
    return this._legend;
  }

  set types(types) {
    this._types = types;
  }
  set colors(colors) {
    this._colors = colors;
  }
  set guides(guides) {
    this._guides = guides;
  }
  set legend(legend) {
    this._legend = legend;
  }

  adjustTypes(dbElementObject) {
    this._types = getTypes(dbElementObject);
  }

  getSeriesTypeByIndicatorKey(key) {
    if (key in this._types) {
      return this._types[key];
    } else {
      return null;
    }
  }

  updateOldFormat(configurationOldFormat) {
    this._types = configurationOldFormat.chart_types;
    this._colors = configurationOldFormat.chart_colors_option;
    this._guides = configurationOldFormat.chart_guides_option;
    this._legend = configurationOldFormat.chart_legend_option;
  }

  getDbObject() {
    return {
      [CHART_TYPES_KEY]: { ...this._types },
      [CHART_COLORS_KEY]: this._colors,
      [CHART_GUIDES_KEY]: this._guides,
      [CHART_LEGEND_KEY]: this._legend,
    };
  }
}

export default ChartConfiguration;
