import React, { useState, useEffect, forwardRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import GenericMessage from './shared/GenericMessage';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {
  COLLECTION_FILTER_ID,
  COLLECTION_FILTER_LABEL,
  KEY_GOOGLE_ANA,
} from './shared/constants';
import PENDataService from './shared/PENDataService.js';
import axios from 'axios';
import GoogleAnalytics from './shared/GoogleAnalytics.js';
import { trackPromise } from 'react-promise-tracker';
import UserSessionDataService from './shared/UserSessionDataService';
import { Link, useHistory } from 'react-router-dom';

/* DATA MANAGER COMPONENT */
const DataManager = forwardRef(({}, ref) => {
  let collectionSelectRef = React.createRef();
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    /*GoogleAnalytics.sendInformation(
      window.location.pathname + window.location.search,
    );*/
  });

  /* Colecciones del sistema */
  const [collections, setCollections] = useState([]);

  /**
   * Efecto para la consulta de colecciones
   */
  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    trackPromise(PENDataService.getCollectionValues(source, false))
      .then((result) => {
        //console.log('result', result);
        setCollections(result);
      })
      .catch((error) => {
        //console.log('error', error);
      });
  }, []);

  /**
   * Devuelve las opciones para el select de colecciones
   */
  const getCollectionsSelect = () => {
    //console.log('collections', collections);
    return (
      <>
        {collections.map((collection, index) => (
          <option value={collection.id} key={btoa(index)}>
            {collection.name}
          </option>
        ))}
      </>
    );
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };
  const showFileUploadConfirmation = () => {
    if (file == null) {
      setShowAlertForUploadFile(true);
    } else {
      setShowConfirm(true);
    }
  };

  const confirmExportActionModal = () => {
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        centered
        show={showConfirm}
        onHide={handleCloseConfirm}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h2>
            ¿Esta seguro que quiere sobreescribir los datos del compendio ?
          </h2>
          <p className="text-center">
            Asegurese de subir los datos correctos, esta operación puede tardar
            varios minutos
          </p>
          <Row>
            <Button
              variant="secondary"
              onClick={onFileUpload}
              className="center-in-row"
            >
              Actualizar
            </Button>
          </Row>
          <Row>
            <Button
              variant="link-like"
              onClick={handleCloseConfirm}
              className="center-in-row"
            >
              cancelar
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  /**
   * Handler para el click del botón de exportar
   */
  const onExportCollectionClicked = (event) => {
    //console.log('event', event);
    //console.log('collectionSelectRef value', collectionSelectRef.current.value);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    // TODO
    // Enviar solicitud para que se cree el archivo de la colección en el server y descargarlo acá
    const collectionId = collectionSelectRef.current.value;
    if (!!collectionId) {
      trackPromise(
        PENDataService.downloadCollectionFileForExport(
          collectionId,
          [],
          source,
        ),
      )
        .then((result) => {
          //console.log('Hola');
          const fileData = result.data;
          handleFileData(fileData);
        })
        .catch((error) => {
          ///console.log('error', error);
        });
    }
  };

  const showModal = () => {
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div>
            <h2>Selecciones el archivo .xlsx</h2>
            <div
              class="custom-file"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20%',
              }}
            >
              <input
                type="file"
                class="custom-file-input"
                id="customFileLang"
                lang="es"
                onChange={onFileChange}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <label class="custom-file-label" for="customFileLang">
                Seleccionar Archivo
              </label>
            </div>
            {showAlertForUploadFile && alertForFileUpload()}
            {showNameOfFileAlert && showNameOfFile()}
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20%',
              }}
            >
              {confirmExportActionModal()}
              <Button onClick={showFileUploadConfirmation}>Importar</Button>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const handleClose = () => {
    setShow(false);
    setShowAlertForUploadFile(false);
    setShowNameOfFileModal(false);
  };

  const handleClickImportar = () => {
    setFile(null);
    setShow(true);
  };

  const onFileChange = (event) => {
    // Update the state
    setFile(event.target.files[0]);
    setShowAlertForUploadFile(false);
    setShowNameOfFileModal(true);
  };

  //Alert file dont upload
  const [showAlertForUploadFile, setShowAlertForUploadFile] = useState(false);
  const alertForFileUpload = () => {
    return (
      <Alert key="alertForUpload" variant="danger">
        Tiene que subir un archivo
      </Alert>
    );
  };

  //Show name of file upload
  const [showNameOfFileAlert, setShowNameOfFileModal] = useState(false);
  const showNameOfFile = (nameFile) => {
    return (
      <Alert key="alertForName" variant="primary">
        Se subio el archivo {file.name} correctamente
      </Alert>
    );
  };

  const onFileUpload = () => {
    // Create an object of formData
    //console.log(collectionSelectRef.current.value);
    if (file == null) {
      setShowAlertForUploadFile(true);
    } else {
      const formData = new FormData();

      // Update the formData object
      formData.append('collection', file, file.name);

      // Details of the uploaded file
      //console.log(file);

      // Request made to the backend api
      // Send formData object
      //axios.post('api/uploadfile', formData);

      trackPromise(
        UserSessionDataService.updateDataBase(
          collectionSelectRef.current.value,
          formData,
        ),
      )
        .then((result) => {
          console.log(result);
          console.log(result.data);
          if (!!result.result) {
            setExecutionMessage(result.data);
            setShowDataExecutionResult(true);
          } else {
            alert(
              'Ocurrio un error, asegurese que el formato del archivo sea el correcto.',
            );
          }
        })
        .catch((error) => {
          //console.log(error);
          alert(
            'Ocurrio un error, asegurese que el formato del archivo sea el correcto.',
          );
        });
      setShowNameOfFileModal(false);
      handleClose();
      handleCloseConfirm();
    }
  };
  const getWarnings = () => {
    return (
      <>
        <Alert key="first-warning" variant="warning">
          Asegúrese de hacer un respaldo de los datos antes de importar datos de
          un compendio
        </Alert>
        <Alert key="first-warning-2" variant="warning">
          Verifique que seleccionó el compendio correcto al importar datos
        </Alert>
        <Alert key="first-warning-3" variant="warning">
          Las operaciones pueden tardar varios minutos
        </Alert>
      </>
    );
  };
  const handleFileData = (fileData) => {
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'compendio.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  const [excutionMessage, setExecutionMessage] = useState('');
  const [dataExecutionResult, setShowDataExecutionResult] = useState(false);
  const handleShowDataExecutionResult = () => {
    setShowDataExecutionResult(true);
  };
  const handleCloseDataExecutionResult = () => {
    setShowDataExecutionResult(false);
  };
  const getDownloadOptionsModal = () => {
    return (
      <Modal
        size="lg"
        className="modal-indicator-details"
        show={dataExecutionResult}
        onHide={setShowDataExecutionResult}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <ul>
            {excutionMessage.split('\n').map((str, idx) => (
              <li key={idx}>{str}</li>
            ))}
          </ul>
          <Button onClick={handleCloseDataExecutionResult}>Ok</Button>
        </Modal.Body>
      </Modal>
    );
  };
  const history = useHistory()
  return (
    <>
      <div className="content" style={{ height: '50% ' }} ref={ref}>
        <div className="col-12">
          <Link onClick={() => history.push(`/home`)} className="volver-link">
            Volver
          </Link>
        </div>
        <Row className="row-info-container">
          <h2>Gestión de datos</h2>
        </Row>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5% ',
          }}
        >
          <Form inline>
            <Form.Group>
              <Form.Label className="my-1 mr-2">
                {COLLECTION_FILTER_LABEL}
              </Form.Label>
              <Form.Control
                as="select"
                id="collection-select"
                className="my-1 mr-sm-2"
                ref={collectionSelectRef}
              >
                {getCollectionsSelect()}
                {showModal()}
                {getDownloadOptionsModal()}
              </Form.Control>
            </Form.Group>
          </Form>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '3%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {getWarnings()}
        </Row>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            margin: '5%',
          }}
        >
          <Button onClick={onExportCollectionClicked}>Exportar</Button>
          <Button onClick={handleClickImportar}>Importar</Button>
        </Row>
      </div>
    </>
  );
});

export default DataManager;
