import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChartTypeEditor from './ChartTypeEditor';
import GenericChartEditor from './GenericChartEditor';

const genericEditors = {
  color: {
    id: 'color',
    title: 'Paleta de Colores',
    titleRegular: 'Paleta de',
    titleEmphasis: 'Colores',
  },
  texts: {
    id: 'texts',
    title: 'Editar Textos',
    titleRegular: 'Editar',
    titleEmphasis: 'Textos',
  },
  legend: {
    id: 'legend',
    title: 'Editar Leyenda',
    titleRegular: 'Editar',
    titleEmphasis: 'Leyenda',
  },
};

function ChartEditor(props) {
  //console.log('props', props);

  const getGenericEditors = () => {
    return Object.values(genericEditors).map((el) => (
      <GenericChartEditor
        id={el.id}
        title={el.title}
        titleRegular={el.titleRegular}
        titleEmphasis={el.titleEmphasis}
        dataElementConfiguration={props.dataElementConfiguration}
      />
    ));
  };

  return (
    <Col>
      <Row>
        <h2>Editar gráfico</h2>
      </Row>
      <Row>
        <h4 className="body-highlight">
          Explore las opciones y cambie el diseño de su gráfico.
        </h4>
      </Row>
      <br />
      <Row>
        <Col md="6" className="chart-editor-column">
          <ChartTypeEditor
            dataElementConfiguration={props.dataElementConfiguration}
          />
        </Col>
        <Col md="6" className="chart-editor-column">
          {getGenericEditors(props.dataElementConfiguration)}
        </Col>
      </Row>
    </Col>
  );
}

export default ChartEditor;
