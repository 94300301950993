import React, { useState, useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import UserSessionDataService from '../../shared/UserSessionDataService';
import DataAdapter from '../../shared/DataAdapter';
import { StoryContext } from '../../components/DataVisualization/StoryContext';

const DataUpdater = ({ storyKey, deleteKeyFunction, resetSessionFunction }) => {
  // console.log('props en OfficialStoryCreator', props);

  /* Acceso a la historia del visualizador */
  const context = useContext(StoryContext);

  const [titleTextValue, setTitleTextValue] = useState(null);
  const [descriptionTextValue, setDescriptionTextValue] = useState(null);
  const [fileValue, setFileValue] = useState(null);
  //console.log(storyKey);
  useEffect(() => {
    //console.log(storyKey);
    if (!!storyKey) {
      UserSessionDataService.getPENStory(storyKey).then((historyData) => {
        //console.log(historyData[0]);
        setTitleTextValue(historyData[0].title);
        setDescriptionTextValue(historyData[0].description);
        setFileValue(null);
      });
    }
  }, [storyKey]);

  const onSubmit = (event) => {
    const story = context.session.getCopy().getSessionStory().getDbObject();
    const extraData = {
      title: titleTextValue,
      description: descriptionTextValue,
      image: fileValue,
    };

    const formData = DataAdapter.adaptPublicStoryDataAsFormData(
      story,
      true,
      extraData,
    );

    UserSessionDataService.updatePENStory(storyKey, formData)
      .then((result) => {
        //console.log(result);
        deleteKeyFunction();
        resetSessionFunction();
        window.location = `/historias`;
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const onInput = (event) => {
    //console.log('event', event);

    if (!!event.target && !!event.target.id) {
      const id = event.target.id;

      if (id === 'title') {
        if (event.target.value !== null && event.target.value !== '') {
          setTitleTextValue(event.target.value);
        } else {
          setTitleTextValue(null);
        }
      } else if (id === 'description') {
        if (event.target.value !== null && event.target.value !== '') {
          setDescriptionTextValue(event.target.value);
        } else {
          setDescriptionTextValue(null);
        }
      } else if (id === 'image') {
        if (event.target.files && event.target.files.length) {
          setFileValue(event.target.files[0]);
        } else {
          setFileValue(null);
        }
      }
    }
  };

  const isSubmitDisabled = () => {
    return titleTextValue === null || descriptionTextValue === null;
  };

  return (
    <Container fluid className="action-modal-container">
      <Row>
        <h2>Datos historia PEN</h2>
      </Row>
      <Row>
        <Form encType="multipart/form-data" as={Col}>
          <Form.Group as={Row}>
            <Col xs={12} md={2}>
              <Form.Label column>Título</Form.Label>
            </Col>
            <Col xs={12} md={10}>
              <Form.Control
                required
                type="text"
                maxLength={255}
                id="title"
                name="titulo"
                value={titleTextValue}
                // ref={inputTitleText}
                onInput={onInput}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col xs={12} md={2}>
              <Form.Label column>Texto secundario</Form.Label>
            </Col>
            <Col xs={12} md={10}>
              <Form.Control
                required
                type="text"
                as="textarea"
                rows={3}
                id="description"
                name="descripcion"
                value={descriptionTextValue}
                // ref={inputDescriptionText}
                onInput={onInput}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col xs={12} md={2}>
              <Form.Label column>Imagen</Form.Label>
            </Col>
            <Col xs={12} md={10}>
              <Form.File
                required
                accept="image/*"
                id="image"
                name="imagen"
                // ref={inputFile}
                onInput={onInput}
              />
            </Col>
          </Form.Group>
          NOTA: Si no sube ninguna imagen se va a usar la imagen que ya esta
          asignada.
          <br />
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={isSubmitDisabled()}
            >
              Actualizar historia
            </Button>
          </Row>
        </Form>
      </Row>
    </Container>
  );
};

export default DataUpdater;
