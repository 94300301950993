import React, { useState } from 'react';
import Tab from './Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
  DATA_ELEMENT_EDITION_PATH,
  DATA_SELECTION_PATH,
  modes,
  dataSelectionModes,
} from '../../shared/constants';
import DataElementDownloadOptions from '../Data/DataElementDownloadOptions';
import { useHistory } from 'react-router-dom';
// import store from '../../store';
// import { HIDE_EXPORT_CHART } from '../../actions/types';

const Tabs = React.forwardRef((props, ref) => {
  let history = useHistory();

  const id = props.id;
  const visualizerMode = props.visualizerMode;

  const [activeTab, setActiveTab] = useState(props.children[0].props.label);

  /* DOWNLOAD OPTIONS */
  const [showDownloadOptionsModal, setShowDownloadOptionsModal] = useState(
    false,
  );

  const handleCloseDownloadOptionsModal = () => {
    // store.dispatch({
    //   type: HIDE_EXPORT_CHART,
    // });

    setShowDownloadOptionsModal(false);
  };

  const handleShowDownloadOptionsModal = () => {
    setShowDownloadOptionsModal(true);
  };

  const getDownloadOptionsModal = () => {
    return (
      <Modal
        size="lg"
        className="modal-indicator-details"
        show={showDownloadOptionsModal}
        onHide={handleCloseDownloadOptionsModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <DataElementDownloadOptions elementKey={props.id} />
        </Modal.Body>
      </Modal>
    );
  };

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  const onEditDataClicked = () => {
    //console.log('Edit data for data element clicked !!!');

    // Sí se envía elementKey
    history.push({
      pathname: `/${DATA_SELECTION_PATH}`,
      state: {
        dataSelectionMode: dataSelectionModes.EDIT,
        elementKey: id,
      },
    });
  };

  const onEditChartClicked = () => {
    //console.log('Edit data element clicked !!!');

    history.push({
      pathname: `/${DATA_ELEMENT_EDITION_PATH}`,
      state: { elementKey: id },
    });
  };

  const onDownloadClicked = async () => {};

  return (
    <>
      {getDownloadOptionsModal()}
      <Col className="tabs">
        <Row>
          <Col>
            <Row>
              <Col>
                <ol className="tab-list">
                  {props.children.map((child) => {
                    const { label } = child.props;
                    return (
                      <Tab
                        activeTab={activeTab}
                        key={label}
                        label={label}
                        onClick={onClickTabItem}
                      />
                    );
                  })}
                </ol>
              </Col>
              <Col className="visualizer-button-div">
                <Button
                  id="download-button"
                  className="download-special-button"
                  onClick={handleShowDownloadOptionsModal}
                >
                  descargar
                </Button>
                {visualizerMode === modes.EDIT && (
                  <Button
                    id="edit-data-button"
                    variant="light"
                    className="visualizer-button"
                    onClick={onEditDataClicked}
                  >
                    editar datos
                  </Button>
                )}
                {visualizerMode === modes.EDIT && (
                  <Button
                    id="edit-chart-button"
                    variant="light"
                    className="visualizer-button"
                    onClick={onEditChartClicked}
                  >
                    editar gráfico
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="tab-content">
                  {props.children.map((child) => {
                    if (child.props.label !== activeTab) return undefined;
                    return child.props.children;
                  })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
});

export default Tabs;
