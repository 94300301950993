import React, { useRef, useContext, memo } from 'react';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';
import { DataSelectionContext } from './DataSelectionContext';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import store from '../../store';
import { SELECTION_SET } from '../../actions/types';
const { v4: uuidv4 } = require('uuid');

/* DOUBLE COLUMN FILTER VALUES TABLE COMPONENT */
const DoubleColumnFilterValuesTable = memo(({ id, values, selectedValues }) => {
  console.log('[DoubleColumnFilterValuesTable] is running', id);

  // Acceso a la selección de datos
  const context = useContext(DataSelectionContext);

  /* Acceso al objeto de la tabla */
  let leftColumnTableRef = useRef(null);
  let rightColumnTableRef = useRef(null);

  const sendActionSelectionSet = () => {
    store.dispatch({
      type: SELECTION_SET,
      payload: context.selection.getSimpleFormatObject(),
    });
  };

  /**
   * Envía a la información compartida la acción de seleccionar un valor del filtro
   */
  const sendActionFilterValueSelected = (filterValue) => {
    context.selectFilterValue(id, filterValue);
    sendActionSelectionSet();
  };

  /**
   * Envía a la información compartida la acción de seleccionar un valor del filtro
   * @param {number} filterValue
   */
  const sendActionFilterValueDeselected = (filterValue) => {
    context.deselectFilterValue(id, filterValue);
    sendActionSelectionSet();
  };

  const getValuesSlice = (columnInd) => {
    const totalNumElems = values.length;
    const mid = Math.ceil(totalNumElems / 2);
    const startIndex = columnInd === 0 ? 0 : mid;
    const endIndex = columnInd === 0 ? mid : totalNumElems;
    const valuesSlice = [...values].slice(startIndex, endIndex);
    return valuesSlice;
  };

  /**
   * Handler para la selección de un valor del filtro
   */
  const onFilterValueRowSelected = (row) => {
    const rowData = row.getData();
    const filterValue = rowData.id;
    sendActionFilterValueSelected(filterValue);
  };

  /**
   * Handler para la deselección de un valor del filtro
   */
  const onFilterValueRowDeselected = (row) => {
    const rowData = row.getData();
    const filterValue = rowData.id;
    sendActionFilterValueDeselected(filterValue);
  };

  /**
   * Handler para la opción selectableCheck de la tabla
   */
  const isRowSelectable = (row) => {
    const rowData = row.getData();
    return rowData.enabled;
  };

  /* Opciones de la tabla, se pasan al componente de tabulator */
  const options = {
    headerVisible: false, //hide header
    resizableColumns: false,
    rowSelected: onFilterValueRowSelected,
    rowDeselected: onFilterValueRowDeselected,
    selectableCheck: isRowSelectable,
    // dataLoaded: onFilterValuesLoaded,
  };

  const onFilterValuesLoaded = (tableId) => {
    const tableRef = tableId === 0 ? leftColumnTableRef : rightColumnTableRef;
    if (!!tableRef && !!tableRef.current) {
      const table = tableRef.current.table;
      if (!!table) {
        if (selectedValues.length) {
          table.selectRow(selectedValues);
        }
      }
    }
  };

  const leftColumnTableDataLoaded = (data) => {
    onFilterValuesLoaded(0);
  };

  const rightColumnTableDataLoaded = (data) => {
    onFilterValuesLoaded(1);
  };

  /* Columnas de la tabla, se pasan al componente de tabulator */
  const columns = [
    {
      formatter: 'rowSelection',
      hozAlign: 'center',
      headerSort: false,
      cellClick:function(e, cell){
         cell.getRow().toggleSelect();
      }
    },
    { field: 'name', headerSort: false, width: '80%' },
  ];

  return (
    <Container fluid>
      <Row>
        <Col xs={6}>
          <ReactTabulator
            ref={leftColumnTableRef}
            data={getValuesSlice(0)}
            columns={columns}
            tooltips={false}
            options={{ ...options, dataLoaded: leftColumnTableDataLoaded }}
            key={uuidv4()}
          />
        </Col>
        <Col xs={6}>
          <ReactTabulator
            ref={rightColumnTableRef}
            data={getValuesSlice(1)}
            columns={columns}
            tooltips={false}
            options={{ ...options, dataLoaded: rightColumnTableDataLoaded }}
            key={uuidv4()}
          />
        </Col>
      </Row>
    </Container>
  );
});

export default DoubleColumnFilterValuesTable;
