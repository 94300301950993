import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/esm/Row';
import { trackPromise } from 'react-promise-tracker';
import UserSessionDataService from './shared/UserSessionDataService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import PENStoryAdminActions from './components/Data/PENStoryAdminActions';
import { Link, useHistory } from 'react-router-dom';


const ListHistories = () => {
  const history = useHistory();

  /*Funcion para elminar una historia oficial*/
  const deleteOficialHistory = (id) => {
    UserSessionDataService.deleteOficialHistory(id).then(result => {
      setDeleteHistoryModal(false);
      setIdDeleted(null)
    })
  };

  const [penStories, setPENStories] = useState([]);
  const [deleteHistoryModal, setDeleteHistoryModal] = useState(false);
  const [idDeleted, setIdDeleted] = useState(null)
  const handleShowDeleteHistoryModal = (id) => {
    setDeleteHistoryModal(true);
    setIdDeleted(id)
  }
  const handleHideDeleteHistoryModal = () => setDeleteHistoryModal(false);
  useEffect(() => {
    trackPromise(
      UserSessionDataService.getPENStories()
        .then((result) => {
          setPENStories(result);
        })
        .catch((error) => {
          console.log('error', error);
        }),
    );
  }, [idDeleted]);

  const getConfirmDeleteModal = () => {
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        show={deleteHistoryModal}
        onHide={handleHideDeleteHistoryModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h2>¿Seguro(a) desea eliminar esta historia?</h2>
          <p className="text-center">esta acción es irreversible</p>
          <Row>
            <Button
              variant="secondary"
              onClick={() => deleteOficialHistory(idDeleted)}
              className="center-in-row"
            >
              Eliminar
            </Button>
          </Row>
          <Row>
            <Button
              variant="link-like"
              onClick={handleHideDeleteHistoryModal}
              className="center-in-row"
            >
              cancelar
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="row " style={{ marginTop: 200 }}>
          <div className="col-12">
            <Link onClick={() => history.push(`/visualizador`)} className="volver-link">
              Volver
            </Link>
          </div>
          {getConfirmDeleteModal()}
          {
            penStories.map((penStory) => (
              <PENStoryAdminActions
                key={penStory.id}
                id={penStory.id}
                name={penStory.name}
                title={penStory.title}
                description={penStory.description}
                extension={penStory.extension}
                handleShowDeleteHistoryModal={handleShowDeleteHistoryModal}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default ListHistories