export const PUBLIC_URL = process.env.PUBLIC_URL;
export const ADMIN_PATH = 'admin';
export const DATA_DOWNLOAD_PATH = 'compendios';
export const VISUALIZER_EDIT_MODE_PATH = 'visualizador';
export const VIEW_ALL_STORIES = 'historias';
export const VISUALIZER_READONLY_MODE_PATH = 'historia';
export const VISUALIZER_SHARE_MODE_PATH = 'compartir';
export const EXPORT_PATH = 'exportar';
export const DATA_SELECTION_PATH = 'datos';
export const HOME_ADMIN = 'home';
export const DATA_MANAGEMENT_PATH = 'gestion_datos';
export const DATA_ELEMENT_EDITION_PATH = 'edicion_grafico';
export const DATA_ELEMENT_METADATA_PATH = 'metadatos_grafico';
export const COLLECTION_FILTER_ID = 'collection';
export const COLLECTION_FILTER_LABEL = 'Compendio estadístico';
export const COLLECTION_FILTER_SHORT_LABEL = 'Compendio';
export const TOPIC_FILTER_ID = 'topic';
export const TOPIC_FILTER_LABEL = 'Temas';
export const REGION_FILTER_ID = 'region';
export const REGION_FILTER_LABEL = 'País';
export const YEAR_FILTER_ID = 'year';
export const YEAR_FILTER_LABEL = 'Año';
export const ERCA_COLLECTION_ID = 2;
export const NUM_MAX_INDICATORS_REGULAR_CASE = 2;
export const NUM_MAX_INDICATORS_ERCA_CASE = 1;
export const NUM_INDICATORS_PER_SEARCH_RESULTS_PAGE = 5;
export const NUM_INDICATORS_PER_INDICATORS_PAGE = 5;
export const NUM_VALUES_PER_FILTER_VALUES_PAGE = 5;
const NUM_MAX_STORY_ELEMENTS = 10;
export const NUM_MAX_STORY_DATA_ELEMENTS = NUM_MAX_STORY_ELEMENTS;
export const NUM_MAX_STORY_TEXT_ELEMENTS = NUM_MAX_STORY_ELEMENTS;
export const X_VARIABLE_FIELD_ID = 'year';
export const Y_VARIABLE_FIELD_ID = 'value';
export const EXPORT_STORY_PATH = 'exportar_historia';
export const KEY_GOOGLE_ANA = 'G-BW1Z3252XJ';
export const KEY_GOOGLE_TAG = 'GTM-PVJ3K95';
// export const NUMBER_MASKING_OPTION = 'es-CR';
// German uses comma as decimal separator and period for thousands
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
export const NUMBER_MASKING_OPTION = 'de-DE';

export const MAX_INDICATORS_MESSAGE =
  'texto que sugiera la cantidad máxima de indicadores para asegurar el mejor uso del visualizador';
export const DISABLED_INDICATORS_MESSAGE =
  'Solo aparecen activos los indicadores que pertenecen al compendio seleccionado';

export const WARNING_CROSS_INDICATORS_MESSAGE =
'RECOMENDACIÓN: Si va a utilizar dos indicadores, le sugerimos que tengan la misma unidad. Es decir, porcentaje con otro porcentaje o bien solo números absolutos';

export const NO_RESPONSIBILITY_DISCLAIMER =
  'El PEN no se hace responsable por el uso e interpretación de los datos obtenidos al usar este módulo gráfico';
export const STORY_DATA_ELEMENTS_LIMIT_REACHED_MESSAGE =
  'Ha alcanzado el límite de gráficos permitidos para la sesión';
export const STORY_TEXT_ELEMENTS_LIMIT_REACHED_MESSAGE =
  'Ha alcanzado el límite de textos permitidos para la sesión';
export const INDICATORS_LIMIT_WARNING_TITLE =
  'La visualización de los datos no es la mejor';
export const INDICATORS_LIMIT_WARNING_MESSAGE =
  'Dada la cantidad de indicadores seleccionados la visualización puede que no se genere de forma correcta. Le recomendamos selecionar menos indicadores. Pruebe creando varios gráficos.';
export const DOWNLOAD_CONFIRMATION_TITLE = 'Esto puede tardar unos minutos';
export const DOWNLOAD_CONFIRMATION_MESSAGE =
  '¿Desea realizar la descarga de todas formas? ';
export const modes = {
  EDIT: 'edit',
  READONLY: 'readonly',
  SHARE: 'share',
  PREVIEW: 'preview',
};
export const legendOptions = {
  NONE: 'none',
  UP: 'up',
  DOWN: 'down',
};
export const chartNestingOptions = {
  INDICATOR: 'indicator',
  REGION: 'region',
};
export const dataSelectionModes = {
  EDIT: 'edit',
  NEW: 'new',
};
export const textModes = {
  READONLY: 'readonly',
  EDIT: 'edit',
};
export const elementTypes = {
  DATA: 'data',
  TEXT: 'text',
};
export const chartTypes = {
  BAR: 'bar',
  LINE: 'line',
};
export const dataElementTypes = {
  CHART: 'chart',
  TABLE: 'table',
};
export const fileExtensions = {
  PDF: 'pdf',
  SPREADSHEET: 'xlsx',
  PNG: 'png',
};
export const resourceTypes = {
  COLLECTION: COLLECTION_FILTER_ID,
  TOPIC: TOPIC_FILTER_ID,
  METADATA: 'metadata',
  CHART: 'chart',
  DATA: 'data',
};
export const headerModes = {
  COMPLETE: 'complete',
  COLLAPSED: 'collapsed',
};
export const orientationModes = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};
export const DATA_ELEMENT_TITLE_PLACEHOLDER = '[ Título ]';
export const DATA_ELEMENT_SUBTITLE_PLACEHOLDER = '[ Subtítulo ]';
export const COOL_PALETTE_1_ID = 'cool1';
export const COOL_PALETTE_2_ID = 'cool2';
export const WARM_PALETTE_1_ID = 'warm1';
export const WARM_PALETTE_2_ID = 'warm2';
export const REGULAR_PALETTE_ID = 'regular';
export const USER_STORIES_KEY = 'user_stories';
export const STORY_ELEMENTS_KEY = 'story_elements';
export const ELEMENT_TYPE_KEY = 'type';
export const INDICATORS_KEY = 'indicators';
export const COLLECTION_KEY = COLLECTION_FILTER_ID;
export const REGIONS_KEY = `${REGION_FILTER_ID}s`;
export const YEARS_KEY = `${YEAR_FILTER_ID}s`;
export const TITLE_TEXT_KEY = 'title_text';
export const SUBTITLE_TEXT_KEY = 'subtitle_text';
export const CHART_TYPES_KEY = 'chart_types_options';
export const CHART_COLORS_KEY = 'chart_colors_option';
export const CHART_GUIDES_KEY = 'chart_guides_option';
export const CHART_LEGEND_KEY = 'chart_legend_option';
export const CONTENT_TEXT_KEY = 'content_text';
export const TEXT_MODE_KEY = 'text_mode';
export const DEFAULT_CHART_TYPE = chartTypes.BAR;
export const DEFAULT_TEXT_ELEMENT = {
  [ELEMENT_TYPE_KEY]: elementTypes.TEXT,
  [CONTENT_TEXT_KEY]: null,
  [TEXT_MODE_KEY]: textModes.READONLY,
};
export const DEFAULT_DATA_ELEMENT = {
  [ELEMENT_TYPE_KEY]: elementTypes.DATA,
  [TITLE_TEXT_KEY]: '',
  [SUBTITLE_TEXT_KEY]: '',
  [CHART_LEGEND_KEY]: legendOptions.DOWN,
  [CHART_COLORS_KEY]: COOL_PALETTE_1_ID,
  [CHART_GUIDES_KEY]: false,
  [INDICATORS_KEY]: [],
  [REGIONS_KEY]: [],
  [YEARS_KEY]: [],
};
const defaultSession = {
  user_stories: {
    0: {
      story_elements: {},
      key: '0',
    },
  },
};
export const USE_MOCK_STORY = false;
export const ORGANIZATION_NAME = 'Programa Estado Nación';
export const MODULE_NAME = 'Estadísticas';
export const MODULE_FULL_NAME = `${MODULE_NAME} - ${ORGANIZATION_NAME}`;
export const PATHS_HIDE_HEADER = {
  [`/${VISUALIZER_SHARE_MODE_PATH}`]: true,
  [`/${EXPORT_PATH}`]: true,
  [`/${EXPORT_STORY_PATH}`]: true,
};
export const PATHS_HIDE_FOOTER = {
  [`/${VISUALIZER_SHARE_MODE_PATH}`]: true,
  [`/${EXPORT_PATH}`]: true,
  [`/${EXPORT_STORY_PATH}`]: true,
};
export const LOADING_SPINNER_COLOR = '#27AAE1';
export const TERMS_CONDITIONS_DOCUMENT_PATH =
  '/doc/Terminos_condiciones.pdf';
export const TOOL_LABEL = 'Herramienta';
