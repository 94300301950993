import React, { useState, useEffect, useRef, useContext, memo } from 'react';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';
import PENDataService from '../../shared/PENDataService.js';
import axios from 'axios';
import {
  INDICATOR_DETAILS_OPTION_OPENED,
  SELECTION_SET,
} from '../../actions/types';
import store from '../../store';
import { DataSelectionContext } from './DataSelectionContext';
import GenericMessage from '../../shared/GenericMessage';
import Modal from 'react-bootstrap/Modal';
import {
  INDICATORS_LIMIT_WARNING_TITLE,
  INDICATORS_LIMIT_WARNING_MESSAGE,
  COLLECTION_FILTER_ID,
  REGION_FILTER_ID,
  TOPIC_FILTER_ID,
  YEAR_FILTER_ID,
} from '../../shared/constants';
import { trackPromise } from 'react-promise-tracker';
const { v4: uuidv4 } = require('uuid');

/* INDICATORS PAGE COMPONENT */
const IndicatorsPage = memo(
  ({
    pageInd,
    numIndicators,
    collectionFilterSelectedValues,
    topicFilterSelectedValues,
    regionFilterSelectedValues,
    yearFilterSelectedValues,
    selectedValues,
    searchString,
  }) => {
    // console.log('[IndicatorsPage] is running', pageInd);

    // Acceso a la selección de datos
    const context = useContext(DataSelectionContext);

    /* Acceso al objeto de la tabla */
    let tableRef = useRef(null);

    /* Indicadores de la tabla */
    const [indicators, setIndicators] = useState([]);

    const sendActionSelectionSet = () => {
      store.dispatch({
        type: SELECTION_SET,
        payload: context.selection.getSimpleFormatObject(),
      });
    };

    /* MESSAGE MODAL */
    const [messageTexts, setMessageTexts] = useState(null);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const handleCloseMessageModal = () => {
      setShowMessageModal(false);
      setMessageTexts(null);
    };
    const handleShowMessageModal = () => setShowMessageModal(true);

    const getMessageModal = () => {
      return (
        <Modal
          className="modal-indicator-details alert-modal"
          show={!!messageTexts && showMessageModal}
          onHide={handleCloseMessageModal}
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <GenericMessage
              title={messageTexts[0]}
              message={messageTexts[1]}
              onOKClicked={handleCloseMessageModal}
            />
          </Modal.Body>
        </Modal>
      );
    };

    /**
     * Envía a la información compartida la acción para desplegar los detalles del indicador
     */
    const onIndicatorDetailsClicked = (e, cell) => {
      //console.log('Indicador info was clicked !!!');
      const row = cell.getRow();
      const rowData = row.getData();
      const id = rowData.id;
      //console.log('id', id);
      store.dispatch({
        type: INDICATOR_DETAILS_OPTION_OPENED,
        payload: { id },
      });
    };

    /**
     * Devuelve el ícono de información para mostrar en la columna de acceso a los metadatos
     */
    const infoIcon = (cell, formatterParams) => {
      //plain text value
      return `<i class="pen-info"></i>`;
    };

    /**
     * Envía a la información compartida la acción de seleccionar un indicador
     * @param {number} indicatorId
     */
    const sendActionIndicatorSelected = (indicatorId) => {
      context.selectIndicator(indicatorId);
      sendActionSelectionSet();
    };

    /**
     * Envía a la información compartida la acción de deseleccionar un indicador
     * @param {number} indicatorId
     */
    const sendActionIndicatorDeselected = (indicatorId) => {
      context.deselectIndicator(indicatorId);
      sendActionSelectionSet();
    };

    /**
     * Handler para la selección de un indicador
     */
    const onIndicatorRowSelected = (row) => {
      //row - row component for the selected row
      //console.log('Row was selected !!!');
      //console.log('row', row);
      const rowData = row.getData();
      const indicatorId = rowData.id;

      if (!context.canSelectIndicator()) {
        // console.log("CAN'T ADD INDICATOR");
        // Si no es posible agregar el indicador a la lista de indicadores seleccionados
        // se debe revertir la selección y terminar la acción
        row.toggleSelect(); //toggle row selected state
        setMessageTexts([
          <h2>{`${INDICATORS_LIMIT_WARNING_TITLE}`}</h2>,
          <p>{`${INDICATORS_LIMIT_WARNING_MESSAGE}`}</p>,
        ]);
        handleShowMessageModal();
        return;
      }

      sendActionIndicatorSelected(indicatorId);
    };

    /**
     * Handler para la deselección de un indicador
     */
    const onIndicatorRowDeselected = (row) => {
      //row - row component for the deselected row
      //console.log('Row was deselected !!!');
      //console.log('row', row);
      const rowData = row.getData();
      const indicatorId = rowData.id;

      sendActionIndicatorDeselected(indicatorId);
    };

    /**
     * Handler para la opción selectableCheck de la tabla
     */
    const isRowSelectable = (row) => {
      //console.log('row', row);
      const rowData = row.getData();
      //console.log('rowData', rowData);
      //console.log('enabled', rowData.enabled);
      return rowData.enabled;
    };

    const onIndicatorsValuesLoaded = () => {
      if (!!tableRef && !!tableRef.current) {
        const table = tableRef.current.table;
        if (!!table) {
          const indicatorsSelectedValues = selectedValues;
          table.selectRow(indicatorsSelectedValues);
        }
      }
    };

    /**
     * Efecto para el cambio en los valores seleccionados para los filtros
     * Se debe repetir la consulta de indicadores de la tabla cuando hay un cambio
     * en los valores seleccionados para los filtros
     */
    useEffect(() => {
      let mounted = true;

      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      const searchString = context.getSearchString();
      trackPromise(
        PENDataService.getIndicators(
          pageInd,
          [
            {
              id: COLLECTION_FILTER_ID,
              selectedValues: collectionFilterSelectedValues,
            },
            {
              id: TOPIC_FILTER_ID,
              selectedValues: topicFilterSelectedValues,
            },
            {
              id: REGION_FILTER_ID,
              selectedValues: regionFilterSelectedValues,
            },
            {
              id: YEAR_FILTER_ID,
              selectedValues: yearFilterSelectedValues,
            },
          ],
          searchString,
          source,
        )
          .then((result) => {
            if (mounted) {
              // console.log('result', result);
              const indicators = result;
              setIndicators(indicators);
            }
          })
          .catch((error) => {
            //console.log('error', error);
          }),
      );

      return function cleanup() {
        mounted = false;
        source.cancel('axios request cancelled');
      };
    }, [
      collectionFilterSelectedValues,
      topicFilterSelectedValues,
      regionFilterSelectedValues,
      yearFilterSelectedValues,
      selectedValues,
      searchString,
    ]);

    /* Opciones de la tabla, se pasan al componente de tabulator */
    const options = {
      headerVisible: false, //hide header
      resizableColumns: false,
      rowSelected: onIndicatorRowSelected,
      rowDeselected: onIndicatorRowDeselected,
      selectableCheck: isRowSelectable,
      dataLoaded: onIndicatorsValuesLoaded,
    };

    /* Columnas de la tabla, se pasan al componente de tabulator */
    const columns = [
      {
        formatter: 'rowSelection',
        hozAlign: 'center',
        headerSort: false,
        cellClick:function(e, cell){
           cell.getRow().toggleSelect();
        }
      },
      {
        title: 'Indicador',
        field: 'name',
        headerSort: false,
        width: '80%',
      },
      {
        formatter: infoIcon,
        hozAlign: 'center',
        cellClick: onIndicatorDetailsClicked,
      },
    ];

    return (
      <>
        {!!messageTexts && getMessageModal()}
        <div className="indicators-carousel-page">
          <ReactTabulator
            ref={tableRef}
            data={indicators}
            columns={columns}
            tooltips={false}
            options={options}
            // Permite que con las actualizaciones en la selección de datos
            // siempre se renderice de nuevo la tabla con los valores actualizados
            key={uuidv4()}
          />
        </div>
      </>
    );
  },
);

export default IndicatorsPage;
