import React, { forwardRef } from 'react';
import { Route } from 'react-router-dom';

const VisualizationToolProtectedRoute = forwardRef(
  ({ component: Component, ...rest }, ref) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} ref={ref} />;
        }}
      />
    );
  },
);

export default VisualizationToolProtectedRoute;
