// TODO
// Configurar el componente y moverlo a un archivo aparte

import React, { forwardRef } from 'react';
import GoogleAnalytics from './shared/GoogleAnalytics.js';
/* NOT FOUND COMPONENT */
const NotFound = forwardRef(({}, ref) => {
  React.useEffect(()=>{
    GoogleAnalytics.sendInformation(window.location.pathname + window.location.search);
  });
  return (
    <div className="content not-found-content" ref={ref}>
      <p>404 Not Found</p>
    </div>
  );
});

export default NotFound;
