import axios from 'axios';
import { COLLECTION_FILTER_ID, NUMBER_MASKING_OPTION } from './constants';
import FiltersHelper from './FiltersHelper';

const getFiltersQueryParamsIndicatorsCase = (indicators) => {
  const stringifiedIndicators = JSON.stringify(indicators);
  return {
    indicadores: stringifiedIndicators,
  };
};

const getFiltersQueryParamsOtherFiltersCase = (otherFilters) => {
  const params = {};
  Object.values(otherFilters).forEach((element) => {
    params[element.id] =
      element.id !== COLLECTION_FILTER_ID
        ? JSON.stringify(element.selectedValues)
        : element.selectedValues[0];
  });
  return params;
};

const getFiltersQueryParams = (type, indicators, otherFilters) => {
  let params = null;
  if (type === null) {
    params = getFiltersQueryParamsIndicatorsCase([]);
  } else if (type === 'indicators') {
    params = getFiltersQueryParamsIndicatorsCase(indicators);
  } else if (type === 'otherFilters') {
    params = getFiltersQueryParamsOtherFiltersCase(otherFilters);
  }
  return params;
};

class PENDataService {
  static async findIndicatorsBySearchString(searchString) {
    const response = await axios.get(
      `/api/busqueda_indicadores/${searchString}`,
    );

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const rows = data.rows;
            resolve(rows);
          } else {
            reject(actualResponse.errorDescription);
          }
        } else {
          reject();
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  static async getIndicatorDetailsByIndicatorKey(indicatorKey, source) {
    const response = await axios.get(
      `/api/detalles_indicador/${indicatorKey}`,
      {
        cancelToken: source.token,
      },
    );

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            resolve(data);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  static async getIndicatorCollectionByIndicatorKey(indicatorKey) {
    const response = await axios.get(
      `/api/coleccion_indicador/${indicatorKey}`,
    );

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            resolve(data);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  static async getIndicatorsDataEntries(
    indicators,
    source,
    regions = [],
    years = [],
  ) {
    //console.log(`Los indicadores: ${indicators}, source: ${source}, regiones: ${regions} y años: ${years}`);
    const stringifiedIndicators = JSON.stringify(indicators);
    const encodedIndicators = encodeURIComponent(stringifiedIndicators);

    const stringifiedRegions = JSON.stringify(regions);
    const encodedRegions = encodeURIComponent(stringifiedRegions);

    const stringifiedYears = JSON.stringify(years);
    const encodedYears = encodeURIComponent(stringifiedYears);

    const params = {
      indicators: encodedIndicators,
      regions: encodedRegions,
      years: encodedYears,
    };

    const response = await axios.get('/api/datos_indicadores', {
      params,
      cancelToken: source.token,
    });

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const indicators = data.indicators;
            const rows = data.rows;
            resolve({
              indicators,
              rows,
            });
          } else {
            reject(actualResponse.errorDescription);
          }
        } else {
          reject();
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  static async getIndicatorsSourcesByIndicatorsKeys(indicatorsKeys, source) {
    const stringifiedIndicatorsKeys = JSON.stringify(indicatorsKeys);
    const encodedIndicatorsKeys = encodeURIComponent(stringifiedIndicatorsKeys);
    const response = await axios.get(
      `/api/fuentes_indicadores/${encodedIndicatorsKeys}`,
      { cancelToken: source.token },
    );

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const rows = data.rows;
            resolve(rows);
          } else {
            reject(actualResponse.errorDescription);
          }
        } else {
          reject();
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  static async getIndicatorsNamesByIndicatorsKeys(indicators, source) {
    const stringifiedIndicators = JSON.stringify(indicators);
    const encodedIndicators = encodeURIComponent(stringifiedIndicators);

    const params = {
      indicators: encodedIndicators,
    };

    const response = await axios.get('/api/nombres_indicadores', {
      params,
      cancelToken: source.token,
    });

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const rows = data.rows;
            resolve(rows);
          } else {
            reject(actualResponse.errorDescription);
          }
        } else {
          reject();
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  static async getNumIndicators(searchString, source) {
    const params = {
      search: searchString,
    };

    const response = await axios.get('/api/num_indicadores', {
      params,
      cancelToken: source.token,
    });

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const numIndicators = data.count;
            resolve(numIndicators);
          } else {
            reject(actualResponse.errorDescription);
          }
        } else {
          reject();
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  static async getIndicators(pageInd, filters, searchString, source) {
    const filtersParams = FiltersHelper.getFiltersParams(filters);
    // console.log('filtersParams', filtersParams);
    const params = {
      page: pageInd,
      search: searchString,
      ...filtersParams,
    };

    const response = await axios.get('/api/indicadores', {
      params,
      cancelToken: source.token,
    });

    return new Promise((resolve, reject) => {
      try {
        // console.log('response', response);
        const actualResponse = response.data;
        if (!!actualResponse && 'indicators' in actualResponse) {
          const indicators = actualResponse.indicators;
          resolve(indicators);
        } else {
          reject();
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  static async getPageIndicator(indicatorID, filters, source) {
    // console.log('filtersParams', filtersParams);
    const filtersParams = FiltersHelper.getFiltersParams(filters);
    const params = {
      indicatorID: indicatorID,
      ...filtersParams,
    };

    const response = await axios.get('/api/indicadores/page', {
      params,
      cancelToken: source.token,
    });

    return new Promise((resolve, reject) => {
      try {
        // console.log('response', response);
        const actualResponse = response.data;
        if (!!actualResponse) {
          resolve(actualResponse);
        } else {
          reject();
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  static async getFilterValues(
    filterId,
    type = null,
    selectedIndicators = [],
    otherFilters = null,
  ) {
    //console.log('selectedIndicators', selectedIndicators);

    let query;
    switch (filterId) {
      case 'collection':
        query = 'colecciones';
        break;
      case 'topic':
        query = 'temas';
        break;
      case 'region':
        query = 'regiones';
        break;
      case 'year':
        query = 'annos';
        break;
      default:
        query = null;
        break;
    }

    if (query === null) {
      return new Promise((resolve, reject) => reject());
    }

    query = `/api/${query}`;

    const params = getFiltersQueryParams(
      type,
      selectedIndicators,
      otherFilters,
    );

    const response = await axios.get(query, { params });

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const rows = data.rows;
            resolve(rows);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  static async getCollectionValues(source, includeTopics = false) {
    const query = includeTopics ? 'colecciones_temas' : 'colecciones';
    const response = await axios.get(`/api/${query}`, {
      cancelToken: source.token,
    });

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const rows = data.rows;
            resolve(rows);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  static async getFilterValuesComplete(filterId, indicators, filters, source) {
    const stringifiedIndicators = JSON.stringify(indicators);
    const encodedIndicators = encodeURIComponent(stringifiedIndicators);

    const otherFiltersParams = FiltersHelper.getOtherFiltersParams(
      filterId,
      filters,
    );

    const requestPath =
      FiltersHelper.getFilterValuesRequestPathString(filterId);

    const params = {
      indicadores: encodedIndicators,
      ...otherFiltersParams,
    };

    const response = await axios.get(`/api/${requestPath}`, {
      params,
      cancelToken: source.token,
    });

    return new Promise((resolve, reject) => {
      try {
        const actualResponse = response.data;
        if (!!actualResponse && 'result' in actualResponse) {
          if (actualResponse.result) {
            const data = actualResponse.data;
            const rows = data.rows;
            resolve(rows);
          } else {
            reject();
          }
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  /* COLLECTION FILES */
  static async downloadCollectionFile(collectionKey, topics, source) {
    const stringifiedTopics = JSON.stringify(topics);
    const encodedTopics = encodeURIComponent(stringifiedTopics);

    const params = {
      topics: encodedTopics,
    };

    const response = await axios({
      url: `/api/coleccion_descarga/${collectionKey}`,
      params,
      cancelToken: source.token,
      method: 'GET',
      responseType: 'blob',
    });

    return new Promise((resolve, reject) => {
      try {
        if (!!response) {
          resolve(response);
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }
  static async downloadCollectionFileForExport(collectionKey, topics, source) {
    const stringifiedTopics = JSON.stringify(topics);
    const encodedTopics = encodeURIComponent(stringifiedTopics);

    const params = {
      topics: encodedTopics,
    };

    const response = await axios({
      url: `/api/coleccion_descarga_exportar/${collectionKey}`,
      params,
      cancelToken: source.token,
      method: 'GET',
      responseType: 'blob',
    });

    return new Promise((resolve, reject) => {
      try {
        if (!!response) {
          resolve(response);
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  /* INDICATORS FILES */
  static async downloadIndicatorsDataFile(
    indicators,
    source,
    regions = [],
    years = [],
  ) {
    const stringifiedIndicators = JSON.stringify(indicators);
    const encodedIndicators = encodeURIComponent(stringifiedIndicators);

    const stringifiedRegions = JSON.stringify(regions);
    const encodedRegions = encodeURIComponent(stringifiedRegions);

    const stringifiedYears = JSON.stringify(years);
    const encodedYears = encodeURIComponent(stringifiedYears);

    const params = {
      indicators: encodedIndicators,
      regions: encodedRegions,
      years: encodedYears,
    };

    const response = await axios({
      url: `/api/datos_indicadores_descarga`,
      params,
      cancelToken: source.token,
      method: 'GET',
      responseType: 'blob',
    });

    return new Promise((resolve, reject) => {
      try {
        if (!!response) {
          resolve(response);
        } else {
          reject();
        }
      } catch (error) {
        //console.log('error', error);
        reject();
      }
    });
  }

  static async downloadIndicatorsMetadataFile(indicators, source) {
    const stringifiedIndicators = JSON.stringify(indicators);
    const encodedIndicators = encodeURIComponent(stringifiedIndicators);

    const params = {
      indicators: encodedIndicators,
    };

    const response = await axios({
      url: `/api/metadatos_indicadores_descarga`,
      params,
      cancelToken: source.token,
      method: 'GET',
      responseType: 'blob',
    });

    return new Promise((resolve, reject) => {
      try {
        if (!!response) {
          resolve(response);
        } else {
          reject();
        }
      } catch (error) {
        console.log('error', error);
        reject();
      }
    });
  }

  /* HELPERS */
  static getMaskedValue(value) {
    return value.toLocaleString(NUMBER_MASKING_OPTION);
  }

  static getFilteredIndicatorsObject(indicatorsArray) {
    const indicatorsObject = {};
    indicatorsArray.forEach((element) => {
      indicatorsObject[element] = true;
    });
    return indicatorsObject;
  }

  static getIndicatorsArray(indicatorsObject) {
    return Object.keys(indicatorsObject).map((indicatorIdString) =>
      parseInt(indicatorIdString),
    );
  }
}

export default PENDataService;
