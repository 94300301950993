import React, { createRef, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import PENDataService from '../../shared/PENDataService';
import axios from 'axios';

function ChartTypeEditor(props) {
  const [chartTypes, setChartTypes] = useState(null);
  const [guidesOption, setGuidesOption] = useState(null);
  const [indicators, setIndicators] = useState(null);

  let inputGuides = createRef();

  const getIndicatorsNamesPromise = (indicators, source) => {
    return PENDataService.getIndicatorsNamesByIndicatorsKeys(
      indicators,
      source,
    );
  };

  useEffect(() => {
    let mounted = true;

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const element = props.dataElementConfiguration;

    if (!!element) {
      //console.log('element', element);
      setChartTypes(element.chart_types);
      setGuidesOption(element.chart_guides_option);

      getIndicatorsNamesPromise(Object.keys(element.chart_types), source)
        .then((result) => {
          if (mounted) {
            // console.log('result', result);
            setIndicators(
              result.reduce((indicatorsAccum, currIndicator) => {
                indicatorsAccum[currIndicator.id] = currIndicator.name;
                return indicatorsAccum;
              }, {}),
            );
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }

    return function cleanup() {
      mounted = false;
      source.cancel('axios request cancelled');
    };
  }, [props]);

  useEffect(() => {
    const element = props.dataElementConfiguration;

    if (!!element) {
      //console.log('element', element);
      setChartTypes(element.chart_types);
      setGuidesOption(element.chart_guides_option);
    }
  }, [props]);

  const getChartEditorHeader = () => {
    return (
      <p>
        <span>
          Tipos de <b>Gráficos</b>
        </span>
      </p>
    );
  };

  const handleTypeChange = (event) => {
    const selectedChartType = event.target.id;
    const indicator = event.target.name.replace('-typeRadios', '');
    console.log('event.target', event.target);
    console.log('indicator', indicator);
    const selectedChartTypesCopy = {
      ...chartTypes,
      [indicator]: selectedChartType,
    };
    setChartTypes(selectedChartTypesCopy);
    props.dataElementConfiguration.chart_types = selectedChartTypesCopy;
  };

  const handleGuidesOptionChange = (event) => {
    setGuidesOption(!guidesOption);
    props.dataElementConfiguration.chart_guides_option = !guidesOption;
  };

  const getChartTypesSection = () => {
    const mappedElements = Object.keys(chartTypes).map((indicator, index) => {
      return (
        <Form.Group as={Row} controlId={`${indicator}-type`}>
          <Col>
            <Form.Label style={{ display: 'flex', justifyContent: 'center' }}>
              {!!indicators && indicator in indicators
                ? indicators[indicator]
                : indicator}
            </Form.Label>

            <Form.Check
              type="radio"
              label="Lineal"
              name={`${indicator}-typeRadios`}
              indicator={indicator}
              id="line"
              className="chart-line-ico"
              checked={!!chartTypes && chartTypes[indicator] === 'line'}
              onChange={handleTypeChange}
            />
            <Form.Check
              type="radio"
              label="Barras"
              name={`${indicator}-typeRadios`}
              indicator={indicator}
              id="bar"
              className="chart-bar-ico"
              checked={!!chartTypes && chartTypes[indicator] === 'bar'}
              onChange={handleTypeChange}
            />
          </Col>
        </Form.Group>
      );
    });

    return mappedElements;
  };

  return (
    <Row className="chart-editor-container filter-container">
      <Col>
        <Row className="chart-editor-header">{getChartEditorHeader()}</Row>
        <Row className="chart-editor-options">
          <Col>
            {!!chartTypes && getChartTypesSection()}
            <hr />
            <Form.Group as={Row} controlId="guides">
              <Col>
                <Form.Check
                  ref={inputGuides}
                  type="checkbox"
                  label="Activar Guías"
                  id="guidesactive"
                  className="special-checkbox"
                  checked={guidesOption !== null && guidesOption}
                  onChange={handleGuidesOptionChange}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ChartTypeEditor;
