import React, { useContext, useState, useEffect, forwardRef } from 'react';
import Container from 'react-bootstrap/esm/Container';
import DataElement from './DataElement';
import TextElement from './TextElement';
import EmptyElement from './EmptyElement';
import { modes, elementTypes } from '../../shared/constants';
import { StoryContext } from './StoryContext';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import GenericMessage from '../../shared/GenericMessage';

const VisualizationToolContent = forwardRef(({ visualizerMode }, ref) => {
  //console.log('props en VisualizationToolContent', props);

  /* Acceso a la historia del visualizador */
  const context = useContext(StoryContext);

  //console.log();
  //console.log('Soy un ref', ref);
  /* HELPERS */
  const getElementsArray = () => {
    return context.session.getSessionStory().getElementsArray();
  };
  /*Modal de informacion de edicion de titulo y subtitulo*/
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    sessionStorage.setItem('notAlreadyDisplayed', false);
  };
  const thereIsGraph = (elements) =>
    !!elements.find((obj) => obj.type === elementTypes.DATA);
  const modalAlreadyDisplayed = () =>
    !!!sessionStorage.getItem('notAlreadyDisplayed');

  useEffect(() => {
    if (!!context.session) {
      const elements = getElementsArray();
      if (thereIsGraph(elements) && modalAlreadyDisplayed()) {
        setShow(true);
      }
    }
  }, [context.session]);

  /* MAIN */
  const getMainContent = () => {
    if (context.session === null) {
      return null;
    }

    const mappedElements = getElementsArray().map((element, index) => {
      //console.log('Estoy visualization toool content', element);
      if (element.type === elementTypes.DATA) {
        return (
          // <>
          //   <Row>
          //     <h1>{element.key}</h1>
          //   </Row>
          <DataElement
            key={`${btoa(index)}-${element.version}`}
            id={element.key}
            visualizerMode={visualizerMode}
            ref={ref}
          />
          // </>
        );
      } else if (element.type === elementTypes.TEXT) {
        return (
          // <>
          //   <Row>
          //     <h1>{element.key}</h1>
          //   </Row>
          <TextElement
            key={btoa(index)}
            id={element.key}
            visualizerMode={visualizerMode}
          />
          // </>
        );
      } else {
        return null;
      }
    });

    return mappedElements;
  };

  const showModal = () => {
    //console.log(!!!sessionStorage.getItem('notAlreadyDisplayed'));
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <GenericMessage
            message="Puede editar el título y subtítulo en el botón de 'editar gráfico'."
            onOKClicked={handleClose}
          />
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <Container className="visualizer-content" fluid>
        {/*
         * Se puede crear el contenido principal una vez que se hayan establecido
         * los elementos de la historia
         */}
        {getMainContent()}
        {showModal()}
        {visualizerMode === modes.EDIT && <EmptyElement key="0"></EmptyElement>}
      </Container>
      {/* <div id="data-export-container" ref={ref}>
        {getExportChart()}
      </div> */}
      <div id="data-tooltips-container" />
    </>
  );
});

export default VisualizationToolContent;
