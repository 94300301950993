import {
  INDICATOR_DETAILS_OPTION_OPENED,
  INDICATOR_DETAILS_OPTION_CLOSED,
  COOKIE_SET,
  SESSION_SET,
  ADMIN_SESSION_SET,
  ERROR_MESSAGE_OPENED,
  ERROR_MESSAGE_CLOSED,
  SELECTION_SET,
  HEADER_MODE_SET,
  // EXPORT_DATA_SET,
  // EXPORT_DATA_CLEARED,
  // RENDER_EXPORT_CHART,
  // HIDE_EXPORT_CHART,
} from './actions/types';

const initialState = {
  adminSession: false,
  indicators: {
    indicatorDetailsId: null,
    showIndicatorDetails: false,
  },
  cookie: null,
  selection: {
    collectionFilterValues: [],
    collectionFilterSelectedValues: [],

    topicFilterValues: [],
    topicFilterSelectedValues: [],

    regionFilterValues: [],
    regionFilterSelectedValues: [],

    yearFilterValues: [],
    yearFilterSelectedValues: [],

    indicatorsSelectedValues: [],
    numIndicators: 0,
    searchString: '',
  },
  exportChart: false,
  // exportData: null,
  headerMode: null,
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case COOKIE_SET: {
      return {
        // Copy the whole state
        ...state,
        // Overwrite the cookie value
        cookie: action.payload,
      };
    }
    case SESSION_SET: {
      return {
        // Copy the whole state
        ...state,
        // Overwrite the session value
        session: action.payload,
      };
    }
    case ADMIN_SESSION_SET: {
      return {
        // Copy the whole state
        ...state,
        // Overwrite the adminSession value
        adminSession: true,
      };
    }
    case INDICATOR_DETAILS_OPTION_OPENED: {
      const indicatorId = action.payload.id;
      return {
        ...state,
        indicators: {
          ...state.indicators,
          showIndicatorDetails: true,
          indicatorDetailsId: indicatorId,
        },
      };
    }
    case INDICATOR_DETAILS_OPTION_CLOSED: {
      return {
        ...state,
        indicators: {
          ...state.indicators,
          showIndicatorDetails: false,
          indicatorDetailsId: null,
        },
      };
    }
    case ERROR_MESSAGE_OPENED: {
      return {
        ...state,
        showErrorMessage: true,
      };
    }
    case ERROR_MESSAGE_CLOSED: {
      return {
        ...state,
        showErrorMessage: false,
      };
    }
    case SELECTION_SET: {
      return {
        ...state,
        selection: action.payload,
      };
    }
    // case RENDER_EXPORT_CHART: {
    //   return {
    //     ...state,
    //     exportChart: action.payload.exportChart,
    //     // exportChartType: action.payload.exportChartType,
    //   };
    // }
    // case HIDE_EXPORT_CHART: {
    //   return {
    //     ...state,
    //     exportChart: false,
    //     // exportChartType: null,
    //   };
    // }
    // case EXPORT_DATA_SET: {
    //   return {
    //     ...state,
    //     exportData: action.payload,
    //   };
    // }
    // case EXPORT_DATA_CLEARED: {
    //   return {
    //     ...state,
    //     exportData: null,
    //   };
    // }
    case HEADER_MODE_SET: {
      return {
        ...state,
        headerMode: action.payload,
      };
    }
    default:
      return state;
  }
}
