import React, { createRef, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

function ChartTextsEditorContent(props) {
  const [initialTitleTextValue, setInitialTitleTextValue] = useState(null);
  const [initialSubtitleTextValue, setInitialSubtitleTextValue] = useState(
    null,
  );

  useEffect(() => {
    const element = props.dataElementConfiguration;

    if (!!element) {
      setInitialTitleTextValue(element.title_text);
      setInitialSubtitleTextValue(element.subtitle_text);
    }
  }, [props]);

  let inputTitleText = createRef();
  let inputSubtitleText = createRef();

  const handleTextChange = (event) => {
    //console.log(event);
    //console.log(inputText.current.value);

    if (!!event.target.id) {
      const elementKey = event.target.id;
      let fieldId;
      let textValue;
      switch (elementKey) {
        case 'titleText':
          fieldId = 'title_text';
          textValue = inputTitleText.current.value;
          // props.dataElementConfiguration.title_text =
          //   inputTitleText.current.value;
          break;
        case 'subtitleText':
          fieldId = 'subtitle_text';
          textValue = inputSubtitleText.current.value;
          // props.dataElementConfiguration.subtitle_text =
          //   inputSubtitleText.current.value;
          break;
        default:
          return;
      }

      if (typeof textValue === 'undefined' || textValue === null) {
        textValue = '';
      }

      props.dataElementConfiguration[fieldId] = textValue;

      //console.log('textValue', textValue);
      //console.log(
      //   'props.dataElementConfiguration',
      //   props.dataElementConfiguration,
      // );
    }
  };

  return (
    <Col>
      <Row>
        <p>Título del gráfico:</p>
      </Row>
      <Row>
        <Form.Control
          id="titleText"
          type="text"
          placeholder="Escriba aquí..."
          defaultValue={initialTitleTextValue}
          onInput={handleTextChange}
          ref={inputTitleText}
        />
      </Row>
      <br />
      <Row>
        <p>Subtítulo del gráfico:</p>
      </Row>
      <Row>
        <Form.Control
          id="subtitleText"
          type="text"
          placeholder="Escriba aquí..."
          defaultValue={initialSubtitleTextValue}
          onInput={handleTextChange}
          ref={inputSubtitleText}
        />
      </Row>
    </Col>
  );
}

export default ChartTextsEditorContent;
