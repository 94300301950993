import React, { useState, useEffect, forwardRef } from 'react';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import ChartEditor from './ChartEditor';
import Button from 'react-bootstrap/Button';
import UserSessionDataService from '../../shared/UserSessionDataService';
import { useSelector } from 'react-redux';
import { VISUALIZER_EDIT_MODE_PATH,  KEY_GOOGLE_ANA } from '../../shared/constants';
import store from '../../store';
import { SESSION_SET, ERROR_MESSAGE_CLOSED } from '../../actions/types';
import UserSession from '../../models/UserSession';
import { useHistory } from 'react-router-dom';
import GoogleAnalytics from '../../shared/GoogleAnalytics.js';
const DataElementEditor = forwardRef((props, ref) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
    GoogleAnalytics.sendInformation(window.location.pathname + window.location.search);
  });

  let history = useHistory();

  const elementKey = props.location.state.elementKey;

  const selectSession = (state) => state.session;
  const session = useSelector(selectSession);
  const selectCookie = (state) => state.cookie;
  const cookie = useSelector(selectCookie);

  const [dataElementConfiguration, setDataElementConfiguration] = useState(
    null,
  );

  useEffect(() => {
    console.log("------------------- estoy aca")
    if (!!session) {
      const parsedSession = new UserSession(session);
      if (!UserSessionDataService.sessionHasValidStory(parsedSession)) {
        //console.log('Setting defaults');
        return;
      }

      const story = UserSessionDataService.getSessionStory(parsedSession);
      //console.log('story', story);

      if (
        !UserSessionDataService.storyHasValidElementByKey(story, elementKey)
      ) {
        //console.log('Setting defaults');
        return;
      }

      const element = UserSessionDataService.getStoryElementByKey(
        story,
        elementKey,
      );
      //console.log('element', element);

      // TODO
      // Deshabilitar botón de aplicar cambios hasta que se haya
      // guardado la configuración que le corresponde al elemento
      // de datos, para que no se guarde incorrectamente la configuración
      // que se carga inicialmente por defecto en el componente

      setDataElementConfiguration({
        title_text: element.titleText,
        subtitle_text: element.subtitleText,
        chart_types: element.chartConfiguration.types,
        chart_legend_option: element.chartConfiguration.legend,
        chart_colors_option: element.chartConfiguration.colors,
        chart_guides_option: element.chartConfiguration.guides,
      });
    }
  }, [session, elementKey]);

  const onRestoreDefaultsClicked = () => {
    //console.log('restablecer predeterminado !!!');
  };

  const onApplyChangesClicked = () => {
    // console.log('dataElementConfiguration', dataElementConfiguration);
    // console.log('Aplicar Cambios !!!');

    const parsedSession = new UserSession(session);

    UserSessionDataService.updateStoryDataElementConfiguration(
      cookie,
      parsedSession,
      elementKey,
      dataElementConfiguration,
    )
      .then((result) => {
        console.log('result', result);
        // Se obtuvieron los datos de la sesión actualizada
        // Se guardan en redux store
        store.dispatch({
          type: SESSION_SET,
          payload: result[1],
        });
        // window.location = `/${VISUALIZER_EDIT_MODE_PATH}`;

        history.push({
          pathname: `/${VISUALIZER_EDIT_MODE_PATH}`,
          state: {
            elementKey: result[0],
          },
        });
      })
      .catch(() => {
        //console.log('error');
      });
  };

  return (
    <div className="content" ref={ref}>
      <Row className="mb-4">
        <Link to={`/${VISUALIZER_EDIT_MODE_PATH}`} className="volver-link">
          Volver
        </Link>
      </Row>
      <Row className="row-info-container">
        {!!dataElementConfiguration && (
          <ChartEditor dataElementConfiguration={dataElementConfiguration} />
        )}
      </Row>
      <Row className="data-selector-action-bar">
        <Button variant="link-like" onClick={onRestoreDefaultsClicked}>
          restablecer predeterminado
        </Button>
        <Button variant="secondary" onClick={onApplyChangesClicked}>
          Aplicar Cambios
        </Button>
      </Row>
    </div>
  );
});

export default DataElementEditor;
