import { Link } from 'react-router-dom';
import {
  VISUALIZER_READONLY_MODE_PATH,
  COLLECTION_FILTER_SHORT_LABEL,
} from '../../shared/constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

function PENStory(props) {
  const id = props.id;
  const name = props.name;
  const title = props.title;
  const description = props.description;
  const extension = props.extension;
  const rootUrl = props.rootUrl;
  const setLink = props.setLink;
  const showModal = props.showShareModal;

  const handleShareClick = () => {
    props.setLink(rootUrl + `${VISUALIZER_READONLY_MODE_PATH}/${id}`)
    props.showShareModal(true);
  }
  return (
    <Col xs={12} sm={6} md={4} className="mb-4">
      <div className="pen-story-container">
        <Row>
          <Col xs={10}>
            <span className="story-categoria">
              [{COLLECTION_FILTER_SHORT_LABEL}]
            </span>
          </Col>
          <Col xs={2}>
            <a onClick={handleShareClick}  className="points text-hide">
              <span>Share</span>
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <Image
              className="story-img"
              src={`/static/assets/images/stories/${id}.${extension}`}
              fluid
              style={{ width: '100%', height: '150px' }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{props.title}</h3>
          </Col>
          {/* El año se eliminó de esta parte */}
          {/* <Col sm={4}><h3 className="story-fecha">2018</h3></Col> */}
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{props.description}</p>
          </Col>
        </Row>
        <Row className="bottom-row">
          <Col>
              <Link
                to={`/${VISUALIZER_READONLY_MODE_PATH}/${id}`}
                className="link-on-story"
              >
                Ver más
              </Link>
          </Col>
        </Row>
      </div>
    </Col>
  );
}
export default PENStory;
