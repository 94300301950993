import React, { memo, useRef, useEffect } from 'react';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';
import ChartHelper from '../../shared/ChartHelper';
import { chartNestingOptions } from '../../shared/constants';

/* TABLE COMPONENT */
const Table = memo(
  ({
    id,
    data,
    columns,
    chartColorsOption,
    nestingKeys,
    chartNestingLevel,
  }) => {
    // console.log('[Table] is running', id);

    // console.log('chartColorsOption', chartColorsOption);
    // console.log('nestingKeys', nestingKeys);

    /* Acceso al objeto de la tabla */
    let tableRef = useRef(null);

    /**
     * Efecto para forzar un rerender después del primero,
     * ya que en este caso despliega las celdas con altura incorrecta
     */
    useEffect(() => {
      setTimeout(() => {
        if (!!tableRef && !!tableRef.current) {
          const table = tableRef.current.table;
          if (!!table) {
            table.redraw(true);
          }
        }
      }, 250);
    }, []);

    const onRendered = () => {
      if (!!tableRef && !!tableRef.current) {
        const table = tableRef.current.table;
        if (!!table) {
          // Se asigna el color del borde izquierdo de acuerdo con el elemento de la serie
          const colorsArray = ChartHelper.getChartColors(chartColorsOption);
          const color = ChartHelper.getColorFunction(nestingKeys, colorsArray);
          const rows = table.getRows();
          let rowElement;
          let nestingKey;
          let borderColor;
          rows.forEach((row) => {
            rowElement = row.getElement();
            nestingKey =
              chartNestingLevel === chartNestingOptions.INDICATOR
                ? row.getData().id
                : row.getData().region;
            borderColor = color(nestingKey);
            rowElement.firstChild.style.borderLeft = `solid 0.5rem ${borderColor}`;
          });
        }
      }
    };

    const options = {
      resizableColumns: false,
      renderComplete: onRendered,
    };

    return (
      <ReactTabulator
        ref={tableRef}
        data={data}
        columns={columns}
        tooltips={false}
        options={options}
      />
    );
  },
);

export default Table;
