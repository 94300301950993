import UserStory from './UserStory';
import { USER_STORIES_KEY } from '../shared/constants';

const getStories = (dbSessionObject) => {
  // console.log('dbSessionObject', dbSessionObject);

  let stories = {};

  if (!(USER_STORIES_KEY in dbSessionObject)) {
    return stories;
  }

  const dbStoriesObject = dbSessionObject[USER_STORIES_KEY];

  if (Object.keys(dbStoriesObject) === 0) {
    return stories;
  }

  // Si no hay historias en la sesión, se devuelve un diccionario vacío
  // De otra manera, se procesan las historias para crear los objetos correspondientes
  // y devolver el diccionario con las historias

  let userStory;
  let userStoryKey;
  stories = Object.values(dbStoriesObject).reduce((accum, curr) => {
    // curr es una historia
    userStory = new UserStory(curr);

    userStoryKey = userStory.key;
    accum[userStoryKey] = userStory;
    return accum;
  }, {});

  return stories;
};

class UserSession {
  constructor(dbSessionObject) {
    // Se maneja como un diccionario donde cada value es una historia de la sesión
    this._stories = getStories(dbSessionObject);
  }

  getStories() {
    return this._stories;
  }

  // Asumiendo que solo puede haber una historia por sesión
  getSessionStory() {
    return this.getSessionStoryByKey(0);
  }

  getSessionStoryByKey(key) {
    if (key in this._stories) {
      return this._stories[key];
    } else {
      return null;
    }
  }

  getCopy() {
    return new UserSession(this.getDbObject());
  }

  getDbObject() {
    let userStoryKey;

    const stories = Object.values(this._stories).reduce((accum, curr) => {
      userStoryKey = curr.key;
      accum[userStoryKey] = curr.getDbObject();
      return accum;
    }, {});

    return {
      [USER_STORIES_KEY]: stories,
    };
  }
}

export default UserSession;
