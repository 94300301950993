import DataAdapter from '../shared/DataAdapter';
import { chartNestingOptions, ERCA_COLLECTION_ID } from '../shared/constants';

class ProcessedDataElement {
  constructor(data, sources, collection, chartTypes, indicatorsNames = null) {
    this._data = data;
    this._sources = DataAdapter.adaptSourcesData(sources);
    this._indicatorsDict = DataAdapter.getIndicatorsDict(data.indicators);
    this._regionAdaptedData = DataAdapter.adaptDataByRegion(data);
    this._indicatorAdaptedData = DataAdapter.adaptDataByIndicator(data);
    // El único caso en que se constuye el gráfico por regiones es cuando
    // la colección corresponde a ERCA
    this._chartNestingLevel =
      collection === ERCA_COLLECTION_ID
        ? chartNestingOptions.REGION
        : chartNestingOptions.INDICATOR;
    this._adaptedData =
      this._chartNestingLevel === chartNestingOptions.REGION
        ? this._regionAdaptedData
        : this._indicatorAdaptedData;
    this._tableSpecification = DataAdapter.adaptTableData(data);
    this._indicatorsNames = indicatorsNames;

    this._chartNestingLevelGroupingKeys = DataAdapter.getChartNestingLevelGroupingKeys(
      this._chartNestingLevel,
      this._adaptedData.nestedData,
    );
    this._maxNumElems = DataAdapter.getMaxNumElems(
      this._adaptedData.nestedData,
    );
    this._yearsNestedData = DataAdapter.getYearsNestedData(
      this._adaptedData.entries,
    );
    this._yearsGroupingKeys = DataAdapter.getYearsGroupingKeys(
      this._yearsNestedData,
    );
    this._linesBarsData = DataAdapter.getLinesAndBarsData(
      this._chartNestingLevel,
      this._adaptedData.nestedData,
      chartTypes,
      this._yearsNestedData,
      data.indicators,
      this._chartNestingLevelGroupingKeys,
    );
  }

  get data() {
    return this._data;
  }
  get sources() {
    return this._sources;
  }
  get indicatorsDict() {
    return this._indicatorsDict;
  }
  get regionNestedData() {
    return this._regionAdaptedData;
  }
  get indicatorNestedData() {
    return this._indicatorNestedData;
  }
  get chartNestingLevel() {
    return this._chartNestingLevel;
  }
  get adaptedData() {
    return this._adaptedData;
  }
  get tableSpecification() {
    return this._tableSpecification;
  }
  get indicatorsNames() {
    return this._indicatorsNames;
  }
  get chartNestingLevelGroupingKeys() {
    return this._chartNestingLevelGroupingKeys;
  }
  get maxNumElems() {
    return this._maxNumElems;
  }
  get yearsNestedData() {
    return this._yearsNestedData;
  }
  get yearsGroupingKeys() {
    return this._yearsGroupingKeys;
  }
  get linesBarsData() {
    return this._linesBarsData;
  }
}

export default ProcessedDataElement;
