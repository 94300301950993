import React, { useState, useEffect, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import PENDataService from './shared/PENDataService.js';
import DataAdapter from './shared/DataAdapter';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { resourceTypes } from './shared/constants';
import SelectionMenu from './components/Data/SelectionMenu';
import GoogleAnalytics from './shared/GoogleAnalytics.js';
/* DATA ELEMENT METADATA COMPONENT */
const DataElementMetadata = forwardRef((props, ref) => {
  useEffect(() => {
    GoogleAnalytics.sendInformation(
      window.location.pathname + window.location.search,
    );
  });

  let history = useHistory();

  const indicatorsIds = props.location.state.indicators;

  const [indicators, setIndicators] = useState(null);
  const [resources, setResources] = useState(null);
  const [selection, setSelection] = useState(null);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [details, setDetails] = useState(null);

  /* DATA REQUESTS */
  const getIndicatorsNamesPromise = (indicators, source) => {
    return PENDataService.getIndicatorsNamesByIndicatorsKeys(
      indicators,
      source,
    );
  };

  const getIndicatorDetailsPromise = (indicatorId, source) => {
    return PENDataService.getIndicatorDetailsByIndicatorKey(
      indicatorId,
      source,
    );
  };

  const getElementSourcesPromise = (indicators, source) => {
    return PENDataService.getIndicatorsSourcesByIndicatorsKeys(
      indicators,
      source,
    );
  };

  /* HELPERS */
  const mapIndicatorSpecificaction = (indicatorSpecification) => {
    const actualIndicatorString = indicatorSpecification.replace(
      `${resourceTypes.METADATA}-indicator-`,
      '',
    );
    return parseInt(actualIndicatorString);
  };

  const getSourcesText = (elementSources) => {
    const enumeratedSourcesString = elementSources.join(', ');
    const finalPoint = '.';
    return `${enumeratedSourcesString}${finalPoint}`;
  };

  const getIndicatorDetail = (detailFieldName) => {
    return detailFieldName in details ? details[detailFieldName] : '';
  };

  const getIndicatorName = () => {
    return getIndicatorDetail('name');
  };
  const getIndicatorDefinition = () => {
    return getIndicatorDetail('description');
  };
  const getIndicatorSource = () => {
    return getIndicatorDetail('source');
  };

  /* HOOKS */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Efecto para la consulta de indicadores
   */
  useEffect(() => {
    let mounted = true;

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    getIndicatorsNamesPromise(indicatorsIds, source)
      .then((result) => {
        if (mounted) {
          // console.log('result', result);
          setIndicators(result);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });

    return function cleanup() {
      mounted = false;
      source.cancel('axios request cancelled');
    };
  }, [indicatorsIds]);

  /**
   * Efectos para manejar los objetos de indicadores y selección
   */
  useEffect(() => {
    if (!!indicators && indicators.length) {
      const resources = {
        [resourceTypes.METADATA]: {
          id: resourceTypes.METADATA,
          fields: [
            {
              id: 'indicator',
              type: 'radio',
              options: indicators.map((indicator) => {
                return {
                  ...indicator,
                  id: `${resourceTypes.METADATA}-indicator-${indicator.id}`,
                  label: indicator.name,
                };
              }),
            },
          ],
        },
      };
      setResources(resources);
    }
  }, [indicators]);

  useEffect(() => {
    if (!!resources) {
      const selection = Object.values(resources).reduce(
        (selectionAccum, currResource) => {
          const fields = currResource.fields.reduce(
            (fieldsAccum, currField) => {
              if (currField.id !== 'empty') {
                let optionsArray = Object.values(currField.options);
                const options = optionsArray.reduce(
                  (optionsAccum, currOption) => {
                    optionsAccum[currOption.id] = {
                      checked: false,
                      label: currOption.label,
                    };
                    return optionsAccum;
                  },
                  {},
                );

                fieldsAccum[currField.id] = options;
              }

              return fieldsAccum;
            },
            {},
          );
          selectionAccum[currResource.id] = {
            fields,
          };
          return selectionAccum;
        },
        {},
      );
      setSelection(selection);
    }
  }, [resources]);

  /**
   * Efecto para manejar lo que se despliega en la columna de detalles
   */
  useEffect(() => {
    if (!!selection) {
      const indicatorsOptions =
        selection[resourceTypes.METADATA]['fields']['indicator'];

      //console.log('indicatorsOptions', indicatorsOptions);

      const selectedIndicator = Object.keys(indicatorsOptions).reduce(
        (indicatorsAccum, currIndicatorOptionId) => {
          if (indicatorsOptions[currIndicatorOptionId]['checked']) {
            indicatorsAccum = mapIndicatorSpecificaction(currIndicatorOptionId);
          }
          return indicatorsAccum;
        },
        null,
      );

      if (selectedIndicator !== null) {
        //console.log('selectedIndicator', selectedIndicator);
        setSelectedIndicator(selectedIndicator);
      }
    }
  }, [selection]);

  /**
   * Efecto para la consulta de detalles del indicador seleccionado
   */
  useEffect(() => {
    if (!!selectedIndicator) {
      const indicatorId = selectedIndicator;
      let mounted = true;

      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      Promise.all([
        getIndicatorDetailsPromise(indicatorId, source),
        getElementSourcesPromise([indicatorId], source),
      ])
        .then((results) => {
          if (mounted) {
            //console.log('result', result);
            const generalDetails = results[0];
            const sources = results[1];
            const adaptedSources = DataAdapter.adaptSourcesData(sources);
            const sourcesString = getSourcesText(adaptedSources);
            setDetails({
              name: generalDetails.name,
              description: generalDetails.description,
              source: sourcesString,
            });
          }
        })
        .catch((error) => {
          //console.log('error', error);
        });

      return function cleanup() {
        mounted = false;
        source.cancel('axios request cancelled');
      };
    }
  }, [selectedIndicator]);

  /* EVENTS HANDLERS */

  /**
   * Handler para el click del botón de regresar
   */
  const onReturnClicked = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const onDownloadMetadataIndicatorsSelection = () => {
    executeDownloads([selectedIndicator]);
  };

  const onDownloadMetadataAllIndicators = () => {
    executeDownloads(indicatorsIds);
  };

  /* DOWNLOAD OPERATIONS */

  const executeDownloads = (selectedIndicators) => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    PENDataService.downloadIndicatorsMetadataFile(selectedIndicators, source)
      .then((result) => {
        // console.log('result', result);
        const fileData = result.data;
        handleFileData(fileData, 'metadatos');
      })
      .catch((error) => {
        //console.log('error', error);
      });
  };

  const handleFileData = (fileData, filename) => {
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  /* INDICATORS COLUMN */
  const getIndicatorsColumnContent = () => {
    return (
      <SelectionMenu
        resourceId={resourceTypes.METADATA}
        fieldId="indicator"
        fieldType="radio"
        setParentSelection={setSelection}
        parentSelection={selection}
        options={resources[resourceTypes.METADATA]['fields'][0]['options']}
      />
    );
  };

  /* DETAILS COLUMN */
  const getDetailsColumnContent = () => {
    if (!!details) {
      return (
        <div className="modal-indicator-details">
          <Row>
            <h4>{getIndicatorName()}</h4>
          </Row>
          <Row>
            <p>
              <b>Definición:</b> {getIndicatorDefinition()}
            </p>
          </Row>
          <Row>
            <p className="txt-resalte-azul">
              <b>Fuente:</b> {getIndicatorSource()}
            </p>
          </Row>
          <Row>
            <ListGroup>
              <ListGroup.Item
                action
                onClick={onDownloadMetadataIndicatorsSelection}
              >
                <span className="detalles-btn-item">Descargar metadatos</span>
                <span className="menu-icon icn-blue">
                  <i className="pen-download"></i>
                </span>
              </ListGroup.Item>
            </ListGroup>
          </Row>
          {/* <Row>
            <ListGroup>
              <ListGroup.Item
                action
                // onClick={onDownloadDataClicked}
              >
                <span className="detalles-btn-item">Descargar datos</span>
                <span className="menu-icon icn-blue">
                  <i className="pen-download"></i>
                </span>
              </ListGroup.Item>
            </ListGroup>
          </Row> */}
        </div>
      );
    } else {
      // TODO: Ver si es necesario ponerle un min height
      return null;
    }
  };

  /* MAIN */
  const getResourcesOptions = () => {
    let indicatorsColumn = (
      <Col xs={6} md={4}>
        <Form.Group className="pen-selection-menu data-menu">
          {getIndicatorsColumnContent()}
        </Form.Group>
      </Col>
    );
    let indicatorDetailsColumn = (
      <Col xs={6} md={8}>
        {getDetailsColumnContent()}
      </Col>
    );

    return (
      <Row>
        {indicatorsColumn}
        {indicatorDetailsColumn}
      </Row>
    );
  };

  return (
    <div className="content" ref={ref}>
      <Row className="mb-4">
        <Col>
          <Link to="/" onClick={onReturnClicked} className="volver-link">
            Volver
          </Link>
        </Col>
      </Row>

      <Row className="row-info-container">
        <h2>Metadatos</h2>
      </Row>
      <Row className="pen-options data-options">
        <Col>
          <Row className="pen-options-header-row">
            <h4 className="pen-options-title">Indicadores</h4>
            {!!selection && (
              <Button
                className="pen-options-main-action-button"
                variant="link-like"
                onClick={onDownloadMetadataAllIndicators}
              >
                Descargar todos
              </Button>
            )}
          </Row>
          {!!selection && getResourcesOptions()}
        </Col>
      </Row>
    </div>
  );
});

export default DataElementMetadata;
