import {
  COLLECTION_FILTER_ID,
  REGION_FILTER_ID,
  TOPIC_FILTER_ID,
  YEAR_FILTER_ID,
} from './constants';

class FiltersHelper {
  static getFilterValuesRequestPathString(filterId) {
    switch (filterId) {
      case COLLECTION_FILTER_ID:
        return 'colecciones';
      case TOPIC_FILTER_ID:
        return 'temas';
      case REGION_FILTER_ID:
        return 'regiones';
      case YEAR_FILTER_ID:
        return 'annos';
      default:
        return null;
    }
  }

  static getFiltersParams(filters) {
    return filters.reduce((accum, curr) => {
      const selectedValues = curr.selectedValues;
      if (Array.isArray(selectedValues) && selectedValues.length !== 0) {
        if (curr.id === COLLECTION_FILTER_ID) {
          accum[curr.id] = selectedValues[0];
        } else {
          accum[curr.id] = encodeURIComponent(
            JSON.stringify(curr.selectedValues),
          );
        }
      }
      return accum;
    }, {});
  }

  static getOtherFiltersParams(filterId, filters) {
    return filters.reduce((accum, curr) => {
      if (curr.id !== filterId) {
        if (curr.id === COLLECTION_FILTER_ID && curr.selectedValues.length) {
          accum[curr.id] = curr.selectedValues[0];
        } else {
          accum[curr.id] = encodeURIComponent(
            JSON.stringify(curr.selectedValues),
          );
        }
      }
      return accum;
    }, {});
  }
}

export default FiltersHelper;
