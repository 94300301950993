import React, { forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import { Col, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';



const HomeAdmin = forwardRef(({ }, ref) => {
    const history = useHistory()
    const showPublicHistory = (path) => {

        history.push(`/${path}`)
    };
    return (
        <Container>
            <div className="content" ref={ref} >
                <Col className="row-info-container mt-2" xs={12} sm={12} md={12}>
                    <h2 className='mx-auto'>Gestión administrativa</h2>
                </Col>
                <Col className='row-info-container' xs={12} sm={12} md={12} >
                    <Button className='mb-2 mr-2 btn btn-primary' onClick={() => showPublicHistory("visualizador")}>Gestión historias</Button>
                    <Button className='mb-2 mr-2 btn btn-secondary' onClick={() => showPublicHistory("gestion_datos")}>Gestión de datos</Button>
                </Col>
            </div>
        </Container >
    )
})

export default HomeAdmin