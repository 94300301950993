import React, { useState, useEffect, forwardRef } from 'react';
import VisualizationToolContent from './DataVisualization/VisualizationToolContent';
import UserSessionDataService from '../shared/UserSessionDataService';
import { useSelector } from 'react-redux';
import IndicatorDetails from '../components/Data/IndicatorDetails';
import ChartEditor from '../components/DataVisualization/ChartEditor';
import DataElementDownloadOptions from '../components/Data/DataElementDownloadOptions';
import SearchResultsCarousel from '../components/DataSelection/SearchResultsCarousel';
import DataElement from '../components/DataVisualization/DataElement';
import OfficialStoryCreator from '../components/Data/OfficialStoryCreator';
import Login from '../Login';
import DoubleColumnFilterValuesTable from '../components/DataSelection/DoubleColumnFilterValuesTable';
import FilterValuesPage from '../components/DataSelection/FilterValuesPage';
import UserSession from '../models/UserSession';
import resourcesOptions from './../models/resourcesOptions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import SelectionMenu from './Data/SelectionMenu';
import { resourceTypes } from '../shared/constants';
import ExportFullElement from '../components/DataVisualization/ExportFullElement';
import ExportChart from './DataVisualization/ExportChart';
import Container from 'react-bootstrap/Container';

const mockIndictators = [
  { id: 10002, name: 'Población total', unit: 'Total de Personas' },
  { id: 10003, name: 'Total de hombres', unit: 'Total de Personas' },
];

const getSessionString = () => {
  return `{"user_stories": {"0":{"story_elements":{
    "4c8a5920-4fd8-4c75-a4fe-c4743e0b1758":{"key":"4c8a5920-4fd8-4c75-a4fe-c4743e0b1758","type":"text","content_text":null,"mode":"readonly"},
    "e9a9c792-1262-459b-8970-5c43360968ce":{"key":"e9a9c792-1262-459b-8970-5c43360968ce","type":"text","content_text":"12345","mode":"readonly"},
    "92fe4de7-d95f-4351-a66e-aef20dbfc6c2":{"key":"92fe4de7-d95f-4351-a66e-aef20dbfc6c2","type":"data","indicators":["10002"],"collection":0,"title_text":"","subtitle_text":"","chart_types_options":{"10002":"bar"},"chart_legend_option":"down","chart_colors_option":"cool1","chart_guides_option":false},
    "dcb138de-b885-42b2-a591-2b07c06668d6":{"key":"dcb138de-b885-42b2-a591-2b07c06668d6","type":"text","content_text":"6789","mode":"readonly"},
    "cc48a536-d1a1-401f-9017-83b897e4b8f6":{"key":"cc48a536-d1a1-401f-9017-83b897e4b8f6","type":"data","indicators":["200004"],"collection":2,"title_text":"","subtitle_text":"","chart_types_options":{"200004":"bar"},"chart_legend_option":"down","chart_colors_option":"cool1","chart_guides_option":false}},"key":"0"}
  }}`;
};

function Test() {
  return (
    <Container fluid className="content">
      <ExportFullElement />
    </Container>
  );
}

export default Test;
