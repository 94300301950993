
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';


const editPublicHistory = (id) => {
  window.location = `/editHistory/${id}`;
};
const PENStoryAdminActions = ({ id, title, description, extension, handleShowDeleteHistoryModal }) => {
  return (
    <Col xs={12} sm={4} md={3} className="mb-4">
      <div className="pen-story-container">
        <Row>
          <Col>
            <Image
              className="story-img"
              src={`/static/assets/images/stories/${id}.${extension}`}
              fluid
              style={{ width: '100%', height: '150px' }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{title}</h3>
          </Col>

        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{description}</p>
          </Col>
        </Row>
        <div className='row bottom-row' >
          <div className='col '></div>
          <div onClick={() => editPublicHistory(id)} className={'col-auto ml-auto btn '}>
            <span className="menu-icon icn-blue">
              <i className="pen-edit"></i>
            </span>
          </div>
          <div onClick={() => handleShowDeleteHistoryModal(id)} className={'col-auto  btn'}>
            <span className="menu-icon icn-red ">
              <i className="pen-delete  "></i>
            </span>
          </div>
        </div>
      </div>
    </Col>
  );
}
export default PENStoryAdminActions;
