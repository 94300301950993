import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

function GenericMessage(props) {
  console.log('props', props);

  const title = props.title;
  const message = props.message;
  const onOKClicked = props.onOKClicked;

  const getTitleSection = () => {
    return <Row>{title}</Row>;
  };

  const getMessageSection = () => {
    return <Row>{message}</Row>;
  };

  return (
    <Container fluid className="action-modal-container">
      {!!title && getTitleSection()}
      {getMessageSection()}
      <br />
      <Row>
        <Button
          variant="secondary"
          onClick={onOKClicked}
          className="center-in-row"
        >
          OK
        </Button>
      </Row>
    </Container>
  );
}

export default GenericMessage;
