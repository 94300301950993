export const COOKIE_SET = 'cookie/cookieSet';
export const SESSION_SET = 'session/sessionSet';
export const ADMIN_SESSION_SET = 'admin/adminSessionSet';
export const INDICATOR_DETAILS_OPTION_OPENED = 'indicators/detailsOptionOpened';
export const INDICATOR_DETAILS_OPTION_CLOSED = 'indicators/detailsOptionClosed';
export const ERROR_MESSAGE_OPENED = 'error/errorMessageOpened';
export const ERROR_MESSAGE_CLOSED = 'error/errorMessageClosed';
export const SELECTION_SET = 'selection/selectionSet';
export const HEADER_MODE_SET = 'header/headerModeSet';
// export const RENDER_EXPORT_CHART = 'export/renderExportChart';
// export const HIDE_EXPORT_CHART = 'export/hideExportChart';
// export const EXPORT_DATA_SET = 'export/exportDataSet';
// export const EXPORT_DATA_CLEARED = 'export/exportDataCleared';
