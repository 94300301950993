import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  forwardRef,
} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from 'react-bootstrap/Button';
import IconButton from '@material-ui/core/IconButton';
import UserSessionDataService from '../../shared/UserSessionDataService';
import DataAdapter from '../../shared/DataAdapter';
import {
  PUBLIC_URL,
  VISUALIZER_SHARE_MODE_PATH,
  elementTypes,
  fileExtensions,
} from '../../shared/constants';
import { useLocation } from 'react-router-dom';
import { StoryContext } from '../../components/DataVisualization/StoryContext';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import UserStory from '../../models/UserStory';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { ReactComponent as Twitterx } from "../../img/twitter-x-icon.svg";

// Para entrar a este componente se debe haber seleccionado un elemento de datos de la historia

const DataElementShareOptions = forwardRef(
  ({ elementKey, publicStoryKey }, ref) => {
    //console.log('elementKey', elementKey);
    //console.log('public storyKey', publicStoryKey);
    // console.log('props', props);

    let location = useLocation();

    // Es el identificador del elemento por compartir cuando se proviene desde
    // las opciones de compartir de un elemento y no compartir todo del visualizador
    // const elementKey = props.elementKey;

    // Es el identificador de una historia pública que ya existe cuando se proviene desde
    // no compartir todo del visualizador y es una historia pública (ya sea oficial o no)
    // const publicStoryKey = props.publicStoryKey;

    /* Acceso a la historia del visualizador */
    const context = useContext(StoryContext);

    const widthSelectRef = useRef(null);
    const textAreaRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [storyKey, setStoryKey] = useState(null);
    const [shareLinkString, setshareLinkString] = useState('');
    const [siteUrl, setSiteUrl] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const getShareHRef = () => {
      return `${
        siteUrl !== null && siteUrl
      }/${VISUALIZER_SHARE_MODE_PATH}/${storyKey}`;
    };

    useEffect(() => {
      const fullUrl = window.location.href;
      const currentPath = location.pathname;
      const siteUrl = fullUrl.replace(currentPath, '');
      //console.log('siteUrl', siteUrl);
      //console.log('PUBLIC_URL', PUBLIC_URL);
      //console.log('process.env.PUBLIC_URL', process.env.PUBLIC_URL);
      setSiteUrl(siteUrl);
    }, []);

    useEffect(() => {
      //console.log('widthSelectRef', widthSelectRef);
      if (
        !!storyKey &&
        !!widthSelectRef.current &&
        !!widthSelectRef.current.value
      ) {
        //console.log('widthSelectRef value', widthSelectRef.current.value);
        const selectedWidth = widthSelectRef.current.value;
        setshareLinkString(
          `<iframe src="${getShareHRef()}" width="${selectedWidth}" height="600" frameBorder="0"></iframe>`,
        );
      }
    }, [storyKey, widthSelectRef]);

    useEffect(() => {
      const getAdaptedPublicStory = () => {
        // elementKey fue pasado desde VisualizationTool o desde TabContent
        // Si fue desde VisualizationTool, se comparte la historia completa y elementKey es null porque no es necesario
        // Si fue desde TabContent, se comparte solo el elemento de la historia y elementKey hace referencia al elemento que se comparte
        // En la historia nueva que se crea en este último caso, se deben agregar todos los elementos de texto que haya después del
        // elemento de datos. Es decir, todos los siguients de texto hasta llegar a otro de datos o hasta que no haya más elementos
        if (!!elementKey) {
          const sessionStoryCopy = context.session.getCopy().getSessionStory();
          const elements =
            sessionStoryCopy.getDataElementByKeyAndFollowingTextElements(
              elementKey,
            );
          //console.log('elements', elements);
          sessionStoryCopy.setStoryElements(elements);
          return sessionStoryCopy.getDbObject();
        } else {
          return context.session.getCopy().getSessionStory().getDbObject();
        }
      };

      const createExportElementPreview = (story) => {
        const parsedStory = new UserStory(story);

        if (!UserSessionDataService.storyHasValidElements(parsedStory)) {
          return null;
        }

        const elements =
          UserSessionDataService.getStoryElementsArray(parsedStory);

        // Se filtran los elementos por el tipo de elemento para el que se evalúa la condición
        const filteredElements = elements.filter(
          (element) => element.type === elementTypes.DATA,
        );

        if (filteredElements.length === 0) {
          return null;
        }

        const firstDataElement = filteredElements[0];

        const texts =
          UserSessionDataService.getFollowingTextsArrayForDataElementByKey(
            elements,
            firstDataElement.key,
          );

        const currentElement = firstDataElement;

        const indicators = currentElement.indicators;
        const regions = currentElement.regions;
        const years = currentElement.years;
        let exportParams;

        if (indicators.length) {
          exportParams = {
            indicators,
            regions,
            years,
            collection: currentElement.collection,
            titleText: currentElement.titleText,
            subtitleText: currentElement.subtitleText,
            chartTypesOptions: currentElement.chartConfiguration.types,
            chartGuidesOption: currentElement.chartConfiguration.guides,
            chartColorsOption: currentElement.chartConfiguration.colors,
            chartLegendOption: currentElement.chartConfiguration.legend,
            text: texts,
            type: fileExtensions.PNG,
            encode: true,
          };
        } else {
          exportParams = null;
        }

        return exportParams;
      };

      let mounted = true;

      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      const story = getAdaptedPublicStory();

      // Si la historia por compartir es pública, se llega al componente con el identificador
      // de historia pública distinto de null, y no es necesario crear una nueva, porque se comparte la misma
      if (publicStoryKey === null || typeof publicStoryKey === 'undefined') {
        const newStoryKey = UserSessionDataService.generatePublicStoryKey();
        const encodedKey =
          UserSessionDataService.getEncodedPublicStoryKey(newStoryKey);
        const formData = DataAdapter.adaptPublicStoryDataAsFormData(story);
        trackPromise(
          UserSessionDataService.createPublicStory(encodedKey, formData)
            .then((result) => {
              // console.log('result', result);
              setStoryKey(newStoryKey);
            })
            .catch((error) => {
              console.log('error', error);
            }),
        );
      } else {
        setStoryKey(publicStoryKey);
      }

      const exportParams = createExportElementPreview(story);

      if (!!exportParams) {
        trackPromise(
          UserSessionDataService.getExportElement(exportParams)
            .then((result) => {
              setPreviewImage(`data:image/png;base64,${result}`);
            })
            .catch((error) => {
              console.log('error', error);
            })
            .finally(() => {
              setLoading(false);
            }),
        );
      } else {
        setLoading(false);
      }

      return function cleanup() {
        mounted = false;
        source.cancel('axios request cancelled');
      };
    }, []);

    const onCopyLinkClicked = (event) => {
      const currentElement = textAreaRef.current;
      if (!!currentElement) {
        currentElement.select();
        document.execCommand('copy');
        textAreaRef.current.focus();
      }
    };

    const onCopyPenLinkClicked = () => {
      var input = document.body.appendChild(document.createElement('input'));
      input.value = getShareHRef();
      input.focus();
      input.select();
      document.execCommand('copy');
      input.parentNode.removeChild(input);
    };

    return (
      <Container fluid className="action-modal-container">
        <Row>
          <h2>Comparta este gráfico</h2>
        </Row>
        <Row>
          <div className="chart-image-holder" id="data-preview-container">
            <img
              className="img-fluid"
              src={!!previewImage && previewImage}
              alt="Previsualización"
            />
          </div>
        </Row>
        <Row>
          <p>en las siguientes plataformas</p>
        </Row>
        <Row>
          <div className="buttons-container">
            {/* FACEBOOK */}
            <FacebookShareButton url={getShareHRef()}>
              <span>
                <IconButton>
                  <i className="pen-facebook" />
                </IconButton>
              </span>
            </FacebookShareButton>
            {/* TWITTER */}
            <TwitterShareButton url={getShareHRef()}>
              <span>
                <IconButton>
                   <Twitterx className="twitter-x-icon-24" />
                </IconButton>
              </span>
            </TwitterShareButton>
            {/* ??? */}
            <IconButton onClick={onCopyPenLinkClicked}>
              <i className="pen-link" />
            </IconButton>
          </div>
        </Row>
        <Row>
          <p>o en su página web</p>
        </Row>
        <Row>
          <TextareaAutosize
            rowsMin="2"
            className="embed-container"
            defaultValue={shareLinkString}
            ref={textAreaRef}
          ></TextareaAutosize>
        </Row>
        <Row>
          <Row className="embed-options-container">
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  Anchofd
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    as="select"
                    ref={widthSelectRef}
                    defaultValue="600"
                  >
                    <option value="600">600</option>
                  </Form.Control>
                </Col>
                <Form.Label column sm={2} className="unit-col">
                  px
                </Form.Label>
              </Form.Group>
            </Col>
            <Col className="text-right">
              <Button variant="primary" onClick={onCopyLinkClicked}>
                Copiar link
              </Button>
            </Col>
          </Row>
        </Row>
      </Container>
    );
  },
);

export default DataElementShareOptions;
