import Cookies from 'js-cookie';

const name = 'ESTADISTICASESTADONACION';
const expiration = Number.MAX_SAFE_INTEGER;

class CookieService {
  /* COOKIES */

  static getCookie() {
    return Cookies.get(name); // => 'value'
  }

  static setCookie(value) {
    Cookies.set(name, value, { expires: expiration });
  }

  static deleteCookie() {
    Cookies.remove(name);
  }
}

export default CookieService;
