
import TagManager from 'react-gtm-module'
import {KEY_GOOGLE_TAG} from './constants'
class GoogleTagManager {
    static InitGoogleTag(){
        const tagManagerArgs = {
            gtmId: KEY_GOOGLE_TAG
        }
        TagManager.initialize(tagManagerArgs)
    }
}


export default GoogleTagManager;