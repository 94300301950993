import {
  axisBottom as d3_axisBottom,
  axisLeft as d3_axisLeft,
  scaleOrdinal as d3_scaleOrdinal,
  scaleLinear as d3_scaleLinear,
  scaleBand as d3_scaleBand,
  select as d3_select,
  max as d3_max,
  min as d3_min,
  curveMonotoneX as d3_curveMonotoneX,
  curveBasis as d3_curveBasis,
} from 'd3';
import { line as d3_line } from 'd3-shape';
import { colors } from '../models/colors';
import {
  X_VARIABLE_FIELD_ID,
  Y_VARIABLE_FIELD_ID,
  REGULAR_PALETTE_ID,
  chartNestingOptions,
} from './constants';
import PENDataService from '../shared/PENDataService';

const getLegendTextForRegion = (key) => {
  //console.log('Llamada a getLegendTextForRegion con key => ', key);
  return key;
};

const getLegendTextForIndicator = (key, indicatorsDict) => {
  //console.log('Llamada a getLegendTextForIndicator con key => ', key);
  return indicatorsDict[key];
};

class ChartHelper {
  static LEGEND_SQUARE = 20;

  // Tomar en cuenta que el tamaño del margen izquierdo influye en si los valores del eje y
  // quedan completamente visibles o si quedan en el overflow
  static MARGIN = { top: 10, right: 10, bottom: 20, left: 75 };

  static getXScale(data, width) {
    return d3_scaleBand()
      .domain(data.map((d) => d[X_VARIABLE_FIELD_ID]))
      .range([ChartHelper.MARGIN.left, width - ChartHelper.MARGIN.right])
      .padding(1);
  }

  static getYScale(data, height) {
    return d3_scaleLinear()
      .domain([
        Math.min(
          0,
          d3_min(data, (d) => d[Y_VARIABLE_FIELD_ID]),
        ),
        d3_max(data, (d) => d[Y_VARIABLE_FIELD_ID]),
      ])
      .nice()
      .range([height - ChartHelper.MARGIN.bottom, ChartHelper.MARGIN.top]);
  }

  static getXAxis(xScale, height) {
    const xAxisFunction = (g) =>
      g
        .attr('transform', `translate(0,${height - ChartHelper.MARGIN.bottom})`)
        .call(d3_axisBottom(xScale).tickSizeOuter(0));
    return xAxisFunction;
  }

  static getYAxis(yScale) {
    const yAxisFunction = (g) =>
      g
        .attr('transform', `translate(${ChartHelper.MARGIN.left},0)`)
        .call(
          d3_axisLeft(yScale).tickFormat((d) =>
            PENDataService.getMaskedValue(d),
          ),
        );
    return yAxisFunction;
  }

  static getMargins() {
    return ChartHelper.MARGIN;
  }

  static getLineFunction(xScale, yScale) {
    return d3_line()
      .curve(d3_curveMonotoneX)
      .x((d) => xScale(d[X_VARIABLE_FIELD_ID]))
      .y((d) => yScale(d[Y_VARIABLE_FIELD_ID]));
  }

  static getColorFunction(groupingKeys, colorsArray) {
    // console.log('groupingKeys', groupingKeys);
    return d3_scaleOrdinal().domain(groupingKeys).range(colorsArray);
  }

  static getChartColors = (colorsOption) => {
    const colorsOptionId =
      !!colorsOption && colorsOption in colors
        ? colorsOption
        : REGULAR_PALETTE_ID;
    return colors[colorsOptionId];
  };

  static getLegendTextFunction(chartNesting) {
    return chartNesting === chartNestingOptions.INDICATOR
      ? getLegendTextForIndicator
      : getLegendTextForRegion;
  }

  static getTooltipContentHtml(
    d,
    colorFunction,
    chartNesting,
    indicatorsDict,
    elementKey,
  ) {
    // console.log('d', d);
    const fieldId =
      chartNesting === chartNestingOptions.INDICATOR
        ? indicatorsDict[d.key]
        : indicatorsDict[d.id];
    const value = d[Y_VARIABLE_FIELD_ID];
    const color = colorFunction(d.key);

    const maskedValue = PENDataService.getMaskedValue(value);

    const additionalRow =
      chartNesting === chartNestingOptions.INDICATOR
        ? ``
        : `<div class="tooltip-x-value-row"><div class="tooltip-x-value">${d.key}</div></div>`;

    return `<div class="d3-tooltip-individual-div d3-tooltip-individual-div-${elementKey}>
    <div class="tooltip-x-value-row">
      <div class="tooltip-x-value">${fieldId}</div>
    </div>
    ${additionalRow}
    <div class="tooltip-y-value-row">
      <div class="box tooltip-legend" style="background-color:${color};"></div>
      <div class="tooltip-y-value">${maskedValue}</div>
    </div>
  </div>`;
  }

  static removeTooltipsDivById(id) {
    // Se eliminan solo los tooltips para el elemento correspondiente a id
    var elements = document.getElementsByClassName(
      `d3-tooltip-general-div-${id}`,
    );
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  static removeTooltipsDivs() {
    // Se eliminan todos los tooltips
    var elements = document.getElementsByClassName(`d3-tooltip-general-div`);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }
}
export default ChartHelper;
