import React, { forwardRef } from 'react';
import { Route } from 'react-router-dom';
import NotFound from '../../NotFound';

const validElementId = (props) => {
  if (typeof props.location.state === 'undefined') {
    return false;
  }

  return (
    !!props.location.state.indicators &&
    Array.isArray(props.location.state.indicators) &&
    props.location.state.indicators.length
  );
};

const DataElementMetadataProtectedRoute = forwardRef(
  ({ component: Component, ...rest }, ref) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (validElementId(props)) {
            const adaptedProps = props;
            return <Component {...adaptedProps} ref={ref} />;
          } else {
            return <Route component={NotFound} />;
          }
        }}
      />
    );
  },
);

export default DataElementMetadataProtectedRoute;
