import React, { forwardRef } from 'react';
import Container from 'react-bootstrap/esm/Container';
import SearchResultsCarousel from './SearchResultsCarousel';

/* SEARCH RESULTS COMPONENT */
const SearchResults = forwardRef(({ data }, ref) => {
  const indicators = data;

  /**
   * Devuelve el contenido para el contenedor de resultados de búsqueda
   * Si hay al menos un resultado, devuelve el carrusel con los resultados
   * Si no, devuelve un elemento con el mensaje de que no hay resultados
   */
  const getContent = () => {
    if (indicators.length) {
      return <SearchResultsCarousel data={indicators} ref={ref} />;
    } else {
      return <p>No se encontró ningún resultado</p>;
    }
  };

  return (
    <Container
      fluid
      id="search-results-container"
      className="search-results-container"
    >
      {getContent()}
    </Container>
  );
});

export default SearchResults;
