import React, { useRef, useContext, memo } from 'react';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';
import Container from 'react-bootstrap/esm/Container';
import { COLLECTION_FILTER_ID } from '../../shared/constants';
import { DataSelectionContext } from './DataSelectionContext';
import store from '../../store';
import { SELECTION_SET } from '../../actions/types';
const { v4: uuidv4 } = require('uuid');

/* FILTER ELEMENT COMPONENT */
const FilterValuesTable = memo(({ id, values, selectedValues }) => {
  console.log('[FilterValuesTable] is running', id);

  /* Acceso a la selección de datos */
  const context = useContext(DataSelectionContext);

  /* Acceso al objeto de la tabla */
  let tableRef = useRef(null);

  const sendActionSelectionSet = () => {
    store.dispatch({
      type: SELECTION_SET,
      payload: context.selection.getSimpleFormatObject(),
    });
  };

  /**
   * Envía a la información compartida la acción de seleccionar un valor del filtro
   */
  const sendActionFilterValueSelected = (filterValue) => {
    context.selectFilterValue(id, filterValue);
    sendActionSelectionSet();
  };

  /**
   * Envía a la información compartida la acción de seleccionar un valor del filtro
   * @param {number} filterValue
   */
  const sendActionFilterValueDeselected = (filterValue) => {
    context.deselectFilterValue(id, filterValue);
    sendActionSelectionSet();
  };

  const onFilterValuesLoaded = (data) => {
    if (!!tableRef && !!tableRef.current) {
      const table = tableRef.current.table;
      if (!!table) {
        if (selectedValues.length) {
          //console.log('Entra a selected values con tamaño > 0');
          table.selectRow(selectedValues);
        }
      }
    }
  };

  /**
   * Handler para la selección de un valor del filtro
   */
  const onFilterValueRowSelected = (row) => {
    const rowData = row.getData();
    const filterValue = rowData.id;
    sendActionFilterValueSelected(filterValue);
  };

  /**
   * Handler para la deselección de un valor del filtro
   */
  const onFilterValueRowDeselected = (row) => {
    const rowData = row.getData();
    const filterValue = rowData.id;
    sendActionFilterValueDeselected(filterValue);
  };

  /**
   * Handler para la opción selectableCheck de la tabla
   */
  const isRowSelectable = (row) => {
    const rowData = row.getData();
    return rowData.enabled;
  };

  /* Opciones de la tabla, se pasan al componente de tabulator */
  const options = {
    headerVisible: false, //hide header
    resizableColumns: false,
    rowSelected: onFilterValueRowSelected,
    rowDeselected: onFilterValueRowDeselected,
    selectableCheck: isRowSelectable,
    dataLoaded: onFilterValuesLoaded,
  };

  /* Columnas de la tabla, se pasan al componente de tabulator */
  const columns = [
    {
      formatter: 'rowSelection',
      hozAlign: 'center',
      headerSort: false,
      cellClick:function(e, cell){
         cell.getRow().toggleSelect();
      }
    },
    { field: 'name', headerSort: false, width: '90%' },
  ];

  return (
    <Container fluid>
      <ReactTabulator
        ref={tableRef}
        data={values}
        columns={columns}
        tooltips={false}
        options={options}
        // Permite que con las actualizaciones en la selección de datos
        // siempre se renderice de nuevo la tabla con los valores actualizados
        key={uuidv4()}
      />
    </Container>
  );
});

export default FilterValuesTable;
