import React, { useState, useEffect, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import SelectionMenu from './SelectionMenu';
import resourceOptionsHistory from '../../models/resourceOptionsHistoryExport';
import PENDataService from '../../shared/PENDataService';
import UserSessionDataService from '../../shared/UserSessionDataService';
import { StoryContext } from '../../components/DataVisualization/StoryContext';
import DataAdapter from '../../shared/DataAdapter';
import axios from 'axios';
import UserStory from '../../models/UserStory';
import {
  DOWNLOAD_CONFIRMATION_TITLE,
  DOWNLOAD_CONFIRMATION_MESSAGE,
  fileExtensions,
  resourceTypes,
  DEFAULT_CHART_TYPE,
  elementTypes,
} from '../../shared/constants';
import jszip from 'jszip';
import jsPDF from 'jspdf';
import { trackPromise } from 'react-promise-tracker';

// Para entrar a este componente se debe haber seleccionado un elemento de datos de la historia

/* DATA ELEMENT DOWNLOAD OPTIONS COMPONENT */
function DataHistoryDownloadOption(props) {
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState(null);
  // const [processedDataElement, setProcessedDataElement] = useState(null);
  const [indicators, setIndicators] = useState(null);
  const [downloadDisabled, setDownloadDisabled] = useState(true);
  const [showConfirmDownloadModal, setShowConfirmDownloadModal] =
    useState(false);

  const [elementKey, setStoryKey] = useState(props.elementKey);
  const [exportParams, setExportParams] = useState(null);

  /* Acceso a la historia del visualizador */
  const context = useContext(StoryContext);
  useEffect(() => {
    const getAdaptedPublicStory = () => {
      // elementKey fue pasado desde VisualizationTool o desde TabContent
      // Si fue desde VisualizationTool, se comparte la historia completa y elementKey es null porque no es necesario
      // Si fue desde TabContent, se comparte solo el elemento de la historia y elementKey hace referencia al elemento que se comparte
      // En la historia nueva que se crea en este último caso, se deben agregar todos los elementos de texto que haya después del
      // elemento de datos. Es decir, todos los siguients de texto hasta llegar a otro de datos o hasta que no haya más elementos
      const elements = context.session
        .getCopy()
        .getSessionStory()
        .getDbObject();
      //console.log('elements', elements);
      return elements;
    };

    const createExportElementPreview = (story) => {
      const parsedStory = new UserStory(story);

      if (!UserSessionDataService.storyHasValidElements(parsedStory)) {
        return null;
      }

      const elements =
        UserSessionDataService.getStoryElementsArray(parsedStory);

      // Se filtran los elementos por el tipo de elemento para el que se evalúa la condición
      const filteredElements = elements.filter(
        (element) => element.type === elementTypes.DATA,
      );

      if (filteredElements.length === 0) {
        return null;
      }

      const firstDataElement = filteredElements[0];

      const texts =
        UserSessionDataService.getFollowingTextsArrayForDataElementByKey(
          elements,
          firstDataElement.key,
        );

      const currentElement = firstDataElement;

      const indicators = currentElement.indicators;
      const regions = currentElement.regions;
      const years = currentElement.years;
      let exportParams;

      if (indicators.length) {
        exportParams = {
          indicators,
          regions,
          years,
          collection: currentElement.collection,
          titleText: currentElement.titleText,
          subtitleText: currentElement.subtitleText,
          chartTypesOptions: currentElement.chartConfiguration.types,
          chartGuidesOption: currentElement.chartConfiguration.guides,
          chartColorsOption: currentElement.chartConfiguration.colors,
          chartLegendOption: currentElement.chartConfiguration.legend,
          text: texts,
          type: fileExtensions.PNG,
          encode: true,
        };
      } else {
        exportParams = null;
      }

      return exportParams;
    };

    let mounted = true;

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const story = getAdaptedPublicStory();

    // Si la historia por compartir es pública, se llega al componente con el identificador
    // de historia pública distinto de null, y no es necesario crear una nueva, porque se comparte la misma
    if (elementKey === null || typeof elementKey === 'undefined') {
      const newStoryKey = UserSessionDataService.generatePublicStoryKey();
      const encodedKey =
        UserSessionDataService.getEncodedPublicStoryKey(newStoryKey);
      const formData = DataAdapter.adaptPublicStoryDataAsFormData(story);
      trackPromise(
        UserSessionDataService.createPublicStory(encodedKey, formData)
          .then(() => {
            // console.log('result', result);
            setStoryKey(newStoryKey);
            //console.log("Esta es la nueva llave", newStoryKey);
          })
          .catch((error) => {
            console.log('error', error);
          }),
      );
    } else {
      setStoryKey(elementKey);
      console.log('Era historia oficial', elementKey);
    }

    const exportParams = createExportElementPreview(story);

    return function cleanup() {
      mounted = false;
      source.cancel('axios request cancelled');
    };
  }, []);

  //console.log("Estoy en la opcion de descarga", elementKey);

  /* DATA REQUESTS */

  const downloadChartPromise = async (type) => {
    //console.log(exportParams);
    //console.log(elementKey);
    const adaptedExportParams = {
      key: elementKey,
      type: type,
      encode: true,
    };

    return new Promise((resolve, reject) => {
      UserSessionDataService.getExportHistory(adaptedExportParams)
        .then((result) => {
          //console.log(result);
          resolve({
            data: result,
            name: 'Historia',
            extension: type,
            createBlob: false,
          });
        })
        .catch((error) => {
          console.log('error', error);
          reject();
        });
    });
  };

  /* OTHER HELPERS */
  const elementKeyIsValid = () => {
    return context.session.getSessionStory().elementKeyIsValid(elementKey);
  };

  const getElement = () => {
    return context.session.getSessionStory().getElementByKey(elementKey);
  };

  const getStory = () => {
    return context.session.getSessionStory();
  };

  const getOptionsArray = (field) => {
    let optionsArray = field.options;
    if (field.id === 'indicator') {
      optionsArray = optionsArray.concat(
        indicators.map((indicator) => {
          return {
            ...indicator,
            id: `${resourceTypes.METADATA}-indicator-${indicator.id}`,
            label: indicator.name,
          };
        }),
      );
    }
    return optionsArray;
  };

  const isFieldDisabled = (resourceId) => {
    return !selection[resourceId]['checked'];
  };

  const getResourceOptionFieldLabel = (resourceId, fieldId, defaultValue) => {
    const selectedValues = Object.values(
      selection[resourceId]['fields'][fieldId],
    ).reduce((valuesAccum, currOption) => {
      if (currOption['checked']) {
        valuesAccum.push(currOption['label']);
      }
      return valuesAccum;
    }, []);

    return selectedValues.length ? selectedValues.join(', ') : defaultValue;
  };

  const mapFormatSpecification = (formatSpecification) => {
    switch (formatSpecification) {
      case `${resourceTypes.CHART}-format-pdf`:
      case `${resourceTypes.DATA}-format-pdf`:
        return fileExtensions.PDF;
      case `${resourceTypes.CHART}-format-png`:
        return fileExtensions.PNG;

      default:
        return null;
    }
  };

  const mapIndicatorSpecificaction = (indicatorSpecification) => {
    const actualIndicatorString = indicatorSpecification.replace(
      `${resourceTypes.METADATA}-indicator-`,
      '',
    );

    if (actualIndicatorString === 'all') {
      return !!elementKey ? getElement().indicators : props.indicators;
    }

    return [parseInt(actualIndicatorString)];
  };

  const getSelectedResources = () => {
    //console.log('hola');
    return Object.keys(selection).reduce((resourcesAccum, currResourceId) => {
      // El recurso debe aparecer como checked para que se considere seleccionado

      if (selection[currResourceId]['checked']) {
        const fields = selection[currResourceId]['fields'];

        if (currResourceId === resourceTypes.DATA) {
          const rangeSelection = Object.keys(fields['range']).reduce(
            (rangeAccum, currRangeOptionId) => {
              if (
                selection[currResourceId]['fields']['range'][currRangeOptionId][
                  'checked'
                ]
              ) {
                rangeAccum = currRangeOptionId.replace(
                  `${resourceTypes.DATA}-range-`,
                  '',
                );
              }
              return rangeAccum;
            },
            null,
          );

          if (!!rangeSelection) {
            Object.keys(fields['format']).reduce(
              (formatAccum, currFormatOptionId) => {
                if (
                  selection[currResourceId]['fields']['format'][
                    currFormatOptionId
                  ]['checked']
                ) {
                  resourcesAccum.push({
                    type: resourceTypes.DATA,
                    format: mapFormatSpecification(currFormatOptionId),
                    range: rangeSelection,
                    indicators: null,
                  });
                }
                return formatAccum;
              },
              null,
            );
          }
        } else if (currResourceId === resourceTypes.CHART) {
          Object.keys(fields['format']).reduce(
            (formatAccum, currFormatOptionId) => {
              if (
                selection[currResourceId]['fields']['format'][
                  currFormatOptionId
                ]['checked']
              ) {
                resourcesAccum.push({
                  type: resourceTypes.CHART,
                  format: mapFormatSpecification(currFormatOptionId),
                  range: null,
                  indicators: null,
                });
              }
              return formatAccum;
            },
            null,
          );
        } else if (currResourceId === resourceTypes.METADATA) {
          Object.keys(fields['indicator']).reduce(
            (indicatorAccum, currIndicatorOptionId) => {
              if (
                selection[currResourceId]['fields']['indicator'][
                  currIndicatorOptionId
                ]['checked']
              ) {
                resourcesAccum.push({
                  type: resourceTypes.METADATA,
                  format: null,
                  range: null,
                  indicators: mapIndicatorSpecificaction(currIndicatorOptionId),
                });
              }
              return indicatorAccum;
            },
            null,
          );
        }
      }

      return resourcesAccum;
    }, []);
  };

  /* HOOKS */

  /**
   * Efecto para crear el objeto que encapsula los datos del elemento en el context
   */
  useEffect(() => {
    // Si elementKey es null porque se viene del selector de datos, no es necesario hacer esta validación
    // Igualmente las asignaciones que dependen de elementKey se brincan si elementKey es null,
    // porque al venir del selector de datos no puede haber contexto de historia ni historia
    //console.log(elementKey);
    if (!!elementKey && !elementKeyIsValid()) {
      //return;
    }

    const cancelToken = axios.CancelToken;
  }, []);

  /**
   * Efecto para manejar el objeto de selección
   */
  useEffect(() => {
    //console.log(resourceOptionsHistory);
    if (true) {
      //console.log(resourceOptionsHistory);
      const selection = Object.values(resourceOptionsHistory).reduce(
        (selectionAccum, currResource) => {
          const fields = currResource.fields.reduce(
            (fieldsAccum, currField) => {
              if (currField.id !== 'empty') {
                let optionsArray = getOptionsArray(currField);
                const options = optionsArray.reduce(
                  (optionsAccum, currOption) => {
                    optionsAccum[currOption.id] = {
                      checked: false,
                      label: currOption.label,
                    };
                    return optionsAccum;
                  },
                  {},
                );

                fieldsAccum[currField.id] = options;
              }

              return fieldsAccum;
            },
            {},
          );
          selectionAccum[currResource.id] = {
            checked: false,
            fields,
          };
          return selectionAccum;
        },
        {},
      );
      setSelection(selection);
    }
  }, [loading]);

  /**
   * Efecto para manejar la deshabilitación del botón de descarga
   */
  useEffect(() => {
    const getNumResourcesWithValidSelection = () => {
      return Object.keys(selection).reduce((resourcesAccum, currResourceId) => {
        if (selection[currResourceId]['checked']) {
          const fields = selection[currResourceId]['fields'];
          const numFields = Object.keys(fields).length;

          // Se recorren todos los campos del recurso para ver si la selección es válida

          const numFieldsWithValidSelection = Object.keys(fields).reduce(
            (fieldsAccum, currFieldId) => {
              const options = selection[currResourceId]['fields'][currFieldId];
              const numSelectedOptions = Object.keys(options).reduce(
                (optionsAccum, currOptionId) => {
                  if (
                    selection[currResourceId]['fields'][currFieldId][
                      currOptionId
                    ]['checked']
                  ) {
                    optionsAccum += 1;
                  }

                  return optionsAccum;
                },
                0,
              );

              if (numSelectedOptions) {
                fieldsAccum += 1;
              }

              return fieldsAccum;
            },
            0,
          );

          if (numFieldsWithValidSelection === numFields) {
            resourcesAccum += 1;
          }
        }

        return resourcesAccum;
      }, 0);
    };

    const getNumSelectedResources = () => {
      return Object.keys(selection).reduce((resourcesAccum, currResourceId) => {
        if (selection[currResourceId]['checked']) {
          resourcesAccum += 1;
        }
        return resourcesAccum;
      }, 0);
    };

    if (!!selection) {
      const numSelectedResources = getNumSelectedResources();
      const numResourcesWithValidSelection =
        getNumResourcesWithValidSelection();
      // Todos los recursos seleccionados tienen que tener una selección válida en sus campos
      const disable =
        numResourcesWithValidSelection === 0 ||
        numSelectedResources !== numResourcesWithValidSelection;
      setDownloadDisabled(disable);
    }
  }, [selection]);

  /* CONFIRMATION */
  const handleCloseConfirmModal = () => setShowConfirmDownloadModal(false);
  const handleShowConfirmModal = () => setShowConfirmDownloadModal(true);

  const getConfirmDownloadModal = () => {
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        show={showConfirmDownloadModal}
        onHide={handleCloseConfirmModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h2>{DOWNLOAD_CONFIRMATION_TITLE}</h2>
          <p className="text-center">{DOWNLOAD_CONFIRMATION_MESSAGE}</p>
          <Row>
            <Button
              variant="secondary"
              onClick={onDownloadClicked}
              className="center-in-row"
            >
              Descargar
            </Button>
          </Row>
          <Row>
            <Button
              variant="link-like"
              onClick={handleCloseConfirmModal}
              className="center-in-row"
            >
              cancelar
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  /* EVENTS HANDLERS */
  const onResourceChecked = (event) => {
    const resourceId = event.target.id;

    const newCheckedValue = !selection[resourceId]['checked'];

    const clearedResourceSelection = Object.keys(
      selection[resourceId]['fields'],
    ).reduce((fieldsAccum, currFieldId) => {
      const fieldSelection = Object.keys(
        selection[resourceId]['fields'][currFieldId],
      ).reduce((optionsAccum, currOptionId) => {
        optionsAccum[currOptionId] = {
          ...selection[resourceId]['fields'][currFieldId][currOptionId],
          checked: false,
        };
        return optionsAccum;
      }, {});
      fieldsAccum[currFieldId] = fieldSelection;
      return fieldsAccum;
    }, {});

    setSelection({
      ...selection,
      [resourceId]: {
        ...selection[resourceId],
        checked: newCheckedValue,
        fields: clearedResourceSelection,
      },
    });
    //console.log(selection);
  };

  const onDownloadClicked = () => {
    // Se extraen del objeto de selección los recursos para descargar
    // y se envían para la ejecución de las descargas correspondientes
    const selectedResources = getSelectedResources();
    executeDownloads(selectedResources);
  };

  /* DOWNLOAD OPERATIONS */

  const executeDownloads = (selectedResources) => {
    // console.log('selectedResources', selectedResources);
    const cancelToken = axios.CancelToken;
    //console.log('Hola', selectedResources);
    const downloadsPromises = selectedResources.map((resource) => {
      const type = resource.type;
      //console.log(type);
      //console.log(resource.format);
      return downloadChartPromise(resource.format);
      return null;
    });

    // console.log('downloadsPromises', downloadsPromises);

    trackPromise(
      Promise.all(downloadsPromises)
        .then((results) => {
          //console.log('results', results);
          if (results.length === 1) {
            const fileWrapper = results[0];
            handleFileData(fileWrapper);
          } else {
            const zip = new jszip();
            let fileData;
            let filename;
            let fileExtension;
            results.forEach((fileWrapper) => {
              fileData = fileWrapper.data;
              filename = fileWrapper.name;
              fileExtension = fileWrapper.extension;
              const createBlob = fileWrapper.createBlob;
              if (
                fileExtension === fileExtensions.PDF &&
                'imageToPdf' in fileWrapper &&
                fileWrapper.imageToPdf
              ) {
                const pdf = new jsPDF('l', 'px', 'letter');

                const imgProps = pdf.getImageProperties(fileData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(fileData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                zip.file(`${filename}.${fileExtension}`, pdf.output('blob'));
              } else {
                if (createBlob) {
                  zip.file(`${filename}.${fileExtension}`, fileData);
                } else {
                  zip.file(`${filename}.${fileExtension}`, fileData, {
                    base64: true,
                  });
                }
              }
            });
            zip.generateAsync({ type: 'blob' }).then((content) => {
              handleZipFile(content, 'descargas');
            });
          }
        })
        .catch((error) => {
          // TODO
          // Mostrar el modal de error
          console.log('error', error);
        })
        .finally(() => {
          handleCloseConfirmModal();
        }),
    );
  };

  const handleFileData = (fileWrapper) => {
    const fileData = fileWrapper.data;
    const filename = fileWrapper.name;
    const fileExtension = fileWrapper.extension;
    const createBlob = fileWrapper.createBlob;
    if (fileExtension === fileExtensions.PDF) {
      const file = new Blob([fileData], { type: 'data:application/pdf' });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}.${fileExtension}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    } else {
      const url = createBlob
        ? window.URL.createObjectURL(new Blob([fileData]))
        : `data:image/png;base64,${fileData}`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}.${fileExtension}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleZipFile = (fileData, filename) => {
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.zip`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  /* MAIN */
  const getResourcesOptions = () => {
    return Object.values(resourceOptionsHistory).map((resource) => {
      let resourceId = resource.id;
      let resourceLabel = resource.label;

      let resourceElement = (
        <Col xs={12} lg={2}>
          <Form.Check
            key={`${resourceId}-resource`}
            id={resourceId}
            label={resourceLabel}
            className="form-boxed"
            onChange={onResourceChecked}
          />
        </Col>
      );

      let numColumns = resource.fields.length === 1 ? 10 : 5;
      let numColumnsXS = resource.fields.length === 1 ? 12 : 12;

      let resourceOptionFields = resource.fields.map((field) => {
        let fieldId = field.id;
        let fieldLabel = field.label;
        let fieldType = field.type;
        switch (fieldId) {
          case 'empty':
            return <Col xs={numColumnsXS} lg={numColumns} />;
          default:
            return (
              <Col xs={numColumnsXS} lg={numColumns}>
                <Dropdown
                  key={`${resourceId}-${fieldId}-dropdown`}
                  id={`${resourceId}-${fieldId}`}
                >
                  <Dropdown.Toggle
                    variant="light"
                    disabled={isFieldDisabled(resourceId)}
                  >
                    <Form.Label>
                      {getResourceOptionFieldLabel(
                        resourceId,
                        fieldId,
                        fieldLabel,
                      )}
                    </Form.Label>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <SelectionMenu
                      resourceId={resourceId}
                      fieldId={fieldId}
                      fieldType={fieldType}
                      setParentSelection={setSelection}
                      parentSelection={selection}
                      options={getOptionsArray(field)}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            );
        }
      });
      return (
        <Row>
          {resourceElement}
          {resourceOptionFields}
        </Row>
      );
    });
  };

  /* COMPONENT DEFINITION */
  return (
    <>
      {getConfirmDownloadModal()}
      <Container fluid className="action-modal-container">
        <Row>
          <h2>Opciones de descarga</h2>
        </Row>
        <Row className="pen-options">
          <Col>
            <Row>
              <h4>Selección de formato</h4>
            </Row>
            {!!selection && getResourcesOptions()}
          </Col>
        </Row>
        <Row>
          <Button
            variant="secondary"
            className="center-in-row"
            disabled={downloadDisabled}
            onClick={handleShowConfirmModal}
          >
            Descargar
          </Button>
        </Row>
      </Container>
    </>
  );
}

export default DataHistoryDownloadOption;
