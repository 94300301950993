import React, { useState, useEffect, useContext, useRef, memo } from 'react';
import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Container from 'react-bootstrap/esm/Container';
import IndicatorsPage from './IndicatorsPage';
import {
  WARNING_CROSS_INDICATORS_MESSAGE,
  NUM_INDICATORS_PER_INDICATORS_PAGE,
  DISABLED_INDICATORS_MESSAGE,
  NUM_MAX_INDICATORS_REGULAR_CASE,
  NUM_MAX_INDICATORS_ERCA_CASE,
  ERCA_COLLECTION_ID,
  COLLECTION_FILTER_ID,
  REGION_FILTER_ID,
  TOPIC_FILTER_ID,
  YEAR_FILTER_ID,
} from '../../shared/constants';
import { SELECTION_SET } from '../../actions/types';
import { DataSelectionContext } from './DataSelectionContext';
import store from '../../store';

import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import PENDataService from '../../shared/PENDataService.js';


const numIndicatorsPerPage = NUM_INDICATORS_PER_INDICATORS_PAGE;

const IndicatorsCarousel = memo(
  ({
    numIndicators,
    collectionFilterSelectedValues,
    topicFilterSelectedValues,
    regionFilterSelectedValues,
    yearFilterSelectedValues,
    selectedValues,
    searchString,
  }) => {
    // console.log('[IndicatorsCarousel] is running');

    let inputSearchBar = useRef();

    // Acceso a la selección de datos
    const context = useContext(DataSelectionContext);

    const [maxIndicatorsMessage, setMaxIndicatorsMessage] = useState(null);
    const [slider, setSlider] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);


    
    const settings = {
      dots: true,
      infinite: false,
      lazyLoad: true,
      speed: 500,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: current => setCurrentPage(current),
    };
    
    useEffect(() => {
      console.log(slider);
      const pageInd =0;
      if(!!slider && selectedValues.length > 0){
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        trackPromise(
          PENDataService.getPageIndicator(
            selectedValues[0],
            [
              {
                id: COLLECTION_FILTER_ID,
                selectedValues: collectionFilterSelectedValues,
              },
              {
                id: TOPIC_FILTER_ID,
                selectedValues: topicFilterSelectedValues,
              },
              {
                id: REGION_FILTER_ID,
                selectedValues: regionFilterSelectedValues,
              },
              {
                id: YEAR_FILTER_ID,
                selectedValues: yearFilterSelectedValues,
              },
            ],
            source,
          )
            .then((result) => {
              console.log(result.PageNumber);
              slider.slickGoTo(result.PageNumber);
            })
            .catch((error) => {
              //console.log('error', error);
            }),
        );
      }
    }, [slider]);


    useEffect(() => {
      if (context.getSearchString() === null) {
        if (!!inputSearchBar.current.value) {
          inputSearchBar.current.value = '';
        }
      } else {
        // console.log('current', inputSearchBar.current.value);
        if (inputSearchBar.current.value === '') {
          inputSearchBar.current.value = context.getSearchString();
        }
      }
    }, []);

    useEffect(() => {
      // Para controlar mensaje de máximo de indicadores permitidos
      if (collectionFilterSelectedValues.length) {
        const selectedCollection = collectionFilterSelectedValues[0];
        const numMaxIndicators =
          selectedCollection === ERCA_COLLECTION_ID
            ? NUM_MAX_INDICATORS_ERCA_CASE
            : NUM_MAX_INDICATORS_REGULAR_CASE;
        setMaxIndicatorsMessage(
          `La cantidad máxima de indicadores permitidos es ${numMaxIndicators}`,
        );
      } else {
        setMaxIndicatorsMessage(null);
      }
    }, [collectionFilterSelectedValues]);

  


    const getIndicatorsPages = (numIndicators) => {
      /**
       * esto se convirtio en let porque si se dejan las paginas en 0, el slick to go no funciona
       */
      let numPages = Math.ceil(numIndicators / numIndicatorsPerPage);
      if(numPages == 0){
        numPages = 1;
      }
      return (
        <Slider ref={slider => (setSlider(slider))} className="indicators-carousel" {...settings}>{
          [...Array(numPages).keys()].map((element) => (
            <IndicatorsPage
              key={btoa(element)}
              pageInd={element}
              numIndicators={numIndicators}
              collectionFilterSelectedValues={collectionFilterSelectedValues}
              topicFilterSelectedValues={topicFilterSelectedValues}
              regionFilterSelectedValues={regionFilterSelectedValues}
              yearFilterSelectedValues={yearFilterSelectedValues}
              selectedValues={selectedValues}
              searchString={searchString}
            />))
            }
        </Slider>
      );
    };

    const[wasEmptyResults, setWasEmptyResult] = useState(false);

    const fixUserPageInSearch = ()  => {
      if(currentPage != 0 || numIndicators == 0){
        slider.slickGoTo(0);
      }
    }
    const handleSearchInputChange = (event) => {
      // console.log('event', event);
      const searchString = event.target.value.trim();
      if (!!searchString) {
        //console.log('searchString', searchString);
        //console.log(slider);
        fixUserPageInSearch()
        context.setSearchString(searchString);
      } else {
        context.setSearchString(null);
      }
      sendActionSelectionSet();
    };

    const sendActionSelectionSet = () => {
      store.dispatch({
        type: SELECTION_SET,
        payload: context.selection.getSimpleFormatObject(),
      });
    };

    const getIndicatorsSearchBar = () => {
      return (
        <div className="row indicadores-search-bar-div">
          <div className="input-group input-group-lg indicadores-search-bar-group">
            <input
              type="text"
              className="form-control indicadores-search-bar"
              aria-label="Large"
              aria-describedby="inputGroup-sizing-sm"
              placeholder="Busque por palabra clave"
              onInput={handleSearchInputChange}
              ref={inputSearchBar}
            />
          </div>
        </div>
      );
    };

    const getIndicatorsHeader = () => {
      return (
        <p>
          <span>
            <b>Indicadores</b>
          </span>
          <span className="filter-counter">
            {selectedValues.length} / {numIndicators}
          </span>
        </p>
      );
    };

    const getMaxIndicatorsMessage = () => {
      return <p>* {maxIndicatorsMessage}</p>;
    };

    const getDisabledIndicatorsMessage = () => {
      return <p>* {DISABLED_INDICATORS_MESSAGE}</p>;
    };

    const getWarningCrossIndicatorsMessage = () => {
      return <p>* {WARNING_CROSS_INDICATORS_MESSAGE}</p>; 
    }
    const getIndicatorsNotes = () => {
      return (
        <>
          {!!maxIndicatorsMessage && getMaxIndicatorsMessage()}
          {getDisabledIndicatorsMessage()}
          {getWarningCrossIndicatorsMessage()}
        </>
      );
    };

    return (
      <Container fluid className="indicators-carousel filter-header">
        {getIndicatorsHeader()}
        {getIndicatorsSearchBar()}
        {getIndicatorsPages(numIndicators)}
        {getIndicatorsNotes()} 
      </Container>
    );
  },
);

export default IndicatorsCarousel;
