import React, { forwardRef } from 'react';
import { Route } from 'react-router-dom';
import NotFound from '../../NotFound';
import { dataSelectionModes } from '../../shared/constants';

const validSessionVariables = (props) => {
  if (typeof props.location.state === 'undefined') {
    return false;
  }

  // dataSelectionMode debe estar definida

  if (!!props.location.state.dataSelectionMode) {
    const dataSelectionMode = props.location.state.dataSelectionMode;
    if (dataSelectionMode === dataSelectionModes.NEW) {
      // Si el modo es new, no es necesario que esté definida elementKey
      return true;
    } else {
      // Si el modo es edit, elementKey debe estar definida
      if (!!props.location.state.elementKey) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
};

const DataSelectorProtectedRoute = forwardRef(
  ({ component: Component, ...rest }, ref) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          //console.log('props en DataSelectorProtectedRoute', props);
          if (validSessionVariables(props)) {
            return <Component {...props} ref={ref} />;
          } else {
            return <Route component={NotFound} />;
          }
        }}
      />
    );
  },
);

export default DataSelectorProtectedRoute;
