import React, { forwardRef } from 'react';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';
import {
  dataSelectionModes,
  DATA_SELECTION_PATH,
} from '../../shared/constants';
import { useHistory } from 'react-router-dom';
import { INDICATOR_DETAILS_OPTION_OPENED } from '../../actions/types';
import store from '../../store';

/* SEARCH RESULTS PAGE COMPONENT */
const SearchResultsPage = forwardRef(({ data }, ref) => {
  const indicators = data;

  let history = useHistory();

  /**
   * Devuelve el ícono de información para mostrar en la columna de acceso a los metadatos
   */
  const infoIcon = (cell, formatterParams) => {
    //plain text value
    return `<i class="pen-info"></i>`;
  };

  const onIndicatorCellClicked = (e, cell) => {
    const row = cell.getRow();
    const rowData = row.getData();
    const indicatorId = rowData.id;
    const indicadorName = rowData.name;
    // Se hace el cambio para que no se grafique de una vez al dar click,
    // sino llevar a los filtros para terminar de escoger lo que se quiere como sucede al dar ENTER.
    console.log(indicatorId);
    console.log(row)
    //console.log('ref', ref);
    
    if (typeof ref.current !== 'undefined') {
      const searchString = ref.current.value;
      history.push({
        pathname: `/${DATA_SELECTION_PATH}`,
        state: {
          dataSelectionMode: dataSelectionModes.NEW,
          selectedValue: indicatorId
        },
      });
    }
  };

  /**
   * Envía a la información compartida la acción para desplegar los detalles del indicador
   */
  const onIndicatorDetailsClicked = (e, cell) => {
    //console.log('Indicador info was clicked !!!');
    const row = cell.getRow();
    const rowData = row.getData();
    const id = rowData.id;
    //console.log('id', id);
    store.dispatch({
      type: INDICATOR_DETAILS_OPTION_OPENED,
      payload: { id },
    });
  };

  /* Columnas de la tabla, se pasan al componente de tabulator */
  const columns = [
    {
      title: 'Indicador',
      field: 'name',
      headerSort: false,
      width: '90%',
      cellClick: onIndicatorCellClicked,
    },
    {
      formatter: infoIcon,
      hozAlign: 'center',
      cellClick: onIndicatorDetailsClicked,
    },
  ];

  /* Opciones de la tabla, se pasan al componente de tabulator */
  const options = {
    headerVisible: false,
  };

  return (
    <>
      <ReactTabulator
        data={indicators}
        columns={columns}
        tooltips={false}
        options={options}
      />
    </>
  );
});

export default SearchResultsPage;
