import Col from 'react-bootstrap/Col';
import {
  NO_RESPONSIBILITY_DISCLAIMER,
  TERMS_CONDITIONS_DOCUMENT_PATH,
} from '../../shared/constants';
import { Link } from 'react-router-dom';

function Disclaimer() {
  return (
    <Col xs={10} md={5} className="visualizer-disclaimer">
      <p>
        <b>Nota importante:</b> {NO_RESPONSIBILITY_DISCLAIMER}.{' '}
        <Link to={TERMS_CONDITIONS_DOCUMENT_PATH} target="_blank">
          Leer términos y condiciones de uso.
        </Link>
      </p>
    </Col>
  );
}

export default Disclaimer;
