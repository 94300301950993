import {
  COLLECTION_FILTER_ID,
  REGION_FILTER_ID,
  TOPIC_FILTER_ID,
  YEAR_FILTER_ID,
} from '../shared/constants';
import UserSessionDataService from '../shared/UserSessionDataService';

class Selection {
  constructor(simpleFormatObject) {
    this._collectionFilterValues = simpleFormatObject.collectionFilterValues;
    this._collectionFilterSelectedValues =
      simpleFormatObject.collectionFilterSelectedValues;

    this._topicFilterValues = simpleFormatObject.topicFilterValues;
    this._topicFilterSelectedValues =
      simpleFormatObject.topicFilterSelectedValues;

    this._regionFilterValues = simpleFormatObject.regionFilterValues;
    this._regionFilterSelectedValues =
      simpleFormatObject.regionFilterSelectedValues;

    this._yearFilterValues = simpleFormatObject.yearFilterValues;
    this._yearFilterSelectedValues =
      simpleFormatObject.yearFilterSelectedValues;

    this._indicatorsSelectedValues =
      simpleFormatObject.indicatorsSelectedValues;

    this._numIndicators = simpleFormatObject.numIndicators;

    this._searchString = simpleFormatObject.searchString;
  }

  get collectionFilterValues() {
    return this._collectionFilterValues;
  }
  get topicFilterValues() {
    return this._topicFilterValues;
  }
  get regionFilterValues() {
    return this._regionFilterValues;
  }
  get yearFilterValues() {
    return this._yearFilterValues;
  }
  get collectionFilterSelectedValues() {
    return this._collectionFilterSelectedValues;
  }
  get topicFilterSelectedValues() {
    return this._topicFilterSelectedValues;
  }
  get regionFilterSelectedValues() {
    return this._regionFilterSelectedValues;
  }
  get yearFilterSelectedValues() {
    return this._yearFilterSelectedValues;
  }
  get numIndicators() {
    return this._numIndicators;
  }
  get searchString() {
    return this._searchString;
  }
  get indicatorsSelectedValues() {
    return this._indicatorsSelectedValues;
  }

  set collectionFilterValues(collectionFilterValues) {
    this._collectionFilterValues = collectionFilterValues;
  }
  set topicFilterValues(topicFilterValues) {
    this._topicFilterValues = topicFilterValues;
  }
  set regionFilterValues(regionFilterValues) {
    this._regionFilterValues = regionFilterValues;
  }
  set yearFilterValues(yearFilterValues) {
    this._yearFilterValues = yearFilterValues;
  }
  set collectionFilterSelectedValues(collectionFilterSelectedValues) {
    this._collectionFilterSelectedValues = collectionFilterSelectedValues;
  }
  set topicFilterSelectedValues(topicFilterSelectedValues) {
    this._topicFilterSelectedValues = topicFilterSelectedValues;
  }
  set regionFilterSelectedValues(regionFilterSelectedValues) {
    this._regionFilterSelectedValues = regionFilterSelectedValues;
  }
  set yearFilterSelectedValues(yearFilterSelectedValues) {
    this._yearFilterSelectedValues = yearFilterSelectedValues;
  }
  set numIndicators(numIndicators) {
    this._numIndicators = numIndicators;
  }
  set searchString(searchString) {
    this._searchString = searchString;
  }
  set indicatorsSelectedValues(indicatorsSelectedValues) {
    this._indicatorsSelectedValues = indicatorsSelectedValues;
  }

  selectIndicator = (indicatorId) => {
    this._indicatorsSelectedValues.push(indicatorId);
  };

  deselectIndicator = (indicatorId) => {
    this._indicatorsSelectedValues = [...this._indicatorsSelectedValues].filter(
      (indicator) => indicator !== indicatorId,
    );
  };

  getCopy() {
    return new Selection(this.getSimpleFormatObject());
  }

  getSimpleFormatObject() {
    return {
      collectionFilterValues: [...this._collectionFilterValues],
      collectionFilterSelectedValues: [...this._collectionFilterSelectedValues],

      topicFilterValues: [...this._topicFilterValues],
      topicFilterSelectedValues: [...this._topicFilterSelectedValues],

      regionFilterValues: [...this._regionFilterValues],
      regionFilterSelectedValues: [...this._regionFilterSelectedValues],

      yearFilterValues: [...this._yearFilterValues],
      yearFilterSelectedValues: [...this._yearFilterSelectedValues],

      indicatorsSelectedValues: [...this._indicatorsSelectedValues],

      numIndicators: this._numIndicators,

      searchString: this._searchString,
    };
  }

  getIndicatorsSelectedValues() {
    return this._indicatorsSelectedValues;
  }

  /* Filters */
  getFilterValues(id) {
    switch (id) {
      case COLLECTION_FILTER_ID:
        return this._collectionFilterValues;
      case TOPIC_FILTER_ID:
        return this._topicFilterValues;
      case REGION_FILTER_ID:
        return this._regionFilterValues;
      case YEAR_FILTER_ID:
        return this._yearFilterValues;
      default:
        return [];
    }
  }

  getFilterSelectedValues(id) {
    switch (id) {
      case COLLECTION_FILTER_ID:
        return this._collectionFilterSelectedValues;
      case TOPIC_FILTER_ID:
        return this._topicFilterSelectedValues;
      case REGION_FILTER_ID:
        return this._regionFilterSelectedValues;
      case YEAR_FILTER_ID:
        return this._yearFilterSelectedValues;
      default:
        return [];
    }
  }

  setFilterValues(id, values) {
    // console.log('setFilterValues !!!');
    // console.log('id', id);
    switch (id) {
      case COLLECTION_FILTER_ID:
        this._collectionFilterValues = values;
        break;
      case TOPIC_FILTER_ID:
        this._topicFilterValues = values;
        break;
      case REGION_FILTER_ID:
        this._regionFilterValues = values;
        break;
      case YEAR_FILTER_ID:
        this._yearFilterValues = values;
        break;
      default:
        break;
    }
  }

  selectFilterValue(id, filterValue) {
    switch (id) {
      case COLLECTION_FILTER_ID:
        this._collectionFilterSelectedValues = [filterValue];
        break;
      case TOPIC_FILTER_ID:
        this._topicFilterSelectedValues.push(filterValue);
        break;
      case REGION_FILTER_ID:
        this._regionFilterSelectedValues.push(filterValue);
        break;
      case YEAR_FILTER_ID:
        this._yearFilterSelectedValues.push(filterValue);
        break;
      default:
        break;
    }
  }

  deselectFilterValue(id, filterValue) {
    const filterFunction = (element) => element !== filterValue;

    switch (id) {
      case COLLECTION_FILTER_ID:
        this._collectionFilterSelectedValues = [
          ...this._collectionFilterSelectedValues,
        ].filter(filterFunction);
        break;
      case TOPIC_FILTER_ID:
        this._topicFilterSelectedValues = [
          ...this._topicFilterSelectedValues,
        ].filter(filterFunction);
        break;
      case REGION_FILTER_ID:
        this._regionFilterSelectedValues = [
          ...this._regionFilterSelectedValues,
        ].filter(filterFunction);
        break;
      case YEAR_FILTER_ID:
        this._yearFilterSelectedValues = [
          ...this._yearFilterSelectedValues,
        ].filter(filterFunction);
        break;
      default:
        break;
    }
  }

  selectAllFilterValues(id) {
    switch (id) {
      case YEAR_FILTER_ID:
        this._yearFilterSelectedValues = this._yearFilterValues.reduce(
          (accum, curr) => {
            if (curr.id !== `${YEAR_FILTER_ID}-all`) {
              accum.push(curr.id);
            }
            return accum;
          },
          [],
        );
        break;
      case COLLECTION_FILTER_ID:
      case TOPIC_FILTER_ID:
      case REGION_FILTER_ID:
      default:
        break;
    }
  }

  deselectAllFilterValues(id) {
    switch (id) {
      case COLLECTION_FILTER_ID:
        this._collectionFilterSelectedValues = [];
        break;
      case TOPIC_FILTER_ID:
        this._topicFilterSelectedValues = [];
        break;
      case REGION_FILTER_ID:
        this._regionFilterSelectedValues = [];
        break;
      case YEAR_FILTER_ID:
        this._yearFilterSelectedValues = [];
        break;
      default:
        break;
    }
  }

  oneCollectionSelected() {
    return this._collectionFilterSelectedValues.length === 1;
  }

  atLeastOneIndicatorSelected() {
    return this._indicatorsSelectedValues.length >= 1;
  }

  canSelectIndicator() {
    // Comprobación de que la selección de este nuevo indicador no excede el límite
    return UserSessionDataService.checkCanAddIndicator(
      this._collectionFilterSelectedValues,
      this._indicatorsSelectedValues,
    );
  }

  setNumIndicators(numIndicators) {
    this._numIndicators = numIndicators;
  }
}

export default Selection;
