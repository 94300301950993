import React, { useState, useEffect, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {
  COLLECTION_FILTER_ID,
  DOWNLOAD_CONFIRMATION_TITLE,
  DOWNLOAD_CONFIRMATION_MESSAGE,
  fileExtensions,
  COLLECTION_FILTER_SHORT_LABEL,
} from './shared/constants';
import PENDataService from './shared/PENDataService.js';
import axios from 'axios';
import jszip from 'jszip';
import SelectionMenu from './components/Data/SelectionMenu';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import GoogleAnalytics from './shared/GoogleAnalytics.js';
/* DATA DOWNLOAD COMPONENT */
const DataDownload = forwardRef(({}, ref) => {
  useEffect(()=>{
    GoogleAnalytics.sendInformation(window.location.pathname + window.location.search);
  });
  const [collections, setCollections] = useState([]);
  const [downloadDisabled, setDownloadDisabled] = useState(true);
  const [showConfirmDownloadModal, setShowConfirmDownloadModal] = useState(
    false,
  );
  const [resources, setResources] = useState(null);
  const [selection, setSelection] = useState(null);

  /* DATA REQUESTS */

  const downloadCollectionPromise = (collection, topics, source) => {
    return new Promise((resolve, reject) => {
      PENDataService.downloadCollectionFile(collection, topics, source)
        .then((result) => {
          // console.log('result', result);
          const fileData = result.data;

          // Para que el nombre sea de la forma compendio_0, compendio_1, ...
          const filename = `${COLLECTION_FILTER_SHORT_LABEL.toLowerCase()}_${collection}`;

          const fileExtension = fileExtensions.SPREADSHEET;

          resolve({
            data: fileData,
            name: filename,
            extension: fileExtension,
          });
        })
        .catch((error) => {
          console.log('error', error);
          reject();
        });
    });
  };

  /* HOOKS */

  /**
   * Efecto para hacer scroll al inicio de la página siempre que se entre al componente
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Efecto para la consulta de colecciones
   */
  useEffect(() => {
    let mounted = true;

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    PENDataService.getCollectionValues(source, true)
      .then((result) => {
        if (mounted) {
          setCollections(result);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });

    return function cleanup() {
      mounted = false;
      source.cancel('axios request cancelled');
    };
  }, []);

  /**
   * Efectos para manejar los objetos de colecciones y selección
   */
  useEffect(() => {
    if (collections.length) {
      const collectionsResources = collections.reduce(
        (resourcesAccum, currCollection) => {
          const collectionId = `${COLLECTION_FILTER_ID}-${currCollection.id}`;
          const collectionLabel = currCollection.name;

          let options = [
            {
              id: `${collectionId}-all`,
              label: 'Todos',
            },
          ];

          options = options.concat(
            currCollection.topics.map((topic) => {
              return {
                id: `${collectionId}-topic-${topic.id}`,
                label: topic.name,
              };
            }),
          );

          const topicsField = {
            id: 'topic',
            label: 'Seleccione los temas',
            type: 'checkbox',
            options,
          };

          const fields = [topicsField];

          const resourceObject = {
            id: collectionId,
            label: collectionLabel,
            fields,
          };

          resourcesAccum[collectionId] = resourceObject;
          return resourcesAccum;
        },
        {},
      );
      setResources(collectionsResources);
    }
  }, [collections]);

  useEffect(() => {
    if (!!resources) {
      const selection = Object.values(resources).reduce(
        (selectionAccum, currResource) => {
          const fields = currResource.fields.reduce(
            (fieldsAccum, currField) => {
              let optionsArray = currField.options;
              const options = optionsArray.reduce(
                (optionsAccum, currOption) => {
                  optionsAccum[currOption.id] = {
                    checked: false,
                    label: currOption.label,
                  };
                  return optionsAccum;
                },
                {},
              );

              fieldsAccum[currField.id] = options;

              return fieldsAccum;
            },
            {},
          );
          selectionAccum[currResource.id] = {
            checked: false,
            fields,
          };
          return selectionAccum;
        },
        {},
      );
      setSelection(selection);
    }
  }, [resources]);

  useEffect(() => {
    const getSelectedCollectionsArray = () => {
      return Object.keys(selection).reduce((resourcesAccum, currResourceId) => {
        if (selection[currResourceId]['checked']) {
          const collectionActualId = parseInt(
            currResourceId.replace(`${COLLECTION_FILTER_ID}-`, ''),
          );
          resourcesAccum.push(collectionActualId);
        }

        return resourcesAccum;
      }, []);
    };

    if (!!selection) {
      const selectedCollections = getSelectedCollectionsArray();
      const disable = selectedCollections.length === 0;
      setDownloadDisabled(disable);
    }
  }, [selection]);

  /* CONFIRMATION */
  const handleCloseConfirmModal = () => setShowConfirmDownloadModal(false);
  const handleShowConfirmModal = () => setShowConfirmDownloadModal(true);

  const getConfirmDownloadModal = () => {
    return (
      <Modal
        className="modal-indicator-details alert-modal"
        show={showConfirmDownloadModal}
        onHide={handleCloseConfirmModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h2>{DOWNLOAD_CONFIRMATION_TITLE}</h2>
          <p className="text-center">{DOWNLOAD_CONFIRMATION_MESSAGE}</p>
          <Row>
            <Button
              variant="secondary"
              onClick={onDownloadClicked}
              className="center-in-row"
            >
              Descargar
            </Button>
          </Row>
          <Row>
            <Button
              variant="link-like"
              onClick={handleCloseConfirmModal}
              className="center-in-row"
            >
              cancelar
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  /* EVENTS HANDLERS */

  /**
   * Handler para el click del botón de exportar
   */
  const onDownloadClicked = (event) => {
    const selectedCollections = Object.keys(selection).reduce(
      (resourcesAccum, currResourceId) => {
        if (selection[currResourceId]['checked']) {
          const collectionActualId = parseInt(
            currResourceId.replace(`${COLLECTION_FILTER_ID}-`, ''),
          );
          resourcesAccum.push(collectionActualId);
        }

        return resourcesAccum;
      },
      [],
    );

    executeDownloads(selectedCollections);
  };

  const onResourceChecked = (event) => {
    const resourceId = event.target.id;

    const newCheckedValue = !selection[resourceId]['checked'];

    const clearedResourceSelection = Object.keys(
      selection[resourceId]['fields'],
    ).reduce((fieldsAccum, currFieldId) => {
      const fieldSelection = Object.keys(
        selection[resourceId]['fields'][currFieldId],
      ).reduce((optionsAccum, currOptionId) => {
        optionsAccum[currOptionId] = {
          ...selection[resourceId]['fields'][currFieldId][currOptionId],
          checked: false,
        };
        return optionsAccum;
      }, {});
      fieldsAccum[currFieldId] = fieldSelection;
      return fieldsAccum;
    }, {});

    setSelection({
      ...selection,
      [resourceId]: {
        ...selection[resourceId],
        checked: newCheckedValue,
        fields: clearedResourceSelection,
      },
    });
  };

  const isFieldDisabled = (resourceId) => {
    return !selection[resourceId]['checked'];
  };

  const getSelectedCollectionsAndTopics = () => {
    return Object.keys(selection).reduce((resourcesAccum, currResourceId) => {
      if (selection[currResourceId]['checked']) {
        const collectionActualId = parseInt(
          currResourceId.replace(`${COLLECTION_FILTER_ID}-`, ''),
        );

        const selectedTopicsForCollection = Object.keys(
          selection[currResourceId]['fields'],
        ).reduce((fieldsAccum, currFieldId) => {
          const fieldSelection = Object.keys(
            selection[currResourceId]['fields'][currFieldId],
          ).reduce((optionsAccum, currOptionId) => {
            if (currOptionId !== `${currResourceId}-all`) {
              const topicActualId = parseInt(
                currOptionId.replace(`${currResourceId}-topic-`, ''),
              );

              // Ignorar la opción de todos y mandar array vacío
              if (
                selection[currResourceId]['fields'][currFieldId][currOptionId][
                  'checked'
                ]
              ) {
                optionsAccum.push(topicActualId);
              }
            }
            return optionsAccum;
          }, []);
          fieldsAccum = fieldsAccum.concat(fieldSelection);
          return fieldsAccum;
        }, []);

        resourcesAccum.push([collectionActualId, selectedTopicsForCollection]);
      }

      return resourcesAccum;
    }, []);
  };

  const onDownloadClickedTest = () => {
    const selectedCollections = getSelectedCollectionsAndTopics();
    // console.log('selectedCollections', selectedCollections);
    executeDownloads(selectedCollections);
  };

  /* DOWNLOAD OPERATIONS */

  const executeDownloads = (selectedCollectionsAndTopics) => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const downloadsPromises = selectedCollectionsAndTopics.map(
      (collectionAndTopics) => {
        const collection = collectionAndTopics[0];
        const topics = collectionAndTopics[1];
        return downloadCollectionPromise(collection, topics, source);
      },
    );

    Promise.all(downloadsPromises)
      .then((results) => {
        if (results.length === 1) {
          const fileWrapper = results[0];
          handleFileData(fileWrapper);
        } else {
          const zip = new jszip();
          let fileData;
          let filename;
          let fileExtension;
          results.forEach((fileWrapper) => {
            fileData = fileWrapper.data;
            filename = fileWrapper.name;
            fileExtension = fileWrapper.extension;
            zip.file(`${filename}.${fileExtension}`, fileData);
          });
          zip.generateAsync({ type: 'blob' }).then((content) => {
            handleZipFile(content, 'compendios');
          });
        }
      })
      .catch((error) => {
        // TODO
        // Mostrar el modal de error
        console.log('error', error);
      })
      .finally(() => {
        handleCloseConfirmModal();
      });
  };

  const handleFileData = (fileWrapper) => {
    const fileData = fileWrapper.data;
    const filename = fileWrapper.name;
    const fileExtension = fileWrapper.extension;

    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.${fileExtension}`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const handleZipFile = (fileData, filename) => {
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.zip`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const getResourceOptionFieldLabel = (resourceId, fieldId, defaultValue) => {
    const selectedValues = Object.values(
      selection[resourceId]['fields'][fieldId],
    ).reduce((valuesAccum, currOption) => {
      if (currOption['checked']) {
        valuesAccum.push(currOption['label']);
      }
      return valuesAccum;
    }, []);

    return selectedValues.length ? selectedValues.join(', ') : defaultValue;
  };

  const getResourcesOptions = () => {
    return Object.values(resources).map((resource) => {
      let resourceId = resource.id;
      let resourceLabel = resource.label;

      let resourceElement = (
        <Col xs={12} md={6}>
          <Form.Check
            key={`${resourceId}-resource`}
            id={resourceId}
            label={resourceLabel}
            className="form-boxed"
            onChange={onResourceChecked}
          />
        </Col>
      );

      let resourceOptionFields = resource.fields.map((field) => {
        let fieldId = field.id;
        let fieldLabel = field.label;
        let fieldType = field.type;
        return (
          <Col xs={12} md={6}>
            <Dropdown
              key={`${resourceId}-${fieldId}-dropdown`}
              id={`${resourceId}-${fieldId}`}
            >
              <Dropdown.Toggle
                variant="light"
                disabled={isFieldDisabled(resourceId)}
              >
                <Form.Label>
                  {getResourceOptionFieldLabel(resourceId, fieldId, fieldLabel)}
                </Form.Label>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <SelectionMenu
                  resourceId={resourceId}
                  fieldId={fieldId}
                  fieldType={fieldType}
                  setParentSelection={setSelection}
                  parentSelection={selection}
                  options={field.options}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        );
      });
      return (
        <Row>
          {resourceElement}
          {resourceOptionFields}
        </Row>
      );
    });
  };

  /* COMPONENT DEFINITION */
  return (
    <>
      {getConfirmDownloadModal()}
      <div className="content" ref={ref}>
        <Row className="mb-4">
          <Col>
            <Link to="/" className="volver-link">
              Volver
            </Link>
          </Col>
        </Row>
        <Row className="row-info-container">
          <h2>Compendios estadísticos</h2>
        </Row>
        <Row className="pen-options data-options">
          <Col>{!!selection && getResourcesOptions()}</Col>
        </Row>
        <br />
        <Row className="mb-4">
          <Button
            className="center-in-row"
            disabled={downloadDisabled}
            // onClick={handleShowConfirmModal}
            onClick={onDownloadClickedTest}
          >
            Descargar
          </Button>
        </Row>
      </div>
    </>
  );
});

export default DataDownload;
