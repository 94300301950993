import { ELEMENT_TYPE_KEY } from '../shared/constants';
const { v4: uuidv4 } = require('uuid');

class StoryElement {
  constructor(dbElementObject) {
    this._key = 'key' in dbElementObject ? dbElementObject.key : uuidv4();
    this._type = null;
    this._version = 'version' in dbElementObject ? dbElementObject.version : 1;
  }

  get key() {
    return this._key;
  }

  get type() {
    return this._type;
  }

  get version() {
    return this._version;
  }

  updateVersion() {
    this._version += 1;
  }

  getDbObject() {
    return {
      key: this._key,
      [ELEMENT_TYPE_KEY]: this._type,
      version: this._version,
    };
  }
}

export default StoryElement;
